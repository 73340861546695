/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { React, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Button, Chip, Divider, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { useForm, useWatch } from 'react-hook-form';
import moment from 'moment';

import DateSelection from '../DatePicker/DateSelection';

import { Icons } from '../../../Constants/Icons';
import {
  HeadingContainer,
  Title,
  ListView,
  ChipsContainer,
  Chips,
  TabDivider,
  DatePickerWrapper,
} from './GoogleMapChips.Style';
import theme from '../../../Themes/theme';

const GoogleMapChips = ({ chipsData, selectedChip, error, loading, applyFilter, fetchData, setSelectedDate }) => {
  const { t } = useTranslation(['homePage', 'calendar']);
  const [isTablet, setIsTablet] = useState(false);

  const useStyles = makeStyles(() => ({
    loadingItem: {
      margin: '0 0.3125rem',
      width: '7.5rem',
      height: '2.5625rem',
      flexShrink: '0',
      borderRadius: '1rem',
      background: 'rgba(250, 217, 179, 0.3)',
    },
  }));
  const classes = useStyles();

  const { control } = useForm({
    defaultValues: {
      specificDate: moment().format('YYYY-MM-DD'),
    },
  });

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  const checkScreenSize = useCallback(() => {
    setIsTablet(window.innerWidth <= 1024);
  }, []);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderChip = (chip) => {
    const { label = '', variant, borderColor, textColor, clickColor, hoverColor, icon, type, count = 0 } = chip;

    const isClickable = !loading && !error && count > 0;
    const onClickHandler = isClickable ? () => applyFilter(chip.type || 'noJob') : null;

    let title = label;
    if (typeof count === 'number' && typeof label === 'string') {
      title = `${count} ${label}`;
    }

    return (
      <Chip
        key={uuidv4()}
        label={title}
        variant={variant}
        onClick={onClickHandler}
        sx={{
          fontFamily: theme.typography.fontFamily,
          borderColor: borderColor,
          color: textColor,
          display: 'flex',
          backgroundColor: selectedChip === type ? clickColor : 'inherit',
          pointerEvents: !isClickable && 'none',
          height: '2.5rem',
          lineHeight: '1.375rem',
          letterSpacing: '0.015625rem',
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.fontWeightLight,
          borderRadius: '6.25rem',
          padding: '0 0.75rem',
          cursor: !isClickable && 'not-allowed',
          opacity: !isClickable ? 0.5 : 1,
          '&:hover': {
            backgroundColor: selectedChip === type ? clickColor : hoverColor,
          },
          '&.MuiChip-clickable:hover': {
            backgroundColor: selectedChip === type ? clickColor : hoverColor,
          },
          '&.MuiChip-label': {
            padding: '0 0.25rem !important',
          },
        }}
        icon={icon || null}
      />
    );
  };

  useEffect(() => {
    const formattedDate = new Date(specificDate).toISOString().split('T')[0];
    fetchData(formattedDate);
    setSelectedDate(specificDate);
  }, [specificDate]);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [checkScreenSize]);

  return (
    <>
      <HeadingContainer>
        <Title>{t('homePage:jobOverView')}</Title>
        {!isTablet && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
              <Link to="/upcoming-shifts" style={{ textDecoration: 'none' }}>
                <Button sx={{ display: 'flex', flexDirection: 'row', padding: 0, textDecoration: 'none' }}>
                  <ListView>{t('homePage:upcomingShifts')}</ListView>
                  <img src={Icons.ArrowForwardIcon} sx={{ paddingLeft: '0.5rem' }} />
                </Button>
              </Link>
              <Link state={{ selectedDate: specificDate }} to="/list-view" style={{ textDecoration: 'none' }}>
                <Button sx={{ display: 'flex', flexDirection: 'row', padding: 0, textDecoration: 'none' }}>
                  <ListView>{t('homePage:listView')}</ListView>
                  <img src={Icons.ArrowForwardIcon} alt={t('altTexts:rightArrow')} sx={{ paddingLeft: '0.5rem' }} />
                </Button>
              </Link>
            </Box>
          </>
        )}
        {isTablet && (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <DatePickerWrapper
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& .MuiInputBase-input': {
                    padding: '0.5625rem 0 0.5625rem 1rem',
                    fontSize: theme.typography.h5.fontSize,
                    lineHeight: '1.375rem',
                    color: theme.palette.primary.dark,
                  },
                },
              }}>
              <DateSelection
                requiredText="Date is mandatory"
                control={control}
                fieldName={'specificDate'}
                label={t('calendar:selectDate')}
                isExcludePastDate={false}
                isExcludeFutureDate={false}
                textFieldStyles={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                      borderWidth: 1,
                      borderColor: theme.palette.primary.dark,
                      borderRadius: '1rem',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: theme.palette.primary.dark,
                    '&.Mui-focused': {
                      color: theme.palette.primary.dark,
                    },
                  },
                }}
              />
            </DatePickerWrapper>
            <TabDivider></TabDivider>
            <Link component={Link} state={{ selectedDate: specificDate }} to="/list-view">
              <Button sx={{ display: 'flex', flexDirection: 'row', padding: 0, textDecoration: 'none' }}>
                <ListView>{t('homePage:listView')}</ListView>
                <img src={Icons.ArrowForwardIcon} alt={t('altTexts:rightArrow')} sx={{ paddingLeft: '0.5rem' }} />
              </Button>
            </Link>
          </Box>
        )}
      </HeadingContainer>

      <Divider sx={{ margin: '0 1.5rem' }}></Divider>

      <ChipsContainer
        sx={{
          gap: '0.5rem',
        }}>
        <Chips>
          {loading &&
            chipsData.map(() => {
              return <Skeleton key={uuidv4()} variant="circle" className={classes.loadingItem} />;
            })}
          {!loading &&
            chipsData.map((chip) => {
              return <div key={uuidv4()}>{renderChip(chip)}</div>;
            })}
        </Chips>
        {!isTablet && (
          <DatePickerWrapper
            sx={{
              '& .MuiOutlinedInput-root': {
                '& .MuiInputBase-input': {
                  padding: '0.5625rem 0 0.5625rem 1rem',
                  fontSize: theme.typography.h5.fontSize,
                  lineHeight: '1.375rem',
                  color: theme.palette.primary.dark,
                },
              },
            }}>
            <DateSelection
              requiredText="Date is mandatory"
              control={control}
              fieldName={'specificDate'}
              label={t('calendar:selectDate')}
              isExcludePastDate={false}
              isExcludeFutureDate={false}
              textFieldStyles={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                    borderWidth: 1,
                    borderColor: theme.palette.primary.dark,
                    borderRadius: '1rem',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.primary.dark,
                  '&.Mui-focused': {
                    color: theme.palette.primary.dark,
                  },
                },
              }}
            />
          </DatePickerWrapper>
        )}
      </ChipsContainer>
    </>
  );
};

export default GoogleMapChips;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useState } from 'react';
import { Select, CircularProgress, Box } from '@mui/material';

import { ICustomDropDownProps } from '../Graphs/Graphs.Model';
import { Labels } from '../GraphContainer/GraphContainer';

import { Icons } from '../../../Constants/Icons';
import theme from '../../../Themes/theme';

import { Options } from '../Dropdown/Dropdown.styles';
import { FilterField, SelectInput } from '../GraphContainer/GraphContainer.styles';
import { JobsTitle } from '../../../../Modules/Jobs/Components/IssuesRaised/JobsIssuesRaised.styles';

const CustomDropDown: React.FC<ICustomDropDownProps> = ({
  options,
  selectedValue,
  onChange,
  isLoading,
  title,
  defaultOption,
}) => {
  const [isValueOpen, setValueOpen] = useState(false);

  const toggleValue = () => setValueOpen(!isValueOpen);

  return (
    <FilterField>
      <JobsTitle sx={{ fontSize: theme.typography.h4?.fontSize }}>{title}</JobsTitle>
      <Box sx={{ minWidth: 120 }}>
        {isLoading ? (
          <CircularProgress size={24} sx={{ color: theme.palette.primary.dark }} />
        ) : (
          <Box onClick={toggleValue}>
            <Select
              value={selectedValue || defaultOption}
              onChange={onChange}
              open={isValueOpen}
              onClose={toggleValue}
              onOpen={toggleValue}
              input={
                <SelectInput
                  sx={{
                    '& .MuiSelect-select': { width: '6.25rem' },
                  }}
                />
              }
              IconComponent={() => (
                <img
                  src={Icons.DropdownIcon}
                  alt="dropdown-icon"
                  style={{
                    transform: isValueOpen ? Labels.Rotate : 'none',
                    position: 'absolute',
                    right: '0.75rem',
                  }}
                />
              )}
              displayEmpty>
              {options?.map((option: { label: string; value: string }) => (
                <Options key={option.value} value={option.value}>
                  {option.label}
                </Options>
              ))}
            </Select>
          </Box>
        )}
      </Box>
    </FilterField>
  );
};

export default CustomDropDown;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const DropDownWrapper = styled('div')(() => ({
  display: 'flex',
  columnGap: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    rowGap: '1.5rem',
    flexDirection: 'column',
  },
}));

export const TitleWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '2.75rem',
  alignItems: 'center',
}));

export const JobsTitle = styled('h2')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: '0.009375rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.25rem',
  },
}));

// export const DropDownWrapper = styled('div')(() => ({}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';
import { associateProfile } from '../../graphql/queries';

import { AuthHeader, AWSAuthMode } from '../Constants/App';
import { addPunchInOutBySupervisor } from '../../graphql/mutations';
import { PunchCardInput } from '../../API';
import { IGraphQLError, IGraphQLResponse } from '../Models';
import { getWebInfo } from '../Utilities/utils';
import { AuthenticationService } from './AuthenticationService';

export default class AssociateApiService {
  static async getAssociateProfile(associateId: string | null | undefined) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const associateProfileDetails: IGraphQLResponse = await API.graphql(
        {
          query: associateProfile,
          variables: { id: associateId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: associateProfileDetails?.data?.associateProfile ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  // static async getTasks(taskDetails: ITaskParameters, isMocked: boolean) {
  //   try {
  //     const variables: ITaskParameters = {};
  //     if (taskDetails.workOrderId) variables.workOrderId = taskDetails.workOrderId;
  //     if (taskDetails.fromDate) variables.fromDate = taskDetails.fromDate;
  //     if (taskDetails.toDate) variables.toDate = taskDetails.toDate;
  //     if (taskDetails.Status) variables.Status = taskDetails.Status;
  //     if (taskDetails.associateId) variables.associateId = taskDetails.associateId;

  //     const associateTasks: IGraphQLResponse = await API.graphql({
  //       query: tasks,
  //       variables: {},
  //       authMode: AWSAuthMode.COGNITO,
  //     });
  //     const associateTasksData = associateTasks.data.tasks ?? [];
  //     return { data: isMocked ? [] : associateTasksData, errors: [] };
  //   } catch (e: unknown) {
  //     const apiError: any = e as any;
  //     return { data: [], errors: apiError.errors };
  //   }
  // }

  static async addPunchInOutBySupervisor(workDayId: string, punchCardInput: PunchCardInput[]) {
    try {
      const punchTimeResponse: IGraphQLResponse = await API.graphql({
        query: addPunchInOutBySupervisor,
        variables: {
          workDayId,
          createPunchCard: punchCardInput,
        },
        authMode: AWSAuthMode.COGNITO,
      });
      return { data: punchTimeResponse.data?.addPunchInOutBySupervisor ?? [], errors: [] };
    } catch (e: unknown) {
      const apiError: IGraphQLError = e as IGraphQLError;
      return { data: [], errors: apiError.errors };
    }
  }
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useState } from 'react';
import {
  BidCardsContainer,
  BidCountContainer,
  BidCountText,
  BidFinalQuoteContainer,
  BidFinalQuoteText,
  BidFinalQuoteValue,
  DetailsContainer,
  DetailsHeadingContainer,
  FacilityDetailsContainer,
  HeadingText,
  ImageContainer,
  NoBidsFoundText,
  TitleImgWrapper,
  buttonStyles,
} from './OneTimeJobDetails.Style';
import { Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SearchFieldContainer, SearchInput } from '../../../../Shared/Components/Layout/styles';
import SearchIcon from '../../../../assets/images/ic_search.svg';
import theme from '../../../../Shared/Themes/theme';
import { useTranslation } from 'react-i18next';
import { JobStatuses, TagNames } from '../../../../Shared/Constants/App';
import { Icons } from '../../../../Shared/Constants/Icons';
import { IBidList, IJobItem, IJobServiceItem } from './OneTimeJobDetails';

type BidDetailsProps = {
  jobData: IJobItem;
  bidsList: IBidList[];
};

const BidDetails = ({ jobData, bidsList }: BidDetailsProps): React.JSX.Element => {
  const { divider } = buttonStyles;
  const navigate = useNavigate();
  const [filteredBidList, setFilteredBidList] = useState<IBidList[]>(bidsList);
  const { t } = useTranslation(['noData', 'altTexts']);
  const selectedBid = bidsList.find((bidData: IBidList) => bidData.dataStatus === ('C' || 'R'));

  const handleSearch = (value: string): void => {
    if (value && value.trim()) {
      const filteredList = bidsList.filter((bidItem: IBidList) =>
        bidItem.bidName.toLowerCase().includes(value.trim().toLowerCase())
      );
      setFilteredBidList(filteredList);
    } else {
      setFilteredBidList(bidsList);
    }
  };

  const handleViewDetails = (index: number): void => {
    if (jobData?.jobStatus === JobStatuses.PendingCustomerApproval) {
      navigate('/bid-review', {
        state: {
          bidDetails: {
            ...jobData,
            facilityType: jobData?.facilityType,
            subJobServices: jobData.subJobServices.map((service: IJobServiceItem) => {
              return {
                ...service,
                totalJobEstimation: filteredBidList[index]?.finalQuote?.toFixed(2),
              };
            }),
          },
          isCreate: false,
          isEditable: false,
        },
      });
    } else {
      navigate('/create-bid', {
        state: {
          jobDetails: {
            ...jobData,
            subJobBids: [filteredBidList[index]],
            serviceSchedule: 'OneTime',
            bidId: jobData.jobBids[0].bidId,
            subJobBidId: filteredBidList[index].bidId,
            profitMargin: jobData.jobBids[0].profitMargin,
            isFlatMargin: jobData.jobBids[0].isFlatMargin,
            hasBidSelected: selectedBid ? true : false,
          },
          jobId: jobData.jobId,
          customerId: jobData.customerId,
          isJobCreation: false,
          tag: TagNames.RejectOrRequestRevision,
        },
      });
    }
  };

  const handleConversationButtonClick = (index: number): void => {
    const queryParams = new URLSearchParams({
      jobId: jobData?.jobId,
    });
    navigate(`/conversations?${queryParams.toString()}`, {
      state: {
        ...jobData,
        subJobBids: [filteredBidList[index]],
        vendorId: filteredBidList[index].vendorId,
        serviceSchedule: 'OneTime',
        bidId: jobData.jobBids[0].bidId,
        subJobBidId: filteredBidList[index].bidId,
        subJobBidVersion: filteredBidList[index].bidVersion,
        profitMargin: jobData.jobBids[0].profitMargin,
        isFlatMargin: jobData.jobBids[0].isFlatMargin,
        hasBidSelected: selectedBid ? true : false,
        jobId: jobData.jobId,
        customerId: jobData.customerId,
        isJobCreation: false,
        tag: 'BID_RELATED',
      },
    });
  };

  return (
    <>
      <BidCountContainer>
        <BidCountText>
          {bidsList?.length} {bidsList?.length <= 1 ? t('dashboard:totalBid') : t('dashboard:totalBids')}
        </BidCountText>
        <div style={{ width: '100%' }}>
          <Divider sx={divider} />
        </div>
      </BidCountContainer>
      {bidsList?.length > 1 && (
        <SearchFieldContainer sx={{ marginBottom: '1.5em' }}>
          <SearchInput
            type="text"
            placeholder={t('oneTimeJob:search')}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <img src={SearchIcon} alt="Search" style={{ width: '1.5rem', height: '1.5rem' }} />
        </SearchFieldContainer>
      )}
      {filteredBidList?.length > 0 ? (
        <BidCardsContainer>
          {filteredBidList.map((bidItem: IBidList, index: number) => (
            <FacilityDetailsContainer sx={{ width: 'fit-content' }} key={index}>
              <DetailsContainer>
                <TitleImgWrapper>
                  <ImageContainer
                    sx={{
                      textAlign: 'center',
                      backgroundColor: '#FFE5A0',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '22px',
                    }}>
                    <div style={{ paddingTop: '28%' }}>{bidItem.bidName[0]}</div>
                  </ImageContainer>
                  <DetailsHeadingContainer>
                    <HeadingText>{bidItem.bidName}</HeadingText>
                  </DetailsHeadingContainer>
                </TitleImgWrapper>
              </DetailsContainer>
              <BidFinalQuoteContainer>
                <BidFinalQuoteText>{t('vendor:finalQuote')}</BidFinalQuoteText>
                <BidFinalQuoteValue>${bidItem?.finalQuote?.toFixed(2)}</BidFinalQuoteValue>
              </BidFinalQuoteContainer>
              <Divider sx={divider} />
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button
                  sx={{ color: theme.palette.primary.dark, textTransform: 'capitalize', fontSize: '1rem' }}
                  onClick={() => handleViewDetails(index)}>
                  {t('altTexts:viewDetails')}
                </Button>
                <Button onClick={() => handleConversationButtonClick(index)}>
                  <img
                    src={Icons.ConversationIcon}
                    alt={t('altTexts:conversation')}
                    style={{ width: '1.3rem', marginTop: '2px' }}
                  />
                </Button>
              </div>
            </FacilityDetailsContainer>
          ))}
        </BidCardsContainer>
      ) : (
        <NoBidsFoundText>{t('noData:noBidsFound')}</NoBidsFoundText>
      )}
    </>
  );
};

export default BidDetails;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import { IconButton, Select, SelectChangeEvent, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { MRT_ColumnDef } from 'material-react-table';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { Icons } from '../../../../Shared/Constants/Icons';
import Dropdown from '../../../../Shared/Components/Common/Dropdown/Dropdown';
import Accordion, { ScreenName } from '../../../../Shared/Components/Common/Accordion/Accordion';
import { TaskData } from '../WorkTasks/WorkTasks';

import { IServices } from '../../../../Shared/Models/WorkTask.model';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { DropDownWrapper, JobsTitle, TitleWrapper } from './JobsIssuesRaised.styles';
import { DropdownIcon, Options, SelectInput } from '../WorkTasks/JobDetailsTasks.styles';
import {
  ActionIcon,
  ActionsColumn,
  Linking,
  Menus,
  MenuWrapper,
  ReadMore,
} from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { CustomerContainer, NoDataWrapper } from '../../../../Shared/Components/Layout/styles';
import { IJobOverview } from '../JobsListView/JobsListView';
import { IJobs } from '../JobView/JobsView';
import { getStoredCustomerDetails, isMock } from '../../../../Shared/Utilities/utils';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import { JobStatus, WorkOrder, WorkOrderStatus } from '../../../../API';
import JobCreateApiService from '../../Services/JobCreateService';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import SnackBar from '../../../../Shared/Components/Common/SnackBar/SnackBar';
import RejectDialog from '../TaskDetails/RejectDialog';
import ApproveDialog from '../TaskDetails/ApproveDialog';
import { useAuth } from '../../../../Configuration/AuthContext';
import { ErrorMessage } from '../../../../Shared/Components/Common/ErrorMessage/ErrorMessage';
import theme from '../../../../Shared/Themes/theme';
import { IBidList } from '../JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { TaskStatus } from '../../../../Shared/Constants/App';

const dropdownData = [
  { id: 1, label: 'Today', value: 'today' },
  { id: 2, label: 'Last 7 days', value: '7D' },
  { id: 3, label: 'Last 15 days', value: '15D' },
  { id: 4, label: 'Last 30 days', value: '30D' },
  { id: 5, label: 'Last 3 months', value: '3M' },
];

const JobsIssuesRaised = () => {
  const { t } = useTranslation(['dashboard', 'altTexts']);
  const [expanded, setExpanded] = useState<number | false>(1);
  const [value, setValue] = useState('3M');
  const [facilityId, setFacilityId] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [serviceList, setServiceList] = useState<IServices[]>([]);
  const [originalServices, setOriginalServices] = useState<IServices[]>([]);
  const [facilityLoading, setFacilityLoading] = useState<boolean>(false);
  const [jobsLoading, setJobsLoading] = useState<boolean>(false);
  const [facilities, setFacilities] = useState([]);
  const [allJobs, setAllJobs] = useState<any>([]);
  const [isOpenApproveAll, setIsOpenApproveAll] = useState(false);
  const [isOpenRejectAll, setIsOpenRejectAll] = useState(false);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenReject, setIsOpenReject] = useState(false);
  const [rowInfo, setRowInfo] = useState<any>({});
  const [isJobsQueryError, setIsJobsQueryError] = useState<boolean>(false);
  const [isFacilityQueryError, setFacilityQueryError] = useState<boolean>(false);

  const {
    handleApprovalAll,
    handleRejectAll,
    handleReject,
    handleApprove,
    setSelectedTaskId,
    selectedTaskId,
    setSnackBarStatus,
    snackBarStatus,
  } = useAuth();

  const dataFiltering = useCallback(
    (expanded: number | boolean) => {
      let filteredFacility: IServices[] = [];
      const deepCloned = [...originalServices].map((data) => {
        return { ...data };
      });
      if (facilityId === 'all' && selectedJob === 'all') {
        filteredFacility = deepCloned;
      } else if (facilityId !== 'all') {
        filteredFacility = deepCloned.filter((data) => data.facilityId === facilityId);
        //if facility has no jobs
        if (filteredFacility.length === 0) {
          return filteredFacility;
        }
      } else if (selectedJob === 'all') {
        filteredFacility = deepCloned;
      } else {
        filteredFacility = deepCloned.filter((data) => data.jobId === selectedJob);
      }
      if (typeof expanded === 'number') {
        let filteredData: TaskData[] = [];
        const activeData = filteredFacility[expanded - 1];
        if (value === 'today') {
          filteredData = activeData.tableData.filter((dataItem) => moment().isSame(dataItem.date, 'day'));
        } else if (value === '7D') {
          filteredData = activeData.tableData.filter((dataItem) => moment().diff(dataItem.date, 'days') <= 7);
        } else if (value === '15D') {
          filteredData = activeData.tableData.filter((dataItem) => moment().diff(dataItem.date, 'days') <= 15);
        } else if (value === '30D') {
          filteredData = activeData.tableData.filter((dataItem) => moment().diff(dataItem.date, 'days') <= 30);
        } else if (value === '3M') {
          filteredData = activeData.tableData.filter((dataItem) => moment().diff(dataItem.date, 'months') <= 3);
        }
        filteredFacility[expanded - 1].tableData = filteredData;
      }

      return filteredFacility;
    },
    [value, originalServices, facilityId, selectedJob]
  );

  const fetchFacilities = async () => {
    setFacilityLoading(true);
    setJobsLoading(true);
    const { customerId = '' } = getStoredCustomerDetails() || {};
    try {
      if (customerId) {
        const allFacilitiesResponse = await FacilityApiService.getAllFacilities(customerId as string);
        if (allFacilitiesResponse.errors.length === 0 && allFacilitiesResponse.data.length) {
          const filteredJobs: any = [];
          const filteredFacility: any = await Promise.all(
            allFacilitiesResponse.data.map(async (facility: any) => {
              const facilities = await Promise.resolve(fetchAllJobs(facility.facilityId as string));
              facilities && filteredJobs.push(...facilities);
              return {
                id: facility.facilityId,
                label: facility.buildingName,
                value: facility.facilityId,
              };
            })
          );
          filteredFacility.unshift({ id: uuidv4(), label: 'All facilities', value: 'all' });
          const shallowServices = filteredJobs.map((data: any, index: number) => {
            return {
              id: index + 1,
              jobId: data.jobId,
              serviceName: 'Furniture',
              taskTotal: 4,
              taskCompleted: 1,
              facilityId: data.facilityId,
              iconUrl: data.icon,
              facilityName: data.facilityName,
              Jobname: data.label,
              facilityIcon: data.icon,
              tableData: [
                {
                  no: 1,
                  taskName: 'Sweeping',
                  floor: '1',
                  timeTaken: '35 min',
                  status: TaskStatus.IssueRaised,
                  comments: 'Lorem ipsum dolar sit... Read more',
                  proof: '12',
                  date: '2023-06-19T07:15:07.135Z',
                  taskId: '5678',
                },
                {
                  no: 2,
                  taskName: 'Vacuuming',
                  floor: '1',
                  timeTaken: '35 min',
                  status: TaskStatus.IssueRaised,
                  comments: 'Lorem ipsum dolar sit... Read more',
                  proof: '12',
                  date: '2023-05-19T07:15:07.135Z',
                  taskId: '1234',
                },
              ],
              isShowButton: false,
            };
          });
          if (filteredJobs.length > 0) {
            // if there is no jobs then to show no records added this condition
            filteredJobs.unshift({ id: uuidv4(), label: 'All Jobs', value: 'all' });
            setSelectedJob('all');
          }
          setFacilityId('all');
          setAllJobs(filteredJobs);
          setFacilities(filteredFacility);
          setServiceList(shallowServices);
          setOriginalServices(shallowServices);
          setFacilityQueryError(false);
        } else {
          setFacilityQueryError(true);
          console.error('facilities query : ', allFacilitiesResponse, customerId);
        }
      }
      setFacilityLoading(false);
      setJobsLoading(false);
    } catch (e) {
      setFacilityQueryError(true);
      console.error('Error', e);
    }
  };

  const fetchJobs = useCallback(async (facilityId: string) => {
    try {
      const { customerId } = getStoredCustomerDetails();
      if (!facilityId || !customerId) {
        return [];
      }

      const jobsResponse = await fetchJobsData(customerId as string, facilityId);
      const facilityDetailsResponse = await fetchFacilityDetails(facilityId, customerId as string);

      const facilityIcon = generateFacilityIcon(facilityDetailsResponse);

      if (jobsResponse.data && jobsResponse.errors.length === 0) {
        const filteredJobs = filterJobs(jobsResponse.data);

        return filteredJobs.map((jobItem: any) => {
          return {
            startDate: jobItem.startDate,
            stopDate: jobItem.stopDate,
            value: jobItem.jobId,
            facilityId: facilityId ?? '',
            jobId: jobItem?.jobId ?? '',
            id: uuidv4(),
            label: jobItem.jobName,
            facilityName: facilityDetailsResponse?.data?.buildingName || '',
            icon: facilityIcon,
          };
        });
      }

      if (jobsResponse.errors.length) {
        console.error('Error', jobsResponse.errors);
      }
    } catch (e) {
      setIsJobsQueryError(true);
      console.error('Error', e);
    }

    return [];
  }, []);

  const fetchJobsData = async (customerId: string, facilityId: string) => {
    return await JobCreateApiService.jobsV2({ customerId, facilityId }, isMock);
  };

  const fetchFacilityDetails = async (facilityId: string, customerId: string) => {
    return await FacilityApiService.facility(facilityId, customerId, isMock);
  };

  const generateFacilityIcon = (facilityDetailsResponse: any) => {
    const logoUrl: string | undefined = facilityDetailsResponse?.data?.address?.logoUrl;
    if (
      facilityDetailsResponse?.data &&
      typeof logoUrl === 'string' &&
      typeof process.env.REACT_APP_GOOGLE_MAPS_API_KEY === 'string'
    ) {
      return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${logoUrl}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    }
    return '';
  };

  const filterJobs = (jobsData: any) => {
    return jobsData.filter((jobItem: any) =>
      [JobStatus.OpenToBid, JobStatus.Awarded, JobStatus.Incomplete].includes(jobItem.dataStatus as JobStatus)
    );
  };

  const fetchOngoingJobs = useCallback(async () => {
    const { customerId = '' } = getStoredCustomerDetails() || {};

    try {
      if (!customerId) {
        return [];
      }

      const allJobResponse = await fetchAllWorkOrders(customerId as string);

      if (allJobResponse.data.length && allJobResponse.errors.length === 0) {
        return await processJobData(allJobResponse.data, customerId as string);
      }

      if (allJobResponse.errors.length && allJobResponse.errors[0]?.message) {
        console.error('Error', allJobResponse.errors);
      }
    } catch (e) {
      setIsJobsQueryError(true);
      console.error('Error', e);
    }

    return [];
  }, []);

  const fetchAllWorkOrders = async (customerId: string) => {
    return await WorkOrdersApiService.getAllWorkOrders({
      customerId,
      status: WorkOrderStatus.InProgress,
    });
  };

  const processJobData = async (jobsData: WorkOrder[], customerId: string) => {
    return Promise.all(
      jobsData.map(async (job) => {
        const { name, facilities } = job;
        const facilityId = facilities?.[0]?.facilityId ?? '';

        const facilityAddressResponse = await FacilityApiService.facility(facilityId, customerId || '', isMock);

        const facilityIcon = generateFacilityIcon(facilityAddressResponse);

        return {
          label: name,
          startDate: job.jobStartDate,
          stopDate: job.jobEndDate,
          facilityName: facilityAddressResponse?.data?.buildingName || '',
          value: job?.jobId ?? '',
          facilityId: facilityId || '',
          jobId: job?.jobId ?? '',
          id: uuidv4(),
          icon: facilityIcon,
        };
      })
    );
  };

  const fetchAllJobs = useCallback(
    async (facilityId: string) => {
      const [upcomingJobs, ongoingJobs] = await Promise.all([fetchJobs(facilityId), fetchOngoingJobs()]);
      if (upcomingJobs?.length || ongoingJobs?.length) {
        const filteredUpcomingJobs = upcomingJobs.filter((job: any) =>
          ongoingJobs.find((ongoingJob) => ongoingJob.jobId !== job.jobId && ongoingJob.facilityId === job.facilityId)
        );
        return [...upcomingJobs, ...filteredUpcomingJobs];
      }
    },
    [fetchJobs, fetchOngoingJobs]
  );

  useEffect(() => {
    fetchFacilities();
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      if (facilityId === 'all') {
        fetchFacilities();
      } else {
        setJobsLoading(true);
        const jobs: any = await Promise.resolve(fetchAllJobs(facilityId));
        if (jobs && jobs.length > 0) {
          // whenever we changing facility we are adding all jobs object if jobs are more than one
          jobs.unshift({ id: uuidv4(), label: 'All Jobs', value: 'all' });
          setSelectedJob('all');
        } else {
          setSelectedJob('');
        }
        setAllJobs(jobs);
        setJobsLoading(false);
      }
    };
    fetchJobs();
  }, [facilityId]);

  useEffect(() => {
    if (originalServices.length && selectedJob && facilityId) {
      const filtered = dataFiltering(expanded);
      setServiceList(filtered);
    }
  }, [originalServices, expanded, value, facilityId, selectedJob]);

  const handleDropdownChange = useCallback((event: SelectChangeEvent) => {
    setValue(event.target.value);
  }, []);

  const onChangeRow = useCallback(
    (rowSelection: any, data: TaskData[], service: IServices) => {
      const allRowSelected = Object.keys(rowSelection);
      const taskIds: any = data.filter(
        (dataItem, index) => allRowSelected?.includes(index.toString()) && dataItem.taskId
      );
      const isShow = allRowSelected.length && data.length && allRowSelected.length === data.length ? true : false;
      //Adding timeout to avoid re rendering because fraction of second allRowSelected and taskIds become empty
      // and because of that approve and reject button gives jerk
      setTimeout(() => {
        setSelectedTaskId(taskIds);
        setServiceList((prevServiceList) =>
          prevServiceList.map((dataItem) =>
            dataItem.id === service.id
              ? { ...dataItem, isShowButton: isShow, selectedRowCount: taskIds.length }
              : dataItem
          )
        );
      }, 0);
    },
    [serviceList]
  );

  const handleAccordion = useCallback(
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    []
  );

  // material UI Logic (table menu handler)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderStatusCellContent = (renderedCellValue: React.ReactNode) => {
    let selectedColor = theme.palette.text.secondary;
    if (typeof renderedCellValue === 'string') {
      if (renderedCellValue === 'Issue raised' || renderedCellValue === 'Rejected') {
        selectedColor = theme.palette.error.main;
      } else if (renderedCellValue === 'Approved') {
        selectedColor = theme.palette.success.main;
      }
      return <span style={{ color: selectedColor }}>{renderedCellValue}</span>;
    }
    return '-';
  };

  const renderCommentsCellContent = (renderedCellValue: React.ReactNode | string | undefined, row: any) => {
    if (typeof renderedCellValue === 'string' && renderedCellValue) {
      if (renderedCellValue.length > 21) {
        return (
          <div>
            {`${renderedCellValue.substring(0, 21)}...`}
            <ReadMore to="/task-details" state={{ taskRecord: row.original }}>
              Read More
            </ReadMore>
          </div>
        );
      } else {
        return renderedCellValue;
      }
    }
    return '-';
  };

  const renderProofCellContent = (renderedCellValue: React.ReactNode | string | undefined) => {
    if (typeof renderedCellValue === 'string') {
      return renderedCellValue ? <span style={{ color: theme.palette.primary.dark }}>{renderedCellValue}</span> : '-';
    }
    return '-';
  };

  const renderActionsCellContent = (row: any) => (
    <ActionsColumn>
      <ActionIcon
        onClick={() => {
          setIsOpenApprove(true);
          setRowInfo(row.original);
        }}
        src={Icons.TickIcon}
        alt={t('altTexts:approve')}
      />
      <ActionIcon
        onClick={() => {
          setIsOpenReject(true);
          setRowInfo(row.original);
        }}
        src={Icons.CancelIcon}
        alt={t('altTexts:reject')}
      />
      <IconButton
        onClick={(e) => {
          handleClick(e);
          setRowInfo(row.original);
        }}
        size="large"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}>
        <MoreVertIcon></MoreVertIcon>
      </IconButton>
      <MenuWrapper anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
        <Linking to="/task-details" state={{ taskRecord: rowInfo }}>
          <Menus>
            <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} /> View details
          </Menus>
        </Linking>
      </MenuWrapper>
    </ActionsColumn>
  );

  const columns: MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[] = [
    {
      accessorKey: 'no',
      header: 'No.',
      enablePinning: false,
      enableSorting: false,
      size: 10,
      enableColumnFilter: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'taskName',
      header: 'Task',
      enableSorting: true,
      enableColumnFilter: false,
      size: 140,
      Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
      enableColumnActions: true,
    },
    {
      accessorKey: 'floor',
      header: 'Floor',
      enableSorting: false,
      enableColumnFilter: false,
      size: 64,
      Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
      enableColumnActions: false,
    },
    {
      accessorKey: 'timeTaken',
      header: 'Time taken',
      enableSorting: false,
      enableColumnFilter: false,
      size: 113,
      Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
      enableColumnActions: false,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableSorting: true,
      enableColumnFilter: false,
      size: 162,
      Cell: ({ renderedCellValue }) => renderStatusCellContent(renderedCellValue),
    },
    {
      accessorKey: 'comments',
      header: 'Comments',
      enableSorting: false,
      enableColumnFilter: false,
      size: 294,
      Cell: ({ renderedCellValue, row }) => renderCommentsCellContent(renderedCellValue, row),
      enableColumnActions: false,
    },
    {
      accessorKey: 'proof',
      header: 'Proof',
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      Cell: ({ renderedCellValue }) => renderProofCellContent(renderedCellValue),
      enableColumnActions: false,
    },
    {
      header: 'Actions',
      enableColumnActions: false,
      enablePinning: false,
      size: 130,
      Cell: ({ row }) => renderActionsCellContent(row),
    },
  ];

  return (
    <>
      <PageTitle title={t('dashboard:issuesRaised')} />
      <CustomerContainer>
        <DropDownWrapper>
          <Dropdown
            isLoading={facilityLoading}
            dropdownData={facilities}
            defaultValue={facilityId}
            onChange={(value) => setFacilityId(value)}
          />
          <Dropdown
            isLoading={jobsLoading}
            dropdownData={allJobs}
            defaultValue={selectedJob}
            onChange={(value) => setSelectedJob(value)}
          />
        </DropDownWrapper>
        <TitleWrapper>
          <JobsTitle>Jobs</JobsTitle>
          <Select
            value={value}
            onChange={handleDropdownChange}
            defaultValue={value}
            input={<SelectInput />}
            IconComponent={(props) => (
              <DropdownIcon {...props}>
                <img src={Icons.DropdownIcon} alt={t('altTexts:dropdown')} />
              </DropdownIcon>
            )}
            MenuProps={{
              PaperProps: {
                sx: {
                  '&.MuiPaper-root': {
                    borderRadius: '1rem',
                    '& .MuiMenuItem-root': {
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.dark,
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: theme.palette.secondary.dark,
                      },
                    },
                  },
                },
              },
            }}
            inputProps={{ 'aria-label': 'Without label' }}>
            {dropdownData.map((dataItem) => {
              return (
                <Options key={dataItem.id} value={dataItem.value}>
                  {dataItem.label}
                </Options>
              );
            })}
          </Select>
        </TitleWrapper>
        {serviceList.length === 0 && !isFacilityQueryError && !isJobsQueryError ? (
          <NoDataWrapper>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </NoDataWrapper>
        ) : null}
        {!isFacilityQueryError || !isJobsQueryError ? (
          serviceList.map((dataItem) => {
            return (
              <Accordion
                serviceList={serviceList}
                key={dataItem.id}
                columns={columns}
                expanded={expanded}
                handleAccordion={handleAccordion}
                onChangeRow={onChangeRow}
                accordionObj={dataItem}
                screenName={ScreenName.IssuesRaised}
                handleApproveAll={() => {
                  setIsOpenApproveAll(true);
                  setSnackBarStatus({
                    ...snackBarStatus,
                    isOpen: false,
                  });
                }}
                handleRejectAll={() => {
                  setIsOpenRejectAll(true);
                  setSnackBarStatus({
                    ...snackBarStatus,
                    isOpen: false,
                  });
                }}
              />
            );
          })
        ) : (
          <ErrorMessage />
        )}
        <ApproveDialog
          isApproveWindowOpen={isOpenApprove || isOpenApproveAll}
          handleCloseApproveWindow={() => {
            setIsOpenApprove(false);
            setIsOpenApproveAll(false);
          }}
          handleApprove={() => {
            if (isOpenApproveAll) {
              handleApprovalAll();
            } else {
              if (Object.keys(rowInfo).length > 0) handleApprove(rowInfo?.taskId as string);
            }
            setIsOpenApproveAll(false);
            setIsOpenApprove(false);
            // setSnackBarStatus({
            //   isOpen: true,
            //   isApproved: true,
            // });
          }}
          rowDetails={rowInfo}
          isShowApproveAll={isOpenApproveAll && selectedTaskId.length > 1}
        />
        <RejectDialog
          isRejectWindowOpen={isOpenReject || isOpenRejectAll}
          handleCloseRejectWindow={() => {
            setIsOpenReject(false);
            setIsOpenRejectAll(false);
          }}
          handleReject={(vendorMsg: string) => {
            if (isOpenRejectAll) {
              handleRejectAll(vendorMsg);
            } else {
              if (Object.keys(rowInfo).length > 0) handleReject(rowInfo?.taskId as string, vendorMsg);
            }
            setIsOpenRejectAll(false);
            setIsOpenReject(false);
            // setSnackBarStatus({
            //   isOpen: true,
            //   isApproved: false,
            // });
          }}
          isShowRejectAll={isOpenRejectAll && selectedTaskId.length > 1}
        />
        {snackBarStatus.isOpen ? (
          <SnackBar
            isApproved={snackBarStatus.isApproved}
            taskCount={selectedTaskId.length ? selectedTaskId.length : 1}
            handleOnClose={() =>
              setSnackBarStatus({
                isOpen: false,
                isApproved: false,
              })
            }
          />
        ) : null}
      </CustomerContainer>
    </>
  );
};

export default JobsIssuesRaised;

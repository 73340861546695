/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { API } from 'aws-amplify';

import {
  AllBidAttachmentsInput,
  BidVendorInput,
  CreateBidAttachmentInput,
  JobBidInput,
  generateInvoiceInput,
  CreateJobBidAdditionalExpensesInput,
  CreateJobBidEstimationInput,
  StandardRatesFilterInput,
  UpdateJobBidInput,
} from '../../../API';

import {
  allBidAttachments,
  allBidAttachmentsWithLatestVersion,
  allJobBidAdditionalExpenses,
  allJobBidEstimation,
  getStandardRatesAndPtiTaxes,
  jobInterests,
} from '../../../graphql/queries';
import {
  addJobBidAdditionalExpenses,
  addJobBidEstimation,
  addJobBid,
  updateBid,
  submitBid,
  createBidAttachments,
  updateBidAttachments,
  deleteBidAttachments,
  generateInvoice,
} from '../../../graphql/mutations';
import { AuthHeader, AWSAuthMode } from '../../../Shared/Constants/App';
import { getWebInfo } from '../../../Shared/Utilities/utils';
import { IGraphQLResponse } from '../../../Shared/Models';
import { AuthenticationService } from '../../../Shared/Services/AuthenticationService';

export default class MarketplaceApiService {
  static async getEstimationTableData() {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const notInterestedJobsResponse: IGraphQLResponse = await API.graphql(
        {
          query: jobInterests,
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: notInterestedJobsResponse.data?.jobInterests ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async submitBid(bidId: string, bidVendorInput: BidVendorInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: submitBid,
          variables: { bidId, bidVendorInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.submitBid ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async addJobBid(jobId: string, vendorId: string, createJobBidInput: JobBidInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: addJobBid,
          variables: { jobId, vendorId, createJobBidInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.addJobBid ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async allJobBidEstimation(jobId: string, bidId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: allJobBidEstimation,
          variables: { jobId, bidId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.allJobBidEstimation ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async createBidAttachments(bidId: string, attachmentInput: CreateBidAttachmentInput[]) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: createBidAttachments,
          variables: { bidId, createBidAttachmentInput: attachmentInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.createBidAttachments ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async allBidAttachments(attachmentInput: AllBidAttachmentsInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: allBidAttachments,
          variables: { allBidAttachmentsInput: attachmentInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.allBidAttachments ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async allBidAttachmentsWithLatestVersion(allBidAttachmentsInput: AllBidAttachmentsInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const allBidAttachmentsResponse: IGraphQLResponse = await API.graphql(
        {
          query: allBidAttachmentsWithLatestVersion,
          variables: { allBidAttachmentsInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: allBidAttachmentsResponse.data?.allBidAttachmentsWithLatestVersion ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async updateBidAttachments(attachmentInput: CreateBidAttachmentInput[]) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: updateBidAttachments,
          variables: { updateBidAttachmentInput: attachmentInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.updateBidAttachments ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async deleteBidAttachments(bidId: string, attachmentInput: string[]) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: deleteBidAttachments,
          variables: { bidId, attachmentIds: attachmentInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.deleteBidAttachments ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async updateBid(vendorId: string, bidId: string, updateJobInput: UpdateJobBidInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: updateBid,
          variables: { vendorId, bidId, updateJobInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.updateBid ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async addJobBidEstimation(
    jobId: string,
    bidId: string,
    createJobBidEstimationInput: CreateJobBidEstimationInput[]
  ) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: addJobBidEstimation,
          variables: { jobId, bidId, createJobBidEstimationInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.addJobBidEstimation ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async addJobBidAdditionalExpenses(
    jobId: string,
    bidId: string,
    createJobBidAdditionalExpensesInput: CreateJobBidAdditionalExpensesInput[]
  ) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: addJobBidAdditionalExpenses,
          variables: { jobId, bidId, createJobBidAdditionalExpensesInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.addJobBidAdditionalExpenses ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async allJobBidAdditionalExpenses(jobId: string, bidId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: allJobBidAdditionalExpenses,
          variables: { jobId, bidId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.allJobBidAdditionalExpenses ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async generateInvoice(jobId: string, bidId: string, generateInvoiceInput: generateInvoiceInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: generateInvoice,
          variables: { bidId, jobId, generateInvoiceInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.generateInvoice ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getStandardRatesAndPtiTaxes(standardRatesFilterInput: StandardRatesFilterInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: getStandardRatesAndPtiTaxes,
          variables: { standardRatesFilterInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      const ptiTax = response.data?.getStandardRatesAndPtiTaxes?.ptiTaxes?.length
        ? response.data?.getStandardRatesAndPtiTaxes?.ptiTaxes[0]
        : {};
      const modifiedResponse = response.data?.getStandardRatesAndPtiTaxes?.standardRates?.length
        ? response.data?.getStandardRatesAndPtiTaxes?.standardRates?.map((rateItem: any) => {
            return {
              ...rateItem,
              ...ptiTax,
            };
          })
        : [{ ...ptiTax }];

      return { data: modifiedResponse ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { Box } from '@mui/material';

import { ILocationMarkerProps } from '../../../Vendor/Models/OneTimeJobDetails.model';

import { Icons } from '../../../../Shared/Constants/Icons';

const LocationMarker: React.FC<ILocationMarkerProps> = () => (
  <Box
    sx={{
      transform: 'translate(-50%, -50%)',
    }}>
    <img src={Icons.LocationMarkerIcon} alt="location" style={{ width: '2.25rem', height: '2.25rem' }} />
  </Box>
);

export default LocationMarker;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { customersList } from '../../Jobs/Components/AddEditOneTimeJob/constants';
import { ICustomAPIEndpoint, IGraphQLError, IGraphQLResponse } from '../../../Shared/Models';

import {
  bidProposalSummary,
  customer,
  customerAddresses,
  customerDetails,
  customerFacilityAccess,
  emailTransaction,
  getAllUsers,
  getAssociateDetailsByUserId,
  getCustomers,
  getUserManagers,
} from '../../../graphql/queries';
import { API } from 'aws-amplify';
import { addCustomerFacilityAccess, processBidReview, updateCustomerFacilityAccess } from '../../../graphql/mutations';
import { EmailTransactionDetailsModel } from '../Models/BidApproval.Model';
import {
  BooleanType,
  CreateFacilityAccessDetails,
  FilterCustomerInput,
  UpdateFacilityAccessDetails,
} from '../../../API';
import { isEmpty } from 'lodash';
import awsConfig from '../../../Configuration/environment-config';
import { AuthHeader, AWSAuthMode } from '../../../Shared/Constants/App';
import { getWebInfo } from '../../../Shared/Utilities/utils';
import { AuthenticationService } from '../../../Shared/Services/AuthenticationService';

export default class CustomerApiService {
  static async getFacilityAccess(customerId: string, facilityId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const facilityAccessResponse: IGraphQLResponse = await API.graphql(
        {
          query: customerFacilityAccess,
          variables: { customerId, facilityId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );

      return { data: facilityAccessResponse?.data?.customerFacilityAccess ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async verifyTin(tin: string, name: string) {
    try {
      const verifyTinEndpoint = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: ICustomAPIEndpoint) => apiEndpoint.name === 'verify-tin'
      );
      if (verifyTinEndpoint?.endpoint) {
        const tinStatus = await fetch(verifyTinEndpoint?.endpoint, {
          method: 'POST',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
          body: JSON.stringify({ requestType: 'validateTin', tin, name }),
        });
        const responseJson = await tinStatus.json();
        return { data: responseJson ?? [], errors: [] };
      }
      return { data: [], errors: [{ message: 'Endpoint not found' }] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getCustomer(id: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const customerResponse: IGraphQLResponse = await API.graphql(
        {
          query: customer,
          variables: { id },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: customerResponse.data?.customer ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getCustomers(filterCustomerInput: FilterCustomerInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const customerResponse: IGraphQLResponse = await API.graphql(
        {
          query: getCustomers,
          variables: { filterCustomerInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: customerResponse.data?.getCustomers ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getCustomerAddress(id: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const customerResponse: IGraphQLResponse = await API.graphql(
        {
          query: customerAddresses,
          variables: { customerId: id },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: customerResponse.data?.customerAddresses ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static getCustomersList(isMocked = true) {
    if (isMocked) {
      return customersList;
    }
  }

  static async getCustomerDetails(id: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const customerDetailsResponse: IGraphQLResponse = await API.graphql(
        {
          query: customerDetails,
          variables: { id },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: customerDetailsResponse.data ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getCustomerDetailsResponse(id: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const customerResponse: IGraphQLResponse = await API.graphql(
        {
          query: customer,
          variables: { id },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: customerResponse.data ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getBidProposalSummary(bidId: string, isMocked = false) {
    try {
      if (isMocked) {
        return { data: null };
      }
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const bidProposalSummaryResponse: IGraphQLResponse = await API.graphql(
        {
          query: bidProposalSummary,
          variables: { bidId },
          authMode: AWSAuthMode.API_KEY,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: bidProposalSummaryResponse.data.bidProposalSummary ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async acceptRejectBidProposal(transactionId: string, bidStatus: string, ipAddress: string, comments = '') {
    const payload = { transactionId: '', bidStatus: '', comments: '', processBidReviewInput: {} };
    if (transactionId) payload.transactionId = transactionId;
    if (bidStatus) payload.bidStatus = bidStatus;
    if (comments) payload.processBidReviewInput = { comments };
    if (ipAddress) payload.processBidReviewInput = { ...payload.processBidReviewInput, IPAddress: ipAddress };
    if (Object.values(payload).length) {
      try {
        const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
        const processBidReviewResponse: IGraphQLResponse = await API.graphql(
          {
            query: processBidReview,
            variables: {
              transactionId: payload.transactionId,
              bidStatus: payload.bidStatus,
              processBidReviewInput: payload.processBidReviewInput,
            },
            authMode: AWSAuthMode.API_KEY,
          },
          { ...getWebInfo(), ...authHeaders }
        );
        return { data: processBidReviewResponse.data.processBidReview ?? [], errors: [] };
      } catch (e: any) {
        return { data: null, errors: e.errors };
      }
    }
  }

  static async getEmailTransactionDetails(
    emailTransactionId: string
  ): Promise<{ data: EmailTransactionDetailsModel; errors: [] } | { data: []; errors: [] }> {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const emailTransactionResponse: IGraphQLResponse = await API.graphql(
        {
          query: emailTransaction,
          variables: { emailTransactionId },
          authMode: AWSAuthMode.API_KEY,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: emailTransactionResponse?.data?.emailTransaction ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getUserManagers(
    managerId: string,
    userId?: string,
    isAssociate?: BooleanType,
    searchString?: string,
    pageNumber?: number,
    limit?: number,
    isPaginationRequire = false // This flag can use if pagination is required for this query.
  ) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const variables: {
        isAssociate?: string;
        limit?: number;
        managerId: string;
        pageNumber?: number;
        searchString?: string;
        userId?: string;
      } = {
        managerId,
      };
      if (!isEmpty(userId)) {
        variables.userId = userId;
      }
      if (!isEmpty(isAssociate)) {
        variables.isAssociate = isAssociate;
      }
      if (!isEmpty(searchString)) {
        variables.searchString = searchString;
      }
      if (pageNumber && pageNumber > 0) {
        variables.pageNumber = pageNumber;
      }
      if (limit && limit > 0) {
        variables.limit = limit;
      }
      if (managerId) {
        let userManagersResponse: IGraphQLResponse = await API.graphql(
          {
            query: getUserManagers,
            variables,
            authMode: AWSAuthMode.COGNITO,
          },
          { ...getWebInfo(), ...authHeaders }
        );
        if (!isPaginationRequire) {
          userManagersResponse = await API.graphql(
            {
              query: getUserManagers,
              variables: {
                ...variables,
                limit: userManagersResponse?.data?.getUserManagers?.metadata?.totalCount ?? 20,
              },
              authMode: AWSAuthMode.COGNITO,
            },
            { ...getWebInfo(), ...authHeaders }
          );
        }
        return {
          data: userManagersResponse?.data?.getUserManagers?.data ?? [],
          metadata: userManagersResponse?.data?.getUserManagers?.metadata,
          errors: [],
        };
      }
      return { data: [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getAllUsers(
    organizationId: string,
    roleName?: string,
    pageNumber?: number,
    limit?: number,
    isAssociate?: BooleanType,
    isPaginationRequire = false // This flag can use if pagination is required for this query.
  ) {
    try {
      const variables: {
        isAssociate?: BooleanType;
        limit?: number;
        organizationId: string;
        pageNumber?: number;
        roleName?: string;
      } = {
        organizationId: '',
        roleName: '',
        limit: 10,
        pageNumber: 1,
      };
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      if (organizationId) {
        variables.organizationId = organizationId;
      }
      if (roleName) {
        variables.roleName = roleName;
      }
      if (limit) {
        variables.limit = limit;
      }
      if (pageNumber && pageNumber > 0) {
        variables.pageNumber = pageNumber;
      }
      if (limit && limit > 0) {
        variables.limit = limit;
      }
      if (isAssociate) {
        variables.isAssociate = isAssociate;
      }

      let usersResponse: IGraphQLResponse = await API.graphql(
        {
          query: getAllUsers,
          variables: { ...variables },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );

      if (!isPaginationRequire) {
        usersResponse = await API.graphql(
          {
            query: getAllUsers,
            variables: {
              ...variables,
              limit: usersResponse?.data?.getAllUsers?.metadata?.totalCount ?? 20,
            },
            authMode: AWSAuthMode.COGNITO,
          },
          { ...getWebInfo(), ...authHeaders }
        );
      }
      return {
        data: usersResponse?.data?.getAllUsers?.data ?? [],
        metadata: usersResponse?.data?.getAllUsers?.metadata ?? {},
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [], metadata: {} };
    }
  }

  static async getAssociateDetailsByUserId(cognitoUserId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const associateDetailsByUserIdResponse: IGraphQLResponse = await API.graphql(
        {
          query: getAssociateDetailsByUserId,
          variables: { cognitoUserId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: associateDetailsByUserIdResponse?.data.getAssociateDetailsByUserId ?? {}, errors: [] };
    } catch (e: unknown) {
      const apiError: IGraphQLError = e as IGraphQLError;
      return { data: {}, errors: apiError?.errors ?? [] };
    }
  }

  static async saveFacilityAccessDetails(
    customerId: string,
    facilityId: string,
    createCustomerFacilityAccessInput: CreateFacilityAccessDetails
  ) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const facilityAccessResponse: IGraphQLResponse = await API.graphql(
        {
          query: addCustomerFacilityAccess,
          variables: { customerId, facilityId, createCustomerFacilityAccessInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: facilityAccessResponse?.data?.addCustomerFacilityAccess ?? null, errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }
  static async updateFacilityAccess(
    customerId: string,
    facilityId: string,
    updateCustomerFacilityAccessInput: UpdateFacilityAccessDetails
  ) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const facilityAccessResponse: IGraphQLResponse = await API.graphql(
        {
          query: updateCustomerFacilityAccess,
          variables: { customerId, facilityId, updateCustomerFacilityAccessInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: facilityAccessResponse?.data?.updateCustomerFacilityAccess ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';

const LogoutDialog: React.FC = () => {
  const { t } = useTranslation(['logout']);
  return (
    <div>
      {t('logout:logoutText')} <br /> <strong>{t('logout:peazyApplication')}</strong>
    </div>
  );
};

export default LogoutDialog;

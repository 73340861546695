/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import theme from '../../../Themes/theme';

export const HeadingContainer = styled('div')(() => ({
  padding: '1.5rem 1.5rem 1rem 1.5rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ChipsContainer = styled('div')(({ theme }) => ({
  padding: '1.5rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  [theme.breakpoints.down('laptop')]: {
    padding: '1rem 1.5rem',
  },
}));

export const Chips = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5rem',
}));

export const DurationInput = styled(InputBase)(() => ({
  border: `0.0625rem solid ${theme.palette.primary.dark}`,
  borderRadius: '1rem',
  padding: '0.5625rem 0.75rem 0.5625rem 1rem',
  color: theme.palette.primary.dark,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.h5?.fontSize,
  lineHeight: '1.375rem',

  '&> div': {
    padding: '0 1.75rem 0 0 !important',
  },
}));

export const Options = styled(MenuItem)(() => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const Title = styled('h6')(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  lineHeight: '1.75rem',
  letterSpacing: '0.009375rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const ListView = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  textTransform: 'none',
  paddingRight: '0.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    paddingRight: '0.25rem',
  },
}));

export const Divider = styled('div')(() => ({
  marginLeft: '1.5rem',
  marginRight: '1.5rem',
  border: `0.03125rem solid ${theme.palette.divider}`,
}));

export const TabDivider = styled('div')(() => ({
  border: `0.03125rem solid ${theme.palette.divider}`,
  paddingTop: '1.25rem',
  paddingBottom: '1.25rem',
  marginRight: '1.5rem',
  marginLeft: '1.5rem',
}));

export const DatePickerWrapper = styled('div')(({ theme }) => ({
  maxWidth: '9.6rem',
  '& .MuiOutlinedInput-root': {
    '& .MuiInputBase-input': {
      fontFamily: theme.typography.fontFamily,
      // color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export interface IBuildingMakeup {
  Collection: string;
  Single: string;
  Subset: string;
}

export const BuildingMakeup: IBuildingMakeup = {
  Single: 'single',
  Subset: 'subset',
  Collection: 'collection',
};

export interface ITrafficLevel {
  High: string;
  Low: string;
  Medium: string;
}

export const TrafficLevel: ITrafficLevel = {
  Low: 'low',
  Medium: 'medium',
  High: 'high',
};

export const buildingMakeupList = [
  {
    label: 'Single',
    value: BuildingMakeup.Single,
  },
  {
    label: 'Subset',
    value: BuildingMakeup.Subset,
  },
  {
    label: 'Collection',
    value: BuildingMakeup.Collection,
  },
];

export const TrafficLevelList = [
  {
    label: 'High',
    value: TrafficLevel.High,
  },
  {
    label: 'Medium',
    value: TrafficLevel.Medium,
  },
  {
    label: 'Low',
    value: TrafficLevel.Low,
  },
];

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { Dialog, styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const buttonStyles = {
  requestReworkButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1.25rem',
    maxWidth: '11.25rem',
    padding: '1.0625rem 2.95rem',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
    },
  },
  cancelButton: {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    background: theme.palette.common.white,
    borderRadius: '1.25rem',
    textTransform: 'none',
    marginRight: '1.5rem',
    padding: '1.0625rem 2rem',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
      marginRight: '1rem',
    },
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: '0.0125rem',
    },
  },
  textField: {
    '& .MuiInputBase-root': {
      paddingRight: '0.75rem !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.light,
        borderRadius: '1rem',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.h4?.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '1.5rem',
      letterSpacing: '0.00375rem',
      '&.Mui-focused': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h5?.fontSize,
      },
    },
    '@media (max-width: 64rem)': {
      '& .MuiInputLabel-root': {
        fontSize: '.875rem',
        lineHeight: '1.375rem',
        letterSpacing: '0.015625rem',
        '&.Mui-focused': {
          fontSize: theme.typography.h6?.fontSize,
        },
      },
    },
  },
  addProofsButton: {
    width: 'fit-content',
    minWidth: 'fit-content',
  },
  radioButton: {
    width: '1.25rem',
    height: '1.25rem',
    color: theme.palette.common.black,
    '&.Mui-checked': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.main,
      '& + span:last-child': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  radioButtonText: {
    fontFamily: theme.typography.fontFamily,
    marginRight: '0 !important',
    marginLeft: '0',
    gap: '1rem',
    '&.MuiFormControlLabel-root': {
      '&> span:last-child': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.h3?.fontSize,
        fontWeight: theme.typography.fontWeightLight,
        lineHeight: '1.375rem',
        letterSpacing: '0.009375rem',
        color: theme.palette.text.primary,
      },
    },
    '@media (max-width: 64rem)': {
      '&.MuiFormControlLabel-root': {
        '&> span:last-child': {
          fontSize: theme.typography.h3?.fontSize,
          lineHeight: '1.5rem',
        },
      },
    },
  },
};

export const RejectAndReworkCardWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    width: '50%',
    padding: '2em',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
}));

export const CardTitle = styled('div')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  marginBottom: '2rem',
}));

export const CardSubTitle = styled('div')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  marginBottom: '1.0625rem',
}));

export const TextFieldLabel = styled('div')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h6?.fontSize,
  lineHeight: '1.25rem',
  letterSpacing: '0.016rem',
  marginBottom: '2px',
}));

export const CloseDialogBoxIcon = styled('span')(() => ({
  cursor: 'pointer',
  position: 'absolute',
  right: '1.5rem',
  top: '-1.125rem',
}));

export const ButtonWrapper = styled('div')(() => ({
  display: 'flex',
}));

export const ProofsWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: '1rem',
  maxHeight: '10rem',
  overflowY: 'hidden',
  paddingBottom: '1rem',
  '&:hover': {
    overflowY: 'auto',
  },
}));

export const DropdownWrapper = styled('div')(() => ({
  display: 'flex',
  columnGap: '1rem',
}));

export const DatePickerWrapper = styled('div')(({ theme }) => ({
  maxWidth: '9.6rem',
  '& .MuiOutlinedInput-root': {
    '& .MuiInputBase-input': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

export const NoteText = styled('div')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h6?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.375rem',
  margin: 'auto',
  marginTop: '1.0625rem',
  color: theme.palette.success.main,
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, TextField, Button, Typography, CircularProgress, IconButton } from '@mui/material';
import { buttonStyles } from '../../../Onboarding/Components/Login/LoginPage.Style';
import {
  BottombarContainer,
  MediaContainer,
  MediaText,
  ReviewContainer,
  ReviewText,
  buttonStyle,
  Divider,
  ErrorMessage,
} from '../CreateBid.Style';
import SuccessScreen from './JobCreationSuccess';
import EstimationTable from '../BidEstimationTable/BidEstimationTable';
import AdditionalExpensesTable from '../BidEstimationTable/AdditionalExpensesTable';
import PrimeVendorExpensesTable from '../BidEstimationTable/PrimeVendorEstimationTable';
import CustomerApiService from '../../../Customer/Services/CustomerService';
import {
  Address,
  BidAttachment,
  BooleanType,
  CommenterType,
  Customer,
  CustomerDetails,
  Facility,
  KeyType,
  ServiceCategoryV3,
  YesNoType,
  generateInvoiceInput,
  CreateBidAttachmentInput,
  DeleteJobBidInput,
  DeleteType,
} from '../../../../API';
import CreateBidServices from '../../Services/CreateBidServices';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import {
  MasterData,
  UserType,
  JobStatus,
  FileContextType,
  DocumentType,
  PricingOptions,
  ModifyQuoteStatus,
} from '../../../../Shared/Constants/App';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import { v4 as uuidv4 } from 'uuid';
import {
  JobDetails,
  convertDateFormatToIso,
  fetchDataFromS3Bucket,
  formatDate,
  getAuthData,
  getStoredCustomerDetails,
  getTimeFormat,
  isDateBefore,
  removeLeadingZeros,
  uploadDocumentToS3Bucket,
} from '../../../../Shared/Utilities/utils';

import { Icons } from '../../../../Shared/Constants/Icons';

import { Patterns } from '../../../../Shared/Constants';
import { isEmpty } from 'lodash';
import { FieldNames, IDetailsProps, RecommendedJobsResponse } from '../../../Jobs/Models/AddEditOneTimeJob.Model';
import MediaPopup from '../../../../Shared/Components/Common/MediaPopUp/MediaPopUp';
import { RESPONSE_CODES } from '../../../Customer/Models/BidApproval.Model';
import { CommentResponseModel, IAllBidAttachments } from '../../Models/Bid.model';
import theme from '../../../../Shared/Themes/theme';
import OneTimeJobApiService from '../../../Jobs/Services/OneTimeJobServices';
import ModifyPopUp from '../../../Jobs/Components/JobDetails/ModifyPopUp';
import VendorApiService from '../../Services/VendorService';
import { useForm } from 'react-hook-form';
import Modal from '../../../Jobs/Utilities/Shared/Components/Modal';
import { Details, HeadingText } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import { DatePickerContainer } from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import ApproveDialog from '../../../Jobs/Components/TaskDetails/logoutDialog';
import { Auth } from 'aws-amplify';
import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';
import { StyleConstants } from '../../../../Shared/Constants/Style.Constants';

type MediaType = 'photo' | 'video';
// eslint-disable-next-line sonarjs/cognitive-complexity
const BidReviewPage = (): JSX.Element => {
  const { t } = useTranslation(['vendor', 'dashboard', 'altTexts', 'noData']);
  const { textField } = buttonStyles;
  const { button, buttonTextStyle } = buttonStyle;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const storedJob = localStorage.getItem('viewJob');
  const [storedJob, setStoredJob] = useState(localStorage.getItem(AsyncStorageKeys.viewJob));
  const currentJob = storedJob ? JSON.parse(storedJob) : {};
  const [customer, setCustomer] = useState<Customer>();
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
  const [vendorDetails, setVendorDetails] = useState<string>('');
  const [customerAddress, setCustomerAddress] = useState<Address>();
  const [isBidSubmitting, setIsBidSubmitting] = useState(false);
  const [facilityDetails, setFacilityDetails] = useState<Facility>();
  // const [showJobInstructionsError, setShowJobInstructionsError] = useState(false);
  const [email, setEmail] = useState('');
  const [customerNotes, setCustomerNotes] = useState('');
  const [isViewOnly, setIsViewOnly] = useState(false);
  const location = useLocation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [currentBidVersion, setCurrentBidVersion] = useState<number>(0);
  const [isBidVersionLoader, setIsBidVersionLoader] = useState(false);
  const [bidSummary, setBidSummary] = useState<any>({});
  const [isShowSessionExpirePopUp, setIsShowSessionExpirePopUp] = useState(false);
  const [isShowSessionExpirePopUpLoading, setIsShowSessionExpirePopUpLoading] = useState(false);

  // Bid/ Vendor table total amount
  // const [subVendorTotalEstimation, setSubVendorTotalEstimation] = useState(0);

  // Bid/ Vendor table total amount
  // const [primeVendorTotalEstimation, setPrimeVendorTotalEstimation] = useState(0);

  const [additionalExpenseTotal, setAdditionalExpenseTotal] = useState(0);
  // const [primeVendorEstimations, setPrimeVendorEstimations] = useState<any[]>(() => []);
  // const [subVendorEstimations, setSubVendorEstimations] = useState<any[]>(() => []);
  const [customerApprovals, setCustomerApprovals] = useState([]);
  const [isCustomerApprovalsLoading, setCustomerApprovalsLoading] = useState(false);
  // Value of profit margin
  const [profitPercentage, setProfitPercentage] = useState(0);
  // const [selectedProfitOption, setSelectedProfitOption] = useState(ProfitOption.Percentage);

  const [isCustomerApprovalFileAvailable, setIsCustomerApprovalFileAvailable] = useState(false);
  const [jobInstructions, setJobInstructions] = useState('');
  // Additional table total
  const [additionalSubTotal, setAdditionalSubTotal] = useState(0);
  const [additionalExpenseData, setAdditionalExpenseData] = useState<any[]>([]);
  const [additionalExpensesOptions, setAdditionalExpensesOptions] = useState([
    {
      name: '',
      additionalCategoryId: '',
      description: '',
      rate: '',
      bidAdditionalExpenseId: '',
    },
  ]);
  const [uploadedInvoices, setUploadedInvoices] = useState<any[]>([]);
  const [isDataUploading, setIsDataUploading] = useState<boolean>(false);
  const [vendorQuotes, setVendorQuotes] = useState([]);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);
  const [isFacilityLoading, setIsFacilityLoading] = useState<boolean>(false);
  const [isConfirmDisable, setIsConfirmDisable] = useState<boolean>(true);
  const [images, setImages] = useState<any[]>([]);
  const [videos, setVideos] = useState<any[]>([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [mediaType, setMediaType] = useState<MediaType>('photo');
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(false);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [isJobSubmitted, setIsJobSubmitted] = useState<boolean>(false);
  const [jobNumber, setJobNumber] = useState(location.state?.bidDetails?.jobNumber ?? '');
  const [isJobNumberLoading, setIsJobNumberLoading] = useState<boolean>(false);
  const [isResendingEmail, setIsResendingEmail] = useState<boolean>(false);
  const [vendorRatesAndTaxes, setVendorRatesAndTaxes] = useState([]);
  const [unionIndicator, setUnionIndicator] = useState<string>('Non-Union');
  const [isServicesLoading, setIsServicesLoading] = useState<boolean>(false);
  const [ptiType, setPtiType] = useState<string>('NoOverTime');
  const [isDiscardQuote, setIsDiscardQuote] = useState(false);
  const [isDiscardQuoteLoading, setIsDiscardQuoteLoading] = useState<boolean>(false);
  const [bidId, setBidId] = useState<string>('');
  const [jobId, setJobId] = useState<string>('');
  const [jobCustodian, setJobCustodian] = useState<string>('');
  const [isEditDateModalOpen, setIsEditDateModalOpen] = useState<boolean>(false);
  const [isJobDatesUpdating, setIsJobDatesUpdating] = useState<boolean>(false);
  const [bidDetails, setBidDetails] = useState(location.state?.bidDetails);
  const [pricingOptionsList, setPricingOptionsList] = useState<any>([{ value: 'Hours', label: 'Hours', id: 1 }]);
  const [selectedPricingOption, setSelectedPricingOption] = useState<PricingOptions>(PricingOptions.Hours);
  // const bidDetails = location.state?.bidDetails;
  const { handleSubmit, control, setError, setValue } = useForm({
    defaultValues: {
      startDate: convertDateFormatToIso(bidDetails?.startDate) ?? null,
      endDate: convertDateFormatToIso(bidDetails?.stopDate) ?? null,
    },
  });

  const { role, isDistrictManager } = getAuthData();

  const authValue = localStorage.getItem('auth');
  let userId: string = '';
  if (authValue !== null) {
    const authData = JSON.parse(authValue);
    // vendorEmail = authData.attributes['email'];
    userId = authData?.username;
  }
  // const [emailCc, setEmailCc] = useState<string>(vendorEmail);
  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleOpenEditDateModal = () => {
    setIsEditDateModalOpen(true);
  };

  const handleClose = () => {
    const startDate = formatDate(bidDetails?.startDate);
    const stopDate = formatDate(bidDetails?.stopDate);
    setValue(FieldNames.startDate, startDate);
    setValue(FieldNames.endDate, stopDate);
    setIsEditDateModalOpen(false);
  };

  const renderEditDateHeaderContent = () => {
    return <HeadingText>{t('assignJob:shiftTimings')}</HeadingText>;
  };

  const renderEditDateBodyContent = () => {
    return (
      <>
        <Details>{t('assignJob:dateRangeToCompleteJobWithin')}</Details>
        <DatePickerContainer>
          <DateSelection
            requiredText={t('oneTimeJob:startDateFieldReq')}
            control={control}
            isExcludePastDate={false}
            fieldName={FieldNames.startDate ?? null}
            label={t('calendar:startDate')}
            iconColor="black"
          />
          <DateSelection
            requiredText={t('oneTimeJob:endDateFieldReq')}
            control={control}
            isExcludePastDate={false}
            fieldName={FieldNames.endDate ?? null}
            label={t('calendar:endDate')}
            iconColor="black"
          />
        </DatePickerContainer>
      </>
    );
  };

  const handleDateError = () => {
    setError(FieldNames.endDate, {
      type: 'validate',
      message: 'End date cannot be before start date.',
    });
    setIsJobDatesUpdating(false);
  };

  const handleApiError = () => {
    setSnackbarMessage('Error occurred while updating Job Date');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
    setIsJobDatesUpdating(false);
    return false;
  };

  const updateJobDates = async (startDate: string, endDate: string, jobId: string) => {
    const { customerId = '' } = getStoredCustomerDetails();
    const updateJobResponse = await JobCreateApiService.updateJob(customerId, jobId, {
      startDate: startDate ?? null,
      stopDate: endDate ?? null,
    });
    if (!isEmpty(updateJobResponse.errors)) {
      return handleApiError();
    }
    return true;
  };

  const handleChangeDate = async (dateDetails: any) => {
    const { startDate, endDate } = dateDetails;

    setIsJobDatesUpdating(true);
    const formatStartDate = formatDate(startDate, 'YYYY-MM-DD');
    const formatEndDate = formatDate(endDate, 'YYYY-MM-DD');

    if (startDate && isDateBefore(endDate, startDate)) {
      handleDateError();
      return;
    }

    try {
      const updateJobDateResponse = await updateJobDates(formatStartDate, formatEndDate, jobId);
      if (!updateJobDateResponse) return;
      const subJobId = location.state?.bidDetails?.subJobId;

      if (subJobId) {
        const updateSubJobDateResponse = await updateJobDates(formatStartDate, formatEndDate, subJobId);
        if (!updateSubJobDateResponse) return;
      }
      await fetchJobDates();
      setIsEditDateModalOpen(false);
    } catch (error) {
      handleApiError();
    } finally {
      setIsJobDatesUpdating(false);
    }
  };

  const fetchJobDates = async () => {
    const { customerId = '' } = getStoredCustomerDetails();
    try {
      const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, location.state.bidDetails.jobId);
      const startDate = formatDate(jobSummaryResponse.data.startDate);
      const stopDate = formatDate(jobSummaryResponse.data.stopDate);
      setValue(FieldNames.startDate, startDate);
      setValue(FieldNames.endDate, stopDate);
      setBidDetails({
        ...bidDetails,
        startDate: jobSummaryResponse.data.startDate,
        stopDate: jobSummaryResponse.data.stopDate,
      });
      if (storedJob) {
        const currentJob = JSON.parse(storedJob);
        setStoredJob(
          JSON.stringify({
            ...currentJob,
            startDate: jobSummaryResponse.data.startDate,
            stopDate: jobSummaryResponse.data.stopDate,
          })
        );
        localStorage.setItem(
          AsyncStorageKeys.viewJob,
          JSON.stringify({
            ...currentJob,
            startDate: jobSummaryResponse.data.startDate,
            stopDate: jobSummaryResponse.data.stopDate,
          })
        );
      }
    } catch (e) {
      console.log('error while fetching job dates:', e);
      setSnackbarMessage(`An error occurred while fetching job dates`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const renderEditJobDatesIcon = () => {
    return (
      <>
        {(isDistrictManager ||
          (role === UserType.Customer && location.state.bidDetails.dataStatus !== JobStatus.Awarded)) && (
          <IconButton onClick={() => handleOpenEditDateModal()}>
            <img
              src={Icons.EditBlack}
              alt={t('altTexts:edit')}
              style={{ width: '1.3rem', height: '1.3rem', color: theme.palette.secondary.contrastText }}
            />
          </IconButton>
        )}
      </>
    );
  };

  const getAdditionalExpensesMasterData = async () => {
    const categoriesResponse = await MasterDataApiService.getMasterDataCategories(MasterData.AdditionalExpenses);
    const finalResponse = categoriesResponse?.data.map((dataItem: any) => {
      return {
        name: dataItem.value,
        additionalCategoryId: dataItem.keyCode,
        description: dataItem.subKey2Value,
        rate: '',
        bidAdditionalExpenseId: '',
      };
    });
    setAdditionalExpensesOptions(finalResponse);
  };

  const getCustomerDetails = async (customerId: string) => {
    try {
      setIsCustomerLoading(true);
      const customerResponse = await CustomerApiService.getCustomer(customerId);
      const customerDetailsResponse = await CustomerApiService.getCustomerDetails(customerId);
      const customerAddressResponse = await CustomerApiService.getCustomerAddress(customerId);
      if (customerResponse.data) {
        setCustomer(customerResponse.data);
      }
      if (customerDetailsResponse.data?.customerDetails?.[0]) {
        setCustomerDetails(customerDetailsResponse.data.customerDetails[0]);
        if (!(location.state && location.state.bidDetails.customerEmail))
          setEmail(customerDetailsResponse.data.customerDetails[0].email);
      }
      if (customerAddressResponse.data) {
        setCustomerAddress(customerAddressResponse.data);
      }
      setIsCustomerLoading(false);
    } catch (error) {
      setIsCustomerLoading(false);
      console.log('customer error', error);
    }
  };

  const getFacilityDetails = async (customerId: string, facilityId: string) => {
    try {
      setIsFacilityLoading(true);
      const facilityResponse = await FacilityApiService.facility(facilityId, customerId);
      if (facilityResponse.errors.length) {
        setIsFacilityLoading(false);
      }

      if (facilityResponse.data) {
        setFacilityDetails(facilityResponse.data);
        setIsFacilityLoading(false);
      }
    } catch (error) {
      setIsFacilityLoading(false);
      console.log('facility error', error);
    }
  };

  const handlePriorityChange = () => {
    if (isViewOnly) {
      return;
    }
    const storedJob = localStorage.getItem(AsyncStorageKeys.currentJob);
    if (storedJob) {
      const currentJob = JSON.parse(storedJob);
      const jobDetails = {
        ...currentJob,
        hasCustomerApproval: !isCustomerApprovalFileAvailable,
      };
      localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify(jobDetails));
    }
    setIsCustomerApprovalFileAvailable(!isCustomerApprovalFileAvailable);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleJobInstructions = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setJobInstructions(e.target.value);
  };

  const isValidJobInstructions = (instruction: string) => {
    return Patterns.WorkDescription.test(instruction);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerNotes(e.target.value);
  };

  const handleDiscardTogglePopup = () => {
    setIsDiscardQuote(!isDiscardQuote);
  };

  const handleSubBidSubmission = async (vendorDetails: any, userEmail: string) => {
    let updateSubBidPayload: any;
    if (currentJob?.vendorDetails) {
      updateSubBidPayload = {
        vendorName: currentJob?.vendorDetails?.vendorId[0]?.vendorName,
        vendorEmail: currentJob?.vendorDetails?.vendorId[0]?.email,
        isCustomerApproved: isCustomerApprovalFileAvailable ? BooleanType.True : BooleanType.False,
        emailCc: email,
      };
    } else if (
      currentJob.subJobs.length &&
      currentJob.subJobs[0].jobBids.length &&
      currentJob.subJobs[0].jobBids[0].vendorName &&
      currentJob.subJobs[0].jobBids[0].vendorEmail
    ) {
      updateSubBidPayload = {
        vendorName: currentJob.subJobs[0].jobBids[0].vendorName,
        vendorEmail: currentJob.subJobs[0].jobBids[0].vendorEmail,
        emailCc: email,
      };
    } else {
      updateSubBidPayload = {
        vendorName: vendorDetails.organizationName,
        vendorEmail: userEmail,
        isCustomerApproved: BooleanType.False,
        emailCc: email,
      };
    }
    return await CreateBidServices.submitBid(currentJob?.subJobBidId, updateSubBidPayload);
  };

  const isValidEmail = (email: string) => {
    return Patterns.VelocitiEmail.test(email);
  };

  const generateInvoice = async (vendorCompanyName: string) => {
    try {
      const generateInvoiceInput: generateInvoiceInput = {
        vendorCompanyName,
        noteToCustomer: customerNotes,
      };
      const generateInvoiceResponse = await CreateBidServices.generateInvoice(
        currentJob.jobId,
        currentJob?.bidId ?? currentJob?.jobBids?.[0]?.bidId,
        generateInvoiceInput
      );
      if (generateInvoiceResponse.data.responseCode !== RESPONSE_CODES.BIDS.SUCCESS) {
        setSnackbarMessage(`An error occurred while generating invoice.`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        throw new Error('An error occurred while generating invoice.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addNoteToCustomer = async () => {
    try {
      if (customerNotes && currentJob.jobBids[0].bidId && vendorDetails) {
        const commentsInput = {
          createdBy: vendorDetails,
          keyType: KeyType.JobBid,
          comment: customerNotes,
          commenterType: CommenterType.DistrictManager,
        };
        const response = await JobCreateApiService.addComments(currentJob.jobBids[0].bidId, commentsInput);
        if (!response.data) {
          throw new Error('failed to add note to customer');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const openDialog = async () => {
    try {
      setIsBidSubmitting(true);
      const vendorDetails = await Promise.resolve(getStoredCustomerDetails());
      const authValue = localStorage.getItem('auth');
      if (authValue && vendorDetails) {
        const authData = JSON.parse(authValue);
        const userEmail = authData.attributes['email'];
        const updateBidPayload: any = {
          customerEmail: email,
          vendorName: vendorDetails.organizationName,
          vendorEmail: userEmail,
          comments: customerNotes,
          isCustomerApproved: isCustomerApprovalFileAvailable ? BooleanType.True : BooleanType.False,
        };
        const updateJobResponse = await JobCreateApiService.updateJob(currentJob?.customerId, currentJob?.jobId, {
          specialInstructions: jobInstructions,
        });
        if (updateJobResponse?.errors?.length) {
          setSnackbarMessage(`An error occurred while awarding the job.`);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsBidSubmitting(false);
          return true;
        }
        let updateJob = {} as { data: any; errors: any };
        if (!location?.state?.bidDetails?.isResubmittingBid) {
          updateJob = await JobCreateApiService.openJobForBid(currentJob?.customerId, currentJob?.jobId, {
            customerEmail: email ?? customerDetails?.email,
            customerName: customer?.legalName ?? '',
          });
        }
        if (updateJob?.errors?.length) {
          setSnackbarMessage(`An error occurred while awarding the job.`);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsBidSubmitting(false);
          return true;
        }

        if (
          currentJob?.subJobBidId &&
          bidDetails?.executionType === JobDetails.SubVendor &&
          bidDetails?.estimationType === JobDetails.EstimateOutsidePeazy
        ) {
          await handleSubBidSubmission(vendorDetails, userEmail);
        }
        await addNoteToCustomer();
        await generateInvoice(vendorDetails.organizationName);
        const submitBidResponse = await CreateBidServices.submitBid(
          currentJob?.bidId ?? currentJob?.jobBids[0]?.bidId,
          updateBidPayload
        );
        if (customerApprovals.length > 0 && isCustomerApprovalFileAvailable && submitBidResponse?.data) {
          const markJobAsAwardedResponse = await JobCreateApiService.markJobAsAwarded(
            currentJob?.jobId,
            currentJob?.bidId ?? currentJob?.jobBids[0]?.bidId,
            {
              facilityAddress: customerAddress?.addressName || '',
            }
          );
          setIsBidSubmitting(false);
          if (
            updateJobResponse.data &&
            (location?.state?.bidDetails?.isResubmittingBid ?? updateJob?.data) &&
            submitBidResponse.data &&
            markJobAsAwardedResponse.data
          ) {
            setIsDialogOpen(true);
            setIsJobSubmitted(true);
          } else {
            setSnackbarMessage(`An error occurred while awarding the job.`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
        }
        if (
          updateJobResponse.data &&
          (location?.state?.bidDetails?.isResubmittingBid ?? updateJob?.data) &&
          submitBidResponse.data
        ) {
          setIsDialogOpen(true);
          setIsJobSubmitted(true);
        } else {
          setSnackbarMessage('An error occurred while creating the job.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsBidSubmitting(false);
        }
      } else {
        setIsBidSubmitting(false);
        setSnackbarMessage(`User not found.`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (e) {
      setSnackbarMessage(`An error occurred while creating the job.`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsBidSubmitting(false);
      setIsDialogOpen(false);
    }
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    if (
      bidDetails?.executionType === JobDetails.SubVendor &&
      bidDetails?.estimationType === JobDetails.EstimateWithinPeazy
    ) {
      navigate('/vendor-jobs/sub-contractor-jobs');
    } else {
      navigate('/vendor-jobs/self-performed-jobs');
    }
  };

  // const isValidJobInstructions = (instructions: string) => {
  //   return Patterns.TitleWithDigitsAndSpecialCharacters.test(instructions);
  // };

  const getBidAttachments = async (bidId: string) => {
    try {
      setCustomerApprovalsLoading(true);
      const bidAttachments = await CreateBidServices.allBidAttachmentsWithLatestVersion({
        bidIds: [bidId],
        attachmentsBasedOnVersionsIndicator: BooleanType.False,
      });
      const modifiedBidAttachmentsResponse =
        bidAttachments?.data?.filter(
          (item: IAllBidAttachments) => item.mdFileContext !== 'Quote' && item.mdFileContext !== 'Invoice'
        ) ?? [];
      if (isEmpty(modifiedBidAttachmentsResponse)) {
        setCustomerApprovals([]);
        setCustomerApprovalsLoading(false);
        throw new Error('failed to fetch bid attachments');
      }
      const modifiedBidAttachments =
        modifiedBidAttachmentsResponse?.filter(
          (item: IAllBidAttachments) => item.mdFileContext !== 'Quote' && item.mdFileContext !== 'Invoice'
        ) ?? [];
      setCustomerApprovals(modifiedBidAttachments);
      setCustomerApprovalsLoading(false);
    } catch (e) {
      console.log(e);
      setCustomerApprovals([]);
      setCustomerApprovalsLoading(false);
    }
  };

  const getVendorQuoteAttachments = async (bidId: string) => {
    try {
      const vendorQuoteAttachments = await CreateBidServices.allBidAttachmentsWithLatestVersion({
        bidIds: [bidId],
        attachmentsBasedOnVersionsIndicator: BooleanType.False,
      });
      const modifiedVendorQuoteAttachments =
        vendorQuoteAttachments?.data?.filter(
          (item: IAllBidAttachments) => item.mdFileContext !== 'Quote' && item.mdFileContext !== 'Invoice'
        ) ?? [];
      setVendorQuotes(modifiedVendorQuoteAttachments);
    } catch (e) {
      console.log('vendorQuoteAttachments error: ', e);
    }
  };

  const getNotesToCustomer = async () => {
    try {
      const commentsResponse = await JobCreateApiService.getComments(bidDetails?.jobBids[0]?.bidId);
      if (!commentsResponse?.data) {
        throw new Error('failed to fetch notes for customer');
      }
      const filteredComment = commentsResponse.data.find(
        (comment: CommentResponseModel) =>
          comment.keyType === KeyType.JobBid && comment.commenterType === CommenterType.DistrictManager
      );
      setCustomerNotes(filteredComment?.comment || '');
    } catch (error) {
      console.error('Error', error);
    }
  };

  // const getOverTimeType = () => {
  //   const bidDetails = location?.state?.bidDetails;
  //   const overTimeType = bidDetails?.ptiType
  //     ? bidDetails?.ptiType
  //     : bidDetails?.jobEstimations?.[0]?.isOverTime === YesNoType.N
  //       ? 'NoOverTime'
  //       : bidDetails?.jobEstimations?.[0]?.isOverTime === YesNoType.O
  //         ? '1.5x'
  //         : 'DoublePay';
  //   setPtiType(overTimeType);
  // };

  const getProfitMargin = async () => {
    // const { customerId = '' } = getStoredCustomerDetails() || {};
    // const jobId = location.state?.bidDetails?.jobId;
    // const jobBidsResponse = await JobCreateApiService.jobBidResponse(jobId, customerId);
    const bidId = location.state?.bidDetails?.bidId || location.state?.bidDetails?.jobBids?.[0]?.bidId;
    const bidSummaryResponse = await VendorApiService.getBidSummary(bidId);
    setProfitPercentage(bidSummaryResponse?.data?.profitMargin.toFixed(2));
    // setSelectedProfitOption(
    //   bidSummaryResponse?.data?.isFlatMargin === 'N' ? ProfitOption.Percentage : ProfitOption.Dollar
    // );
  };

  const getServiceCategories = async (customerId: string) => {
    try {
      const masterData = await MasterDataApiService.getAllServiceCategoryV3(customerId);
      return masterData.data ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const getServiceUnits = async (jobId: string) => {
    const { customerId = '' } = getStoredCustomerDetails() || {};
    const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, jobId);
    if (jobSummaryResponse.data && isEmpty(jobSummaryResponse.errors)) {
      return jobSummaryResponse.data.jobServices[0].actualMdServiceUnits;
    }
    return 0;
  };

  const isPricingOptionsHasValue = (serviceMeasure: string) => {
    const pricingOptionsArray = Object.values(PricingOptions);
    for (const option of pricingOptionsArray) {
      if (option === (serviceMeasure as PricingOptions)) {
        return true;
      }
    }
    return false;
  };

  const getBidSummaryResponse = async (bidId: string) => {
    try {
      setIsBidVersionLoader(true);
      const bidSummaryResponse = await VendorApiService.getBidSummary(bidId);
      if (bidSummaryResponse?.data && bidSummaryResponse?.errors?.length === 0) {
        setBidSummary(bidSummaryResponse.data);
        setCurrentBidVersion(bidSummaryResponse.data.bidVersion ?? 0);
        const additionalExpenses = bidSummaryResponse.data.bidAdditionalExpenses.map((expense: any) => {
          return {
            bidAdditionalExpenseId: expense.bidAdditionalExpenseId,
            additionalCategoryId: expense.mdAdditionalExpenseCategory,
            mdServiceId: expense.name,
            name: expense.name,
            totalJobEstimation: expense.totalCost,
          };
        });
        setAdditionalSubTotal(
          additionalExpenses
            ?.reduce((sum: number, element: any) => sum + parseFloat(element.totalJobEstimation), 0)
            .toFixed(2)
        );
        setAdditionalExpenseData(additionalExpenses);
        const serviceUnits = await getServiceUnits(location.state.bidDetails.jobId);
        const serviceCategories = await getServiceCategories(bidSummaryResponse.data.customerId);
        const jobServices = bidSummaryResponse?.data?.bidSimpleEstimation?.map((service: any, index: number) => {
          setSelectedPricingOption(
            isPricingOptionsHasValue(service.mdMeasureType) ? service.mdMeasureType : PricingOptions.Hours
          );
          const selectedService: any = serviceCategories.find(
            (serviceCategoryItem: ServiceCategoryV3) => serviceCategoryItem.serviceCategoryId === service.mdCategoryId
          );
          return {
            estimationId: service.estimationId,
            id: index,
            mdServiceId: service.mdCategoryId,
            numOfHours: service.quantity,
            quantity: service.quantity,
            ratePerHour: service.rate,
            service: selectedService?.serviceCategoryName ?? '',
            serviceMeasure: service.mdMeasureType,
            totalJobEstimation: service.totalCost,
            totalServices: 1,
            unit: serviceUnits ?? service.mdUnits,
          };
        });
        if (storedJob) {
          const storedJobDetails = JSON.parse(storedJob);
          setStoredJob(JSON.stringify({ ...storedJobDetails, jobServices: jobServices }));
          localStorage.setItem(
            AsyncStorageKeys.viewJob,
            JSON.stringify({ ...storedJobDetails, jobServices: jobServices })
          );
        }

        const overTimeType =
          bidSummaryResponse?.data?.bidSimpleEstimation?.[0].isOverTime === YesNoType.N ? 'NoOverTime' : '1.5x';
        setPtiType(overTimeType);
      }
      setIsBidVersionLoader(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsBidVersionLoader(false);
    }
  };

  const fetchAllPricingOptions = async () => {
    try {
      const pricingOptionsList = await MasterDataApiService.getAllPricingOptions();
      const list = pricingOptionsList.data.map((pricingOption: any) => {
        return {
          ...pricingOption,
          label: pricingOption.subKey1Value,
          id: pricingOption.keyCode,
        };
      });
      setPricingOptionsList([...list]);
    } catch (e) {
      console.error('Errors', e);
      setPricingOptionsList([{ value: 'Hours', label: 'Hours', id: 1 }]);
    }
  };

  const getSelectedPricingOption = () => {
    setSelectedPricingOption(
      isPricingOptionsHasValue(location?.state?.bidDetails?.jobServices?.[0]?.serviceMeasure)
        ? location?.state?.bidDetails?.jobServices?.[0]?.serviceMeasure
        : PricingOptions.Hours
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    fetchAllPricingOptions();
    if (location.state?.bidDetails) {
      localStorage.setItem(AsyncStorageKeys.viewJob, JSON.stringify(location.state?.bidDetails));
      setStoredJob(JSON.stringify(location.state?.bidDetails));
      getSelectedPricingOption();
      fetchJobDates();
      setEmail(location.state.bidDetails.customerEmail);
      getBidSummaryResponse(location.state.bidDetails.jobBids?.[0].bidId ?? location.state.bidDetails.bidId);
      getProfitMargin();
      if (
        location.state.isCreate ||
        location.state.isEditable ||
        (location.state.bidDetails.jobBids[0].dataStatus !== 'S' && location.state.bidDetails.jobStatus !== 'Awarded')
      ) {
        setIsViewOnly(false);
      } else {
        setIsViewOnly(true);
        getNotesToCustomer();
      }
      setJobInstructions(location.state.bidDetails.specialInstructions);

      getCustomerDetails(location.state.bidDetails?.customerId);
      getFacilityDetails(
        location.state.bidDetails?.customerId,
        location.state.bidDetails?.jobFacilities?.[0].facilityId
      );
      // setAdditionalSubTotal(location.state?.bidDetails.additionalSubTotal);
      getAdditionalExpensesMasterData();
      setBidId(location.state?.bidDetails.jobBids?.[0]?.bidId);
      setJobId(location.state?.bidDetails?.jobId);
      setJobCustodian(location.state?.bidDetails?.jobCustodian);
      getBidAttachments(location.state?.bidDetails.jobBids?.[0]?.bidId ?? location.state?.bidDetails?.bidId);
      if (location.state.bidDetails.subJobBidId) getVendorQuoteAttachments(location.state.bidDetails.subJobBidId);
    } else {
      getCustomerDetails(currentJob?.customerId);
      getBidAttachments(currentJob.bidId ? currentJob.bidId : currentJob.jobBids[0].bidId);
      if (currentJob?.subJobBidId) getVendorQuoteAttachments(currentJob?.subJobBidId);
    }
    // if (isViewOnly) {
    //   getBidAttachments(location.state.bidDetails.jobBids?.[0]?.bidId);
    // } else {
    //   getBidAttachments(currentJob?.bidId);
    // }
    fetchAllJobAttachments();
    if (!jobNumber) getJobNumber();
    getVendorStandardRatesAndPTITaxes();
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const fileDetails = Array.from(selectedFiles).map((file) => {
        const attachmentUniqueId = uuidv4();
        const fileNameSplits = file.name.split('.');
        return {
          url: URL.createObjectURL(file),
          fileName: fileNameSplits[0],
          fileExtension: fileNameSplits[fileNameSplits.length - 1],
          mdAttachmentType: file.type.includes('image') ? DocumentType.Image : DocumentType.PDF,
          attachmentId: '',
          id: attachmentUniqueId,
        };
      });
      setUploadedInvoices([...uploadedInvoices, ...fileDetails]);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (uploadedInvoices.length) {
      createBidAttachment();
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [uploadedInvoices]);

  const handleAttachmentErrors = (errorMessage = '') => {
    setIsDataUploading(false);
    setSnackbarMessage(!isEmpty(errorMessage) ? errorMessage : `An error occurred while uploading the files.`);
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
    setUploadedInvoices([]);
  };

  const handleSuccessfulUpload = (bidId: string, createBidAttachmentPayload: any[]) => {
    getBidAttachments(bidId);
    setIsDataUploading(false);
    if (createBidAttachmentPayload.length > 0 && createBidAttachmentPayload.length === uploadedInvoices.length) {
      setSnackbarMessage(`Customer approval uploaded successfully!`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage(`Some files are not uploaded. Please retry the failed one`);
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
    setUploadedInvoices([]);
  };

  const createBidAttachment = async () => {
    try {
      setIsDataUploading(true);
      const savedS3BucketKeys = await storeAttachmentsToS3Cloud(uploadedInvoices);
      if (savedS3BucketKeys?.length ?? 0) {
        const createBidAttachmentPayload: CreateBidAttachmentInput[] = [];
        uploadedInvoices?.forEach((item: any, index: number) => {
          const url = savedS3BucketKeys[index];
          if (url) {
            createBidAttachmentPayload.push({
              mdAttachmentType: item?.mdAttachmentType ?? '',
              fileName: item?.fileName + '.' + item?.fileExtension,
              fileExtension: item?.fileExtension,
              url: url,
              mdFileContext: FileContextType.CustomerApproval,
            });
          }
        });
        const bidId = currentJob?.bidId ? currentJob?.bidId : currentJob?.jobBids?.[0]?.bidId;
        const createBidAttachmentResponse = await CreateBidServices.createBidAttachments(
          bidId,
          createBidAttachmentPayload
        );

        if (createBidAttachmentResponse?.errors?.length !== 0) {
          handleAttachmentErrors();
        } else {
          handleSuccessfulUpload(bidId, createBidAttachmentPayload);
        }
      } else {
        handleAttachmentErrors('Failed to upload file to cloud');
      }
    } catch (error) {
      setIsDataUploading(false);
      console.log('error', error);
    }
  };

  const handleDeleteDidAttachment = async (attachmentId: string) => {
    try {
      const deleteAttachmentResponse = await CreateBidServices.deleteBidAttachments(
        currentJob.bidId ? currentJob.bidId : currentJob.jobBids[0].bidId,
        [attachmentId]
      );
      if (deleteAttachmentResponse.errors.length !== 0) {
        setSnackbarMessage(`An error occurred while deleting the file.`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        getBidAttachments(currentJob.bidId ? currentJob.bidId : currentJob.jobBids[0].bidId);
        setSnackbarMessage(`File deleted successfully!`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleFileView = async (key: string) => {
    const link = await fetchDataFromS3Bucket(navigate, key);
    window.open(link);
  };

  const handleInvoice = async (key: string) => {
    const invoiceLink = await fetchDataFromS3Bucket(navigate, key);
    window.open(invoiceLink);
  };

  const storeAttachmentsToS3Cloud = async (attachments: any[] = []) => {
    try {
      const customerDetails = localStorage.getItem('customerDetails');
      const vendorDetails = customerDetails ? JSON.parse(customerDetails).organization : {};
      const uploadPromises = attachments?.map(async (data: any) => {
        try {
          const uploadDocumentResponse = await uploadDocumentToS3Bucket(
            navigate,
            `vendor/${vendorDetails?.organizationId}/bid-attachments/${
              currentJob?.bidId ? currentJob?.bidId : currentJob?.jobBids?.[0]?.bidId
            }/version${currentBidVersion ?? 0}/${data?.id}.${data?.fileExtension}`,
            data?.url ?? '',
            {
              level: 'public',
            },
            data?.fileExtension
          );
          return uploadDocumentResponse?.file?.key || '';
          // return `${uploadDocumentResponse?.file?.key}version:${currentBidVersion ?? 0}`;
        } catch (error: any) {
          console.error('Error uploading document:', error);
          throw new Error(error);
        }
      });

      const uploadDocumentData = await Promise.allSettled(uploadPromises);
      return uploadDocumentData
        .filter((result) => result.status === 'fulfilled')
        .map((result: { status: string; value?: string }) => result.value?.toString());
    } catch (error) {
      console.error('error', error);
      return [];
    }
  };
  const setConfirmCustomerApprovalDisable = () => {
    setIsConfirmDisable(
      (isCustomerApprovalFileAvailable && customerApprovals.length === 0) ||
        (!isCustomerApprovalFileAvailable && !email) ||
        isDataUploading
    );
  };

  const fetchAllJobAttachments = async () => {
    try {
      setIsMediaLoading(true);
      const updatedJobId = bidDetails.jobId;
      const allJobAttachmentInput = { jobIds: [updatedJobId] };
      const attachmentResponse = await JobCreateApiService.getAllJobAttachments(allJobAttachmentInput);
      if (isEmpty(attachmentResponse.errors)) {
        const updatedDetails = await Promise.all(
          attachmentResponse.data.map(async (details: IDetailsProps) => {
            const attachmentUrl = await fetchDataFromS3Bucket(navigate, details?.url ?? '');
            return {
              ...details,
              url: attachmentUrl,
              id: details.attachmentId,
              attachmentId: details.attachmentId,
              responseUrl: details.url,
            };
          })
        );
        const imagesList: any[] = [];
        const videosList: any[] = [];
        updatedDetails.forEach((attachment: any) => {
          if (attachment.mdAttachmentType.includes('image')) {
            imagesList.push(attachment);
          } else {
            videosList.push(attachment);
          }
        });
        setImages([...images, ...imagesList]);
        setVideos([...videos, ...videosList]);
        setIsMediaLoading(false);
      } else {
        setIsMediaLoading(false);
      }
    } catch (e) {
      setIsMediaLoading(false);
      console.log('error', e);
    }
  };

  const openMediaPopup = (type: MediaType) => {
    const mediaArray = type === 'photo' ? images : videos;
    if (mediaArray.length) {
      setPopupOpen(true);
      setMediaType(type);
    }
  };

  const closeMediaPopup = () => {
    setPopupOpen(false);
    setCurrentMediaIndex(0);
  };

  const showMedia = (direction: 'previous' | 'next') => {
    setCurrentMediaIndex((prevIndex) => {
      const mediaArray = mediaType === 'photo' ? images : videos;
      return direction === 'previous'
        ? (prevIndex - 1 + mediaArray?.length) % mediaArray?.length
        : (prevIndex + 1) % mediaArray?.length;
    });
  };

  const getVendorId = async () => {
    const { customerId } = await Promise.resolve(getStoredCustomerDetails());
    if (customerId) {
      setVendorDetails(customerId);
    }
  };

  const handleResendEmail = async () => {
    try {
      setIsResendingEmail(true);
      const response = await OneTimeJobApiService.resendEmail(currentJob?.jobBids[0]?.bidId);
      if (!response.data) {
        throw new Error('Failed to resend email');
      }
      setSnackbarMessage('Successfully re-sent email!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error: any) {
      console.error(error.message);
      setSnackbarMessage(error.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsResendingEmail(false);
    }
  };

  const getJobNumber = async () => {
    setIsJobNumberLoading(true);
    const customerDetails = await Promise.resolve(getStoredCustomerDetails());
    const recommendedJobs = (await JobCreateApiService.getRegionalJobs(
      customerDetails.customerId,
      userId
    )) as RecommendedJobsResponse;
    const selectedFacility = recommendedJobs.data.find(
      (facility) => facility.facilityId === location.state?.bidDetails?.facilityId
    );
    setJobNumber(selectedFacility?.jobNumber);
    setIsJobNumberLoading(false);
  };

  const getTotalCost = () => {
    if (!isEmpty(bidSummary)) {
      return bidSummary.totalCost.toFixed(2);
    }
  };

  // const grossProfitCalculatedValue = () => {
  //   if (location?.state?.bidDetails?.jobBids?.[0]?.isFlatMargin === 'Y') {
  //     const profitMargin = parseFloat(location?.state?.bidDetails?.jobBids?.[0]?.profitMargin ?? 0);
  //     const finalQuote = parseFloat(location?.state?.bidDetails?.jobBids?.[0]?.finalQuote ?? 0);
  //     return ((profitMargin / finalQuote) * 100).toFixed(2);
  //   } else {
  //     const totalCost = parseFloat(
  //       location?.state?.bidDetails?.primeVendorTotalEstimation ??
  //         location?.state?.bidDetails?.jobBids?.[0]?.totalCost ??
  //         0
  //     );
  //     const additionalExpenseTotal = parseFloat(
  //       location?.state?.bidDetails?.additionalExpenseData?.reduce(
  //         (total: number, expense: any) => total + (expense?.totalJobEstimation ?? 0),
  //         0
  //       ) ?? 0
  //     );
  //     const vendorsQuote = parseFloat(location?.state?.bidDetails?.subJobBids?.[0]?.totalCost ?? 0);
  //     const profitMargin = parseFloat(location?.state?.bidDetails?.jobBids?.[0]?.profitMargin ?? 0);
  //     return ((totalCost + additionalExpenseTotal + vendorsQuote) * (profitMargin / 100)).toFixed(2);
  //   }
  // };

  // const grossProfitPercentageValue = () => {
  //   const grossProfitValue = parseFloat(grossProfitCalculatedValue());
  //   const finalQuote = parseFloat(location?.state?.bidDetails?.jobBids?.[0]?.finalQuote ?? 0);
  //   if (grossProfitValue && finalQuote) {
  //     return ((grossProfitValue / finalQuote) * 100).toFixed(2);
  //   }
  //   return 0;
  // };

  const getProfitValue = () => {
    if (!isEmpty(bidSummary)) {
      if (bidSummary.isFlatMargin === 'N') {
        const totalCost = bidSummary.totalCost;
        const profitMargin = bidSummary.profitMargin;
        return ((totalCost * profitMargin) / 100).toFixed(2);
      } else {
        return bidSummary.profitMargin.toFixed(2);
      }
    } else {
      return '0.00';
    }
  };

  const getVendorQuote = () => {
    // if (isViewOnly || location.state.bidDetails.jobStatus === JobStatus.PendingCustomerApproval) {
    //   return location?.state?.bidDetails?.subJobBids?.length
    //     ? location?.state?.bidDetails?.subJobBids?.[0]?.totalCost?.toFixed(2)
    //     : '0.00';
    // } else {
    //   return currentJob.subJobServices
    //     ?.reduce((sum: number, element: any) => sum + parseFloat(element.totalJobEstimation), 0)
    //     .toFixed(2);
    // }
    return location.state.bidDetails.subJobServices
      .reduce((sum: number, serviceItem: any) => {
        return sum + parseFloat(String(serviceItem.totalJobEstimation));
      }, 0)
      .toFixed(2);
  };
  const getFinalQuote = () => {
    if (!isEmpty(bidSummary)) {
      return bidSummary.finalQuote.toFixed(2);
    } else {
      return '0.00';
    }
  };

  const getGrossProfitPercentage = () => {
    if (!isEmpty(bidSummary)) {
      const grossProfit = bidSummary.finalQuote - bidSummary.totalCost;
      const finalGrossProfit = parseFloat(((grossProfit * 100) / bidSummary.finalQuote).toFixed(2));
      return isNaN(finalGrossProfit) ? '0.00' : finalGrossProfit;
    } else {
      return '0.00';
    }
  };

  const getVendorStandardRatesAndPTITaxes = async () => {
    setIsServicesLoading(true);
    const { customerId } = await Promise.resolve(getStoredCustomerDetails());
    const recommendedJobs = (await JobCreateApiService.getRegionalJobs(customerId, userId)) as RecommendedJobsResponse;
    let facilityId = '';
    const storedJob = localStorage.getItem(AsyncStorageKeys.viewJob);
    if (storedJob) {
      const parsedJob = JSON.parse(storedJob);
      facilityId = parsedJob?.jobFacilities?.[0]?.facilityId;
    }
    const facility = recommendedJobs.data.find((job) => job.facilityId === facilityId);
    setUnionIndicator(facility?.unionIndicator ?? null);
    const response = await CreateBidServices.getStandardRatesAndPtiTaxes({
      keyId: customerId,
      stateCode: facility?.stateCode,
    });
    if (response.errors.length === 0 && response.data) {
      setVendorRatesAndTaxes(response.data);
    }
    setIsServicesLoading(false);
  };

  const handleOnDiscardQuote = useCallback(async () => {
    if (!isEmpty(jobCustodian)) {
      setIsDiscardQuoteLoading(true);
      try {
        const discardBitEstimationsInput: DeleteJobBidInput = {
          bidIds: [bidId],
          jobId,
          deleteType: DeleteType.Discard,
        };
        const discardBitEstimationsResponse = await VendorApiService.deleteJobBids(discardBitEstimationsInput);

        if (!isEmpty(discardBitEstimationsResponse.errors)) {
          setSnackbarMessage(discardBitEstimationsResponse.errors[0].message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } else if (
          isEmpty(discardBitEstimationsResponse?.errors ?? []) &&
          !isEmpty(discardBitEstimationsResponse?.data ?? {})
        ) {
          setSnackbarMessage('success');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setTimeout(() => {
            navigate('/vendor-jobs/self-performed-jobs');
          }, 1000);
        }
        setIsDiscardQuote(isDiscardQuote);
      } catch (e: any) {
        console.error('Error', e);
      } finally {
        setIsDiscardQuoteLoading(false);
      }
    }
  }, [bidId, jobId, t]);

  useEffect(() => {
    setConfirmCustomerApprovalDisable();
    getVendorId();
  }, [email, isCustomerApprovalFileAvailable, customerApprovals.length, isDataUploading]);

  useEffect(() => {
    if (customerApprovals?.length > 0) {
      setIsCustomerApprovalFileAvailable(true);
    }
  }, [customerApprovals]);

  const signOut = useCallback(async () => {
    try {
      setIsShowSessionExpirePopUpLoading(true);
      await Auth.signOut();
      localStorage.removeItem('auth');
      localStorage.removeItem('customerDetails');
      navigate('/login');
    } catch (error) {
      console.error('An error occurred during sign out:', error);
    } finally {
      setIsShowSessionExpirePopUpLoading(false);
    }
  }, []);

  const LogoutUI = () => {
    return (
      <div>
        {t('logout:logoutConfirm')} <br /> <strong>{t('logout:peazyApplication')}</strong>
      </div>
    );
  };

  return (
    <>
      <PageTitle title={isViewOnly ? t('dashboard:viewDetails') : t('vendor:reviewAndConfirm')}></PageTitle>
      <CustomerContainer>
        <ReviewContainer
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: StyleConstants.JustifyContentBetween,
            gap: '1.5rem',
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <ReviewText>
              {t('vendor:jobNumber')} :{' '}
              {isJobNumberLoading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
              ) : (
                <span style={{ fontWeight: theme.typography.fontWeightBold }}>{jobNumber}</span>
              )}
            </ReviewText>
            <ReviewText>
              {t('vendor:jobName')} :{' '}
              <span style={{ fontWeight: theme.typography.fontWeightBold }}>{bidDetails?.jobName}</span>
            </ReviewText>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <ReviewText>
                {t('vendor:customer')} :{' '}
                {isCustomerLoading ? (
                  <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                ) : (
                  <span style={{ fontWeight: theme.typography.fontWeightBold }}>{customer?.legalName}</span>
                )}
              </ReviewText>
              <ReviewText>
                <span style={{ fontWeight: theme.typography.fontWeightBold }}>{customerAddress?.addressLine3}</span>
              </ReviewText>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <ReviewText>
                {t('vendor:facility')} :{' '}
                {isFacilityLoading ? (
                  <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                ) : (
                  <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                    {/* {isViewOnly ? facilityDetails?.buildingName : bidDetails?.buildingName} */}
                    {isViewOnly
                      ? facilityDetails?.buildingName ||
                        facilityDetails?.address?.addressName ||
                        facilityDetails?.address?.addressLine1 ||
                        facilityDetails?.address?.addressLine2 ||
                        ''
                      : bidDetails?.buildingName ||
                        bidDetails?.address?.addressName ||
                        bidDetails?.address?.addressLine1 ||
                        bidDetails?.address?.addressLine2 ||
                        ''}
                  </span>
                )}
              </ReviewText>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <ReviewText sx={{ display: 'flex' }}>
                {t('vendor:jobVisuals')} :{' '}
                <span style={{ fontWeight: theme.typography.fontWeightBold, display: 'flex', marginLeft: '0.25em' }}>
                  {isMediaLoading ? (
                    <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                  ) : (
                    // <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <>
                      <MediaContainer onClick={() => openMediaPopup('photo')}>
                        <img src={Icons.PhotosIcon} alt={t('altTexts:galleryIcon')} />
                        <MediaText>
                          {images?.length} {images?.length <= 1 ? t('dashboard:photo') : t('dashboard:photos')}
                        </MediaText>
                      </MediaContainer>
                      <Divider />
                      <MediaContainer onClick={() => openMediaPopup('video')}>
                        <img src={Icons.VideoIcon} alt={t('altTexts:videoIcon')} />
                        <MediaText>
                          {videos?.length} {videos?.length <= 1 ? t('dashboard:video') : t('dashboard:videos')}
                        </MediaText>
                      </MediaContainer>
                    </>
                    // </Box>
                  )}
                </span>
              </ReviewText>
            </Box>
            <MediaPopup
              isOpen={isPopupOpen}
              onClose={closeMediaPopup}
              currentMediaIndex={currentMediaIndex}
              media={mediaType === 'photo' ? images : videos}
              showPreviousMedia={() => showMedia('previous')}
              showNextMedia={() => showMedia('next')}
              numMedia={mediaType === 'photo' ? images?.length : videos?.length}
              type={mediaType}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <ReviewText>
              {t('vendor:expectedStartAndEndDate')} : {''}
              <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                {formatDate(bidDetails?.startDate)} & {formatDate(bidDetails?.stopDate)}
              </span>
              {renderEditJobDatesIcon()}
            </ReviewText>
            {bidDetails?.startTime ? (
              <ReviewText>
                {t('vendor:expectedShiftStartTime')} :{' '}
                <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                  {getTimeFormat(bidDetails?.startTime)}
                </span>
              </ReviewText>
            ) : null}
            {bidDetails?.endTime ? (
              <ReviewText>
                {t('vendor:expectedShiftEndTime')} :{' '}
                <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                  {getTimeFormat(bidDetails?.endTime)}
                </span>
              </ReviewText>
            ) : null}
          </Box>
        </ReviewContainer>
        <ReviewContainer>
          {bidDetails?.executionType === JobDetails.Self ? (
            <>
              <PrimeVendorExpensesTable
                setTotalEstimation={() => {}}
                estimationType={UserType.SubVendor}
                storedJob={storedJob && JSON.parse(storedJob)}
                setCopyTableData={() => {}}
                profitPercentage={profitPercentage}
                additionalSubTotal={additionalSubTotal}
                vendorRatesAndTaxes={vendorRatesAndTaxes}
                unionIndicator={unionIndicator}
                isServicesLoading={isServicesLoading}
                setIsServicesLoading={setIsServicesLoading}
                ptiType={ptiType}
                setPtiType={setPtiType}
                isEditable={false}
                isCardView={false}
                serviceData=""
                pricingOptionsList={pricingOptionsList}
                selectedPricingOption={selectedPricingOption}
                setSelectedPricingOption={setSelectedPricingOption}
              />
              <AdditionalExpensesTable
                totalEstimation={additionalExpenseTotal}
                setTotalEstimation={setAdditionalExpenseTotal}
                storedJob={storedJob && JSON.parse(storedJob)}
                additionalExpenseData={additionalExpenseData}
                setAdditionalExpenseData={setAdditionalExpenseData}
                additionalSubTotal={additionalSubTotal}
                setAdditionalSubTotal={setAdditionalSubTotal}
                serviceOptions={additionalExpensesOptions}
                handleExpensesError={() => {}}
                isEditable={false}
                isCardView={false}
              />
            </>
          ) : null}
          {bidDetails?.executionType === JobDetails.SubVendor &&
            bidDetails?.estimationType === JobDetails.EstimateWithinPeazy && (
              <>
                <EstimationTable
                  setTotalEstimation={() => {}}
                  estimationType={UserType.SubVendor}
                  storedJob={storedJob && JSON.parse(storedJob)}
                  setCopyTableData={() => {}}
                  profitPercentage={profitPercentage}
                  additionalSubTotal={additionalSubTotal}
                  uploadedVendorQuotes={[]}
                  setUploadedVendorQuotes={() => {}}
                  vendorQuotesAttachments={vendorQuotes}
                  handleDeleteQuoteAttachment={() => {}}
                  isQuoteUploading={false}
                  isEditable={false}
                  isCardView={false}
                  setIsBidVersionLoader={false}
                  selectedPricingOption={selectedPricingOption}
                />
                <PrimeVendorExpensesTable
                  setTotalEstimation={() => {}}
                  estimationType={UserType.SubVendor}
                  storedJob={storedJob && JSON.parse(storedJob)}
                  setCopyTableData={() => {}}
                  profitPercentage={profitPercentage}
                  additionalSubTotal={additionalSubTotal}
                  vendorRatesAndTaxes={vendorRatesAndTaxes}
                  unionIndicator={unionIndicator}
                  isServicesLoading={isServicesLoading}
                  setIsServicesLoading={setIsServicesLoading}
                  ptiType={ptiType}
                  setPtiType={setPtiType}
                  isEditable={false}
                  isCardView={false}
                  serviceData=""
                  pricingOptionsList={pricingOptionsList}
                  selectedPricingOption={selectedPricingOption}
                  setSelectedPricingOption={setSelectedPricingOption}
                />
                <AdditionalExpensesTable
                  totalEstimation={additionalExpenseTotal}
                  setTotalEstimation={setAdditionalExpenseTotal}
                  storedJob={storedJob && JSON.parse(storedJob)}
                  additionalExpenseData={additionalExpenseData}
                  setAdditionalExpenseData={setAdditionalExpenseData}
                  additionalSubTotal={additionalSubTotal}
                  setAdditionalSubTotal={setAdditionalSubTotal}
                  serviceOptions={additionalExpensesOptions}
                  handleExpensesError={() => {}}
                  isEditable={false}
                  isCardView={false}
                />
              </>
            )}
          {bidDetails?.executionType === JobDetails.SubVendor &&
            bidDetails?.estimationType === JobDetails.EstimateOutsidePeazy && (
              <>
                <EstimationTable
                  setTotalEstimation={() => {}}
                  estimationType={UserType.SubVendor}
                  storedJob={storedJob && JSON.parse(storedJob)}
                  setCopyTableData={() => {}}
                  profitPercentage={profitPercentage}
                  additionalSubTotal={additionalSubTotal}
                  uploadedVendorQuotes={[]}
                  setUploadedVendorQuotes={() => {}}
                  vendorQuotesAttachments={vendorQuotes}
                  handleDeleteQuoteAttachment={() => {}}
                  isQuoteUploading={false}
                  isEditable={false}
                  isCardView={false}
                  setIsBidVersionLoader={false}
                  selectedPricingOption={selectedPricingOption}
                />
                <PrimeVendorExpensesTable
                  setTotalEstimation={() => {}}
                  estimationType={UserType.SubVendor}
                  storedJob={storedJob && JSON.parse(storedJob)}
                  setCopyTableData={() => {}}
                  profitPercentage={profitPercentage}
                  additionalSubTotal={additionalSubTotal}
                  vendorRatesAndTaxes={vendorRatesAndTaxes}
                  unionIndicator={unionIndicator}
                  isServicesLoading={isServicesLoading}
                  setIsServicesLoading={setIsServicesLoading}
                  ptiType={ptiType}
                  setPtiType={setPtiType}
                  isEditable={false}
                  isCardView={false}
                  serviceData=""
                  pricingOptionsList={pricingOptionsList}
                  selectedPricingOption={selectedPricingOption}
                  setSelectedPricingOption={setSelectedPricingOption}
                />
                <AdditionalExpensesTable
                  totalEstimation={additionalExpenseTotal}
                  setTotalEstimation={setAdditionalExpenseTotal}
                  storedJob={storedJob && JSON.parse(storedJob)}
                  additionalExpenseData={additionalExpenseData}
                  setAdditionalExpenseData={setAdditionalExpenseData}
                  additionalSubTotal={additionalSubTotal}
                  setAdditionalSubTotal={setAdditionalSubTotal}
                  serviceOptions={additionalExpensesOptions}
                  handleExpensesError={() => {}}
                  isEditable={false}
                  isCardView={false}
                />
              </>
            )}
          <Box sx={{ justifyContent: StyleConstants.JustifyContentBetween, display: 'flex', padding: '0.5rem 0' }}>
            <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>{t('vendor:totalCost')}</Typography>
            <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>
              ${isNaN(Number(getTotalCost())) ? '0.00' : getTotalCost()}
            </Typography>
          </Box>
          <Box sx={{ justifyContent: StyleConstants.JustifyContentBetween, display: 'flex', padding: '0.5rem 0' }}>
            <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>
              {!isEmpty(bidSummary) && bidSummary.isFlatMargin === 'N'
                ? t('vendor:markupPercentage')
                : t('vendor:profitMargin')}
              {!isEmpty(bidSummary) && bidSummary.isFlatMargin === 'N' && (
                <span>@ {bidSummary.profitMargin ? removeLeadingZeros(bidSummary.profitMargin) : 0}%</span>
              )}
            </Typography>
            <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>
              ${isNaN(parseFloat(getProfitValue())) ? '0.00' : getProfitValue()}
            </Typography>
          </Box>
          {bidDetails?.executionType === JobDetails.SubVendor && (
            <Box sx={{ justifyContent: StyleConstants.JustifyContentBetween, display: 'flex', padding: '0.5rem 0' }}>
              <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>
                {' '}
                {t('vendor:vendorQuote')} :{' '}
              </Typography>
              <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>
                ${isNaN(getVendorQuote()) ? '0.00' : getVendorQuote()}
              </Typography>
            </Box>
          )}
          <Box sx={{ justifyContent: StyleConstants.JustifyContentBetween, display: 'flex', padding: '0.5rem 0' }}>
            <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}> {t('vendor:finalQuote')} : </Typography>
            <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>
              ${isNaN(getFinalQuote()) ? '0.00' : getFinalQuote()}
            </Typography>
          </Box>
          <Box sx={{ justifyContent: StyleConstants.JustifyContentBetween, display: 'flex', padding: '0.5rem 0' }}>
            <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}> {t('vendor:grossProfit')} : </Typography>
            <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>
              {!isEmpty(bidSummary)
                ? bidSummary.isFlatMargin === 'N'
                  ? `$${(bidSummary.finalQuote - bidSummary.totalCost).toFixed(2)} (${getGrossProfitPercentage()}%)`
                  : `${isNaN(parseFloat(((bidSummary.profitMargin * 100) / bidSummary.finalQuote).toFixed(2))) ? '0.00' : parseFloat(((bidSummary.profitMargin * 100) / bidSummary.finalQuote).toFixed(2))}%`
                : '$0.00'}
            </Typography>
          </Box>
        </ReviewContainer>

        <ReviewContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}>
          <ReviewText sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {t('vendor:customerEmail')}&nbsp;
            {!isCustomerApprovalFileAvailable ? <span className="mandatory-field">*</span> : null}
          </ReviewText>
          <TextField
            sx={textField}
            label={t('vendor:enterEmail')}
            required={!isCustomerApprovalFileAvailable}
            value={email}
            defaultValue={customerDetails?.email ?? ''}
            onChange={handleEmailChange}
            onBlur={(e) => {
              if (e.target.value && !isCustomerApprovalFileAvailable && !isValidEmail(e.target.value)) {
                setShowEmailError(true);
              } else {
                setShowEmailError(false);
              }
            }}
          />
          {showEmailError && <ErrorMessage>{t('vendor:pleaseEnterValidEmail')}</ErrorMessage>}
          <ReviewText sx={{ fontWeight: theme.typography.fontWeightBold }}>{t('vendor:noteToCustomer')}</ReviewText>
          <TextField
            multiline
            onChange={handleNoteChange}
            value={customerNotes}
            sx={{
              ...textField,
              width: '100%',
              '& .MuiOutlinedInput-input': {
                color: theme.palette.text.primary,
                fontSize: theme.typography.h5?.fontSize,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightRegular,
                lineHeight: '1.375rem',
                letterSpacing: '0.015625rem',
              },
            }}></TextField>
          <ReviewText sx={{ fontWeight: theme.typography.fontWeightBold }}>
            {bidDetails?.estimationType === JobDetails.EstimateOutsidePeazy &&
            bidDetails?.executionType !== JobDetails.Self
              ? `${t('vendor:jobInstructionsForAssociates')}`
              : `${t('vendor:jobInstructionsForSubcontractor')}`}
          </ReviewText>
          <TextField
            multiline
            onChange={(e) => {
              if (isValidJobInstructions(e.target.value)) {
                handleJobInstructions(e);
              }
            }}
            value={jobInstructions}
            sx={{
              ...textField,
              width: '100%',
              '& .MuiOutlinedInput-input': {
                color: theme.palette.text.primary,
                fontSize: theme.typography.h5?.fontSize,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightRegular,
                lineHeight: '1.375rem',
                letterSpacing: '0.015625rem',
              },
            }}></TextField>
        </ReviewContainer>
        <ReviewContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}>
          <ReviewText sx={{ fontWeight: theme.typography.fontWeightBold }}>{t('vendor:customerApproval')}</ReviewText>
          {isViewOnly ? (
            <>
              {location.state.bidDetails.jobStatus === 'Awarded' && (
                <Typography sx={{ fontSize: '0.8rem' }}>{t('vendor:jobHasBeenAwardedByCustomer')}</Typography>
              )}
              {location.state.bidDetails.jobBids[0].dataStatus === 'S' && (
                <Typography sx={{ fontSize: '0.8rem' }}>{t('vendor:jobHasBeenSentForCustomerApproval')}</Typography>
              )}
            </>
          ) : (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '1rem',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  {isCustomerApprovalFileAvailable ? (
                    <img
                      src={Icons.CheckedIcon}
                      alt="Checked Icon"
                      onClick={handlePriorityChange}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <img
                      src={Icons.Unchecked}
                      alt="UnChecked Icon"
                      onClick={handlePriorityChange}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      marginRight: '2.125rem',
                      gap: '1rem',
                      '&.MuiFormControlLabel-root': {
                        '&> span:last-child': {
                          fontFamily: theme.typography.fontFamily,
                          fontSize: theme.typography.h3?.fontSize,
                          fontWeight: theme.typography.fontWeightLight,
                          lineHeight: '1.375rem',
                          letterSpacing: '0.009375rem',
                          color: theme.palette.text.primary,
                        },
                      },
                      '@media (max-width: 64rem)': {
                        '&.MuiFormControlLabel-root': {
                          '&> span:last-child': {
                            fontSize: theme.typography.h3?.fontSize,
                            lineHeight: '1.5rem',
                          },
                        },
                      },
                      marginLeft: '0.5rem',
                    }}>
                    {t('vendor:alreadyHaveCustomerApproval')}
                  </Typography>
                </Box>
              </Box>
              {isCustomerApprovalsLoading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
              ) : null}
              {!isCustomerApprovalsLoading
                ? customerApprovals.map((approvalItem: BidAttachment) => {
                    return (
                      <div style={{ display: 'flex' }}>
                        <Button sx={{ padding: '0' }} onClick={() => handleFileView(approvalItem?.url ?? '')}>
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              textTransform: 'capitalize',
                            }}>{`${approvalItem?.fileName}`}</Typography>
                        </Button>
                        <Button
                          onClick={() => handleDeleteDidAttachment(approvalItem.attachmentId ?? '')}
                          sx={{
                            backgroundColor: 'transparent',
                            padding: 0,
                            margin: 0,
                            minWidth: 0,
                            paddingLeft: '1em',
                          }}>
                          {!isViewOnly && <img src={Icons.DeleteIcon} alt="delete" />}
                        </Button>
                      </div>
                    );
                  })
                : null}
              {!isCustomerApprovalsLoading && customerApprovals.length === 0 && isCustomerApprovalFileAvailable ? (
                <Typography sx={{ fontSize: '0.8rem' }}>{t('noData:noDocumentsFound')}</Typography>
              ) : null}
            </Box>
          )}
        </ReviewContainer>
      </CustomerContainer>
      <BottombarContainer>
        {isViewOnly && location.state.bidDetails.jobStatus === JobStatus.PendingCustomerApproval ? (
          <Button
            sx={button}
            onClick={() => {
              const mBidId =
                currentJob?.jobBids?.[0]?.bidId ??
                location.state.bidDetails.bidId ??
                location.state.bidDetails.jobBids?.[0].bidId;
              handleInvoice(`vendor/${vendorDetails}/bid-attachments/${mBidId}/invoice.pdf`);
            }}
            disabled={isResendingEmail}>
            <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>{t('vendor:viewQuote')}</Typography>
          </Button>
        ) : null}

        {isViewOnly && location.state.bidDetails.jobStatus === JobStatus.PendingCustomerApproval ? (
          <Button sx={button} onClick={handleResendEmail} disabled={isResendingEmail}>
            {isResendingEmail ? (
              <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
            ) : (
              <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>{t('vendor:resendEmail')}</Typography>
            )}
          </Button>
        ) : null}

        {!isViewOnly &&
        !isEmpty(jobCustodian) &&
        (location.state.bidDetails.jobStatus === JobStatus.Awarded ||
          location.state.bidDetails.jobStatus === ModifyQuoteStatus.ContinueQuoteUpdate) &&
        !isCustomerApprovalFileAvailable ? (
          <>
            <Button
              sx={{ ...button, opacity: isDiscardQuoteLoading || isBidSubmitting ? '0.7' : '1' }}
              onClick={() => handleDiscardTogglePopup()}
              disabled={isDiscardQuoteLoading || isBidSubmitting}>
              {isDiscardQuoteLoading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
              ) : (
                <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>
                  {t('assignJob:discardQuote')}
                </Typography>
              )}
            </Button>
          </>
        ) : null}

        {!isViewOnly && isCustomerApprovalFileAvailable ? (
          <>
            <Button
              sx={{ ...button, opacity: isDataUploading || isBidVersionLoader ? '0.7' : '1' }}
              onClick={() => {
                const authInfo = localStorage.getItem('auth');
                const { signedInTime } = JSON.parse(authInfo as string);
                const isPreviousSignedSessionMoreThan_55_minutes = new Date().getTime() - signedInTime > 1000 * 60 * 55; //checking 55 minutes difference from singed in time.
                if (isPreviousSignedSessionMoreThan_55_minutes) {
                  setIsShowSessionExpirePopUp(true);
                } else {
                  handleUploadClick();
                }
              }}
              disabled={isDataUploading || isBidVersionLoader}>
              {isDataUploading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
              ) : (
                <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>
                  {t('vendor:uploadCustomerApproval')}
                </Typography>
              )}
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept=".pdf, .jpg, .jpeg, .png"
              multiple
              onChange={handleFileChange}
            />
          </>
        ) : null}
        {!isViewOnly && !isJobSubmitted ? (
          <>
            <Button
              sx={{
                ...button,
                opacity: isConfirmDisable || isBidSubmitting || isDiscardQuoteLoading || showEmailError ? '0.7' : '1',
              }}
              disabled={isConfirmDisable || isBidSubmitting || isDiscardQuoteLoading || showEmailError}
              onClick={() => {
                if (isCustomerApprovalFileAvailable) {
                  openDialog();
                } else {
                  if (!isValidEmail(email)) {
                    return;
                  } else {
                    openDialog();
                  }
                }
              }}>
              {isBidSubmitting ? (
                <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
              ) : (
                <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>
                  {!isCustomerApprovalFileAvailable
                    ? `${t('vendor:confirmandSendForCustomerApproval')}`
                    : `${t('vendor:confirm')}`}
                </Typography>
              )}
            </Button>
          </>
        ) : null}
        <SuccessScreen
          open={isDialogOpen}
          onClose={closeDialog}
          bidDetails={bidDetails}
          bidVersion={currentBidVersion}
          customerApprovals={customerApprovals}
        />
      </BottombarContainer>
      <ModifyPopUp
        open={isDiscardQuote}
        handleClose={handleDiscardTogglePopup}
        handleOnCloseJob={handleOnDiscardQuote}
        loading={isDiscardQuoteLoading}
        heading={t('assignJob:discardQuote')}
        text={t('assignJob:discardModifyQuote')}
      />
      <Modal
        open={isEditDateModalOpen}
        onClose={handleClose}
        primaryButtonLoading={isJobDatesUpdating}
        primaryButtonLabel={t('dashboard:changeDate')}
        primaryButtonVisible={true}
        secondaryButtonLabel={t('assignJob:cancel')}
        secondaryButtonVisible
        renderHeader={renderEditDateHeaderContent()}
        renderBody={renderEditDateBodyContent()}
        primaryAction={handleSubmit(handleChangeDate)}
        secondaryAction={handleClose}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
      <ApproveDialog
        title={t('logout:sessionTimedOutTitle')}
        loading={isShowSessionExpirePopUpLoading}
        ChildComponent={<LogoutUI />}
        isApproveWindowOpen={isShowSessionExpirePopUp}
        showCancelButton={false}
        isShowCloseIcon={false}
        handleCloseApproveWindow={() => setIsShowSessionExpirePopUp(false)}
        handleApprove={() => signOut()}
      />
    </>
  );
};

export default BidReviewPage;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const TabsWrapper = styled('div')(() => ({
  borderBottom: `0.0625rem solid ${theme.palette.divider}`,
  marginBottom: '1.5rem',
}));

export const Loader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../Themes/theme';

interface ISnackBarProps {
  isApproved: boolean;
}
export const SnackBarWrapper = styled('div')(({ isApproved }: ISnackBarProps) => ({
  display: 'flex',
  justifyContent: 'center',
  '& .MuiSnackbarContent-root': {
    maxWidth: '46.5rem',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10)',
    borderRadius: '1rem',
    border: `0.0625rem solid ${isApproved ? theme.palette.success.main : theme.palette.error.main}`,
    padding: '1rem',
    [theme.breakpoints.down('laptop')]: {
      maxWidth: '26.25rem',
    },
    '& .MuiSnackbarContent-message': {
      padding: 0,
    },
    '& .MuiSnackbarContent-action': {
      cursor: 'pointer',
    },
  },
}));

export const MsgWrapper = styled('div')(() => ({
  display: 'flex',
}));

export const Msg = styled('h3')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  paddingLeft: '1rem',
}));

export const MsgBold = styled('span')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { Box } from '@mui/material';

import FacilityAccessLabel from './FacilityAccessLabel';
import { NoDataFound } from '../../../../Shared/Components/Common/NoDataFound/NoDataFound';

import { Icons } from '../../../../Shared/Constants/Icons';

import { FacilityAccessContainer, NoDataContainer } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';

const FacilityAccessComponent = (props: any) => {
  const { facilityAccess } = props;

  const facilityAccessData = [
    {
      title: facilityAccess?.accessInfo,
      icon: <img src={Icons.AccountIcon} alt="Account Icon" style={{ width: '1.5rem', height: '1.5rem' }} />,
    },
    {
      title: `${facilityAccess?.accessContactPersonName} | ${facilityAccess?.roleName}`,
      icon: <img src={Icons.LockIcon} alt="Account Icon" style={{ width: '1.5rem', height: '1.5rem' }} />,
    },
    {
      title: facilityAccess?.otherInformation,
      icon: <img src={Icons.ParkingIcon} alt="Account Icon" style={{ width: '1.5rem', height: '1.5rem' }} />,
    },
  ];

  return (
    <FacilityAccessContainer>
      <Box sx={{ width: '100%' }}>
        {facilityAccess ? (
          facilityAccessData.map((item, i) => <FacilityAccessLabel key={i} icon={item.icon} label={item.title} />)
        ) : (
          <NoDataContainer>
            <NoDataFound />
          </NoDataContainer>
        )}
      </Box>
    </FacilityAccessContainer>
  );
};

export default FacilityAccessComponent;

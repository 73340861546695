/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useEffect, useState } from 'react';
import { ButtonWrapper, HeadingText, RatingSystemContainer, RatingWrapper, buttonStyles } from './RatingSystem.styles';
import { Button, CircularProgress, Rating, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../../../Shared/Themes/theme';

interface RatingSystem {
  title: string;
  ratingValue: number | null;
  precisionValue: number;
  readOnly: boolean;
  showLoader: boolean;
  handleStarRating: (value: number | null) => void;
}

const RatingSystem = ({ title, ratingValue, precisionValue, readOnly, showLoader, handleStarRating }: RatingSystem) => {
  const { t } = useTranslation(['ratingSystem']);
  const { starRatingButton, cancelButton, buttonText } = buttonStyles;
  const [value, setValue] = useState<number | null>(ratingValue);

  const handleCancel = () => {
    setValue(ratingValue);
  };

  useEffect(() => {
    console.log('ratingValue', ratingValue);
  }, [ratingValue]);

  return (
    <>
      <RatingSystemContainer>
        <HeadingText>{title}</HeadingText>
        <RatingWrapper>
          <Rating
            sx={{
              gap: '1rem',
              '.MuiSvgIcon-root': {
                width: '3rem',
                height: '3rem',
              },
            }}
            name="rating"
            size="large"
            readOnly={readOnly || value || showLoader ? true : false}
            precision={readOnly ? precisionValue : 1}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
        </RatingWrapper>
        {!readOnly && (
          <ButtonWrapper>
            <Button
              sx={{
                ...cancelButton,
                opacity: !value || showLoader ? 0.5 : 1,
              }}
              disabled={!value || showLoader}
              onClick={handleCancel}>
              <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>
                {t('ratingSystem:cancel')}
              </Typography>
            </Button>
            <Button
              sx={{
                ...starRatingButton,
                opacity: !value || showLoader ? 0.5 : 1,
              }}
              disabled={!value || showLoader}
              onClick={() => handleStarRating(value)}>
              {showLoader ? (
                <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
              ) : (
                <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
                  {t('ratingSystem:submitRating')}
                </Typography>
              )}
            </Button>
          </ButtonWrapper>
        )}
      </RatingSystemContainer>
    </>
  );
};

export default RatingSystem;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled, Dialog } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const DialogWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    padding: '1.5rem',
    width: '24.75rem',
    alignItems: 'center',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
}));

export const PopupHeadingText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  marginBottom: '2rem',
  alignSelf: 'flex-start',
}));

export const LogoContainer = styled('div')(() => ({
  border: `0.125rem solid ${theme.palette.warning.dark}`,
  padding: '0.291875rem',
  borderRadius: '50%',
}));

export const ImageField = styled('div')(() => ({
  width: '3.5rem',
  height: '3.5rem',
  background: theme.palette.secondary.customColor7,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme.typography.h5?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
}));

export const NameField = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.25rem',
  letterSpacing: '0.025rem',
  color: theme.palette.text.secondary,
  display: 'flex',
  justifyContent: 'center',
}));

export const FinalQuoteValue = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.success.main,
}));

export const ConfirmText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  color: theme.palette.text.primary,
}));

export const SuccessText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '2rem',
  color: theme.palette.text.primary,
  marginBottom: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const JobAwardedText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.5rem',
  color: theme.palette.text.secondary,
}));

export const LogoAndNameContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0 1.5rem',
  marginBottom: '2rem',
}));

export const Logo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  alignItems: 'center',
  justifyContent: 'center',
  width: '33%',
}));

export const buttonStyles = {
  divider: {
    border: `0.0625rem dashed ${theme.palette.warning.dark}`,
    width: '100%',
    margin: '1.5rem 0',
  },
};

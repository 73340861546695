/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../../Themes/theme';

const WhiteTooltip = withStyles({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    borderRadius: '0.5rem',
    fontSize: theme.typography.h4?.fontSize,
    boxShadow: '0px 10px 20px 6px rgba(0, 107, 107, 0.15)',
    padding: '0.25rem 1rem',
  },
})(Tooltip);

export default WhiteTooltip;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const CurrentLocation = styled('div')(() => ({
  background: theme.palette.common.white,
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: 'column',
}));

export const CurrentLocationText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  marginBottom: '1rem',
}));

export const AddressText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.secondary,
  marginBottom: '2.5rem',
}));

export const buttonStyles = {
  textField: {
    width: '100%',
    '& .MuiInputBase-root': {
      paddingRight: '0.75rem !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.light,
        borderRadius: '1rem',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.h4?.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '1.5rem',
      letterSpacing: '0.00375rem',
      '&.Mui-focused': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h5?.fontSize,
      },
    },
    '@media (max-width: 64rem)': {
      width: '100%',
      '& .MuiInputLabel-root': {
        fontSize: '.875rem',
        lineHeight: '1.375rem',
        letterSpacing: '0.015625rem',
        '&.Mui-focused': {
          fontSize: theme.typography.h6?.fontSize,
        },
      },
    },
  },
};

export const InfoField = styled('div')(() => ({
  backgroundColor: theme.palette.warning.light,
  borderRadius: '1rem',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'row',
}));

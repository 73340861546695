/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Checkbox, CircularProgress, FormControlLabel, TextField } from '@mui/material';

import { FacilityData } from '../../../../API';
import { buildingMakeupList, TrafficLevelList } from './AddFacilityDetails.model';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';

import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';

import theme from '../../../../Shared/Themes/theme';

import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { BottomBarContainer, buttonStyles } from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { Loader } from '../../../Jobs/Components/JobView/JobsView.styles';
import { styles } from './ViewFacilityDetails.Style';
import { ITypes, defaultFieldValues } from '../../../Vendor/Models/ViewFacilityDetails.model';

const AddFacilityDetails = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setValue, handleSubmit, watch } = useForm({
    defaultValues: defaultFieldValues,
  });

  const [isDetailsSaving, setIsDetailsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [facilityTypes, setFacilityTypes] = useState<ITypes[]>([]);
  const [errors, setErrors] = useState({
    noOfFloors: '',
    sqFootage: '',
    serviceableSqFootage: '',
    occupancy: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isValid, setIsValid] = useState(true);

  const { textField } = buttonStyles;
  const { checkBoxText } = styles;
  const { t } = useTranslation(['companyDetails']);
  const buttonLabel = location?.state ? t('companyDetails:updateDetails') : t('companyDetails:saveDetails');
  const NUMBER_OF_ITEMS_PER_PAGE = 10;

  const validateNumericInput = (name: any, value: any) => {
    const numericValue = Number(value);
    if (isNaN(numericValue) || numericValue < 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: t('companyDetails:invalidNumber'),
      }));
      setIsValid(false);
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
      setValue(name, numericValue);
      setIsValid(true);
      return true;
    }
  };

  const onSubmit = async (details: FacilityData): Promise<void> => {
    const facilityData = { ...details };
    const sqFootage = details?.sqFootage ?? 0;
    const serviceableSqFootage = details?.serviceableSqFootage ?? 0;
    const occupancy = details?.occupancy ?? 0;
    facilityData.sqFootage = +sqFootage;
    facilityData.serviceableSqFootage = +serviceableSqFootage;
    facilityData.occupancy = +occupancy;
    try {
      if (+sqFootage >= +serviceableSqFootage) {
        setIsDetailsSaving(true);
        setSnackbarMessage(t('companyDetails:successMessage'));
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        const updateFacilityResponse = await FacilityApiService.updateFacility(facilityData);
        if (updateFacilityResponse.data && updateFacilityResponse.errors.length === 0) {
          navigate('/company/facilities');
        }
        setIsDetailsSaving(false);
      } else {
        setSnackbarMessage(t('companyDetails:areaError'));
        setSnackbarOpen(true);
        setSnackbarSeverity('error');
      }
    } catch (e) {
      setIsDetailsSaving(false);
    }
  };

  const fetchFacility = useCallback(async () => {
    setIsLoading(true);
    try {
      let facilityTypesResponse = await MasterDataApiService.getAllFacilityTypes();
      if (
        facilityTypesResponse &&
        facilityTypesResponse.metaData &&
        facilityTypesResponse.metaData.totalCount > NUMBER_OF_ITEMS_PER_PAGE
      ) {
        facilityTypesResponse = await MasterDataApiService.getAllFacilityTypes(
          facilityTypesResponse.metaData.totalCount
        );
        if (facilityTypesResponse.errors.length === 0) {
          const types = facilityTypesResponse.data.map((item: any) => ({
            label: item.value,
            value: item.keyCode,
          }));
          setFacilityTypes(types);
        } else {
          console.error('Error fetching facility types:', facilityTypesResponse.errors[0]?.message);
        }
      }
    } catch (error) {
      console.error('Error fetching facility types:', error);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchFacility();
  }, [fetchFacility]);

  useEffect(() => {
    if (location && location.state) {
      const {
        buildingName,
        facilityId,
        customerId,
        addressId,
        contactId,
        noOfFloors,
        emergencyContactName,
        emergencyContactPhone,
        buildingMakeup,
        cleaningInstructions,
        facilityTrafficLevel,
        occupancy,
        serviceableSqFootage,
        transportRequiredWithinLocation,
        supplyStorageAvailability,
        sqFootage,
        mdFacilityType,
      } = location.state;
      const carpetArea = sqFootage === 0 ? null : sqFootage;
      const floorCount = noOfFloors === 0 ? null : noOfFloors;
      setValue('buildingName', buildingName);
      setValue('facilityId', facilityId);
      setValue('customerId', customerId);
      setValue('addressId', addressId);
      setValue('contactId', contactId);
      setValue('mdFacilityType', mdFacilityType || '');
      setValue('buildingMakeup', buildingMakeup);
      setValue('noOfFloors', floorCount);
      setValue('sqFootage', carpetArea);
      setValue('serviceableSqFootage', serviceableSqFootage);
      setValue('occupancy', occupancy);
      setValue('emergencyContactName', emergencyContactName);
      setValue('emergencyContactPhone', emergencyContactPhone);
      setValue('cleaningInstructions', cleaningInstructions);
      setValue('facilityTrafficLevel', facilityTrafficLevel);
      setValue('supplyStorageAvailability', supplyStorageAvailability);
      setValue('transportRequiredWithinLocation', transportRequiredWithinLocation);
    }
  }, [location.state, setValue]);

  return (
    <>
      <PageTitle title={t('companyDetails:modifyFacilityDetails')} />
      <CustomerContainer>
        {isLoading ? (
          <Loader sx={{ marginBottom: '2rem' }}>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </Loader>
        ) : (
          <Box>
            <form
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '2rem',
                margin: '3rem 0 2rem',
              }}>
              <TextField
                name="mdFacilityType"
                sx={textField}
                select
                label={t('companyDetails:facilityType')}
                value={watch('mdFacilityType') || location?.state?.mdFacilityType || ''}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setValue('mdFacilityType', selectedValue);
                }}
                variant="outlined">
                {facilityTypes.map((option) => (
                  <Options key={option.value} value={option.value}>
                    {option.label}
                  </Options>
                ))}
              </TextField>

              <TextField
                name="buildingMakeup"
                sx={textField}
                select
                label={t('companyDetails:buildingMakeup')}
                defaultValue={location?.state?.buildingMakeup || ''}
                onChange={(e) => setValue('buildingMakeup', e.target.value)}
                variant="outlined">
                {buildingMakeupList.map((option) => (
                  <Options key={option.value} value={option.value}>
                    {option.label}
                  </Options>
                ))}
              </TextField>

              <TextField
                name="noOfFloors"
                sx={textField}
                label={t('companyDetails:numberOfFloors')}
                defaultValue={location?.state?.noOfFloors || ''}
                onChange={(e) => validateNumericInput('noOfFloors', e.target.value)}
                variant="outlined"
                error={!!errors.noOfFloors}
                helperText={errors.noOfFloors}
              />

              <TextField
                name="sqFootage"
                sx={textField}
                label={t('companyDetails:totalCarpetArea')}
                defaultValue={location?.state?.sqFootage || ''}
                onChange={(e) => validateNumericInput('sqFootage', e.target.value)}
                variant="outlined"
                error={!!errors.sqFootage}
                helperText={errors.sqFootage}
              />

              <TextField
                name="serviceableSqFootage"
                sx={textField}
                label={t('companyDetails:serviceableSquareFootage')}
                defaultValue={location?.state?.serviceableSqFootage || ''}
                onChange={(e) => validateNumericInput('serviceableSqFootage', e.target.value)}
                variant="outlined"
                error={!!errors.serviceableSqFootage}
                helperText={errors.serviceableSqFootage}
              />

              <TextField
                name="occupancy"
                sx={textField}
                label={t('companyDetails:occupancy')}
                defaultValue={location?.state?.occupancy || ''}
                onChange={(e) => validateNumericInput('occupancy', e.target.value)}
                variant="outlined"
                error={!!errors.occupancy}
                helperText={errors.occupancy}
              />

              <TextField
                name="emergencyContactName"
                sx={textField}
                label={t('companyDetails:emergencyContactName')}
                defaultValue={location?.state?.emergencyContactName || ''}
                onChange={(e) => setValue('emergencyContactName', e.target.value)}
                variant="outlined"
              />

              <TextField
                name="emergencyContactPhone"
                sx={textField}
                label={t('companyDetails:emergencyContactNumber')}
                defaultValue={location?.state?.emergencyContactPhone || ''}
                onChange={(e) => setValue('emergencyContactPhone', e.target.value)}
                variant="outlined"
              />

              <TextField
                name="cleaningInstructions"
                sx={textField}
                label={t('companyDetails:cleaningInstructions')}
                defaultValue={location?.state?.cleaningInstructions || ''}
                onChange={(e) => setValue('cleaningInstructions', e.target.value)}
                variant="outlined"
              />

              <TextField
                name="facilityTrafficLevel"
                sx={textField}
                select
                label={t('companyDetails:facilityTrafficLevel')}
                value={watch('facilityTrafficLevel') || location?.state?.facilityTrafficLevel || ''}
                onChange={(e) => setValue('facilityTrafficLevel', e.target.value)}
                variant="outlined">
                {TrafficLevelList.map((option) => (
                  <Options key={option.value} value={option.value}>
                    {option.label}
                  </Options>
                ))}
              </TextField>
            </form>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem', gap: '1rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={location?.state?.supplyStorageAvailability || false}
                    onChange={(e) => setValue('supplyStorageAvailability', e.target.checked)}
                  />
                }
                label={<span style={checkBoxText}>{t('companyDetails:supplyStorageCapabilities')}</span>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={location?.state?.transportRequiredWithinLocation || false}
                    onChange={(e) => setValue('transportRequiredWithinLocation', e.target.checked)}
                  />
                }
                label={<span style={checkBoxText}>{t('companyDetails:transportRequired')}</span>}
              />
            </Box>
          </Box>
        )}
        <BottomBarContainer>
          <Button
            isLoading={isDetailsSaving}
            label={buttonLabel}
            onClick={handleSubmit(onSubmit)}
            type={ButtonType.Primary}
            disabled={!isValid}
          />
        </BottomBarContainer>
        <SnackbarMessage
          open={snackbarOpen}
          successMessage={snackbarMessage}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      </CustomerContainer>
    </>
  );
};

export default AddFacilityDetails;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useTranslation } from 'react-i18next';
import { HeaderContainer } from '../CreateBid.Style';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import {
  CommentsText,
  CommentsWrapper,
  CustomerCommentsText,
  JobCardWrapper,
  RevisionHeadingText,
  RevisionWrapper,
} from './BidComments.Styles';
import { UserType } from '../../../../Shared/Constants/App';
import { IBidRequestRevisionComments } from '../../Models/BidRequestRevision.model';

const BidRequestRevisionComments: React.FC<IBidRequestRevisionComments> = ({ comment, hasJobCustodian }) => {
  const { t } = useTranslation(['vendor', 'dashboard']);

  const authValue = localStorage.getItem('auth');
  let role: UserType;
  if (authValue !== null) {
    const authData = JSON.parse(authValue);
    role = authData?.attributes?.['custom:Role'];
  } else {
    // case where 'auth' is not found in localStorage
    role = UserType.NoUser;
  }

  return (
    <HeaderContainer sx={{ position: 'relative' }}>
      <JobCardWrapper sx={{ display: 'flex', flexDirection: 'column' }}>
        {role !== UserType.Customer && (
          <RevisionWrapper sx={{ display: 'flex', flexDirection: 'row' }}>
            <img src={Icons.RevisedBidIcon} />
            <RevisionHeadingText sx={{ color: theme.palette.text.secondary }}>
              {hasJobCustodian ? t('vendor:revisedRequestByDm') : t('vendor:revisedRequest')}
            </RevisionHeadingText>
          </RevisionWrapper>
        )}
        <CommentsWrapper>
          {role !== UserType.Customer ? (
            hasJobCustodian ? (
              <CommentsText>{t('vendor:dmsComment')}</CommentsText>
            ) : (
              <CommentsText>{t('vendor:customersComment')}</CommentsText>
            )
          ) : (
            <CommentsText>{t('vendor:vendorsComment')}</CommentsText>
          )}
          <CustomerCommentsText>{comment}</CustomerCommentsText>
        </CommentsWrapper>
      </JobCardWrapper>
    </HeaderContainer>
  );
};

export default BidRequestRevisionComments;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useFeatureIsEnabled } from './FeatureSwitchContext';
import FeatureSwitchServiceBase from './FeatureSwitchServiceBase';

const featureSwitchService = new FeatureSwitchServiceBase();

export * from './FeatureSwitchConstants';
export { featureSwitchService, useFeatureIsEnabled };

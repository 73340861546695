/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import { IFacilityType, IFacilityTypeItem } from '../../../Vendor/Models/Marketplace.model';

export const fetchFacilityTypes = async (keyCode: string): Promise<string> => {
  try {
    const facilityTypesResponse = await MasterDataApiService.getAllFacilityTypes();
    if (!facilityTypesResponse.errors?.length) {
      const limit = facilityTypesResponse?.metaData?.totalCount;
      const facilityTypesResponseNew = await MasterDataApiService.getAllFacilityTypes(limit);
      const modifiedFacilityTypes = facilityTypesResponseNew.data.map((dataItem: IFacilityTypeItem) => ({
        keyCode: dataItem.keyCode,
        name: dataItem.value,
      }));
      const facilityType = modifiedFacilityTypes.find((ft: IFacilityType) => ft.keyCode === keyCode);
      return facilityType ? facilityType.name : '';
    }
  } catch (e) {
    console.log('Error', e);
  }
  return '';
};

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import { Icons } from '../../../Constants/Icons';
import theme from '../../../Themes/theme';

interface IDatePickerProps {
  isResponsive: boolean;
}

export const DatePickerWrapper = styled('div')(({ isResponsive }: IDatePickerProps) => ({
  position: 'relative',
  zIndex: 'inherit',
  display: 'flex',
  flex: `${isResponsive ? 1 : 'unset'}`,
  '& .react-datepicker-wrapper': {
    width: '100%',
    '& .react-datepicker__input-container': {
      zIndex: 0,
    },
  },
  '& .react-datepicker__day--outside-month': {
    color: theme.palette.secondary.contrastText,
    pointerEvents: 'none',
  },
  '& .react-datepicker-popper': {
    zIndex: 10000,
  },
  '& .react-datepicker': {
    display: 'flex',
    flexDirection: 'column',
    border: `0.0625rem solid ${theme.palette.divider}`,
    borderRadius: '1rem',
    '& .react-datepicker__header': {
      backgroundColor: theme.palette.common.white,
      border: 'none',
      padding: 0,
      borderRadius: '1rem',
      '& .react-datepicker__day-names': {
        display: 'flex',
        margin: '0 0.75rem',
        '& .react-datepicker__day-name': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '3rem',
          height: '3rem',
          margin: 0,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.h5?.fontSize,
          fontWeight: theme.typography.fontWeightMedium,
          lineHeight: '1.375rem',
          letterSpacing: '0.015625rem',
          color: theme.palette.text.secondary,
          [theme.breakpoints.down('laptop')]: {
            width: '1.875rem',
            height: '1.875rem',
            fontSize: '0.625rem',
            lineHeight: '1.125rem',
          },
        },
      },
    },
    '& .react-datepicker__month': {
      margin: '0 0.75rem',
      '& .react-datepicker__week': {
        display: 'flex',
        '& .react-datepicker__day': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '3rem',
          height: '3rem',
          margin: 0,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.h5?.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          lineHeight: '1.375rem',
          letterSpacing: '0.015625rem',
          '&:hover': {
            borderRadius: '6.25rem',
            backgroundColor: theme.palette.primary.main,
          },
          [theme.breakpoints.down('laptop')]: {
            width: '1.875rem',
            height: '1.875rem',
            fontSize: '0.625rem',
            lineHeight: '1.125rem',
          },
          '&.react-datepicker__day--selected': {
            borderRadius: '6.25rem',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.secondary,
            fontWeight: theme.typography.fontWeightBold,
          },
          '&.react-datepicker__day--keyboard-selected': {
            borderRadius: '6.25rem',
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
    '& .react-datepicker__children-container': {
      padding: 0,
      margin: 0,
      width: 'auto',
    },
  },
}));

export const DateLabel = styled('h2')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h1?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '2rem',
  color: theme.palette.primary.dark,
  textAlign: 'left',
  padding: '1.75rem 1.5rem 1.5rem',
  borderBottom: `0.0625rem solid ${theme.palette.divider}`,
  [theme.breakpoints.down('laptop')]: {
    padding: '1.125rem 1rem 1rem',
    fontSize: theme.typography.h3?.fontSize,
    lineHeight: '1.75rem',
  },
}));

export const SliderWrapper = styled('div')(() => ({
  display: 'flex',
  columnGap: '1.5rem',
  justifyContent: 'space-around',
  paddingTop: '0.5rem',
  [theme.breakpoints.down('laptop')]: {
    columnGap: '1rem',
  },
}));

export const DropdownWrapper = styled('div')(() => ({
  display: 'flex',
}));

export const ArrowButton = styled('button')(() => ({
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  backgroundColor: theme.palette.common.white,
  width: '1.5rem',
  height: '1.5rem',
  padding: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '1rem',
  [theme.breakpoints.down('laptop')]: {
    width: '1rem',
    height: '1rem',
    padding: '0.25rem',
  },
}));

export const Select = styled('select')(() => ({
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  appearance: 'none',
  background: `url(${Icons.DropdownDataIcon}) center right no-repeat`,
  backgroundSize: '0.5625rem',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  width: '4.125rem',
  marginRight: '0.75rem',
  marginLeft: '0.75rem',
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('laptop')]: {
    width: '3rem',
    fontSize: theme.typography.h6?.fontSize,
    lineHeight: '1.125rem',
    marginRight: '0.25rem',
    marginLeft: '0.25rem',
  },
}));

export const DatepickerButtonWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '0.5rem',
  padding: '0.5rem 0.75rem',
  [theme.breakpoints.down('laptop')]: {
    padding: '0.375rem 0.625rem',
  },
}));

export const DatepickerButton = styled('button')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.primary.dark,
  textDecoration: 'none',
  padding: '0.625rem 0.75rem',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5?.fontSize,
    lineHeight: '1.125rem',
  },
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { Linking } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { useTranslation } from 'react-i18next';
import {
  SuccessWrapper,
  CloseIconStyle,
  CongratsContainer,
  CongratsText,
  MessageText,
  ButtonsContainer,
} from '../CreateBid.Style';
import { Box } from '@mui/material';

import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';

import theme from '../../../../Shared/Themes/theme';
import { Icons } from '../../../../Shared/Constants/Icons';
import { JobDetails } from '../../../../Shared/Utilities/utils';

interface SuccessScreenProps {
  open: boolean;
  onClose: () => void;
  bidVersion: any;
  bidDetails: any;
  customerApprovals: any[];
}

const SuccessScreen: React.FC<SuccessScreenProps> = ({ open, onClose, bidVersion, bidDetails, customerApprovals }) => {
  const { t } = useTranslation(['vendor', 'oneTimeJob', 'dashboard']);
  return (
    <SuccessWrapper open={open} onClose={onClose}>
      <CloseIconStyle onClick={onClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      <CongratsContainer>
        <Box sx={{ margin: '3rem 0 2.5rem 0' }}>
          <img src={Icons.CongratulationsImg} />
        </Box>
        <CongratsText>{t('dashboard:congratulations')}</CongratsText>
        <MessageText sx={{ margin: '0 1.5rem 1rem 1.5rem' }}>
          {t('dashboard:your')} {t(`dashboard:${bidDetails?.executionType === JobDetails.Self ? 'job' : 'proposal'}`)}{' '}
          <span style={{ fontWeight: theme.typography.fontWeightBold }}>{bidDetails?.jobName}</span>{' '}
          {customerApprovals.length
            ? bidVersion && bidVersion > 0
              ? t('dashboard:hasBeenSuccessfullyModified')
              : t('dashboard:hasBeenSuccessfullyCreated')
            : bidVersion && bidVersion > 0
              ? t('dashboard:hasBeenModifiedAndSubmittedForCustomerApproval')
              : t('dashboard:hasBeenSubmittedForCustomerApproval')}
        </MessageText>
        <MessageText sx={{ marginBottom: '3rem' }}>
          {t('dashboard:YouCanFindItUnder')}{' '}
          <span style={{ fontWeight: theme.typography.fontWeightBold }}>
            {t(
              `vendor:${
                bidDetails?.executionType === JobDetails.SubVendor &&
                bidDetails?.estimationType === JobDetails.EstimateWithinPeazy
                  ? 'subContractorBids'
                  : 'selfPerformedJobs'
              }`
            )}
          </span>
        </MessageText>
      </CongratsContainer>
      <ButtonsContainer>
        <Linking to={{ pathname: `/createOneTimeJob` }}>
          <Button type={ButtonType.Secondary} label={t('dashboard:createOneTimeJob')} />
        </Linking>

        <Linking
          to={{
            pathname:
              bidDetails?.executionType === JobDetails.SubVendor &&
              bidDetails?.estimationType === JobDetails.EstimateWithinPeazy
                ? '/vendor-jobs/sub-contractor-jobs'
                : `/vendor-jobs/self-performed-jobs`,
          }}>
          <Button
            type={ButtonType.Primary}
            label={t(
              `vendor:${
                bidDetails?.executionType === JobDetails.SubVendor &&
                bidDetails?.estimationType === JobDetails.EstimateWithinPeazy
                  ? 'subContractorBids'
                  : 'selfPerformedJobs'
              }`
            )}
          />
        </Linking>
      </ButtonsContainer>
    </SuccessWrapper>
  );
};

export default SuccessScreen;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { ACTION_TYPES } from '../actions/postActions';

export const INITIALSTATE = {
  loading: false,
  error: false,
  data: {},
};

const { FETCH_START, FETCH_SUCCESS, FETCH_ERROR } = ACTION_TYPES;

export const postReducer = (state, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        loading: true,
        error: false,
        data: {},
      };
    case FETCH_SUCCESS:
      return {
        loading: false,
        error: false,
        data: action.payload,
      };
    case FETCH_ERROR:
      return {
        loading: false,
        error: true,
        data: action.payload,
      };
    default:
      return state;
  }
};

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { createContext, useContext } from 'react';

import { FeatureKeys } from '.';

export type HashMap<T> = Record<string, T>;

interface IFeatureSwitchContext {
  featureIsEnabled: (featureKey: FeatureKeys) => boolean;
  setFeaturesFromMap: (features: HashMap<boolean>) => void;
}

const initialFeatureSwitchContext: IFeatureSwitchContext = {
  featureIsEnabled: () => {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFeaturesFromMap: () => {},
};

export const FeatureSwitchContext = createContext(initialFeatureSwitchContext);

export function useFeatureIsEnabled(key: FeatureKeys): boolean {
  const featureSwitchContext = useContext(FeatureSwitchContext);
  if (featureSwitchContext === undefined) {
    throw new Error('useFeatureIsEnabled must be used within a FeatureSwitchProvider');
  }
  const { featureIsEnabled } = featureSwitchContext;
  return featureIsEnabled(key);
}

export function useSetFeatureFlags(): (features: HashMap<boolean>) => void {
  const featureSwitchContext = useContext(FeatureSwitchContext);
  if (featureSwitchContext === undefined) {
    throw new Error('useSetFeatureFlags must be used within a FeatureSwitchProvider');
  }
  const { setFeaturesFromMap } = featureSwitchContext;
  return setFeaturesFromMap;
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled, Dialog } from '@mui/material';
import { CustomStyles } from '../Shared/Components/Modal';

interface ModalWrapperProps {
  styles?: CustomStyles;
}

export const ModalWrapper = styled(Dialog)<ModalWrapperProps>(({ styles }) => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    width: styles?.['& .MuiPaper-root']?.width || '50%',
    padding: '2em',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
}));

export const CloseIconStyle = styled('span')(() => ({
  cursor: 'pointer',
  position: 'absolute',
  right: '1.5rem',
  top: '-1.125rem',
}));

export const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1.5rem',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import theme from '../Themes/theme';

const colorConfigs = {
  sidebar: {
    bg: theme.palette.common.white,
    color: theme.palette.common.white,
    hoverBg: theme.palette.primary.main,
  },
  topbar: {
    bg: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  mainBg: theme.palette.secondary.main,
};

export default colorConfigs;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { createTheme } from '@mui/material';
import { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { PaletteColor } from '@mui/material/styles/createPalette';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';

export interface CustomTypographyOptions extends TypographyOptions {
  customSize1?: TypographyStyleOptions;
  customSize2?: TypographyStyleOptions;
}

// Extend the BreakpointOverrides
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

interface CustomPaletteColor extends PaletteColor {
  customColor1?: string;
  customColor2?: string;
  customColor3?: string;
  customColor4?: string;
  customColor5?: string;
  customColor6?: string;
  customColor7?: string;
  customColor8?: string;
  customColor9?: string;
  customColor10?: string;
  customColor11?: string;
  customColor12?: string;
  customColor13?: string;
  customColor14?: string;
}

interface CustomTheme {
  breakpoints: {
    down: (key: number | Breakpoint) => string;
    values: {
      mobile: number;
      tablet: number;
      laptop: number;
      desktop: number;
    };
  };
  typography: CustomTypographyOptions;
  palette: {
    primary: PaletteColor;
    secondary: CustomPaletteColor;
    success: PaletteColor;
    error: PaletteColor;
    warning: PaletteColor;
    common: {
      black: string;
      white: string;
    };
    text: {
      primary: string;
      secondary: string;
      disabled: string;
    };
    info: PaletteColor;
    divider: string;
  };
}

const greenTheme: CustomTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 580,
      tablet: 768,
      laptop: 1128,
      desktop: 1440,
    },
  },

  typography: {
    fontFamily: 'manrope',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,

    h1: {
      fontSize: '1.5rem',
    },
    h2: {
      fontSize: '1.25rem',
    },
    h3: {
      fontSize: '1.125rem',
    },
    h4: {
      fontSize: '1rem',
    },
    h5: {
      fontSize: '0.875rem',
    },
    h6: {
      fontSize: '0.75rem',
    },
    customSize1: {
      fontSize: '1.75rem',
    } as TypographyStyleOptions,
    customSize2: {
      fontSize: '0.625rem',
    } as TypographyStyleOptions,
  } as CustomTypographyOptions,

  palette: {
    primary: { main: '#FFE30A', dark: '#006B6B', light: '', contrastText: '' },
    secondary: {
      main: '#E6F0F0',
      dark: '#FFF28E',
      light: '#A8A08C',
      contrastText: '#646B87',
      customColor1: '#71E0EF',
      customColor2: '#B8B2FB',
      customColor3: '#FBAFCF',
      customColor4: '#66BFFF',
      customColor5: '#E6E1D1',
      customColor6: '#474D66',
      customColor7: '#FFE5A0',
      customColor8: '#D9EEFD',
      customColor9: '#8ABBBB',
      customColor10: '#004C4C',
      customColor11: '#D9EEFD',
      customColor12: '#74b0d7',
      customColor13: '#81c25d',
      customColor14: '#fb8c34',
    } as CustomPaletteColor,
    success: { main: '#148740', dark: '', light: '#E8F3EC', contrastText: '' },
    error: { main: '#E51717', dark: '', light: '#FCE8E8', contrastText: '' },
    warning: { main: '#E8CF09', dark: '#AD640E', light: '#F7EFE7', contrastText: '#FFFCE7' },
    info: { main: '#E7EDFF', dark: '#09268C', light: '#101840', contrastText: '#0A1165' },
    common: { black: '#000000', white: '#FFFFFF' },
    text: { primary: '#0F1532', secondary: '#292F4D', disabled: '#484E68' },
    divider: '#B0D1D1',
  },
});
// const yellowTheme: CustomTheme = createTheme({
//   breakpoints: {
//     values: {
//       mobile: 375,
//       tablet: 768,
//       laptop: 1128,
//       desktop: 1440,
//     },
//   },

// const yellowTheme: CustomTheme = createTheme({
//   breakpoints: {
//     values: {
//       mobile: 375,
//       tablet: 768,
//       laptop: 1128,
//       desktop: 1440,
//     },
//   },

//   typography: {
//     fontFamily: 'manrope',
//     fontWeightLight: 400,
//     fontWeightRegular: 500,
//     fontWeightMedium: 600,
//     fontWeightBold: 700,

//     h1: {
//       fontSize: '1.5rem',
//     },
//     h2: {
//       fontSize: '1.25rem',
//     },
//     h3: {
//       fontSize: '1.125rem',
//     },
//     h4: {
//       fontSize: '1rem',
//     },
//     h5: {
//       fontSize: '0.875rem',
//     },
//     h6: {
//       fontSize: '0.75rem',
//     },
//     customSize1: {
//       fontSize: '1.75rem',
//     } as TypographyStyleOptions,
//     customSize2: {
//       fontSize: '0.625rem',
//     } as TypographyStyleOptions,
//   } as TypographyOptions,

//   palette: {
//     primary: { main: '#FFD058', dark: '#0B30B2', light: '', contrastText: '' },
//     secondary: {
//       main: '#FFFAE8',
//       dark: '#FFEDBF',
//       light: '#D9D4C5',
//       contrastText: '#646B87',
//       customColor1: '#71E0EF',
//       customColor2: '#B8B2FB',
//       customColor3: '#FBAFCF',
//       customColor4: '#66BFFF',
//       customColor5: '#E6E1D1',
//       customColor6: '#474D66',
//       customColor7: '#FFE5A0',
//       customColor8: '#D9EEFD',
//       customColor9: '',
//       customColor10: '',
//       customColor11: '',
//     } as CustomPaletteColor,
//     success: { main: '#148740', dark: '', light: '#E8F3EC', contrastText: '' },
//     error: { main: '#E51717', dark: '', light: '#FCE8E8', contrastText: '' },
//     warning: { main: '#D9A521', dark: '#AD640E', light: '#F7EFE7', contrastText: '' },
//     info: { main: '#E7EDFF', dark: '#09268C', light: '#101840', contrastText: '#0A1165' },
//     common: { black: '#000000', white: '#FFFFFF' },
//     text: { primary: '#0F1532', secondary: '#292F4D', disabled: '#484E68' },
//     divider: '#C8CDDF',
//   },
// });

export default greenTheme;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import devConfig from '../Shared/aws-export.dev';
import qaConfig from '../Shared/aws-export.qa';
import stagingConfig from '../Shared/aws-export.staging';
import prodConfig from '../Shared/aws-export.prod';
import preProdConfig from '../Shared/aws-export.pre-prod';
import { Environment } from '../Shared/Constants/App';

type ConfigType = typeof devConfig | typeof qaConfig | typeof stagingConfig | typeof prodConfig | typeof preProdConfig;

export const getConfig = (env: Environment): ConfigType => {
  let defaultConfig: ConfigType = devConfig;

  switch (env) {
    case Environment.Dev:
      defaultConfig = devConfig;
      break;
    case Environment.QA:
      defaultConfig = qaConfig;
      break;
    case Environment.Staging:
      defaultConfig = stagingConfig;
      break;
    case Environment.Prod:
      defaultConfig = prodConfig;
      break;
    case Environment.PreProd:
      defaultConfig = preProdConfig;
      break;
    default:
      defaultConfig = devConfig;
  }

  return defaultConfig;
};

const appEnvironment = process.env.REACT_APP_ENVIRONMENT as Environment;
const awsConfig = appEnvironment ? getConfig(appEnvironment) : devConfig;

export default awsConfig;

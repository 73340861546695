/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { styled } from '@mui/material';
import theme from '../../../Themes/theme';

export const taskContainerStyle = {
  minWidth: '18.5rem',
  padding: '1rem',
  borderRadius: '1rem',
  background: theme.palette.warning.light,
  paddingTop: '1rem',
  paddingLeft: '1rem',
  paddingRight: 0,
};

export const taskTextStyle = {
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
};

export const taskProgressStyle = {
  height: '0.5rem',
  borderRadius: '3.125rem',
  background: 'rgba(0,0,0,0.1)',
  marginTop: '0.5rem',
  marginRight: '1rem',
};

export const taskIndicatorStyle = {
  height: '100%',
  borderRadius: '3.125rem',
  background: theme.palette.warning.dark,
  marginTop: '0.5rem',
};

export const taskInfoTextStyle = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '0.5rem',
  right: '1rem',
  fontSize: theme.typography.h5.fontSize,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightMedium,
  letterSpacing: '0.015625rem',
  lineHeight: '1.375rem',
  marginBottom: '0.5rem',
}));

export const closeIconContainerStyle = {
  borderRadius: '50%',
  padding: 0,
  cursor: 'pointer',
  position: 'absolute',
  zIndex: 5,
  left: '18.6rem',
  top: '-0.7rem',
};

export const mainTaskContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1rem',
  gap: '1rem',
};

export const logoContainerStyle = {
  minWidth: '3.5rem',
  height: '3.5rem',
  overflow: 'hidden',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '1rem',
};

export const textContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
};

export const textStyle = {
  whiteSpace: 'nowrap',
  width: '15rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  color: theme.palette.text.primary,
  lineHeight: '1.375rem',
  letterSpacing: '.0.9375rem',
};

export const additionalTextStyle = {
  fontWeight: 'regular',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  marginTop: '0.5rem',
  color: theme.palette.secondary.contrastText,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
};

export const mainContainerStyle = {
  position: 'relative',
  marginTop: '0.5rem',
  width: '19.5rem',
  maxHeight: '17.25rem',
  borderRadius: '1rem',
  background: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  overflowY: 'auto',
  padding: '1.5rem',
  zIndex: 3,
  boxSizing: 'unset',
};

export const titleLogoStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const facilityImg = {
  width: '3.5rem',
  height: 'inherit',
};

export const InfoWindowWrapper = styled('div')(() => ({
  '.companyProfileWrapper': {
    width: '3.5rem',
    height: '3.5rem',
    marginRight: '1rem',
    '& div': {
      width: 'inherit',
      height: 'inherit',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

export const InfoWindowText = styled('span')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
}));

export const InfoWindowContent = styled('span')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
export const dropdownData = [
  { id: 0, label: 'All facilities', value: 'all' },
  { id: 1, label: 'Stay Fit Gym  - Sandy Springs, GA', value: '1' },
  { id: 2, label: 'Stay Fit Gym - Cander Park', value: '2' },
];

export const dropdownData1 = [
  { id: 1, label: 'Daily General Cleaning', value: 'daily' },
  { id: 2, label: 'Deep Cleaning', value: 'deep' },
  { id: 3, label: 'Topical Cleaning', value: 'topical' },
];

export const servicesData = [
  {
    id: 1,
    serviceName: 'Floor care',
    taskTotal: 9,
    taskCompleted: 4,
    sericeIcon: 'url',
    facilityId: '2',
    facilityName: 'Stay Fit Gym - Cander Park',
    Jobname: 'Daily general cleaning',
    tableData: [
      {
        no: 1,
        taskName: 'Sweeping',
        floor: '1',
        timeTaken: '35 min',
        status: 'Issue raised',
        comments: 'Lorem ipsum dolar sit',
        proof: '12',
        date: '2023-07-04T07:15:07.135Z',
      },
      {
        no: 2,
        taskName: 'Vacuuming',
        floor: '1',
        timeTaken: '',
        status: 'Approved',
        comments: 'Lorem ipsum dolar sit s',
        proof: '12',
        date: '2023-06-14T07:15:07.135Z',
      },
      {
        no: 3,
        taskName: 'Sweeping',
        floor: '1',
        timeTaken: '35 min',
        status: 'Issue raised',
        comments: '',
        proof: '12',
        date: '2023-06-16T07:15:07.135Z',
      },
      {
        no: 4,
        taskName: 'Sweeping',
        floor: '1',
        timeTaken: '35 min',
        status: 'Complete',
        comments: '',
        proof: '12',
        date: '2023-06-02T07:15:07.135Z',
      },
    ],
  },
  {
    id: 2,
    serviceName: 'Furniture',
    taskTotal: 4,
    taskCompleted: 1,
    sericeIcon: 'url',
    facilityId: '1',
    facilityName: 'Stay Fit Gym  - Sandy Springs, GA',
    Jobname: 'Daily general cleaning',
    tableData: [
      {
        no: 1,
        taskName: 'Sweeping',
        floor: '1',
        timeTaken: '35 min',
        status: 'Issue raised',
        comments: 'Lorem ipsum dolar sit... Read more',
        proof: '12',
        date: '2023-06-19T07:15:07.135Z',
      },
      {
        no: 2,
        taskName: 'Vacuuming',
        floor: '1',
        timeTaken: '35 min',
        status: 'Issue raised',
        comments: 'Lorem ipsum dolar sit... Read more',
        proof: '12',
        date: '2023-05-19T07:15:07.135Z',
      },
    ],
  },
];

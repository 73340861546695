/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { DialogTitle, DialogContent, TextField, DialogActions, Button, Box, Typography } from '@mui/material';
import { buttonStyles, DialogWrapper } from './TaskDetailsPage.Style';
import theme from '../../../../Shared/Themes/theme';
import { Icons } from '../../../../Shared/Constants/Icons';

interface IRejectDialogProps {
  handleCloseRejectWindow: () => void;
  isRejectWindowOpen: boolean;
  isShowRowDetails?: boolean;
  isShowRejectAll?: boolean;
  handleReject: (vendorMsg: string) => void;
  t: any;
}

const RejectDialog = ({
  isRejectWindowOpen,
  handleCloseRejectWindow,
  handleReject,
  isShowRejectAll,
  t,
}: // isShowRowDetails,
IRejectDialogProps) => {
  const { closeIconStyle, rejectHeaderText, infoCancelButton, infoRejectButton, buttonText } = buttonStyles;
  const [vendorMsg, setVendorMsg] = useState('');

  return (
    <DialogWrapper open={isRejectWindowOpen} onClose={handleCloseRejectWindow} sx={{ borderRadius: '1rem' }}>
      <Box sx={closeIconStyle} onClick={handleCloseRejectWindow}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </Box>
      <DialogTitle id="alert-dialog-title" sx={rejectHeaderText}>
        {isShowRejectAll ? `${t('dashboard:rejectAllWorkTasks')}` : `${t('dashboard:rejectWorkTask')}`}
      </DialogTitle>
      <DialogContent>
        <TextField
          sx={{
            width: '100%',
            padding: 0,
            '& fieldset': {
              borderRadius: '1rem',
              borderColor: theme.palette.secondary.light,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderWidth: 1,
                borderColor: theme.palette.secondary.light,
              },
              '&:hover fieldset': {
                borderWidth: 1,
                borderColor: theme.palette.secondary.light,
              },
              '&.Mui-focused fieldset': {
                borderWidth: 1,
                borderColor: theme.palette.secondary.light,
              },
            },
          }}
          id="outlined-required"
          label="Reply to vendor"
          value={vendorMsg}
          onChange={(e) => setVendorMsg(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0.75rem 1.5rem 1.5rem 24.8125rem',
          }}>
          <Button
            sx={infoCancelButton}
            onClick={() => {
              handleCloseRejectWindow();
              setVendorMsg('');
            }}>
            <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>Cancel</Typography>
          </Button>
          <Button
            sx={infoRejectButton}
            onClick={() => {
              handleReject(vendorMsg);
              setVendorMsg('');
            }}>
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {isShowRejectAll ? `${t('dashboard:rejectAll')}` : `${t('dashboard:rejectButtonText')}`}
            </Typography>
          </Button>
        </Box>
      </DialogActions>
    </DialogWrapper>
  );
};

RejectDialog.defaultProps = {
  isShowRejectAll: false,
  isShowRowDetails: false,
};

export default withTranslation(['dashboard'])(RejectDialog);

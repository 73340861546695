/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../Themes/theme';

export const GraphWrapper = styled('div')(({ theme }) => ({
  height: '25rem',
  [theme.breakpoints.down('laptop')]: {
    height: '20rem',
  },
}));

export const CustomTooltipWrapper = styled('div')(() => ({
  padding: '0.25rem 0.5rem',
  borderRadius: '0.5rem',
  border: `0.0625rem solid ${theme.palette.common.black}`,
  backgroundColor: theme.palette.secondary.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  maxWidth: '18.75rem',
}));

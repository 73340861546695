/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { IconButton } from '@mui/material';

import { Icons } from '../../../Constants/Icons';
import { buttonStyles, SnackBar, MessageText } from './SnackbarMessage.Style';

interface SnackbarMessageProps {
  open: boolean;
  successMessage: string;
  errorMessage: string;
  severity: string;
  onClose: () => void;
  styles?: any;
}

function SnackbarMessage({ open, successMessage, errorMessage, severity, onClose }: SnackbarMessageProps): JSX.Element {
  const { t } = useTranslation(['altTexts']);
  const { snackbarContentStyle, snackbarStyle } = buttonStyles;

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  const icon =
    severity === 'success' ? (
      <img src={Icons.SuccessIcon} alt={t('altTexts:successImg')} />
    ) : (
      <img src={Icons.RejectIcon} alt={t('altTexts:errorImg')} />
    );

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} style={{ ...snackbarStyle }}>
      <SnackBar>
        <SnackbarContent
          sx={{
            ...snackbarContentStyle,
          }}
          message={
            <>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {icon}
                <MessageText>{severity === 'success' ? successMessage : errorMessage}</MessageText>
              </div>
            </>
          }
          action={
            <IconButton onClick={handleClose}>
              <img src={Icons.CancelIcon} alt={t('altTexts:closeIcon')} />
            </IconButton>
          }
        />
      </SnackBar>
    </Snackbar>
  );
}

export default SnackbarMessage;

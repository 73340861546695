/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState, useCallback, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import { ThemeProvider } from '@mui/material';
import { usePermission } from '@abrightlab/client-rbac';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Role, UserType } from './Shared/Constants/App';
import MainLayout from './Shared/Components/Layout/MainLayout';
import LoginPage from './Modules/Onboarding/Components/Login/LoginPage';
import PageNotFound from './Modules/Onboarding/Components/PageNotFound/PageNotFound';
import { routes } from './routes';
import theme from './Shared/Themes/theme';
import './index.css';
import AuthProvider from './Configuration/AuthContext';
import MasterDataApiService from './Shared/Services/MasterDataService';
import SnackbarMessage from './Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import CustomerApiService from './Modules/Customer/Services/CustomerService';
import BidApprovalEmail from './Modules/Customer/Components/BidApprovalEmail/BidApprovalEmail';
import { FeatureSwitchProvider } from './Infrastructure/FeatureSwitch/FeatureSwitchProvider';
import { FeatureKeys } from './Infrastructure/FeatureSwitch';
import { AuthenticationService } from './Shared/Services/AuthenticationService';

export interface IRole {
  roleId: string;
  roleName: string;
}

const App = (): JSX.Element => {
  const [snackbarDetails, setSnackbarDetails] = useState({
    isOpen: false,
    errorMsg: 'Your session has expired. Please log in again',
    successMsg: '',
    severity: 'error',
  });
  const { setUser } = usePermission();
  const signOut = useCallback(async () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('customerDetails');
    setSnackbarDetails((prevState) => {
      return {
        ...prevState,
        isOpen: true,
        severity: 'error',
      };
    });
    await Auth.signOut();
  }, []);

  const handleCustomersList = async (userScopesResponse: any): Promise<void> => {
    try {
      const filteredUserScope = userScopesResponse.data.map((item: any) => item.userScopes).flat();
      const uniqueCustomerIds = [...new Set(filteredUserScope.map((item: any) => item.customerId))];
      const payload = {
        customerIds: uniqueCustomerIds,
      };
      const customersList = await CustomerApiService.getCustomers(payload as any);

      const customerDetails = customersList.data.map((item: any) => ({
        ...item,
        legalName: item.legalName,
        customerId: item.customerId,
        address: item.address,
        email: item?.email,
      }));
      const sortedCustomerDetails = customerDetails.sort((a: any, b: any) => a.legalName.localeCompare(b.legalName));

      localStorage.setItem('customersList', JSON.stringify(sortedCustomerDetails));
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const refreshSession = async (): Promise<void> => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      if (currentUser) {
        await Auth.currentSession();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    return Hub.listen('auth', async ({ payload: { event } }) => {
      switch (event) {
        case 'implicitFlow':
          break;
        case 'signOut':
          localStorage.removeItem('auth');
          localStorage.removeItem('customerDetails');
          localStorage.removeItem('userDetails');
          localStorage.removeItem('screenName');
          localStorage.removeItem('__permissionUser');

          break;
        case 'signIn': {
          const customer = await MasterDataApiService.getOrgDetailsByUserName();
          const loggedInUser = await MasterDataApiService.getCurrentUserInfo();
          const userDetails = await AuthenticationService.getUserByCognitoUserId(loggedInUser.username as string);
          const userScopesResponse: any = await MasterDataApiService.getUserScopes(userDetails?.data?.userId as string);
          await handleCustomersList(userScopesResponse);
          let userRoles: string[] = [];
          if (userScopesResponse.data) {
            userRoles = userScopesResponse.data
              .map((scope: any) => {
                return scope?.roles?.map((role: IRole) => {
                  return role.roleName;
                });
              })
              .flat();
            loggedInUser.canCreateOneTimeJob = userScopesResponse.data[0]?.canCreateOneTimeJob ?? false;
          }
          const userType = loggedInUser.attributes['custom:Role'];
          if (userType === UserType.Customer) userRoles = [Role.Customer];
          loggedInUser.roles = userRoles;

          if (userType === UserType.Customer || userType === UserType.Vendor) {
            localStorage.setItem('auth', JSON.stringify({ ...loggedInUser, signedInTime: new Date().getTime() }));
            if (customer.data) {
              localStorage.setItem('customerDetails', JSON.stringify(customer.data));
            }
            window.location.pathname = '/home';
          }

          setUser?.({
            id: loggedInUser,
            roles: userRoles,
            permissions: [],
          });
          break;
        }
        case 'tokenRefresh_failure':
          signOut();
          break;
        default:
      }
    });
  }, []);

  useEffect(() => {
    refreshSession();
  }, []);

  const { isOpen, errorMsg, successMsg, severity } = snackbarDetails;

  return (
    <ThemeProvider theme={theme}>
      <FeatureSwitchProvider enabledByDefault={[FeatureKeys.AccessPreShiftCheckList]}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<MainLayout />}>
                  {routes}
                </Route>
                <Route path="/email-transactions" element={<BidApprovalEmail />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </BrowserRouter>
          </AuthProvider>
          <SnackbarMessage
            open={isOpen}
            successMessage={successMsg}
            errorMessage={errorMsg}
            severity={severity}
            onClose={() =>
              setSnackbarDetails((prevState) => {
                return {
                  ...prevState,
                  isOpen: false,
                };
              })
            }
          />
        </LocalizationProvider>
      </FeatureSwitchProvider>
    </ThemeProvider>
  );
};

export default App;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { ListItemButton, ListItemIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../../Constants/App';
import { Linking } from '../DataGrid/DataGrid.styles';
import { Icons } from '../../../Constants/Icons';
import { TextStyle } from './LeftNavBar.styles';

interface IUserFacilityLinksProps {
  isSideBarVisible: boolean;
  isUserSwitchActive: boolean;
  setIsUserSwitchActive: React.Dispatch<React.SetStateAction<boolean>>;
  isLogoutActive: boolean;
  setIsLogoutActive: React.Dispatch<React.SetStateAction<boolean>>;
  isFacilitySwitchActive: boolean;
  setIsFacilitySwitchActive: React.Dispatch<React.SetStateAction<boolean>>;
  role: UserType;
  theme: any;
  optionsStyle: any;
  styles: any;
  isTablet: boolean;
}

const AddOptions: React.FC<IUserFacilityLinksProps> = ({
  isSideBarVisible,
  isUserSwitchActive,
  setIsUserSwitchActive,
  setIsLogoutActive,
  isFacilitySwitchActive,
  setIsFacilitySwitchActive,
  role,
  theme,
  optionsStyle,
  styles,
}) => {
  const { t } = useTranslation(['addUsers', 'addFacility']);

  return (
    <>
      <Linking to="/addusers">
        <ListItemButton
          onClick={() => {
            setIsUserSwitchActive(false);
            setIsLogoutActive(false);
          }}
          sx={{
            ...optionsStyle.options,
            backgroundColor: isUserSwitchActive ? theme.palette.primary.main : 'unset',
          }}>
          <ListItemIcon
            sx={{
              ...styles.iconStyle,
            }}>
            <img src={Icons.AddUserIcon} alt={t('addUsers:addUsers')} />
          </ListItemIcon>
          {isSideBarVisible && <TextStyle>{t('addUsers:addUsers')}</TextStyle>}
        </ListItemButton>
      </Linking>
      {role === UserType.Customer && (
        <Linking to="/add-facility">
          <ListItemButton
            onClick={() => {
              setIsFacilitySwitchActive(false);
              setIsLogoutActive(false);
            }}
            sx={{
              ...optionsStyle.options,
              backgroundColor: isFacilitySwitchActive ? theme.palette.primary.main : 'unset',
            }}>
            <ListItemIcon
              sx={{
                ...styles.iconStyle,
              }}>
              <img src={Icons.AddFacilityIcon} alt={t('addFacility:addFacility')} />
            </ListItemIcon>
            {isSideBarVisible && <TextStyle>{t('addFacility:addFacility')}</TextStyle>}
          </ListItemButton>
        </Linking>
      )}
    </>
  );
};

export default AddOptions;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Typography } from '@mui/material';

import { isEmpty, isNaN } from 'lodash';
import { BidStatus, BooleanType, YesNoType } from '../../../../API';
import { BidSubmitStatus, ProfitMargin, TagNames, UserType } from '../../../../Shared/Constants/App';
import {
  calculateTotalEstimationWithProfit,
  fetchDataFromS3Bucket,
  getStoredCustomerDetails,
  JobDetails,
} from '../../../../Shared/Utilities/utils';
import { BottomBarProps, ProfitOption } from './BottomBar.Models';
import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';
import CreateBidServices from '../../Services/CreateBidServices';

import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import BidRequestRevisionPopup from '../BidRevision/BidRequestRevisionPopup';
import AwardJobPopup from '../JobAward/JobAwardPopup';
import JobAwardSuccessPopup from '../JobAward/JobAwardSuccessPopup';

import { Icons } from '../../../../Shared/Constants/Icons';

import theme from '../../../../Shared/Themes/theme';

import {
  BottomBarButtonOptions,
  BottomBarContainer,
  BottomBarFields,
  BottomBarCustomerButtonOptions,
  BottomBarProfits,
  buttonStyle,
  Field,
  FinalQuoteContainer,
  Percentage,
  ProfitPercentageContainer,
  ProfitText,
  TextField,
} from '../CreateBid.Style';
import './index.css';
import { buttonStyles } from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';
import ResubmitBidPopup from '../BidRevision/ResubmitBidPopup';

//currently backend not supporting flat margin. Once backend implemented for flat margin please add ProfitOption.Dollar
const profitOptions: ProfitOption[] = [ProfitOption.Percentage, ProfitOption.Dollar];

const BottomBar = ({
  bidDetails,
  subVendorTotalEstimation,
  totalEstimation = 0,
  setTotalEstimation,
  updateAndSubmit,
  profitMargin = 0,
  setProfitMargin,
  storedJob,
  setAdditionalSubTotal,
  selectedProfitOption,
  setSelectedProfitOption,
  totalEstimationWithMargin,
  setTotalEstimationWithMargin,
  vendorQuotesAttachments,
  finalQuote,
  isFinalQuoteLoading,
  stateValue,
  isServicesLoading,
  isDataLoading,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: BottomBarProps): JSX.Element => {
  const { t } = useTranslation(['altTexts', 'vendor', 'dashboard']);
  const [, setProfiPercentageError] = useState('');

  // const [isTablet, setIsTablet] = useState(false);
  const checkScreenSize = useCallback(() => {
    // setIsTablet(window.innerWidth <= 1024);
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const { button, buttonTextStyle, secondaryButton } = buttonStyle;

  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [facilityLogoUrl, setFacilityLogoUrl] = useState<string>('');
  const [vendorLogoUrl, setVendorLogoUrl] = useState<string>('');
  const [isSubmitBidPopupOpen, setIsSubmitBidPopupOpen] = useState(false);
  const [isResubmitBidPopupOpen, setResubmitBidPopupOpen] = useState(false);
  const [reason, setReason] = useState('');

  const { saveExitButton, buttonText } = buttonStyles;

  type SnackbarSeverity = 'success' | 'error';

  const grossProfit =
    selectedProfitOption === ProfitOption.Percentage
      ? totalEstimation * (parseFloat(profitMargin) / 100) || 0
      : (parseFloat(profitMargin) / totalEstimationWithMargin) * 100 || 0;
  const grossProfitPercentage = totalEstimationWithMargin ? (grossProfit / totalEstimationWithMargin) * 100 : 0;

  const handleDropdownIconClick = (): void => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCloseDropdown = (): void => {
    setIsDropdownOpen(false);
  };

  const [isAwardJobPopupOpen, setIsAwardJobPopupOpen] = useState(false);

  const handleAwardJobClick = (): void => {
    void fetchFacilityIcon();
    setIsAwardJobPopupOpen(!isAwardJobPopupOpen);
  };

  const [isRequestRevisionPopupOpen, setRequestRevisionPopupOpen] = useState(false);
  const handleRequestRevision = (): void => {
    setRequestRevisionPopupOpen(true);
  };

  const [isRejectionPopupOpen, setRejectionPopupOpen] = useState(false);
  const handleRejection = (): void => {
    setRejectionPopupOpen(true);
  };

  const handleResubmitBid = (): void => {
    setResubmitBidPopupOpen(true);
  };

  const authValue = localStorage.getItem('auth');
  let role: UserType;
  if (authValue !== null) {
    const authData = JSON.parse(authValue);
    role = authData?.attributes?.['custom:Role'];
  } else {
    // case where 'auth' is not found in localStorage
    role = UserType.NoUser;
  }

  useEffect(() => {
    const storedJob = localStorage.getItem(AsyncStorageKeys.currentJob) || '';
    if (storedJob) {
      const parsedVal = JSON.parse(storedJob);
      const { totalEstimation, additionalSubTotal, profitMargin, isFlatMargin = YesNoType.N } = parsedVal;
      const flatMargin = parsedVal?.jobBids?.[0]?.isFlatMargin || isFlatMargin;
      const selectedProfitOption = flatMargin === YesNoType.Y ? ProfitOption.Dollar : ProfitOption.Percentage;
      setSelectedProfitOption(selectedProfitOption);
      setProfitMargin(profitMargin);
      setAdditionalSubTotal(additionalSubTotal);
      setTotalEstimation(totalEstimation);
    }
  }, []);

  const checkIfImageValid = async (url: string): Promise<unknown> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = url;
      image.onload = (): void => {
        resolve(true);
      };
      image.onerror = (): void => {
        resolve(false);
      };
    });
  };

  const fetchFacilityIcon = useCallback(async () => {
    const customerLogoResponse = await fetchDataFromS3Bucket(
      navigate,
      `customer/${bidDetails?.customerId}/company-logos/${bidDetails?.customerId}`
    );
    const isFacilityValid = await checkIfImageValid(customerLogoResponse);
    if (isFacilityValid) {
      setFacilityLogoUrl(customerLogoResponse);
    } else {
      setFacilityLogoUrl('');
    }
    const vendorLogoResponse = await fetchDataFromS3Bucket(
      navigate,
      `customer/${bidDetails?.venodorId}/company-logos/${bidDetails?.venodorId}`
    );
    const isVendorValid = await checkIfImageValid(vendorLogoResponse);
    if (isVendorValid) {
      setVendorLogoUrl(vendorLogoResponse);
    } else {
      setVendorLogoUrl('');
    }
  }, []);

  const handleProfitMarginChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const pattern = /^\d+(\.\d*)?$/;
    const value = event.target.value;
    if (pattern.test(value) || value === '') {
      // For now we are not allowing to enter the profit margin above 1000 for percentage and above 1,00,000 for dollar
      if (
        selectedProfitOption === ProfitOption.Percentage &&
        value !== '' &&
        (parseFloat(value) as ProfitMargin) > ProfitMargin.Percentage
      ) {
        return;
      }
      if (
        selectedProfitOption === ProfitOption.Dollar &&
        value !== '' &&
        (parseFloat(value) as ProfitMargin) > ProfitMargin.Dollar
      ) {
        return;
      }
      if (value.includes('.')) {
        const splicedInput = value.split('.');
        if (splicedInput?.[1]?.length <= 2) {
          setProfitMargin(value);
          setProfiPercentageError('');
        }
      } else {
        setProfitMargin(value);
        setProfiPercentageError('');
      }
    } else {
      setProfitMargin('');
      setProfiPercentageError('Please enter valid value');
    }
  };

  const handleSubmitBidPopupClick = (): void => {
    setIsSubmitBidPopupOpen(true);
  };

  const handleClose = (): void => {
    setIsSubmitBidPopupOpen(false);
    if (bidDetails?.isVendorBid) {
      navigate('/home');
    }
  };

  const updateSubmitBid = async (bidId: string) => {
    try {
      const vendorDetails = await Promise.resolve(getStoredCustomerDetails());

      if (authValue && vendorDetails) {
        const authData = JSON.parse(authValue);
        const userEmail = authData.attributes['email'];

        const updateBidPayload: any = {
          vendorName: vendorDetails.organizationName,
          vendorEmail: userEmail,
          isCustomerApproved: BooleanType.False,
          ...(!isEmpty(reason) ? { comments: reason } : {}),
        };

        return await CreateBidServices.submitBid(bidId, updateBidPayload);
      }
    } catch (error) {
      console.error('Error updating and submitting bid:', error);
    }
  };

  const showSnackbar = (message: string, severity: SnackbarSeverity): void => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleReviewOnClick = async (type: string): Promise<void> => {
    if (isInvalidSubVendorQuote()) {
      return;
    }
    setLoadingState(type);
    await updateAndSubmit(type);
    const currentJob = localStorage.getItem(AsyncStorageKeys.currentJob) ?? '';

    let jobDetails = bidDetails;
    let storedJob;
    if (currentJob) {
      storedJob = JSON.parse(currentJob);
      const modifiedStoredJob = { ...storedJob };
      modifiedStoredJob.profitMargin = profitMargin;
      modifiedStoredJob.isFlatMargin = selectedProfitOption === ProfitOption.Percentage ? 'N' : 'Y';
      localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify(modifiedStoredJob));
      jobDetails = modifiedStoredJob;
    }
    if ((type as BidSubmitStatus) !== BidSubmitStatus.saveAndExit) {
      if (bidDetails?.isVendorBid) {
        const submitBid = await updateSubmitBid(storedJob?.bidId);
        if (isEmpty(submitBid?.data)) {
          showSnackbar(`${t('vendor:submitBidErrorMsg')}`, 'error');
          setLoadingFalse();
        } else {
          setLoadingFalse();
          handleSubmitBidPopupClick();
        }
      } else {
        setLoadingFalse();
        navigateToBidReview(jobDetails);
      }
    }
  };

  const isInvalidSubVendorQuote = (): boolean => {
    if (
      bidDetails?.executionType === JobDetails.SubVendor &&
      bidDetails?.estimationType === JobDetails.EstimateOutsidePeazy &&
      !subVendorTotalEstimation
    ) {
      showSnackbar(`${t('vendor:enterVendorQuote')}`, 'error');
      return true;
    } else if (
      bidDetails?.executionType === JobDetails.SubVendor &&
      bidDetails?.estimationType === JobDetails.EstimateOutsidePeazy &&
      vendorQuotesAttachments.length === 0
    ) {
      showSnackbar(`${t('vendor:uploadVendorQuoteFile')}`, 'error');
      return true;
    } else {
      return false;
    }
  };

  const setLoadingState = (type: string): void => {
    if ((type as BidSubmitStatus) === BidSubmitStatus.saveAndExit) {
      setIsSaveLoading(true);
    } else {
      setIsSubmitLoading(true);
    }
  };

  const setLoadingFalse = (): void => {
    setIsSubmitLoading(false);
    setIsSaveLoading(false);
  };

  const navigateToBidReview = (jobDetails: any): void => {
    navigate('/bid-review', {
      state: {
        bidDetails: jobDetails,
        isCreate: true,
        isEdit: false,
        grossProfit,
        grossProfitPercentage,
      },
    });
  };

  const handleSelectBidClick = (): void => {
    setTotalEstimation(finalQuote);
    navigate('/create-bid', { state: { ...stateValue, tag: TagNames.SelectBid } });
  };

  useEffect(() => {
    const totalPrice = parseFloat(totalEstimation.toString());
    let newTotalWithMargin = 0;
    if (selectedProfitOption === ProfitOption.Percentage) {
      newTotalWithMargin = calculateTotalEstimationWithProfit(totalPrice, parseFloat(profitMargin));
    } else if (selectedProfitOption === ProfitOption.Dollar) {
      newTotalWithMargin = totalPrice + parseFloat(profitMargin);
    }
    setTotalEstimationWithMargin(newTotalWithMargin);
  }, [selectedProfitOption, profitMargin, totalEstimation]);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [checkScreenSize]);

  const isThirdFlow = storedJob?.executionType === 'subVendor' && storedJob?.estimationType === 'estimateOutsidePeazy';

  return (
    <>
      <BottomBarContainer sx={{ justifyContent: 'space-between' }}>
        <BottomBarProfits>
          {role === UserType.Vendor && stateValue?.tag !== TagNames.RejectOrRequestRevision && (
            <BottomBarFields>
              <ProfitText>{t('vendor:jobCost')}</ProfitText>
              <ProfitText sx={{ fontWeight: theme.typography.fontWeightBold }}>
                $ {isNaN(totalEstimation) ? '0.00' : totalEstimation.toFixed(2)}
              </ProfitText>
            </BottomBarFields>
          )}

          {role === UserType.Vendor &&
            profitOptions.length > 1 &&
            stateValue?.tag !== TagNames.RejectOrRequestRevision && (
              <ProfitPercentageContainer>
                <ProfitText sx={{ marginBottom: '0rem' }}>
                  {isThirdFlow ? `${t('vendor:profit')}` : `${t('vendor:profitMargin')}`}
                </ProfitText>
                <Field>
                  <TextField
                    id="profit-percentage"
                    value={profitMargin}
                    onChange={(event) => handleProfitMarginChange(event)}
                  />
                  <Percentage>
                    <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Select
                        sx={{
                          '& fieldset': {
                            border: 'none',
                          },
                        }}
                        className="formControlSelect"
                        value={selectedProfitOption}
                        onChange={(e) => setSelectedProfitOption(e.target.value as ProfitOption)}
                        open={isDropdownOpen}
                        onOpen={handleDropdownIconClick}
                        onClose={handleCloseDropdown}
                        IconComponent={() => null}>
                        {profitOptions.map((option: ProfitOption) => (
                          <MenuItem
                            key={option}
                            value={option}
                            sx={{
                              '&:hover': {
                                backgroundColor: theme.palette.secondary.dark,
                              },
                              '&.Mui-selected': {
                                backgroundColor: theme.palette.primary.main,
                                '&:hover': {
                                  backgroundColor: theme.palette.primary.main,
                                },
                              },
                            }}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <Box
                        onClick={handleDropdownIconClick}
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                        <img
                          src={Icons.DropdownUpIcon}
                          alt={t('altTexts:dropdown')}
                          style={{ width: '1.25rem', height: '1.25rem', marginLeft: '0.25rem' }}
                        />
                      </Box>
                    </FormControl>
                  </Percentage>
                </Field>
              </ProfitPercentageContainer>
            )}

          {role === UserType.Vendor && profitOptions.length == 1 && (
            <ProfitPercentageContainer>
              <ProfitText>{isThirdFlow ? `${t('vendor:profit')}` : `${t('vendor:profitMargin')}`}</ProfitText>
              <Field>
                <TextField
                  id="profit-percentage"
                  value={profitMargin}
                  onChange={(event) => handleProfitMarginChange(event)}
                />
                <Percentage>%</Percentage>
              </Field>
            </ProfitPercentageContainer>
          )}
          {role === UserType.Vendor && stateValue?.tag !== TagNames.RejectOrRequestRevision && (
            <BottomBarFields>
              <ProfitText sx={{ whiteSpace: 'nowrap' }}>{t('vendor:grossProfit')}</ProfitText>
              <ProfitText
                sx={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  fontWeight: theme.typography.fontWeightBold,
                }}>
                {selectedProfitOption === ProfitOption.Percentage ? (
                  <>
                    ${grossProfit.toFixed(2)} ({grossProfitPercentage.toFixed(2)}%)
                  </>
                ) : (
                  <>{grossProfit.toFixed(2)}%</>
                )}
              </ProfitText>
            </BottomBarFields>
          )}
          <FinalQuoteContainer>
            <ProfitText>{t('vendor:finalQuote')}</ProfitText>
            {(role === UserType.Vendor && bidDetails?.estimationType !== JobDetails.EstimateWithinPeazy) ||
            (role === UserType.Vendor && bidDetails?.executionType === JobDetails.Self) ? (
              <ProfitText sx={{ fontWeight: theme.typography.fontWeightBold }}>
                {`$ ${totalEstimationWithMargin ? parseFloat(totalEstimationWithMargin).toFixed(2) : isNaN(totalEstimation) ? '0.00' : totalEstimation.toFixed(2)}`}
              </ProfitText>
            ) : isFinalQuoteLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            ) : (
              <ProfitText
                sx={{
                  fontWeight: theme.typography.fontWeightBold,
                }}>
                {stateValue && stateValue.tag === TagNames.RejectOrRequestRevision
                  ? `$ ${isNaN(finalQuote) ? 0 : parseFloat(finalQuote).toFixed(2)}`
                  : stateValue && stateValue.tag === TagNames.SelectBid
                    ? `$ ${isNaN(totalEstimationWithMargin) ? 0 : parseFloat(totalEstimationWithMargin).toFixed(2)}`
                    : `$ ${isNaN(finalQuote) ? 0 : parseFloat(finalQuote).toFixed(2)}`}
              </ProfitText>
            )}
          </FinalQuoteContainer>
        </BottomBarProfits>

        {role === UserType.Vendor &&
          !bidDetails?.hasBidSelected &&
          (stateValue?.tag !== TagNames.RejectOrRequestRevision || !stateValue.tag) && (
            <BottomBarButtonOptions>
              <Button
                disabled={isSaveLoading || isSubmitLoading || isServicesLoading || isDataLoading}
                sx={{
                  ...saveExitButton,
                  opacity: isSaveLoading || isSubmitLoading || isServicesLoading || isDataLoading ? '0.5' : '1',
                  cursor: 'pointer',
                }}
                onClick={() => handleReviewOnClick(BidSubmitStatus.saveAndExit)}>
                {isSaveLoading ? (
                  <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                ) : (
                  <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>
                    {t('oneTimeJob:saveAndExit')}
                  </Typography>
                )}
              </Button>
              {bidDetails?.isRequestRevision ? (
                <Button
                  sx={{
                    ...button,
                    opacity: isSubmitLoading || isSaveLoading || isServicesLoading || isDataLoading ? '0.7' : '1',
                  }}
                  disabled={isSubmitLoading || isSaveLoading || isServicesLoading || isDataLoading}
                  onClick={handleResubmitBid}>
                  {isSubmitLoading ? (
                    <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
                  ) : (
                    <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>
                      {t('vendor:resubmitBid')}
                    </Typography>
                  )}
                </Button>
              ) : (
                <Button
                  sx={{
                    ...button,
                    opacity: isSubmitLoading || isSaveLoading || isServicesLoading || isDataLoading ? '0.7' : '1',
                  }}
                  disabled={isSubmitLoading || isSaveLoading || isServicesLoading || isDataLoading}
                  onClick={() => handleReviewOnClick(BidSubmitStatus.updateAndSubmit)}>
                  {isSubmitLoading ? (
                    <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
                  ) : (
                    <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>
                      {bidDetails?.isVendorBid ? t('vendor:submitBid') : t('vendor:review')}
                    </Typography>
                  )}
                </Button>
              )}
            </BottomBarButtonOptions>
          )}

        {(role === UserType.Customer || (stateValue && stateValue.tag === TagNames.RejectOrRequestRevision)) && (
          <BottomBarCustomerButtonOptions>
            <Button
              sx={{
                ...secondaryButton,
                opacity: bidDetails.bidStatus === BidStatus.V || isServicesLoading || isDataLoading ? '0.5' : '1',
              }}
              disabled={bidDetails.bidStatus === BidStatus.V || isServicesLoading || isDataLoading}
              onClick={handleRequestRevision}>
              <Typography sx={{ textTransform: 'none', color: theme.palette.primary.dark }}>
                {t('dashboard:requestRevision')}
              </Typography>
            </Button>

            <Button
              sx={{
                ...secondaryButton,
                opacity: isServicesLoading || isDataLoading ? '0.5' : '1',
                cursor: 'pointer',
              }}
              onClick={handleRejection}
              disabled={isServicesLoading || isDataLoading}>
              <Typography sx={{ textTransform: 'none', color: theme.palette.primary.dark }}>
                {t('dashboard:rejectBid')}
              </Typography>
            </Button>

            {bidDetails?.estimationType === JobDetails.EstimateWithinPeazy ? (
              <Button sx={button} onClick={handleSelectBidClick}>
                <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>{t('dashboard:selectBid')}</Typography>
              </Button>
            ) : (
              <Button
                sx={{
                  ...button,
                  opacity: isServicesLoading || isDataLoading ? '0.5' : '1',
                  cursor: 'pointer',
                }}
                onClick={handleAwardJobClick}
                disabled={isServicesLoading || isDataLoading}>
                <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>{t('dashboard:awardJob')}</Typography>
              </Button>
            )}
          </BottomBarCustomerButtonOptions>
        )}

        {isAwardJobPopupOpen && (
          <AwardJobPopup
            isOpen={isAwardJobPopupOpen}
            onClose={() => setIsAwardJobPopupOpen(false)}
            facilityName={bidDetails?.facilityName}
            vendorName={bidDetails?.vendorName}
            finalQuote={finalQuote}
            bidDetails={bidDetails}
            facilityUrl={facilityLogoUrl}
            vendorUrl={vendorLogoUrl}
          />
        )}

        {isSubmitBidPopupOpen && (
          <JobAwardSuccessPopup isOpen={true} onClose={() => handleClose()} bidDetails={bidDetails} />
        )}

        {isRequestRevisionPopupOpen && (
          <BidRequestRevisionPopup
            isOpen={isRequestRevisionPopupOpen}
            vendorName={bidDetails?.vendorName}
            vendorId={
              bidDetails?.executionType === JobDetails.SubVendor &&
              bidDetails?.estimationType === JobDetails.EstimateWithinPeazy
                ? bidDetails?.subJobBids[0].vendorId
                : bidDetails?.vendorId
            }
            bidId={
              bidDetails?.executionType === JobDetails.SubVendor &&
              bidDetails?.estimationType === JobDetails.EstimateWithinPeazy
                ? bidDetails?.subJobBidId
                : bidDetails?.bidId
            }
            finalQuote={finalQuote}
            rejectionFlag={false}
            onClose={() => setRequestRevisionPopupOpen(false)}
          />
        )}
        {isResubmitBidPopupOpen && (
          <ResubmitBidPopup
            isOpen={isResubmitBidPopupOpen}
            handleOnCloseJob={() => handleReviewOnClick(BidSubmitStatus.updateAndSubmit)}
            onClose={() => setResubmitBidPopupOpen(false)}
            loader={isSubmitLoading}
            reason={reason}
            setReason={setReason}
          />
        )}
        {isRejectionPopupOpen && (
          <BidRequestRevisionPopup
            isOpen={isRejectionPopupOpen}
            vendorName={bidDetails?.vendorName}
            vendorId={
              bidDetails?.executionType === JobDetails.SubVendor &&
              bidDetails?.estimationType === JobDetails.EstimateWithinPeazy
                ? bidDetails?.subJobBids[0].vendorId
                : bidDetails?.vendorId
            }
            bidId={
              bidDetails?.executionType === JobDetails.SubVendor &&
              bidDetails?.estimationType === JobDetails.EstimateWithinPeazy
                ? bidDetails?.subJobBidId
                : bidDetails?.bidId
            }
            finalQuote={finalQuote}
            rejectionFlag={true}
            onClose={() => setRejectionPopupOpen(false)}
          />
        )}
      </BottomBarContainer>
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default BottomBar;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';

const wrapperWidth = 1024 + 80; // 1024 -> max width and 80 is navbar width

export const ReportsContainer = styled('div')(({ theme }) => ({
  margin: '0 auto',
  padding: '9rem 4rem 1.5rem 4.75rem',
  height: '100%',
  [theme.breakpoints.down(wrapperWidth)]: {
    boxSizing: 'border-box',
    padding: '6.5rem 1.5rem 5.5rem 1.5rem',
  },
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SuccessWrapper,
  CloseIconStyle,
  CongratsContainer,
  CongratsText,
  MessageText,
  ButtonsContainer,
} from './SuccessMessagePopup.Style';
import { Box } from '@mui/material';

import Button, { ButtonType } from '../Buttons/Button';

import { Icons } from '../../../Constants/Icons';

interface SuccessScreenProps {
  open: boolean;
  onClose: () => void;
  message: string;
  primaryAction: () => void;
  secondaryAction?: () => void;
  primaryButtonLabel: string;
  secondaryButtonLabel?: string;
}

const SuccessMessagePopup: React.FC<SuccessScreenProps> = ({
  open,
  onClose,
  message,
  primaryButtonLabel,
  secondaryButtonLabel,
  primaryAction,
  secondaryAction,
}) => {
  const { t } = useTranslation(['vendor', 'oneTimeJob', 'dashboard']);

  return (
    <SuccessWrapper open={open} onClose={onClose}>
      <CloseIconStyle onClick={onClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      <CongratsContainer>
        <Box sx={{ margin: '3rem 0 2.5rem 0' }}>
          <img src={Icons.CongratulationsImg} />
        </Box>
        <CongratsText>{t('dashboard:congratulations')}</CongratsText>
        <MessageText sx={{ marginBottom: '1rem' }}>{message}</MessageText>
      </CongratsContainer>
      {secondaryButtonLabel && (
        <ButtonsContainer>
          <Button type={ButtonType.Secondary} label={secondaryButtonLabel} onClick={secondaryAction} />
          <Button type={ButtonType.Primary} label={primaryButtonLabel} onClick={primaryAction} />
        </ButtonsContainer>
      )}
      {!secondaryButtonLabel && (
        <ButtonsContainer>
          <Button type={ButtonType.Primary} label={primaryButtonLabel} onClick={primaryAction} />
        </ButtonsContainer>
      )}
    </SuccessWrapper>
  );
};

export default SuccessMessagePopup;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React from 'react';
import { Icons } from '../../../Constants/Icons';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Typography } from '@mui/material';
import theme from '../../../Themes/theme';
import { CloseIconStyle, ContentText, HeadingText, WarningMessageWrapper, buttonStyles } from './WarningMessage.Style';

interface WarningMessage {
  open: boolean;
  onClose: () => void;
  messageTitle: string;
  messageContent: string;
  showLoader?: boolean;
  onButtonClick: () => void;
  buttonText: string;
}

const WarningMessage = ({
  open,
  onClose,
  messageTitle,
  messageContent,
  showLoader,
  onButtonClick,
  buttonText,
}: WarningMessage): JSX.Element => {
  const { t } = useTranslation(['altTexts']);
  const { warningButton, warningButtonText } = buttonStyles;

  return (
    <WarningMessageWrapper open={open} onClose={onClose}>
      <CloseIconStyle onClick={onClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      <HeadingText>{messageTitle}</HeadingText>
      <ContentText>{messageContent}</ContentText>
      <Button sx={{ ...warningButton }} onClick={onButtonClick}>
        {showLoader ? (
          <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
        ) : (
          <Typography sx={{ ...warningButtonText }}>{buttonText}</Typography>
        )}
      </Button>
    </WarningMessageWrapper>
  );
};

export default WarningMessage;

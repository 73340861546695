/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ErrorWrapper,
  ErrorTitleWrapper,
  ErrorTitle,
  ErrorDesc,
} from '../../Common/ErrorMessage/ErrorAndNoData.styles';
import { Icons } from '../../../Constants/Icons';

export type NoDataProps = {
  title?: string;
  description?: string;
};

const NoDataFound: React.FC<NoDataProps> = ({ title, description }) => {
  const { t } = useTranslation(['noData']);

  return (
    <ErrorWrapper>
      <img src={Icons.ErrorCautionImg} alt={t('altTexts:nodata')} />
      <ErrorTitleWrapper>
        {title && <ErrorTitle>{title}</ErrorTitle>}
        {description && <ErrorDesc>{description}</ErrorDesc>}
        {!title && !description && (
          <>
            <ErrorTitle>{t('noData:noDataYet')}</ErrorTitle>
            <ErrorDesc>{t('noData:noDataComeBackLater')}</ErrorDesc>
          </>
        )}
      </ErrorTitleWrapper>
    </ErrorWrapper>
  );
};

export { NoDataFound };

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Typography, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Auth } from 'aws-amplify';

import { CognitoUserStatus } from '../../../../API';
import { Patterns } from '../../../../Shared/Constants';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import { ForgotPasswordProps, CognitoUserData, ForgotPasswordFormValues } from '../../Models/Login.Model';

import { EmailContainer, buttonStyles, InputWrapper, ErrorMsg } from './LoginPage.Style';

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  onBackPress,
  setEmail,
}) => {
  const { t } = useTranslation(['forgotPassword']);
  const [isLoading, setLoading] = useState(false);
  const [isOTPSent, setOTPSent] = useState(false);
  const { textField } = buttonStyles;
  const { handleSubmit, control } = useForm<ForgotPasswordFormValues>({
    defaultValues: isOTPSent
      ? {
          emailId: '',
          newPassword: '',
          confirmPassword: '',
          oneTimeVerificationCode: 0,
        }
      : { emailId: '' },
  });

  const handleFormSubmit = async (data: ForgotPasswordFormValues): Promise<void> => {
    if (!isLoading) {
      await handleForgotPassword(data);
    }
  };

  const handleForgotPassword = async (data: ForgotPasswordFormValues): Promise<void> => {
    try {
      setLoading(true);
      const cognitoUser = await MasterDataApiService.checkIfUserExists(data.emailId);
      const userData = cognitoUser.data as CognitoUserData;
      const isEnabled = userData.user?.isEnabled ?? true;
      if (isEnabled) {
        if (userData.user?.status === CognitoUserStatus.CONFIRMED) {
          await Auth.forgotPassword(data.emailId);
          setSnackbarMessage(t('forgotPassword:otpSentSuccessfully'));
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setOTPSent(true);
          onBackPress();
        } else {
          setSnackbarMessage(t('forgotPassword:userNotRegistered'));
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      }
    } catch (error) {
      setSnackbarMessage(t('forgotPassword:userNotRegistered'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography sx={{ mb: 5 }}>
        {t('forgotPassword:enter')} <b>{t('forgotPassword:username')}</b> {t('forgotPassword:forSendingCode')}
      </Typography>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <EmailContainer>
          <Controller
            control={control}
            name={'emailId'}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <InputWrapper
                  sx={{
                    width: '100%',
                    margin: 'auto',
                  }}>
                  <TextField
                    sx={{
                      ...textField,
                    }}
                    disabled={isOTPSent}
                    id="outlined-required"
                    label={t('forgotPassword:userNameEmail')}
                    value={value || ''}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      onChange(e.target.value);
                    }}
                  />
                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </InputWrapper>
              );
            }}
            rules={{
              required: t('forgotPassword:emailAddressIsRequired'),
              pattern: {
                value: Patterns.Email,
                message: t('forgotPassword:InvalidEmailProvided'),
              },
            }}
          />
        </EmailContainer>
        <Button
          type={ButtonType.Primary}
          width="100%"
          label={isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : t('forgotPassword:sendOtp')}
          onClick={handleSubmit(handleForgotPassword)}
        />
      </form>
    </>
  );
};

export default ForgotPassword;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../Themes/theme';

const wrapperWidth = 1346;

export const buttonStyles = {
  disabledButtonStyle: {
    border: `0.0625rem solid ${theme.palette.divider}`,
    padding: '1rem',
    borderRadius: '1rem',
    width: '100%',
    alignItem: 'center',
    justifyContent: 'center',
    '@media (max-width: 64rem)': {
      padding: '0.75rem  1rem',
      alignItem: 'flex-start',
      justifyContent: 'flex-start',
    },
  },

  ButtonStyle: {
    padding: '1rem',
    borderRadius: '1rem',
    width: '100%',
    justifyContent: 'space-between',
    '@media (max-width: 64rem)': {
      padding: '0.75rem 1rem',
    },
  },

  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5?.fontSize,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.375rem',
    letterSpacing: '0.015625rem',
    color: theme.palette.text.secondary,
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h6?.fontSize,
      lineHeight: '1.25rem',
      letterSpacing: '0.025rem',
      paddingRight: '0.6875rem',
    },
  },
};

export const BottomBarButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1.5rem',
  padding: '1.5rem',
  [theme.breakpoints.down(wrapperWidth)]: {
    padding: '1rem 1.5rem',
    flexDirection: 'column',
    gap: '1rem',
  },
}));

export const DisabledButtonContainer = styled('div')(({ theme }) => ({
  width: '40%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2,1fr)',
  gap: '1.5rem',
  [theme.breakpoints.down(wrapperWidth)]: {
    width: '100%',
    gap: '1rem',
  },
}));

export const ButtonsContainer = styled('div')(({ theme }) => ({
  flexWrap: 'wrap',
  width: '60%',
  display: 'grid',
  gridTemplateColumns: 'repeat(3,1fr)',
  gap: '1.5rem',
  [theme.breakpoints.down(wrapperWidth)]: {
    gap: '1rem',
    width: '100%',
  },
}));

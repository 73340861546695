/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'react-i18next';

import { IGoogleMapPopupProps } from '../../../Vendor/Models/OneTimeJobDetails.model';
import LocationMarker from './LocationMarker';

import { Icons } from '../../../../Shared/Constants/Icons';

const GoogleMapPopup: React.FC<IGoogleMapPopupProps> = ({ open, onClose, latitude, longitude }) => {
  const center = {
    lat: latitude || 0,
    lng: longitude || 0,
  };

  const zoom = 12;

  const { t } = useTranslation(['dashboard', 'altTexts']);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{
        '& .MuiPaper-root': { borderRadius: '1.5rem', overflowY: 'unset' },
      }}>
      <DialogTitle sx={{ fontSize: '1.25rem' }}>
        {t('dashboard:viewMap')}
        <IconButton onClick={onClose} style={{ position: 'absolute', right: '1rem', top: '-1.6rem' }}>
          <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ height: '500px' }}>
          {latitude && longitude && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string }}
              defaultCenter={center}
              defaultZoom={zoom}>
              <LocationMarker lat={latitude} lng={longitude} />
            </GoogleMapReact>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GoogleMapPopup;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../Themes/theme';

export const ErrorWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1.25rem',
  height: '100%',
}));

export const ErrorTitleWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '1.5rem',
}));

export const ErrorTitle = styled('h2')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem' /* 122.222% */,
  letterSpacing: '0.009375rem',
}));

export const ErrorDesc = styled('h3')(({ theme }) => ({
  color: theme.palette.text.disabled,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  padding: '0.5rem 0 3rem 0',
}));

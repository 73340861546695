/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useState } from 'react';

import { newGuid } from '../Utilities';
import { FeatureSwitchContext } from './FeatureSwitchContext';
import { FeatureKeys, featureSwitchService } from '.';

interface IFeatureSwitchProviderProps {
  enabledByDefault?: FeatureKeys[];
  children: React.ReactNode;
}

export type HashMap<T> = Record<string, T>;

type FeatureSwitchProviderProps = IFeatureSwitchProviderProps;

export const FeatureSwitchProvider: React.FunctionComponent<FeatureSwitchProviderProps> = ({
  children,
  enabledByDefault = [],
}: React.PropsWithChildren<FeatureSwitchProviderProps>): React.ReactElement<FeatureSwitchProviderProps> => {
  const [key, setKey] = useState<string>(newGuid());

  useEffect(() => {
    if (enabledByDefault.length > 0) {
      const map: HashMap<boolean> = {};
      for (let index = 0; index < enabledByDefault?.length; index++) {
        const featureKey = enabledByDefault[index];
        map[featureKey] = true;
      }
      featureSwitchService.setFeaturesFromMap(map);
      setKey(newGuid());
    }
  }, [enabledByDefault]);

  const featureIsEnabled = useCallback(
    (featureKey: FeatureKeys): boolean => featureSwitchService.featureIsEnabled(featureKey),
    []
  );

  const setFeaturesFromMap = useCallback((features: HashMap<boolean>) => {
    featureSwitchService.setFeaturesFromMap(features);
    setKey(newGuid());
  }, []);

  const renderElement = useCallback(
    (): React.ReactElement<FeatureSwitchProviderProps> => (
      <FeatureSwitchContext.Provider
        key={key}
        value={{
          featureIsEnabled,
          setFeaturesFromMap,
        }}>
        {children}
      </FeatureSwitchContext.Provider>
    ),
    [children, featureIsEnabled, key, setFeaturesFromMap]
  );

  return renderElement();
};

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';

import { CreateInputJobInterest, CreateBidAttachmentInput, AllBidAttachmentsInput } from '../../../API';
import {
  addJobInterest,
  updateJobInterest,
  createBidAttachments,
  deleteBidAttachments,
} from '../../../graphql/mutations';
import { jobInterests, allBidAttachments } from '../../../graphql/queries';
import { IGraphQLResponse } from '../../../Shared/Models';
import { AuthHeader, AWSAuthMode } from '../../../Shared/Constants/App';
import { getWebInfo } from '../../../Shared/Utilities/utils';
import { AuthenticationService } from '../../../Shared/Services/AuthenticationService';

export default class BidCreationApiService {
  static async addJobInterest(customerId: string, vendorId: string, createJobInterestInput: CreateInputJobInterest[]) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const jobsInterestResponse: IGraphQLResponse = await API.graphql(
        {
          query: addJobInterest,
          variables: { customerId, vendorId, createJobInterestInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: jobsInterestResponse.data?.addJobInterest ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async updateJobInterest(customerId: string, vendorId: string, createJobInterestInput: CreateInputJobInterest) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const jobsInterestResponse: IGraphQLResponse = await API.graphql(
        {
          query: updateJobInterest,
          variables: { customerId, vendorId, createJobInterestInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: jobsInterestResponse.data?.updateJobInterest ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getJobInterests(customerId: string, vendorId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const allJobInterestsResponse: IGraphQLResponse = await API.graphql(
        {
          query: jobInterests,
          variables: { customerId, vendorId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: allJobInterestsResponse.data?.jobInterests ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async createBidAttachments(bidId: string, createBidAttachmentInput: CreateBidAttachmentInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const createBidAttachmentsResponse: IGraphQLResponse = await API.graphql(
        {
          query: createBidAttachments,
          variables: { bidId, createBidAttachmentInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: createBidAttachmentsResponse.data?.createBidAttachments ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async allBidAttachments(allBidAttachmentsInput: AllBidAttachmentsInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const allBidAttachmentsResponse: IGraphQLResponse = await API.graphql(
        {
          query: allBidAttachments,
          variables: { allBidAttachmentsInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: allBidAttachmentsResponse.data?.allBidAttachments ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async deleteBidAttachments(bidId: string, attachmentIds: string[]) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const deleteBidAttachmentsResponse: IGraphQLResponse = await API.graphql(
        {
          query: deleteBidAttachments,
          variables: { bidId, attachmentIds },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: deleteBidAttachmentsResponse.data?.deleteBidAttachments ?? [], errors: [] };
    } catch (error: any) {
      return { data: [], errors: error.errors };
    }
  }
}

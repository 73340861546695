/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
const sizeConfigs = {
  sidebar: {
    openWidth: '22.5rem',
    closedWidth: '5rem',
    position: 'absolute',
  },
};

const tabletSizeConfigs = {
  sidebar: {
    openWidth: '19.5rem',
    closedWidth: '4.5rem',
  },
};

export default sizeConfigs;
export { tabletSizeConfigs };

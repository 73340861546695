/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../Themes/theme';

const wrapperWidth = 1024 + 80; // 1024 -> max width and 80 is navbar width

export const CustomerContainer = styled('div')(({ theme }) => ({
  margin: '0 auto',
  padding: '9rem 4rem 6.5rem 4.75rem',
  height: '100%',
  [theme.breakpoints.down(wrapperWidth)]: {
    boxSizing: 'border-box',
    padding: '6.5rem 1.5rem 5.5rem 1.5rem',
  },
}));

export const HomePageContainer = styled('div')(({ theme }) => ({
  margin: '0 auto',
  padding: '0 9.75rem 2.5rem 4.75rem',
  [theme.breakpoints.down(wrapperWidth)]: {
    boxSizing: 'border-box',
    padding: '0 1.5rem 2.5rem 1.5rem',
  },
}));

export const CenterAlignment = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const NoDataWrapper = styled('div')(() => ({
  boxShadow: '0 0.625rem 1.25rem 0.375rem #7957001A',
  borderRadius: '1rem',
  padding: '1.25rem',
  backgroundColor: theme.palette.common.white,
  marginTop: '1.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
}));

export const SearchFieldContainer = styled('div')(({ theme }) => ({
  height: '3.625rem',
  backgroundColor: theme.palette.common.white,
  borderRadius: '1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingRight: '1rem',
  [theme.breakpoints.down(wrapperWidth)]: {
    height: '3rem',
  },
}));

export const SearchInput = styled('input')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.text.disabled,
  lineHeight: '1.5rem',
  height: '3rem',
  backgroundColor: theme.palette.common.white,
  borderRadius: '1rem',
  width: '100%',
  padding: '0 1.5rem',
  border: 'none',
  outline: 'none',
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
export interface ICustomAPIEndpoint {
  endpoint: string;
  name: string;
  region: string;
}

interface GraphQLError {
  message: string;
}

interface GraphQLErrorLocations {
  column: number;
  line: number;
}
export interface IGraphQLError {
  errors: GraphQLError;
  locations: GraphQLErrorLocations[];
}

export interface IGraphQLResponse {
  data?: any;
  errors?: any;
}

export enum ApproverType {
  Vendor = 'Vendor',
  Customer = 'Customer',
  Supervisor = 'Supervisor',
  PrimeVendor = 'PrimeVendor',
}

export enum JobStatus {
  Draft = 'Draft',
  Awarded = 'Awarded',
  OpenToBid = 'OpenToBid',
  Completed = 'Completed',
  Incomplete = 'Incomplete',
  InProgress = 'InProgress',
  Closed = 'Closed',
  Expired = 'Expired',
  Rejected = 'Rejected',
  PendingEstimation = 'PendingEstimation',
}

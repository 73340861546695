/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';

export const RevisionWrapper = styled('div')(() => ({
  flexDirection: 'row',
  display: 'flex',
}));

export const JobCardWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
}));

export const RevisionHeadingText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  padding: '0.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

export const CommentsWrapper = styled('div')(() => ({
  gap: '0.25rem',
  display: 'flex',
  flexDirection: 'column',
}));

export const CommentsText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  color: theme.palette.secondary.contrastText,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
  },
}));

export const CustomerCommentsText = styled('label')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  whiteSpace: 'nowrap',
  marginBottom: '0.25rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

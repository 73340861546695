/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '../../../../Shared/Constants/Icons';
import {
  SuccessText,
  JobAwardedText,
  LogoContainer,
  ImageField,
  NameField,
  PopupHeadingText,
  FinalQuoteValue,
  LogoAndNameContainer,
  Logo,
} from './Popup.Styles';
import { Box } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import { SuccessWrapper, CloseIconStyle, CongratsContainer, ButtonsContainer } from '../CreateBid.Style';
import { Linking } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';

interface IJobAwardSuccessPopupProps {
  isOpen: boolean;
  onClose: () => void;
  facilityName?: string;
  vendorName?: string;
  finalQuote?: number;
  vendorUrl?: any;
  facilityUrl?: any;
  bidDetails?: any;
}

const JobAwardSuccessPopup: React.FC<IJobAwardSuccessPopupProps> = ({
  isOpen,
  onClose,
  vendorName,
  finalQuote,
  facilityUrl,
  vendorUrl,
  bidDetails,
}) => {
  const { organizationName = '' } = getStoredCustomerDetails() || {};
  const { t } = useTranslation(['dashboard', 'vendor']);
  return (
    <SuccessWrapper
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: '30rem',
        },
      }}>
      <CloseIconStyle onClick={onClose}>
        <img src={Icons.InfoWindowCloseIcon} />
      </CloseIconStyle>
      <CongratsContainer sx={{ display: 'flex' }}>
        <Box sx={{ margin: '2.5rem 0' }}>
          <img src={Icons.CongratulationsImg} />
        </Box>
        <SuccessText>{t('dashboard:success')}</SuccessText>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {bidDetails?.isVendorBid ? (
            <>
              <JobAwardedText>{t('vendorBidCreation:bidSubmitted')}</JobAwardedText>
              <JobAwardedText sx={{ fontWeight: theme.typography.fontWeightBold, marginBottom: '2rem' }}>
                {bidDetails?.jobName}
              </JobAwardedText>
            </>
          ) : (
            <>
              <JobAwardedText>{t('dashboard:jobAwardedTo')}</JobAwardedText>
              <JobAwardedText sx={{ fontWeight: theme.typography.fontWeightBold, marginBottom: '2rem' }}>
                {vendorName}
              </JobAwardedText>
            </>
          )}
        </Box>
        {!bidDetails?.isVendorBid && (
          <>
            <LogoAndNameContainer>
              <Box
                sx={{
                  display: 'flex',
                  width: '33%',
                  justifyContent: 'flex-end',
                }}>
                <NameField sx={{ marginBottom: 0, marginRight: '2rem' }}>{organizationName}</NameField>
              </Box>
              <Logo>
                <LogoContainer
                  sx={{
                    border: `0.125rem solid ${theme.palette.success.main}`,
                    marginBottom: 0,
                    background: theme.palette.common.white,
                  }}>
                  {facilityUrl ? (
                    <img
                      src={facilityUrl}
                      style={{
                        height: '3.5rem',
                        width: '3.5rem',
                        background: theme.palette.secondary.customColor7,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: theme.typography.h5?.fontSize,
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                      alt={t('altTexts:companyLogo')}
                    />
                  ) : (
                    <ImageField>{organizationName[0]}</ImageField>
                  )}
                </LogoContainer>

                <Box
                  sx={{
                    marginLeft: '-1.5rem',
                    '& img': {
                      width: '2rem',
                      height: '2rem',
                      borderRadius: '50%',
                    },
                    zIndex: 3,
                  }}>
                  <img src={Icons.AwardedIcon} />
                </Box>

                <LogoContainer
                  sx={{
                    border: `0.125rem solid ${theme.palette.success.main}`,
                    marginLeft: '-1.5rem',
                    marginBottom: 0,
                    background: theme.palette.common.white,
                  }}>
                  {vendorUrl ? (
                    <img
                      src={vendorUrl}
                      style={{
                        height: '3.5rem',
                        width: '3.5rem',
                        background: theme.palette.secondary.customColor7,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: theme.typography.h5?.fontSize,
                        fontWeight: theme.typography.fontWeightBold,
                      }}
                      alt={t('altTexts:companyLogo')}
                    />
                  ) : (
                    <ImageField>{vendorName?.[0]}</ImageField>
                  )}
                </LogoContainer>
              </Logo>

              <Box sx={{ display: 'flex', width: '33%' }}>
                <NameField sx={{ marginBottom: 0, marginLeft: '2rem' }}>{vendorName}</NameField>
              </Box>
            </LogoAndNameContainer>
            <Box sx={{ display: 'flex', gap: '1rem', marginBottom: '.5rem' }}>
              <PopupHeadingText sx={{ fontSize: theme.typography.h5?.fontSize }}>
                {t('vendor:finalQuote')}
              </PopupHeadingText>
              <FinalQuoteValue>{`$ ${finalQuote && finalQuote.toFixed(2)}`}</FinalQuoteValue>
            </Box>
          </>
        )}
      </CongratsContainer>
      <ButtonsContainer sx={{ padding: '2rem' }}>
        {bidDetails?.isVendorBid ? (
          <Linking to={{ pathname: `/home` }} sx={{ width: '100%' }}>
            <Button type={ButtonType.Primary} width="100%" label={t('dashboard:backToHome')} onClick={onClose} />
          </Linking>
        ) : (
          <Linking to={{ pathname: `/jobs-view/onetime` }} sx={{ width: '100%' }}>
            <Button type={ButtonType.Primary} width="100%" label={t('dashboard:backToJobs')} onClick={onClose} />
          </Linking>
        )}
      </ButtonsContainer>
    </SuccessWrapper>
  );
};

export default JobAwardSuccessPopup;

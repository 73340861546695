/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType } from '../Buttons/Button';
import { Icons } from '../../../Constants/Icons';
import theme from '../../../Themes/theme';

const ErrorScreen = ({ fetchData }) => {
  const { t } = useTranslation(['altTexts', 'noData']);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        padding: 3,
      }}>
      <img src={Icons.CautionBordErrorImg} alt={t('altTexts:error')} />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h3.fontSize,
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: '1.375rem' /* 122.222% */,
            letterSpacing: '0.009375rem',
          }}>
          {t('noData:errorLoadingData')}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.disabled,
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h5.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: '1.375rem',
            letterSpacing: '0.015625rem',
            padding: '0.5rem 0 1.5rem 0',
          }}>
          {t('noData:mapError')}
        </Typography>
        <Button width="30.75rem" type={ButtonType.Primary} onClick={fetchData} label="Reload" />
      </Box>
    </Box>
  );
};

export default ErrorScreen;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { withTranslation } from 'react-i18next';
import { useState, useCallback, useEffect } from 'react';
import { Button, Stack, Box } from '@mui/material';
import { Auth } from '@aws-amplify/auth';
import CircularProgress from '@mui/material/CircularProgress';

import { Icons } from '../../../../Shared/Constants/Icons';

import Carousel from './Carousel';
import LoginWithEmail from './LoginWithEmail';
import LoginWithPhoneNo from './LoginWithPhoneNo';
import LoginOptions from './LoginOptions';
import Signup from './SignUp';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import {
  LoginContainer,
  NameAndLogoContainer,
  LogoContainer,
  SignUpText,
  WelcomeText,
  ArrowandTextContainer,
} from './LoginPage.Style';
import theme from '../../../../Shared/Themes/theme';

function Login({ t }) {
  const [isEmailVisible, setEmailVisibility] = useState(false);
  const [isPhoneNoVisible, setPhoneNoVisibility] = useState(false);
  const [isSignupVisible, setSignupVisibility] = useState(false);
  const [isResetPasswordVisible, setResetPasswordVisibility] = useState(false);
  const [isForgotPasswordVisible, setForgotPasswordVisibility] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState(false);

  const handleLoginOptionClick = (value, option) => {
    if (option === 'email') {
      setEmailVisibility(value);
    }
    if (option === 'phoneNumber') {
      setPhoneNoVisibility(value);
    }
    if (option === 'resetPassword') {
      setResetPasswordVisibility(value);
    }
    if (option === 'forgotPassword') {
      setForgotPasswordVisibility(value);
    }

    setLoading(false);
    setSignupVisibility(false);
  };

  const handleForgotPasswordClick = () => {
    handleLoginOptionClick(true, 'forgotPassword');
    setEmailVisibility(false);
  };

  // const handleSignupClick = () => {
  //   setSignupVisibility(true);
  //   setEmailVisibility(false);
  //   setPhoneNoVisibility(false);
  // };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [isTablet, setIsTablet] = useState(false);

  const checkScreenSize = useCallback(() => {
    setIsTablet(window.innerWidth <= 1024);
  }, []);

  const signInUsing = async (provider) => {
    try {
      setLoading(true);

      await Auth.federatedSignIn({
        provider,
      });

      // Redirect to home page
    } catch (error) {
      console.log('error', error);
      setSnackbarMessage(`{t('signIn:welcome')}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [checkScreenSize]);

  return (
    <LoginContainer>
      <Stack
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          width: '100%',
          ...(isTablet
            ? {
                height: '100vh',
                padding: '0rem 3.75rem 0rem 3.75rem',
              }
            : {
                height: '100%',
                padding: '0rem 6.5rem 0rem 6.75rem',
              }),
        }}>
        <NameAndLogoContainer>
          <LogoContainer>
            <img src={Icons.PeazyLogoLogin} alt={t('altTexts:peazyLogo')} />
          </LogoContainer>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              justifyContent: isEmailVisible || isPhoneNoVisible || isSignupVisible ? 'space-between' : 'center',
            }}>
            <ArrowandTextContainer sx={{ display: 'flex' }}>
              {(isEmailVisible ||
                isPhoneNoVisible ||
                isSignupVisible ||
                isResetPasswordVisible ||
                isForgotPasswordVisible) && (
                <Button
                  sx={{ padding: 0, minWidth: '2rem' }}
                  onClick={() => {
                    if (isForgotPasswordVisible) {
                      setEmailVisibility(true);
                      setPhoneNoVisibility(false);
                      setSignupVisibility(false);
                      setResetPasswordVisibility(false);
                      setForgotPasswordVisibility(false);
                    }
                    if (isResetPasswordVisible) {
                      setForgotPasswordVisibility(true);
                      setEmailVisibility(false);
                      setPhoneNoVisibility(false);
                      setSignupVisibility(false);
                      setResetPasswordVisibility(false);
                    } else {
                      setEmailVisibility(false);
                      setPhoneNoVisibility(false);
                      setSignupVisibility(false);
                      setResetPasswordVisibility(false);
                      setForgotPasswordVisibility(false);
                    }
                  }}>
                  <img src={Icons.LeftArrowIcon} alt={t('altTexts:backArrow')} style={{}} />
                </Button>
              )}

              {/* {isSignupVisible ? (
                <SignUpText>{t('signUp:signUp')}</SignUpText>
              ) : (
                <WelcomeText>{t('signIn:welcome')}</WelcomeText>
              )} */}
              {isSignupVisible ? (
                <SignUpText>{t('signUp:signUp')}</SignUpText>
              ) : isResetPasswordVisible ? (
                <WelcomeText>{t('signIn:resetPassword')}</WelcomeText>
              ) : isForgotPasswordVisible ? (
                <WelcomeText>{t('signIn:forgotPassword')}</WelcomeText>
              ) : (
                <WelcomeText>{t('signIn:welcome')}</WelcomeText>
              )}
            </ArrowandTextContainer>
            {isSignupVisible ? (
              <Signup />
            ) : (
              <div>
                {/* {!isEmailVisible && !isPhoneNoVisible && <LoginUsingText>{t('signIn:signInUsing')}</LoginUsingText>} */}
              </div>
            )}
          </Box>
        </NameAndLogoContainer>

        <>
          {!isLoading ? (
            <>
              {!isEmailVisible &&
                !isPhoneNoVisible &&
                !isSignupVisible &&
                !isResetPasswordVisible &&
                !isForgotPasswordVisible && <LoginOptions onClick={handleLoginOptionClick} signInUsing={signInUsing} />}
            </>
          ) : (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          )}
        </>
        {isPhoneNoVisible && <LoginWithPhoneNo onBackPress={setPhoneNoVisibility} />}
        {/* {!isEmailVisible && !isPhoneNoVisible && !isSignupVisible && (
          <NotYouText>
            {t('signIn:notYou')} <NewUserText onClick={handleSignupClick}>{t('signIn:signUpAsNewUser')}</NewUserText>
          </NotYouText>
        )} */}

        {isResetPasswordVisible && (
          <ResetPassword
            email={email}
            onBackPress={() => {
              setResetPasswordVisibility(true);
            }}
            redirect={handleLoginOptionClick}
          />
        )}
        {isForgotPasswordVisible && (
          <ForgotPassword
            email={email}
            setEmail={setEmail}
            onBackPress={() => {
              handleLoginOptionClick(true, 'resetPassword');
              setForgotPasswordVisibility(false);
            }}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
        {isEmailVisible && <LoginWithEmail onForgotPasswordClick={handleForgotPasswordClick} />}
        {isTablet && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              width: '100vw',
              backgroundColor: theme.palette.common.white,
              position: 'absolute',
              bottom: 0,
            }}>
            <img src={Icons.ExteriorCleaningImg} alt={t('altTexts:exteriorCleaning')} style={{ marginRight: '1rem' }} />
            <img src={Icons.JanitorialServicesImg} alt={t('altTexts:janitorialServices')} style={{ bottom: 0 }} />
            <img
              src={Icons.FurnitureCleaningImg}
              alt={t('altTexts:furnitureCleaning')}
              style={{ marginLeft: '-1rem' }}
            />
            <img src={Icons.FacadeCleaningImg} alt={t('altTexts:facadeCleaning')} style={{ marginLeft: '-2rem' }} />
          </Box>
        )}
      </Stack>
      {!isTablet && (
        <Box
          sx={{
            flex: 1,
            background: theme.palette.secondary.main,
            borderRadius: '2rem',
            width: '100%',
            margin: '1.5rem 1.5rem 1.5rem 0',
            paddingBottom: '4.5rem',
            height: '100%',
          }}>
          <style>
            {`
              button[aria-label^='carousel indicator'] {
                color: ${theme.palette.primary.dark};
              }
              button[aria-label^='carousel indicator']:hover {
                color: ${theme.palette.primary.dark}; 
              }
            `}
          </style>
          <Carousel />
        </Box>
      )}
      <SnackbarMessage
        open={snackbarOpen}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
        successMessage={t('forgotPassword:otpSentSuccessfully')}
      />
    </LoginContainer>
  );
}

export default withTranslation(['signIn', 'altTexts'])(Login);

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';

import { resendBidEmail } from '../../../graphql/mutations';
import { ResendBidEmail } from '../../Vendor/Models/OneTimeJob.model';
import { AuthHeader, AWSAuthMode } from '../../../Shared/Constants/App';
import { getWebInfo } from '../../../Shared/Utilities/utils';
import { IGraphQLResponse } from '../../../Shared/Models';
import { AuthenticationService } from '../../../Shared/Services/AuthenticationService';

export default class OneTimeJobApiService {
  static async resendEmail(bidId: string): Promise<{ data: ResendBidEmail | []; errors: [] }> {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const resendEmailResponse: IGraphQLResponse = await API.graphql(
        {
          query: resendBidEmail,
          variables: { bidId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: resendEmailResponse.data?.resendBidEmail ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { ReactNode } from 'react';
import { Route } from 'react-router-dom';

import PageWrapper from '../Shared/Components/Layout/PageWrapper';
import appRoutes from './appRoutes';
import { RouteType } from './config';
import ProtectedRoute from './ProtectedRoute';

const generateRoute = (routes: RouteType[]): ReactNode => {
  return routes.map((route, index) =>
    route.index ? (
      <Route
        index
        path={route.path}
        element={
          <ProtectedRoute>
            <PageWrapper state={route.state}>{route.element}</PageWrapper>
          </ProtectedRoute>
        }
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        element={
          <ProtectedRoute>
            <PageWrapper state={route.child ? undefined : route.state}>{route.element}</PageWrapper>
          </ProtectedRoute>
        }
        key={index}>
        {route.child && generateRoute(route.child)}
      </Route>
    )
  );
};

export const routes: ReactNode = generateRoute(appRoutes);

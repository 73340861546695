/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MRT_SortingState } from 'material-react-table';
import { IconButton } from '@mui/material';
import { isEmpty } from 'lodash';

import {
  deepClone,
  getBidAddress,
  getBidFacility,
  getStoredCustomerDetails,
  getVendorBids,
  handleFilter,
  handleSort,
  NUMBER_OF_ITEMS_PER_PAGE,
} from '../../../../Shared/Utilities/utils';

import MarketplaceApiService from '../../Services/marketplaceServices';
import VendorApiService from '../../../../Shared/Services/VendorService';
import CreateBidServices from '../../Services/CreateBidServices';
import { BidQueryType, BidStatus, DraftStatus, UpdateJobBidInput } from '../../../../API';

import { IJobRow } from '../../../Jobs/Models/JobsView.Model';
import { GetAllBids, JobType, MarketplaceTabs, SortingItem } from '../../Models/Marketplace.model';
import { SortByType, SubmittedBidStatus } from '../../../../Shared/Constants/App';
import { FilterItem } from '../../../Jobs/Components/JobsListView/JobsListView';

import DataGridComponent from './BaseTable';
import ModifyPopUp from '../../../Jobs/Components/JobDetails/ModifyPopUp';
import useColumns from './useColumns';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';

import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import { ActionsColumn, Linking } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';

const SubmittedBids = (): JSX.Element => {
  const { t } = useTranslation(['vendor', 'tableHeader', 'altTexts', 'vendorBidCreation', 'jobAward']);

  const [sortBy, setSortBy] = useState('');
  const [submittedBids, setSubmittedBids] = useState<any[]>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [newSortOrder, setNewSortOrder] = useState('');
  const [newSort, setNewSort] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRequestRevisionBidPopupOpen, setIsRequestRevisionBidPopupOpen] = useState(false);
  const [RequestRevisionBidDetails, setRequestRevisionBidDetails] = useState<any>({});
  const [isResubmitLoader, setIsResubmitLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [columnFilters, setColumnFilters] = useState<{ id: string; value: string }[]>([]);

  const hasFetchedDataRef = useRef(false);

  const navigate = useNavigate();

  const myCustomSortingFn = (): void => {
    setSortBy(SortByType.DESC);
  };

  const onSortChange = async (sortingObj: SortingItem[]): Promise<void> => {
    await handleSort(
      sortingObj,
      newSortOrder,
      setSorting,
      setNewSortOrder,
      setNewSort,
      fetchData,
      hasFetchedDataRef,
      newSort,
      currentPage,
      itemsPerPage
    );
  };

  const onFilterChange = async (filtersObj: FilterItem[]): Promise<void> => {
    await handleFilter(
      filtersObj,
      setColumnFilters,
      fetchData,
      currentPage,
      itemsPerPage,
      newSortOrder,
      hasFetchedDataRef,
      newSort
    );
  };

  const handleChange = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const handleRequestRevisionBidPopup = (): void => {
    setIsRequestRevisionBidPopupOpen(!isRequestRevisionBidPopupOpen);
  };

  const getBidStatus = (bidDataStatus: BidStatus) => {
    switch (bidDataStatus) {
      case BidStatus.S:
        return SubmittedBidStatus.PendingForCustomerApproval;
      case BidStatus.V:
        return SubmittedBidStatus.RevisionRequested;
      case BidStatus.R:
        return SubmittedBidStatus.Rejected;
      default:
        return null;
    }
  };

  const transformBidsData = (data: GetAllBids[], customerId: string) => {
    return data.map((selectedBid: GetAllBids) => {
      const jobBids = getVendorBids(selectedBid, customerId);
      const jobFacility = getBidFacility(selectedBid);
      const address = getBidAddress(selectedBid);
      const services = selectedBid?.jobServices?.map((service) => service?.mdServiceName).join(', ');

      return {
        ...selectedBid,
        id: selectedBid?.bidId ?? '',
        bidDueDate: selectedBid?.bidDueDate,
        createdOn: selectedBid?.bidCreatedOn,
        area: selectedBid?.totalSqftArea ?? selectedBid?.totalUnitsArea,
        location: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
        facilityAddress: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
        facilityName: selectedBid?.facilityBuildingName ?? '',
        type: JobType.BidsInProgress,
        bidId: selectedBid?.bidId ?? '',
        bidVersion: selectedBid?.bidVersion,
        bidSubmissionDate: selectedBid?.bidSubmissionDate,
        vendorId: customerId,
        jobFacility: jobFacility,
        jobBids: jobBids,
        address: address,
        Rejected: jobBids?.[0]?.dataStatus === ('R' as BidStatus) ? 'Rejected' : null,
        bidStatus: getBidStatus(jobBids?.[0]?.dataStatus as BidStatus),
        status: selectedBid?.jobDataStatus,
        mdServiceName: services,
      };
    });
  };

  const fetchData = async (
    pageNumber?: number,
    limit?: number,
    sortBy?: string,
    sortOrder?: boolean,
    filterBy?: string,
    filter?: string
  ) => {
    const { customerId = '' } = getStoredCustomerDetails();
    setIsLoading(true);
    try {
      const sortOrderString = sortOrder ? 'desc' : sortBy ? 'asc' : undefined;

      const submittedBids = await MarketplaceApiService.getAllBids(
        customerId,
        BidQueryType.SubmittedBids,
        pageNumber,
        limit ?? NUMBER_OF_ITEMS_PER_PAGE,
        sortBy,
        sortOrderString,
        filterBy,
        filter
      );
      if (submittedBids?.errors?.length) {
        setSnackbarMessage(submittedBids?.errors?.[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      if (submittedBids?.data && submittedBids?.errors?.length === 0) {
        const modifiedData = transformBidsData(submittedBids?.data, customerId);
        const filteredModifiedData = modifiedData.filter(Boolean);
        if (!isEmpty(filteredModifiedData)) {
          setSubmittedBids(filteredModifiedData);
          setTotalCount(submittedBids?.metadata?.totalCount);
          setIsLoading(false);
        } else {
          setSubmittedBids([]);
          setIsLoading(false);
        }
      }
    } catch (error: any) {
      console.log('fetch submitted bids error: ', error);
    }
    setIsLoading(false);
  };

  const renderSubmittedBidsActionsCell = (row: any): JSX.Element => {
    const { jobId, jobCustodian } = row.original as unknown as IJobRow;
    const { customerId } = getStoredCustomerDetails();
    return (
      <ActionsColumn>
        {row.original?.bidStatus === SubmittedBidStatus.RevisionRequested && (
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
            onClick={() => {
              setIsRequestRevisionBidPopupOpen(true);
              setRequestRevisionBidDetails({
                jobDetails: { ...submittedBids[row.index], isVendorBid: true, isRequestRevision: true },
                jobId: submittedBids[row.index].jobId,
              });
            }}>
            <WhiteTooltip title={t('vendor:reviseBid')}>
              <img src={Icons.EditBlackIcon} alt={t('altTexts:modify')} />
            </WhiteTooltip>
          </IconButton>
        )}
        {(!jobCustodian || (jobCustodian && customerId !== jobCustodian)) && (
          <Linking
            to={{ pathname: `/conversations`, search: `?jobId=${jobId}` }}
            state={{ ...row.original, tag: 'BID_RELATED' }}
            key={0}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              <WhiteTooltip title={t('vendor:message')}>
                <img
                  src={Icons.ConversationIcon}
                  alt={t('altTexts:conversation')}
                  style={{ width: '1.3rem', marginTop: '2px' }}
                />
              </WhiteTooltip>
            </IconButton>
          </Linking>
        )}
        <Linking
          to={{
            pathname: '/onetimejobdetails',
            search: `?id=${jobId}`,
          }}
          state={{
            facilityItem: { ...row.original },
            facility: {
              ...row.original.jobFacility,
              address: { addressName: row.original?.jobFacility?.facilityAddress },
              facilityType: row.original?.jobFacility?.mdFacilityType,
            },
            job: { ...row.original },
            isRequestRevision: true,
          }}
          key={2}>
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}>
            {' '}
            <WhiteTooltip title={t('homePage:viewDetails')}>
              <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} />
            </WhiteTooltip>
          </IconButton>
        </Linking>
      </ActionsColumn>
    );
  };

  const { columns } = useColumns(MarketplaceTabs.SubmittedBids);

  const submittedBidsColumns = useMemo(() => {
    const jobColumns = deepClone(columns);
    jobColumns.splice(16, 0, {
      header: t('tableHeader:actions'),
      enableColumnActions: false,
      enablePinning: false,
      size: 90,
      Cell: ({ row }: any) => renderSubmittedBidsActionsCell(row),
    });

    return jobColumns;
  }, [columns]);

  const navigateToCreateBid = (bidDetails: any) => {
    setTimeout(() => {
      navigate('/create-bid', { state: { ...bidDetails } });
    }, 500);
  };

  const resubmitBid = async (bidDetails: any) => {
    try {
      setIsResubmitLoading(true);
      const bidId = bidDetails?.jobDetails?.bidId;
      const bidSummaryResponse = await VendorApiService.getBidSummary(bidId);

      const bidVersion = (bidSummaryResponse?.data?.bidVersion ?? 0) + 1;
      const bidDraftStatus = bidSummaryResponse?.data?.draftStatus;
      const updateBidInput: UpdateJobBidInput = {
        dataStatus: BidStatus.V,
        draftStatus: DraftStatus.Y, // Bid draft status should be Y to handle bid version update.
        bidVersion,
      };
      const response =
        bidDraftStatus === DraftStatus.C
          ? await CreateBidServices.updateBid('1234', bidId, updateBidInput)
          : { data: [], errors: [] }; // Don't update bidVersion if bid is already in in-progress.;
      if ((!isEmpty(response?.data?.bidId) && isEmpty(response?.errors ?? [])) || bidDraftStatus === DraftStatus.Y) {
        navigateToCreateBid(bidDetails);
      } else {
        setSnackbarMessage(`${t('dashboard:bidUpdateError')}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsResubmitLoading(false);
    } catch (error) {
      console.error('Error while updating the bid:', error);
    }
  };

  useEffect(() => {
    if (!hasFetchedDataRef.current && !sorting.length && !columnFilters.length) {
      fetchData(currentPage, itemsPerPage);
      hasFetchedDataRef.current = true;
    }
  }, [sorting, columnFilters]);

  return (
    <>
      <DataGridComponent
        columns={submittedBidsColumns}
        data={submittedBids}
        sorting={sorting}
        sortBy={sortBy}
        isLoading={isLoading}
        handleSort={onSortChange}
        myCustomSortingFn={myCustomSortingFn}
        handleChange={handleChange}
        totalCount={totalCount}
        handleApi={fetchData}
        currentPage={currentPage}
        newSortOrder={newSortOrder}
        newSort={newSort}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        filterJobs={onFilterChange}
      />
      <ModifyPopUp
        open={isRequestRevisionBidPopupOpen}
        handleClose={handleRequestRevisionBidPopup}
        handleOnCloseJob={() => resubmitBid(RequestRevisionBidDetails)}
        heading={t('assignJob:bidRevision')}
        text={t('assignJob:confirmBidRevision')}
        loading={isResubmitLoader}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default SubmittedBids;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  marginBottom: '2.5rem',
  position: 'fixed',
  width: 'inherit',
  top: 0,
  zIndex: 10,
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
    position: 'absolute',
    zIndex: 1,
  },
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2rem 9.75rem 2rem 4.75rem',
  boxSizing: 'content-box',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    columnGap: '1rem',
  },
  [theme.breakpoints.down('laptop')]: {
    padding: '1.625rem 1.5rem',
    '& > div': {
      columnGap: '0.75rem',
    },
  },
  [theme.breakpoints.down('mobile')]: {
    padding: '1rem 1.5rem',
  },
}));

export const Title = styled('h1')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '2.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h2.fontSize,
    lineHeight: '1.75rem',
  },
}));

export const Icon = styled('img')(({ theme }) => ({
  width: '1.875rem',
  height: '1.875rem',
  userSelect: 'none',
  WebkitUserDrag: 'none',
  cursor: 'pointer',
  [theme.breakpoints.down('laptop')]: {
    width: '1.25rem',
    height: '1.25rem',
  },
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';
import awsConfig from '../../../Configuration/environment-config';
import { facilities, facility, facilityServiceSpecification, vendor, vendorAddresses } from '../../../graphql/queries';

import { facilitiesMock, vendorAddressesMock, vendorDetailsMock } from '../../Jobs/Components/IssuesRaised/MockData';
import { formatDateToYYMMDDFormat, getWebInfo } from '../../../Shared/Utilities/utils';
import { AddressData, CreateFacilityServiceSpecificationInput, FacilityData, FacilityInput } from '../../../API';
import {
  addFacility,
  addFacilityServiceSpecification,
  updateAddress,
  updateFacility,
} from '../../../graphql/mutations';
import { AuthHeader, AWSAuthMode } from '../../../Shared/Constants/App';
import { IGraphQLResponse } from '../../../Shared/Models';
import { AuthenticationService } from '../../../Shared/Services/AuthenticationService';

function urlEncodeDoubleQuotes(id: string | number | boolean) {
  const encodedString = encodeURIComponent(id);
  return `%22${encodedString}%22`;
}

export default class FacilityApiService {
  static async getIds(
    id = '521bd586-4b1d-435a-b70d-e8257840328d',
    startDate: string | number | Date,
    endDate: string | number | Date
  ) {
    try {
      const getFacilities = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'getIdsApi'
      );

      if (!getFacilities?.endpoint) {
        return { data: [], errors: [{ message: 'Endpoint not found' }] };
      }

      const requestOptions = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      const startDateYMDFormat = formatDateToYYMMDDFormat(startDate);
      const endDateYMDFormat = formatDateToYYMMDDFormat(endDate);

      const queryParams = `?fromDate=${startDateYMDFormat}&toDate=${endDateYMDFormat}&customerId=${id}`;
      // const queryParams = `?fromDate=2023-01-01&toDate=2023-08-06&customerId=521bd586-4b1d-435a-b70d-e8257840328d`;

      const endpointWithParams = `${getFacilities.endpoint}${queryParams}`;

      const idsApiResponse = await fetch(endpointWithParams, requestOptions);
      const responseJson = await idsApiResponse.json();

      return { data: responseJson ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getAllFacilities(
    customerId: string,
    pageNumber?: number,
    limit?: number,
    sortBy?: string,
    sortOrder?: string
  ) {
    const variables: { customerId: string; limit?: number; pageNumber?: number; sortBy?: string; sortOrder?: string } =
      { customerId };
    if (pageNumber) {
      variables.pageNumber = pageNumber;
    }
    // if (limit) {
    //   variables.limit = limit;
    // }
    if (sortBy) {
      variables.sortBy = sortBy;
    }
    if (sortOrder) {
      variables.sortOrder = sortOrder;
    }

    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      let response: IGraphQLResponse = await API.graphql(
        {
          query: facilities,
          variables,
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      response = await API.graphql(
        {
          query: facilities,
          variables: { ...variables, limit: limit || response.data?.facilities?.metadata.totalCount },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return {
        data: response.data?.facilities?.data ?? [],
        metaData: response.data?.facilities?.metadata ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getFacilities(ids: Array<string>) {
    try {
      const getFacilities = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'getFacilities'
      );

      if (!getFacilities?.endpoint) {
        return { data: [], errors: [{ message: 'Endpoint not found' }] };
      }

      const requestOptions = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      // Encode the array of IDs
      const encodedIds = ids.map(urlEncodeDoubleQuotes);

      // Join the encoded IDs into a single comma-separated string (if your API expects it as a comma-separated list)
      const commaSeparatedIds = encodedIds.join(',');

      const queryParams = `?requestIds=[${commaSeparatedIds}]`;

      // To get ontime job graph data
      // const queryParams = `?requestIds=[%227fa03125-bd6f-46d5-80e3-7e9ccef5ef86%22]`;

      const endpointWithParams = `${getFacilities.endpoint}${queryParams}`;

      const idsApiResponse = await fetch(endpointWithParams, requestOptions);
      const responseJson = await idsApiResponse.json();

      return { data: responseJson ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async facility(id: string, customerId: string, isMocked = false) {
    if (!isMocked) {
      try {
        const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
        const facilityResponse: IGraphQLResponse = await API.graphql(
          {
            query: facility,
            variables: { id, customerId },
            authMode: AWSAuthMode.COGNITO,
          },
          { ...getWebInfo(), ...authHeaders }
        );
        return { data: facilityResponse?.data?.facility ?? [], errors: [] };
      } catch (e: any) {
        return { data: null, errors: e.errors };
      }
    }
    return { data: facilitiesMock, errors: [] };
  }

  static async facilityServiceSpecification(customerId: string, facilityId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const facilityServiceSpecificationResponse: IGraphQLResponse = await API.graphql(
        {
          query: facilityServiceSpecification,
          variables: { customerId, facilityId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: facilityServiceSpecificationResponse.data?.facilityServiceSpecification ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async facilityIcon(photoReference: string) {
    try {
      const photoReferenceResponse: any = await fetch(
        `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${photoReference}&key='AIzaSyC2t6qfdqtIXTZzPGJkFbFE3YQKTxLo614'`,
        {
          // method: 'GET',
          mode: 'no-cors',
          // headers: {
          //   'Access-Control-Allow-Origin': '*',
          // },
        }
      );

      if (photoReferenceResponse.status !== 400) {
        const photoReferenceJson = await photoReferenceResponse.blob();
        return { data: photoReferenceJson };
      }
      return { data: null, errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async vendorDetails(id: string, isMocked = false) {
    if (!isMocked) {
      try {
        const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
        const vendorDetailsResponse: IGraphQLResponse = await API.graphql(
          {
            query: vendor,
            variables: { id },
            authMode: AWSAuthMode.COGNITO,
          },
          { ...getWebInfo(), ...authHeaders }
        );
        return { data: vendorDetailsResponse.data?.vendor ?? [], errors: [] };
      } catch (e: any) {
        console.error('Error vendorDetails', e);
        return { data: [], errors: e.errors };
      }
    }
    return { data: vendorDetailsMock, errors: [] };
  }

  static async getVendorAddresses(vendorId: string, isMocked = false) {
    if (!isMocked) {
      try {
        const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
        const VendorAddressesResponse: IGraphQLResponse = await API.graphql(
          {
            query: vendorAddresses,
            variables: { vendorId },
            authMode: AWSAuthMode.COGNITO,
          },
          { ...getWebInfo(), ...authHeaders }
        );
        return { data: VendorAddressesResponse.data?.vendorAddresses ?? [], errors: [] };
      } catch (e: any) {
        return { data: [], errors: e.errors };
      }
    }
    return { data: vendorAddressesMock, errors: [] };
  }
  static async updateFacility(facilityData: FacilityData) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const updateFacilityResponse: IGraphQLResponse = await API.graphql(
        {
          query: updateFacility,
          variables: { facilityData },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: updateFacilityResponse.data?.updateFacility ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async updateAddress(addressData: AddressData) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const updateFacilityResponse: IGraphQLResponse = await API.graphql(
        {
          query: updateAddress,
          variables: { addressData },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: updateFacilityResponse.data?.updateAddress ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async addFacility(facilityInput: FacilityInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const addFacilityResponse: IGraphQLResponse = await API.graphql(
        {
          query: addFacility,
          variables: { facilityInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: addFacilityResponse.data?.addFacility ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async addFacilityServiceSpecification(
    customerId: string,
    facilityId: string,
    facilityServiceSpecificationInput: CreateFacilityServiceSpecificationInput[]
  ) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const jobFacilityResponse: IGraphQLResponse = await API.graphql(
        {
          query: addFacilityServiceSpecification,
          variables: { customerId, facilityId, facilityServiceSpecificationInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: jobFacilityResponse.data?.addFacilityServiceSpecification ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API, Auth } from 'aws-amplify';

import { getUserByCognitoUserId } from '../../graphql/queries';
import { AWSAuthMode, AuthHeader } from '../Constants/App';
import { IGraphQLResponse } from '../Models';
import { getWebInfo } from '../Utilities/utils';
import { UserAuthEventInput } from '../../API';
import { createUserAuthEvent } from '../../graphql/mutations';

export class AuthenticationService {
  static async getUserByCognitoUserId(cognitoUserId: string) {
    try {
      const authHeaders: AuthHeader = await this.getAuthHeaders();
      const userByCognitoUserIdResponse: IGraphQLResponse = await API.graphql(
        {
          query: getUserByCognitoUserId,
          variables: { cognitoUserId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: userByCognitoUserIdResponse?.data?.getUserByCognitoUserId ?? {}, errors: [] };
    } catch (e: any) {
      return { data: {}, errors: e?.errors ?? [] };
    }
  }

  static async getAuthHeaders() {
    const session = await Auth.currentSession();
    return {
      Authorization: session.getIdToken().getJwtToken(),
    };
  }

  static async createUserAuthEvent(userAuthEventInput: UserAuthEventInput) {
    try {
      const authHeaders = await AuthenticationService.getAuthHeaders();
      const createUserAuthEventResponse: IGraphQLResponse = await API.graphql(
        {
          query: createUserAuthEvent,
          variables: { userAuthEventInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...authHeaders }
      );
      return { data: createUserAuthEventResponse?.data?.createUserAuthEvent ?? {}, errors: [] };
    } catch (e: any) {
      return { data: {}, errors: e?.errors ?? [] };
    }
  }
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

class Auth {
  // eslint-disable-next-line class-methods-use-this
  isAuthenticated() {
    return Boolean(localStorage.getItem('auth'));
  }
}

export default new Auth();

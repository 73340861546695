/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export enum FilterType {
  All = 'All',
  NoShow = 'NoShow',
  InProgress = 'InProgress',
  Completed = 'Completed',
  PendingReview = 'PendingReview',
  Approved = 'Approved',
  NoJob = 'NoJob',
  Scheduled = 'Scheduled',
}

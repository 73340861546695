/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Select, DialogActions, Box } from '@mui/material';

import moment from 'moment';
import { getDateRange } from '../../../Utilities/utils';
import { DateRangeOptionType } from '../../../Constants/App';
import Button, { ButtonType } from '../Buttons/Button';
import { Labels } from '../GraphContainer/GraphContainer';
import DateSelection from '../DatePicker/DateSelection';
import { FieldNames } from '../../../../Modules/Jobs/Models/AddEditOneTimeJob.Model';
import { DateRangeSelectionProps } from './DateRangeSelection.Model';

import { Icons } from '../../../Constants/Icons';
import theme from '../../../Themes/theme';

import { DatePickerContainer } from '../../../../Modules/Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import { CloseIconStyle, DialogWrapper, PopupHeadingText } from '../../../../Modules/Vendor/Components/CreateBid.Style';
import { Options } from '../Dropdown/Dropdown.styles';
import { JobsTitle } from '../../../../Modules/Jobs/Components/IssuesRaised/JobsIssuesRaised.styles';
import { DropDown, SelectInput } from '../GraphContainer/GraphContainer.styles';
import { DateRangeSelectionField } from './DateRangeSelection.Style';

const DateRangeSelection = ({ onDateRangeChange, defaultOption, options }: DateRangeSelectionProps): JSX.Element => {
  const [selectedRange, setSelectedRange] = useState<string>(defaultOption || '');
  const [isCustomRangeDialogOpen, setIsCustomRangeDialogOpen] = useState(false);
  const [customFromDate, setCustomFromDate] = useState<Date | null>(null);
  const [customToDate, setCustomToDate] = useState<Date | null>(null);
  const [isValueOpen, setValueOpen] = useState(false);

  const { t } = useTranslation(['Dashboard']);

  const { control, watch, getValues } = useForm({
    defaultValues: {
      [FieldNames.startDate]: customFromDate,
      [FieldNames.endDate]: customToDate,
    },
  });
  const watchedFromDate = watch(FieldNames.startDate, customFromDate);
  const watchedToDate = watch(FieldNames.endDate, customToDate);
  const isSubmitDisabled = !customFromDate || !customToDate || customFromDate > customToDate;

  const toggleValue = () => {
    setValueOpen(!isValueOpen);
  };

  const handleRangeChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedRange(event.target.value);
    let fromDate: Date | null = null;
    let toDate: Date | null = null;
    const selectedOption = options.find((option) => option.value === event.target.value);
    if (selectedOption?.type === DateRangeOptionType.Days && selectedOption.value) {
      const days = parseInt(selectedOption.value, 10);
      ({ fromDate, toDate } = getDateRange(days));
    } else if (selectedOption?.type === DateRangeOptionType.CustomDateRange) {
      setIsCustomRangeDialogOpen(true);
      return;
    } else {
      fromDate = null;
      toDate = null;
    }

    onDateRangeChange({ fromDate, toDate });
  };

  const handleCustomRangeSubmit = () => {
    const { startDate, endDate } = getValues();
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    const isStartDateValid = startMoment.isValid();
    const isEndDateValid = endMoment.isValid();

    if (isStartDateValid && isEndDateValid) {
      onDateRangeChange({
        fromDate: startMoment.toDate(),
        toDate: endMoment.toDate(),
      });
      setIsCustomRangeDialogOpen(false);
    } else {
      console.error(t('dashboard:invalidDateFormat'));
    }
  };

  useEffect(() => {
    setCustomFromDate(watchedFromDate);
    setCustomToDate(watchedToDate);
  }, [watchedFromDate, watchedToDate]);

  useEffect(() => {
    if (defaultOption !== undefined) {
      setSelectedRange(defaultOption);
    }
  }, [defaultOption]);

  return (
    <>
      <DateRangeSelectionField>
        <JobsTitle sx={{ fontSize: theme.typography.h4?.fontSize }}>{t('dashboard:dateRange')}</JobsTitle>
        <DropDown>
          <Box onClick={toggleValue}>
            <Select
              value={selectedRange}
              onChange={handleRangeChange}
              label={t('dashboard:dateRange')}
              input={
                <SelectInput
                  sx={{
                    '& .MuiSelect-select': {
                      width: '6.25rem',
                    },
                  }}
                />
              }
              IconComponent={() => (
                <img
                  src={Icons.DropdownIcon}
                  style={{
                    transform: isValueOpen ? Labels.Rotate : 'none',
                    position: 'absolute',
                    right: '0.75rem',
                  }}
                />
              )}
              open={isValueOpen}
              onClose={toggleValue}
              onOpen={toggleValue}>
              {options?.map((dataItem) => (
                <Options key={dataItem.id} value={dataItem.value}>
                  {dataItem.label}
                </Options>
              ))}
            </Select>
          </Box>
        </DropDown>
      </DateRangeSelectionField>

      <DialogWrapper open={isCustomRangeDialogOpen} onClose={() => setIsCustomRangeDialogOpen(false)}>
        <CloseIconStyle onClick={() => setIsCustomRangeDialogOpen(false)}>
          <img src={Icons.InfoWindowCloseIcon} />
        </CloseIconStyle>
        <PopupHeadingText>{t('dashboard:selectCustomDateRange')}</PopupHeadingText>
        <DatePickerContainer sx={{ marginBottom: '1.5rem', padding: '1rem' }}>
          <DateSelection
            requiredText={t('oneTimeJob:startDateFieldReq')}
            control={control}
            fieldName={FieldNames.startDate}
            label={t('calendar:startDate')}
            iconColor="black"
            isExcludePastDate={false}
          />
          <DateSelection
            requiredText={t('oneTimeJob:endDateFieldReq')}
            control={control}
            fieldName={FieldNames.endDate}
            label={t('calendar:endDate')}
            iconColor="black"
            isExcludePastDate={false}
          />
        </DatePickerContainer>
        <DialogActions sx={{ gap: '0.5rem' }}>
          <Button
            label={t('dashboard:cancel')}
            onClick={() => setIsCustomRangeDialogOpen(false)}
            type={ButtonType.Secondary}
          />
          <Button
            label={t('dashboard:submit')}
            onClick={handleCustomRangeSubmit}
            type={ButtonType.Primary}
            disabled={isSubmitDisabled}
          />
        </DialogActions>
      </DialogWrapper>
    </>
  );
};

export default DateRangeSelection;

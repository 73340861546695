/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const HeadingText = styled('div')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: '1.255rem',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  paddingBottom: '1rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4?.fontSize,
    lineHeight: '1.5rem',
  },
}));

export const bidCardStyles = {
  headerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  finalQuoteWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '1.5rem',
  },
};

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import RecommendedJobs from '../Components/Marketplace/RecommendedJobs';
import BidsInProgress from '../Components/Marketplace/BidsInProgress';
import AllJobs from '../Components/Marketplace/AllJobs';
import NotInterestedJobs from '../Components/Marketplace/NotInterestedJobs';
import SubmittedBids from '../Components/Marketplace/SubmittedBids';
import AwardedJobs from '../Components/Marketplace/AwardedJobs';

interface JobTab {
  id: number;
  label: string;
  route: string;
}

interface MarketplaceTab {
  label: string;
  route: string;
  component: React.FC;
}

interface GraphTab {
  id: number;
  label: string;
  route: string;
}

export const getMarketplaceTabs = (): MarketplaceTab[] => {
  const { t } = useTranslation('vendor');

  return [
    {
      label: t('vendor:recommended'),
      route: 'recommended-jobs',
      component: RecommendedJobs,
    },
    {
      label: t('vendor:allJobs'),
      route: 'all-jobs',
      component: AllJobs,
    },
    {
      label: t('vendor:notInterestedJobs'),
      route: 'not-interested-jobs',
      component: NotInterestedJobs,
    },
    {
      label: t('vendor:awardedJobs'),
      route: 'awarded-jobs',
      component: AwardedJobs,
    },
    {
      label: t('vendor:bidsIProgress'),
      route: 'bids-in-progress',
      component: BidsInProgress,
    },
    {
      label: t('vendor:submittedBids'),
      route: 'submitted-bids',
      component: SubmittedBids,
    },
  ];
};

export const getJobsTab = (): JobTab[] => {
  const { t } = useTranslation('vendor');

  return [
    {
      id: 0,
      label: t('vendor:selfPerformedJobs'),
      route: 'self-performed-jobs',
    },
    {
      id: 1,
      label: t('vendor:subContractorBids'),
      route: 'sub-contractor-jobs',
    },
    {
      id: 2,
      label: t('vendor:ongoingJobs'),
      route: 'ongoing',
    },
  ];
};

export const getBidsTab = (): JobTab[] => {
  const { t } = useTranslation('vendor');

  return [
    {
      id: 0,
      label: t('vendor:selfPerformedJobs'),
      route: 'job-details',
    },
    {
      id: 1,
      label: t('vendor:subContractorBids'),
      route: 'bids',
    },
  ];
};

export const facilityTypes = [
  {
    facilityType: 'acc',
    facilityName: 'Gym',
  },
  {
    facilityType: 'office',
    facilityName: 'Building',
  },
  {
    facilityType: 'hospital',
    facilityName: 'Hospitals',
  },
  {
    facilityType: 'bank',
    facilityName: 'Bank',
  },
  {
    facilityType: 'railRoad',
    facilityName: 'Rail Road',
  },
  {
    facilityType: 'warehouse',
    facilityName: 'Industrial/Warehouse',
  },
  {
    facilityType: 'financial',
    facilityName: 'Financial Services',
  },
];

export const getGraphsTab = (): GraphTab[] => {
  return [
    {
      id: 0,
      label: 'Quality And Inspection',
      route: 'quality-and-inspection',
    },
    {
      id: 1,
      label: 'Job Performance',
      route: 'job-performance',
    },
  ];
};

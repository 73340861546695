/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  DialogWrapper,
  PopupHeadingText,
  DeleteWithdrawText,
  CloseIconStyle,
} from '../../../Vendor/Components/CreateBid.Style';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import { Icons } from '../../../../Shared/Constants/Icons';

interface ConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleOnCloseJob: () => void;
  isCompleteJobLoading: boolean;
}

const ConfirmPopup: React.FC<ConfirmationDialogProps> = ({
  open,
  handleClose,
  handleOnCloseJob,
  isCompleteJobLoading,
}) => {
  const { t } = useTranslation(['assignJob']);
  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <CloseIconStyle onClick={handleClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      <PopupHeadingText>{t('assignJob:markTheJobAsComplete')}</PopupHeadingText>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem' }}>
        <DeleteWithdrawText>{t('assignJob:confirmCompleteTheJob')}</DeleteWithdrawText>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1.5rem',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}>
        <Button type={ButtonType.Secondary} label={t('assignJob:no')} onClick={handleClose} />
        <Button
          type={ButtonType.Primary}
          label={t('assignJob:yes')}
          onClick={handleOnCloseJob}
          disabled={isCompleteJobLoading}
          isLoading={isCompleteJobLoading}
        />
      </Box>
    </DialogWrapper>
  );
};

export default ConfirmPopup;

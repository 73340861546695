/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { TooltipProps } from 'recharts';
import { Typography } from '@mui/material';
import { CustomTooltipWrapper } from '../style';
import { IBarConfig } from '../Graphs.Model';

const CustomTooltip: React.FC<TooltipProps<number, string> & { labels: IBarConfig[]; labelName: string }> = ({
  active,
  payload,
  labels,
  label,
  labelName,
}) => {
  if (active && payload && payload.length) {
    return (
      <CustomTooltipWrapper>
        <Typography>
          {labelName}: {label}
        </Typography>
        {labels.map((bar, index) => (
          <Typography key={index} style={{ margin: 0 }}>
            {`${bar.name}: ${payload[index]?.value}`}
          </Typography>
        ))}
      </CustomTooltipWrapper>
    );
  }
  return null;
};

export default CustomTooltip;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { MRT_ColumnDef } from 'material-react-table';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TaskData } from '../../../../Modules/Jobs/Components/WorkTasks/WorkTasks';
import { IJobOverview } from '../../../../Modules/Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../../Modules/Jobs/Components/JobView/JobsView';
import { IBidList } from '../../../../Modules/Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../../Modules/Customer/Components/ViewFacilityDetails/ViewFacilityDetails';

import { WorkOrder } from '../../../../API';
import { ISchedules, Task } from '../../../../Shared/Models/WorkTask.model';
import {
  AccordionInfo,
  AssociateAccordionDescription,
  AccordionWrapper,
  ServicesTitle,
  ServicesSubTitle,
} from '../../../../Shared/Components/Common/Accordion/Accordion.styles';
import AssociateOnTheJob from './AssociateOnTheJob';
import { FacilityInterface } from '../../../Company/Models/Company.Model';
import { AllShiftDetailsQuery, SchedulesByDateQuery } from './AssociateCard';
import { CircularProgress } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

interface IDataItem {
  id: number;
  jobId?: string;
  isShowButton?: boolean;
  selectedRowCount?: number;
  taskCompleted?: number;
  tableData?: TaskData[];
  serviceName?: string;
  shiftTiming?: string;
  shiftType?: string;
  Jobname?: string;
  facilityName?: string;
  iconUrl?: any;
  categoryTitle?: any;
  totalTasks?: number;
  totalCompletedTasks?: any;
  options?: any;
}

export enum ScreenName {
  IssuesRaised = 'IssuesRaised',
  TasksReviewed = 'TaskReviewed',
  JobDetails = 'JobDetails',
}

interface IAccordionProps {
  vendorTypes?: [];
  accordionObj?: IDataItem;
  accordionObjNew: SchedulesByDateQuery;
  handleAccordion: (id: number) => (event: React.SyntheticEvent, expanded: boolean) => void;
  columns?: MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[];
  onChangeRow?: (rowSelection: object, data: TaskData[], service: any) => void;
  expanded: number | boolean;
  screenName: ScreenName;
  scheduleList: ISchedules[];
  handleApproveAll?: () => void;
  handleRejectAll?: () => void;
  dailyTaskData?: any;
  handleCustomerApproval?: (data: Task[]) => void;
  sendingForApproval?: boolean;
  isOtjJob?: boolean;
  optionsData?: any;
  jobData?: WorkOrder;
  FacilityData?: FacilityInterface;
  jobId: string;
  jobsLoader?: boolean;
  specificDate?: any;
  shiftDetails: AllShiftDetailsQuery[];
  isDataLoading: boolean;
  sectionTitle: string | null | undefined;
  scheduleSubName: string | null | undefined;
  shiftData: AllShiftDetailsQuery[];
}

/**
 * @returns Accordion
 */
const AssociateOnTheJobAccordion = ({
  accordionObjNew,
  handleAccordion,
  expanded,
  screenName,
  scheduleList,
  jobData,
  FacilityData,
  jobId,
  jobsLoader,
  specificDate,
  isDataLoading,
  sectionTitle,
  scheduleSubName,
  shiftData,
}: IAccordionProps) => {
  const { workOrderScheduleId = '', woScheduleMasterId = '' } = accordionObjNew ?? {};

  const expandStatus = scheduleList.findIndex((data) => data.id === workOrderScheduleId);

  return (
    <AccordionWrapper expanded={expanded === expandStatus + 1} onChange={handleAccordion(expandStatus + 1)}>
      <AssociateAccordionDescription
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header">
        {screenName === ScreenName.JobDetails ? (
          isDataLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ServicesTitle>{sectionTitle}</ServicesTitle>
              <ServicesSubTitle>{scheduleSubName}</ServicesSubTitle>
            </div>
          )
        ) : null}
      </AssociateAccordionDescription>
      <AccordionInfo>
        <AssociateOnTheJob
          jobData={jobData}
          FacilityData={FacilityData}
          jobId={jobId}
          jobsLoader={jobsLoader}
          specificDate={specificDate}
          workOrderScheduleId={workOrderScheduleId}
          woScheduleMasterId={woScheduleMasterId}
          scheduleData={accordionObjNew}
          shiftData={shiftData}
        />
      </AccordionInfo>
    </AccordionWrapper>
  );
};

export default AssociateOnTheJobAccordion;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../Themes/theme';
import { ButtonType } from './Button';

interface ICustomButtonProps {
  buttonType: ButtonType;
  width?: string;
}

export const CustomButton = styled('button')(({ buttonType, width = '' }: ICustomButtonProps) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  width: `${width ?? ''}`,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  borderRadius: '1rem',
  backgroundColor: `${buttonType === ButtonType.Primary ? theme.palette.primary.dark : theme.palette.common.white}`,
  padding: '1.0625rem 2rem',
  color: `${buttonType === ButtonType.Primary ? theme.palette.common.white : theme.palette.primary.dark}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '0.5rem',
  cursor: 'pointer',
  outline: 'none',
  whiteSpace: 'nowrap',
  // eslint-disable-next-line sonarjs/no-nested-template-literals
  border: `${buttonType === ButtonType.Primary ? 'none' : `0.0625rem solid ${theme.palette.primary.dark}`}`,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5?.fontSize,
    padding: '0.75rem 1.5rem',
  },
}));

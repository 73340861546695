/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';
import { AuthHeader, AWSAuthMode } from '../../../Shared/Constants/App';
import { getUserRoleScopes } from '../../../graphql/queries';
import { IGraphQLResponse } from '../../../Shared/Models';
import { getWebInfo } from '../../../Shared/Utilities/utils';
import { AuthenticationService } from '../../../Shared/Services/AuthenticationService';

export class UserService {
  static async getUserRoleScopes(userId: string, roleId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const usersResponse: IGraphQLResponse = await API.graphql(
        {
          query: getUserRoleScopes,
          variables: { userId, roleId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: usersResponse?.data?.getUserRoleScopes ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export enum ShiftTypes {
  Day = 'Day',
  DayAndNight = 'Day & Night',
  Night = 'Night',
}

export interface IJobData {
  address: any;
  id: number;
  facilityName: string;
  facilityId: string;
  jobId: string;
  location: string;
  FacilityIcon: string;
  buildingName: string;
  Jobname: string;
  startTime: string;
  endTime: string;
  vendorId: string;
  customerId?: string;
}

export interface IServices {
  id: number;
  serviceName: string;
  mdServiceId: string;
  iconUrl: string;
  shiftType: string;
  shiftTiming: string;
}
[];

export interface IVendorDetails {
  vendorPersonName: string;
  vendorPersonIcon: string;
  vendorName: string;
  vendorLocation: string;
  VendorPersonMailId: string;
  vendorContactNumber: string;
}

export interface IPerformanceMetrics {
  totalTasks: number;
  completed: number;
  inComplete: number;
  punchInTime: string;
  punchOutTime: string;
  inspectionScore: number;
  title: string;
  issuesRaised: number;
  oneTimeJobs: number;
  days?: string;
}

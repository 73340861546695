/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useEffect, useRef } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Linking } from '../DataGrid/DataGrid.styles';
import { checkImageValid } from '../../../Utilities/utils';
import {
  closeIconContainerStyle,
  mainContainerStyle,
  logoContainerStyle,
  textContainerStyle,
  textStyle,
  additionalTextStyle,
  titleLogoStyle,
  facilityImg,
  InfoWindowWrapper,
  InfoWindowText,
  InfoWindowContent,
} from './InfoWindow.Style';
import theme from '../../../Themes/theme';
import { Icons } from '../../../Constants/Icons';

const InfoWindow = ({
  onClose,
  facilityName,
  facilityAddress,
  tasks,
  facilityId,
  logoUrl,
  selectedDate,
  scheduled,
  completed,
  inProgress,
  noShow,
  noJob,
  workOrderId,
  jobId,
  customerId,
}) => {
  const { t } = useTranslation(['altTexts', 'homePage']);
  const infoWindowRef = useRef(null);
  const validImgRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoWindowRef.current && !infoWindowRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  checkImageValid(
    logoUrl,
    function () {
      validImgRef.current = true;
    },
    function () {
      validImgRef.current = false;
    }
  );

  const isNavigationDisabled = completed === 0 && inProgress === 0 && noShow === 0 && scheduled === 0;

  return (
    <div>
      <InfoWindowWrapper ref={infoWindowRef}>
        <div>
          <div style={closeIconContainerStyle}>
            <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} onClick={() => onClose()} />
          </div>
          <Linking
            to={isNavigationDisabled ? '#' : '/job-detail/worktasks'}
            state={
              !isNavigationDisabled
                ? {
                    jobDetails: tasks,
                    customerId: customerId,
                    jobId: jobId,
                    facilityItem: { facilityId, workOrderId },
                    selectedDate: selectedDate,
                  }
                : undefined
            }
            style={{ pointerEvents: isNavigationDisabled ? 'none' : 'auto' }}>
            <div style={mainContainerStyle}>
              <div style={titleLogoStyle}>
                <div className="companyProfileWrapper">
                  {typeof validImgRef.current === 'boolean' ? (
                    <div style={logoContainerStyle}>
                      <img
                        ref={validImgRef}
                        style={facilityImg}
                        src={!validImgRef.current ? Icons.FacilityDetailsLogo : logoUrl}
                        alt={t('altTexts:facility')}
                      />
                    </div>
                  ) : (
                    <div>
                      <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                    </div>
                  )}
                </div>
                <div style={textContainerStyle}>
                  <span style={textStyle} title={facilityName}>
                    {facilityName}
                  </span>
                  <span style={additionalTextStyle}>{facilityAddress}</span>
                </div>
              </div>
              <Box sx={{ marginTop: '1rem' }}>
                <InfoWindowContent>
                  <InfoWindowText
                    sx={{
                      color: theme.palette.info.dark,
                    }}>
                    Scheduled Shifts
                  </InfoWindowText>
                  <Typography sx={{ color: theme.palette.info.dark }}>{scheduled}</Typography>
                </InfoWindowContent>
                <InfoWindowContent>
                  <InfoWindowText
                    sx={{
                      color: theme.palette.error.main,
                    }}>
                    {t('homePage:noShow')}
                  </InfoWindowText>
                  <Typography sx={{ color: theme.palette.error.main }}>{noShow}</Typography>
                </InfoWindowContent>
                <InfoWindowContent>
                  <InfoWindowText
                    sx={{
                      color: theme.palette.secondary.contrastText,
                    }}>
                    In Progress Shifts
                  </InfoWindowText>
                  <Typography sx={{ color: theme.palette.secondary.contrastText }}>{inProgress}</Typography>
                </InfoWindowContent>
                <InfoWindowContent>
                  <InfoWindowText
                    sx={{
                      color: theme.palette.success.main,
                    }}>
                    Completed Shifts
                  </InfoWindowText>
                  <Typography sx={{ color: theme.palette.success.main }}>{completed}</Typography>
                </InfoWindowContent>
                <InfoWindowContent>
                  <InfoWindowText
                    sx={{
                      color: theme.palette.warning.dark,
                    }}>
                    No Job
                  </InfoWindowText>
                  <Typography sx={{ color: theme.palette.warning.dark }}>{noJob}</Typography>
                </InfoWindowContent>
              </Box>
            </div>
          </Linking>
        </div>
      </InfoWindowWrapper>
    </div>
  );
};

export default InfoWindow;

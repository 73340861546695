/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
export const jobsV2Mock = [
  {
    jobId: '0267b834-5912-45b7-8d03-f343c95c789f',
    jobName: 'Floor cleaning',
    startDate: '2023-08-17T18:29:59.000Z',
    stopDate: '2023-08-29T18:29:59.000Z',
    dataStatus: 'OpenToBid',
    jobType: 'Recurring',
    specialInstructions: '',
    draftStep: 'JobSummary',
    draftStatus: 'Y',
    createdOn: '2023-07-31T13:03:44.892Z',
    createdBy: '04788916-c27b-4052-895d-76e1ab40986f',
    bidCount: 0,
    jobDueDates: [
      {
        jobId: null,
        mdDueType: 'Approval',
        dueDescription: '',
        dueDate: '2023-08-08T18:29:59.000Z',
      },
      {
        jobId: null,
        mdDueType: 'Bid',
        dueDescription: '',
        dueDate: '2023-08-08T18:29:59.000Z',
      },
      {
        jobId: null,
        mdDueType: 'Question',
        dueDescription: '',
        dueDate: '2023-08-08T18:29:59.000Z',
      },
    ],
    jobBids: [],
  },
  {
    jobId: 'b3f26326-667d-4aed-ae70-370b1ebe743e',
    jobName: 'Garden cleaning',
    startDate: '2023-08-23T18:29:59.000Z',
    stopDate: '2023-08-31T18:29:59.000Z',
    dataStatus: 'OpenToBid',
    jobType: 'Recurring',
    specialInstructions: '',
    draftStep: 'JobSummary',
    draftStatus: 'Y',
    createdOn: '2023-07-31T05:41:05.097Z',
    createdBy: '04788916-c27b-4052-895d-76e1ab40986f',
    bidCount: 0,
    jobDueDates: [
      {
        jobId: null,
        mdDueType: 'Approval',
        dueDescription: '',
        dueDate: '2023-08-16T18:29:59.000Z',
      },
      {
        jobId: null,
        mdDueType: 'Bid',
        dueDescription: '',
        dueDate: '2023-08-16T18:29:59.000Z',
      },
      {
        jobId: null,
        mdDueType: 'Question',
        dueDescription: '',
        dueDate: '2023-08-16T18:29:59.000Z',
      },
    ],
    jobBids: [],
  },
  {
    jobId: '548bef2c-80f6-402d-a47f-a2964b905f26',
    jobName: 'Laundry',
    startDate: '2023-07-29T07:17:59.580Z',
    stopDate: '2023-07-30T07:17:59.582Z',
    dataStatus: 'OpenToBid',
    jobType: 'Emergency',
    specialInstructions: '',
    draftStep: 'JobSummary',
    draftStatus: 'Y',
    createdOn: '2023-07-29T07:17:27.028Z',
    createdBy: '04788916-c27b-4052-895d-76e1ab40986f',
    bidCount: 0,
    jobDueDates: [
      {
        jobId: null,
        mdDueType: 'Approval',
        dueDescription: '',
        dueDate: '2023-07-29T07:17:59.580Z',
      },
      {
        jobId: null,
        mdDueType: 'Bid',
        dueDescription: '',
        dueDate: '2023-07-29T07:17:59.580Z',
      },
      {
        jobId: null,
        mdDueType: 'Question',
        dueDescription: '',
        dueDate: '2023-07-29T07:17:59.580Z',
      },
    ],
    jobBids: [],
  },
];

export const allFacilitiesMock = [
  {
    facilityId: '25c3b028-cc81-4eeb-95e0-c55a54b73532',
    customerId: '65f62b1c-8309-4290-b844-7c96969c74d0',
    buildingName: 'Name',
    buildingMakeup: null,
    noOfFloors: 0,
    sqFootage: 0,
    mdFacilityType: 'street_address',
    serviceableSqFootage: null,
    occupancy: null,
    supplyStorageAvailability: null,
    transportRequiredWithinLocation: null,
    contactId: '',
    facilityTrafficLevel: null,
    emergencyContactName: null,
    emergencyContactPhone: null,
    cleaningInstructions: null,
    addressId: '6edd4858-613d-441e-96c9-2559648a211f',
    address: {
      addressId: '6edd4858-613d-441e-96c9-2559648a211f',
      customerId: '65f62b1c-8309-4290-b844-7c96969c74d0',
      addressName: 'Name',
      noOfBuildings: 1,
      addressLine1: '3',
      addressLine2: '113/3, Nallurahalli Road, Karnataka, Bengaluru, 560066, India',
      addressLine3: '3, 113/3, Nallurahalli Road, Karnataka, Bengaluru, 560066, India',
      city: 'Bengaluru',
      stateCode: 'KA',
      county: 'India',
      postalCode: '560066',
      mdCountryCode: 'IN',
      landmark: '',
      googlePlaceId: 'ChIJsdkUuhoSrjsR_ZvAyBymftM',
      lat: '12.9623301',
      long: '77.7327656',
      mdMsaId: null,
      logoUrl: '',
    },
  },
];

export const facilitiesMock = {
  facilityId: '25c3b028-cc81-4eeb-95e0-c55a54b73532',
  customerId: '65f62b1c-8309-4290-b844-7c96969c74d0',
  buildingName: 'Name',
  buildingMakeup: null,
  noOfFloors: 0,
  sqFootage: 0,
  mdFacilityType: 'street_address',
  serviceableSqFootage: null,
  occupancy: null,
  supplyStorageAvailability: null,
  transportRequiredWithinLocation: null,
  contactId: '',
  facilityTrafficLevel: null,
  emergencyContactName: null,
  emergencyContactPhone: null,
  cleaningInstructions: null,
  addressId: '6edd4858-613d-441e-96c9-2559648a211f',
  address: {
    addressId: '6edd4858-613d-441e-96c9-2559648a211f',
    customerId: '65f62b1c-8309-4290-b844-7c96969c74d0',
    addressName: 'Name',
    noOfBuildings: 1,
    addressLine1: '3',
    addressLine2: '113/3, Nallurahalli Road, Karnataka, Bengaluru, 560066, India',
    addressLine3: '3, 113/3, Nallurahalli Road, Karnataka, Bengaluru, 560066, India',
    city: 'Bengaluru',
    stateCode: 'KA',
    county: 'India',
    postalCode: '560066',
    mdCountryCode: 'IN',
    landmark: '',
    googlePlaceId: 'ChIJsdkUuhoSrjsR_ZvAyBymftM',
    lat: '12.9623301',
    long: '77.7327656',
    mdMsaId: null,
    logoUrl: '',
  },
};

export const jobSummaryMock = {
  jobId: '309ca7fe-d5d8-46f3-ae64-21e2c32478fb',
  jobName: 'Ground floor clean',
  specialInstructions: '',
  startDate: '2023-08-23T18:29:59.000Z',
  stopDate: '2023-08-31T18:29:59.000Z',
  jobType: 'Recurring',
  jobFacilities: [
    {
      jobId: '309ca7fe-d5d8-46f3-ae64-21e2c32478fb',
      facilityId: '52035972-273e-49d6-8f18-0c1c3a051cae',
      mdFacilityType: 'res',
      remarks: null,
      status: null,
      jobStartDate: null,
      jobEndData: null,
    },
  ],
  jobServices: [
    {
      jobId: '309ca7fe-d5d8-46f3-ae64-21e2c32478fb',
      mdServiceId: 'I0C1',
      serviceMeasure: 100,
      mdServiceUnits: 'sqft',
    },
    {
      jobId: '309ca7fe-d5d8-46f3-ae64-21e2c32478fb',
      mdServiceId: 'I2C1',
      serviceMeasure: 20,
      mdServiceUnits: 'sqft',
    },
    {
      jobId: '309ca7fe-d5d8-46f3-ae64-21e2c32478fb',
      mdServiceId: 'I2C2',
      serviceMeasure: 304,
      mdServiceUnits: 'sqft',
    },
    {
      jobId: '309ca7fe-d5d8-46f3-ae64-21e2c32478fb',
      mdServiceId: 'I7C1',
      serviceMeasure: 129,
      mdServiceUnits: 'sqft',
    },
  ],
  jobFrequencies: [
    {
      mdShiftType: 'Day',
      mdScheduleType: 'Weekly',
      mdServiceId: ['I0C1'],
      scheduleDays: 'Sunday,Tuesday',
      fromTime: '',
      toTime: '',
      cronExpression: null,
      repeatitions: null,
    },
    {
      mdShiftType: 'Day & Night',
      mdScheduleType: 'Monthly',
      mdServiceId: ['I2C1', 'I7C1'],
      scheduleDays: '18,20,21',
      fromTime: '',
      toTime: '',
      cronExpression: null,
      repeatitions: null,
    },
    {
      mdShiftType: 'Night',
      mdScheduleType: 'Daily',
      mdServiceId: ['I2C2'],
      scheduleDays: '',
      fromTime: '',
      toTime: '',
      cronExpression: null,
      repeatitions: null,
    },
  ],
  jobVendors: [
    {
      jobId: '309ca7fe-d5d8-46f3-ae64-21e2c32478fb',
      vendorId: '',
      mdVendorType: 'All',
    },
  ],
  jobDueDates: [
    {
      jobId: '309ca7fe-d5d8-46f3-ae64-21e2c32478fb',
      mdDueType: 'Approval',
      dueDescription: '',
      dueDate: '2023-08-16T18:29:59.000Z',
    },
    {
      jobId: '309ca7fe-d5d8-46f3-ae64-21e2c32478fb',
      mdDueType: 'Bid',
      dueDescription: '',
      dueDate: '2023-08-16T18:29:59.000Z',
    },
    {
      jobId: '309ca7fe-d5d8-46f3-ae64-21e2c32478fb',
      mdDueType: 'Question',
      dueDescription: '',
      dueDate: '2023-08-16T18:29:59.000Z',
    },
  ],
};

export const vendorDetailsMock = {
  vendorId: '3a4c86b4-c815-4da6-be70-8d4737212355',
  vendorCompanyName: 'Abrightlab',
  vendorCompanyType: '',
  doingBusinessAs: 'Abrightlab',
  SSID: '',
  taxId: '',
  websiteUrl: '',
  logoUrl: 'file:///data/user/0/com.abil.peazyApp/cache/ImagePicker/65b51fec-05fc-4efd-b503-e75002f4c4c9.jpeg',
  referralSource: 'linkedIn',
  phone: '9090909090',
  email: 'dev-vendor-dev-398@yopmail.com',
  dataStatus: 'A',
  modifiedOn: '2023-07-11T11:38:57.296Z',
};

export const vendorAddressesMock = [
  {
    addressId: '56737fd1-8e4a-4a10-a425-0b0d95c17111',
    vendorId: '3a4c86b4-c815-4da6-be70-8d4737212355',
    addressName: 'Argenbright',
    addressLine1: '2',
    addressLine2: 'Argenbright Innovation Labs',
    addressLine3:
      '2, Argenbright Innovation Labs, 2nd floor, The Gateway By UKn, Nallurahalli Main Rd, Brookefield, Bengaluru, Karnataka 560066, India',
    city: 'Bangalore Division',
    stateCode: 'KA',
    county: 'India',
    postalCode: '560066',
    mdCountryCode: 'IN',
    landmark: '2nd floor, The Gateway By UKn, Nallurahalli Main Road, Brookefield, Bengalu',
    googlePlaceId: 'ChIJ33xeSNcTrjsRdmdpnAtRnTE',
    latitude: '12.9624812',
    longitude: '77.7328675',
    mdMsaId: null,
    modifiedOn: '2023-07-11T11:39:32.259Z',
  },
];

export const workOrderMock = [
  {
    jobStartDate: '2023-07-31T18:29:59.000Z',
    jobType: 'Recurring',
    jobEndDate: '2023-08-01T18:29:59.000Z',
    bidId: '8003c85d-4397-4ce0-98ac-60eff4301c37',
    createdBy: '04788916-c27b-4052-895d-76e1ab40986f',
    createdOn: '2023-07-08T00:02:00.550Z',
    customerId: '8c25b253-3845-4b12-941a-e32ff4f4b059',
    facilities: [
      {
        accessCode: '',
        accessContactPerson: null,
        accessContactDetails: '',
        accessInfo: '',
        day: null,
        facilityId: '47cb37ac-8975-4781-b30e-f72338e3c2cb',
        from: null,
        otherInformation: '',
        to: null,
        workOrderFacilityAccessId: 'da766d47-829e-4385-834e-d6c2f42a022c',
      },
    ],
    jobBidSummary: {
      bidDetails: null,
      jobBidSummaryId: null,
      jobDetails:
        '{"jobFrequencies":[{"jobId":"a70efe8e-99c3-4ba1-bff1-d48bc03ac6ac","recSeq":0,"toTime":"","endDate":"2099-12-31T00:00:00.000Z","fromDate":"2023-07-07T19:01:53.649Z","fromTime":"","createdBy":"04788916-c27b-4052-895d-76e1ab40986f","createdOn":"2023-07-07T19:01:53.649Z","recStatus":"A","dataStatus":"A","jobsRecSeq":0,"modifiedBy":"04788916-c27b-4052-895d-76e1ab40986f","modifiedOn":"2023-07-07T19:01:53.649Z","repetition":1439,"mdServiceId":"I7C1","mdShiftType":"Noche","scheduleDays":"Lunes","cronExpression":"","mdScheduleType":"Semanalmente","jobServicesRecSeq":0}]}',
    },
    jobId: 'a70efe8e-99c3-4ba1-bff1-d48bc03ac6ac',
    jobOrderAcceptedBy: null,
    jobOrderFilePath: null,
    jobOrderAcceptedOn: null,
    modifiedBy: '9afa4216-cfe7-4f96-adde-46faf3fe1056',
    modifiedOn: '2023-07-25T14:09:14.000Z',
    name: 'Light services',
    paymentDay: 'Daily',
    paymentFrequency: 'Daily',
    paymentMethod: 'Wallet',
    services: [
      {
        mdServiceId: 'I7C1',
        mdServiceUnits: 'sqft',
        serviceMeasure: 45,
        workOrderServiceId: '5c832be8-da84-44a9-95ba-fc50566ca543',
        taskSchedule: [
          {
            isProofOfCompletionRequired: 'Yes',
            mdTaskId: 'I7C1T1',
            taskScheduleId: 'f471b9f8-3f94-46c2-a8ce-671e5a113700',
          },
        ],
      },
    ],
    status: 'InProgress',
    totalAmount: 6,
    vendorId: '573e4c9b-1a02-4f1a-99bf-260cda24a3dc',
    workOrderId: '03085161-09e9-4e2e-8eba-ad5376b9d97b',
  },
];

export const serviceCategoryMock = [
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C1',
    categoryName: 'Restroom deep clean',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C1T2',
    taskName: 'Dissolve hard water-toilet',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T36',
    taskName: 'IVAC dispenser - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T41',
    taskName: 'Refrigerator, medical - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C2',
    categoryName: 'Floors',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C2T1',
    taskName: 'Clean Floor with grout',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T1',
    taskName: 'Autoclave, exterior',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T7',
    taskName: 'Replace hand soap in wall dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T9',
    taskName: 'Clean lounge',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C2',
    categoryName: 'Elevator cabs',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C2T3',
    taskName: 'Dust mop',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'unit',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T7',
    taskName: 'Clean laundry room',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T13',
    taskName: 'Remove trash in building without elevators',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T52',
    taskName: 'Wheelchair - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C3',
    categoryName: 'Retail',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    taskId: 'I0C3T5',
    taskName: 'Clean grocery store cooler',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C4',
    categoryName: 'Microwave, Refrigerators, Coffee pots and Dispensers',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C4T3',
    taskName: 'Restock roll paper towel dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T21',
    taskName: 'Clean tub and shower grout with shower-cleaning chemical and hand brush',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T27',
    taskName: 'Examination table - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C3',
    categoryName: 'Steps and landing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C3T3',
    taskName: 'Damp mop',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C2',
    categoryName: 'Elevator cabs',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C2T5',
    taskName: 'Spot mop',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C1',
    categoryName: 'Drains',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C1T4',
    taskName: 'Disinfect',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T11',
    taskName: 'Replace roll towels',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I2',
    industryName: 'Ceilings & Walls',
    industryImageUrl: null,
    categoryId: 'I2C3',
    categoryName: 'Light cleaning',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    taskId: 'I2C3T3',
    taskName: 'Dust, clean out bugs, and wipe cover inside and out',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T12',
    taskName: 'Replace roll towels including stub roll',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I16',
    industryName: 'Trash Barrels',
    industryImageUrl: null,
    categoryId: 'I16C1',
    categoryName: 'Trash Barrels',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    taskId: 'I16C1T5',
    taskName: 'Empty 55 gal, trash can, spot wipe inside and out, replace liner',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C3',
    categoryName: 'Mats',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C3T1',
    taskName: 'Hand wash fatigue mats',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I15',
    industryName: 'Tools',
    industryImageUrl: null,
    categoryId: 'I15C1',
    categoryName: 'Tool cleaning and changing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    taskId: 'I15C1T7',
    taskName: 'Wipe',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T6',
    taskName: 'Bathroom, shower tall - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C4',
    categoryName: 'General asset vacuuming',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C4T3',
    taskName: 'Vacuum with tank/canister',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C6',
    categoryName: 'Schools',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    taskId: 'I0C6T5',
    taskName: 'Spray and clean whiteboard',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C3',
    categoryName: 'Retail',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    taskId: 'I0C3T9',
    taskName: 'Clean walk in cooler',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C1',
    categoryName: 'Restroom deep clean',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C1T1',
    taskName: 'Dissolve hard water-sink',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I7',
    industryName: 'Floor Care',
    industryImageUrl: null,
    categoryId: 'I7C1',
    categoryName: 'Apply floor finish or sealer',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    taskId: 'I7C1T1',
    taskName: 'Apply finish',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T14',
    taskName: 'Bedside call switch - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C2',
    categoryName: 'Elevator cabs',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C2T6',
    taskName: 'Vacuum',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C1',
    categoryName: 'Restroom deep clean',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C1T3',
    taskName: 'Dissolve hard water-urinal',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I1',
    industryName: 'Carpet Maintenance',
    industryImageUrl: null,
    categoryId: 'I1C13',
    categoryName: 'Vacuum',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    taskId: 'I1C13T1',
    taskName: 'Vacuum',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I7',
    industryName: 'Floor Care',
    industryImageUrl: null,
    categoryId: 'I7C5',
    categoryName: 'Grout cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    taskId: 'I7C5T1',
    taskName: 'Grout cleaning',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    unit: 'unit',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T2',
    taskName: 'Clean corridor with carpeted flooring',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I15',
    industryName: 'Tools',
    industryImageUrl: null,
    categoryId: 'I15C1',
    categoryName: 'Tool cleaning and changing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    taskId: 'I15C1T9',
    taskName: 'Wrap and wipe',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    unit: 'unit',
  },
  {
    industryId: 'I15',
    industryName: 'Tools',
    industryImageUrl: null,
    categoryId: 'I15C1',
    categoryName: 'Tool cleaning and changing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    taskId: 'I15C1T6',
    taskName: 'Wash and Rinse',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C1',
    categoryName: 'Drains',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C1T2',
    taskName: 'Clean and dry',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I15',
    industryName: 'Tools',
    industryImageUrl: null,
    categoryId: 'I15C1',
    categoryName: 'Tool cleaning and changing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    taskId: 'I15C1T5',
    taskName: 'Rinse',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    unit: 'unit',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C1',
    categoryName: 'Escalator Rails',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C1T2',
    taskName: 'Seal',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I15',
    industryName: 'Tools',
    industryImageUrl: null,
    categoryId: 'I15C1',
    categoryName: 'Tool cleaning and changing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    taskId: 'I15C1T1',
    taskName: 'Change',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    unit: 'unit',
  },
  {
    industryId: 'I7',
    industryName: 'Floor Care',
    industryImageUrl: null,
    categoryId: 'I7C2',
    categoryName: 'Dust mopping and debris pickup',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    taskId: 'I7C2T1',
    taskName: 'Floor Care',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    unit: 'unit',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C2',
    categoryName: 'Window blinds',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C2T4',
    taskName: 'Wipe',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C6',
    categoryName: 'Health care bundled times',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C6T5',
    taskName: 'Trash/clean and disinfect surfaces and bath/replace supplies/wet mop floor',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T39',
    taskName: 'Light, wall mounted - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C3',
    categoryName: 'Windows',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C3T1',
    taskName: 'Wash',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T18',
    taskName: 'Blood pressure cuff - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C10',
    categoryName: 'Manufacturing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C2T1',
    taskName: 'Sweep loading dock open areas, edges and corners',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C2',
    categoryName: 'Restroom assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C2T5',
    taskName: 'Spot clean-empty trash flush, spot wipe, disinfect, spot mop, and check dispensers',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T5',
    taskName: 'Refill hand soap under counter',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C3',
    categoryName: 'Steps and landing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C3T2',
    taskName: 'Clean escalator steps',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I4',
    industryName: 'Drinking fountains',
    industryImageUrl: null,
    categoryId: 'I4C1',
    categoryName: 'Drinking fountains',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    taskId: 'I4C1T2',
    taskName: 'Clean bottle drain mat',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T4',
    taskName: 'Refill hand soap in wall dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I4',
    industryName: 'Drinking fountains',
    industryImageUrl: null,
    categoryId: 'I4C1',
    categoryName: 'Drinking fountains',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    taskId: 'I4C1T3',
    taskName: 'Clean water cooler and empty tray',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C5',
    categoryName: 'Tables and chairs',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C5T1',
    taskName: 'Clean dining tables',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T4',
    taskName: 'Disinfect surfaces',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T38',
    taskName: 'Light, over bed - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C5',
    categoryName: 'Tables and chairs',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C5T2',
    taskName: 'Damp wipe hard-surface chairs with cloth and disinfectant',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I7',
    industryName: 'Floor Care',
    industryImageUrl: null,
    categoryId: 'I7C3',
    categoryName: 'Floor burnishing, Buffing, and Polishing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    taskId: 'I7C3T1',
    taskName: 'Floor burnishing, Buffing, and Polishing',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T50',
    taskName: 'Toilet-clean to remove weekly mineral condition with acid and brush agitation',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I7',
    industryName: 'Floor Care',
    industryImageUrl: null,
    categoryId: 'I7C10',
    categoryName: 'Scrub floor',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    taskId: 'I7C10T1',
    taskName: 'Scrub floor',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C1',
    categoryName: 'Glass cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C1T4',
    taskName: 'Clean large display case exterior',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C3',
    categoryName: 'Restrooms cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C3T4',
    taskName:
      'Empty trash, clean and disinfect fixures, mirrors and partitions, replace supplies; dust; sweep; and wet mop floor',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I16',
    industryName: 'Trash Barrels',
    industryImageUrl: null,
    categoryId: 'I16C1',
    categoryName: 'Trash Barrels',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    taskId: 'I16C1T2',
    taskName: 'Empty 32 gal, trash can, spot wipe inside and out, replace liner',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C7',
    categoryName: 'Transportation',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    taskId: 'I0C7T2',
    taskName: 'Clean and disinfect golf cart',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    unit: 'unit',
  },
  {
    industryId: 'I6',
    industryName: 'Exteriors',
    industryImageUrl: null,
    categoryId: 'I6C1',
    categoryName: 'Exterior cleaning, Snow removal & shoveling',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    taskId: 'I6C1T2',
    taskName: 'Empty ash urn and replace sand',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T37',
    taskName: 'Light, over sink - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C2',
    categoryName: 'General asset phone',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C2T1',
    taskName: 'Sanitize using trigger sprayer and cloth/cleaner - disinfectant',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I1',
    industryName: 'Carpet Maintenance',
    industryImageUrl: null,
    categoryId: 'I1C11',
    categoryName: 'Robot Vacuuming',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    taskId: 'I1C11T3',
    taskName: 'Vacuum low speed',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C2',
    categoryName: 'Railings',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C2T2',
    taskName: 'Dust',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T5',
    taskName: 'Disinfect toilet',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T7',
    taskName: 'Replace hand sanitizer in wall dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'liter',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C1',
    categoryName: 'Light-duty assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C1T1',
    taskName: 'Detail clean - empty trash and reline can, detail wipe, spot dust, and pick up debris from floor',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C3',
    categoryName: 'General asset trash removal and pickup',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C3T3',
    taskName: 'Pick up loose debris with lobby pan and porter broom/scrape up gum',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T4',
    taskName: 'Bathroom, call switch - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I8',
    industryName: 'Furniture',
    industryImageUrl: null,
    categoryId: 'I8C1',
    categoryName: 'Hard surface',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    taskId: 'I8C1T3',
    taskName: 'Polish',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    unit: 'unit',
  },
  {
    industryId: 'I1',
    industryName: 'Carpet Maintenance',
    industryImageUrl: null,
    categoryId: 'I1C12',
    categoryName: 'Spraying and spotting',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    taskId: 'I1C12T3',
    taskName: 'Spot Repair',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T8',
    taskName: 'Clean lobby',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C1',
    categoryName: 'Light-duty assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C1T4',
    taskName: 'Spot clean - empty trash and reline can, spot wipe, spot dust, and pick up debris from floor',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C3',
    categoryName: 'Retail',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    taskId: 'I0C5T1',
    taskName: 'Disinfect pin pad',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    unit: 'unit',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C2',
    categoryName: 'Window blinds',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C2T3',
    taskName: 'Vacuum',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C4',
    categoryName: 'Microwave, Refrigerators, Coffee pots and Dispensers',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C4T7',
    taskName: 'Wipe exterior front of Microwave',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I8',
    industryName: 'Furniture',
    industryImageUrl: null,
    categoryId: 'I8C1',
    categoryName: 'Hard surface',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    taskId: 'I8C1T2',
    taskName: 'Dust',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    unit: 'unit',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C4',
    categoryName: 'Vacuuming Assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C4T3',
    taskName: 'Spot Vacuum',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T26',
    taskName: 'Examination light - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C2',
    categoryName: 'Elevator cabs',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C2T1',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T10',
    taskName: 'Bed - remove linen',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T13',
    taskName: 'Replace toilet tissue rolls in twin-roll dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C7',
    categoryName: 'Transportation',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    taskId: 'I0C7T1',
    taskName: 'Clean and disinfect 15-passenger shuttle bus',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T43',
    taskName: 'Shelf, over sink - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C2',
    categoryName: 'Restroom assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C2T2',
    taskName:
      'Detail cleaning-empty trash, flush, wipe, disinfect, mop, refill dispensers, dust and remove mineral buildup',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C3',
    categoryName: 'Restrooms cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C3T1',
    taskName: 'Clean standard restroom mirror',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T1',
    taskName: 'Clean bathroom without shower',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C3',
    categoryName: 'Steps and landing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C3T7',
    taskName: 'Vacuum',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I8',
    industryName: 'Furniture',
    industryImageUrl: null,
    categoryId: 'I8C1',
    categoryName: 'Hard surface',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    taskId: 'I8C1T1',
    taskName: 'Disinfect',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    unit: 'unit',
  },
  {
    industryId: 'I4',
    industryName: 'Drinking fountains',
    industryImageUrl: null,
    categoryId: 'I4C1',
    categoryName: 'Drinking fountains',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    taskId: 'I4C1T4',
    taskName: 'Disinfect',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    unit: 'unit',
  },
  {
    industryId: 'I1',
    industryName: 'Carpet Maintenance',
    industryImageUrl: null,
    categoryId: 'I1C12',
    categoryName: 'Spraying and spotting',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    taskId: 'I1C12T6',
    taskName: 'Stain removal - color loss',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I1',
    industryName: 'Carpet Maintenance',
    industryImageUrl: null,
    categoryId: 'I1C11',
    categoryName: 'Robot Vacuuming',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    taskId: 'I1C11T2',
    taskName: 'Vacuum high speed',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C3',
    categoryName: 'Restrooms cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C3T5',
    taskName: 'Empty trash, clean and disinfect fixures, wipe mirrors, replace supplies, dust, sweep and sweep floor',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T11',
    taskName: 'Replace toilet tissue rolls in quad-roll dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C10',
    categoryName: 'Manufacturing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C10T4',
    taskName: 'Clean and disinfect Semi Circular Classic Wash Fountain basin sink - half sized',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'unit',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T11',
    taskName: 'Clean showers',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T12',
    taskName: 'Clean Vending area',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C3',
    categoryName: 'Retail',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    taskId: 'I0C3T4',
    taskName: 'Clean and disinfect shopping card handles',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    unit: 'unit',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C3',
    categoryName: 'Elevator walls aluminium',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C3T1',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I8',
    industryName: 'Furniture',
    industryImageUrl: null,
    categoryId: 'I8C2',
    categoryName: 'Upholstered',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    taskId: 'I8C2T2',
    taskName: 'Vacuum',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C2',
    categoryName: 'Restroom disinfection',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C2T2',
    taskName: 'Disinfect surfaces',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T9',
    taskName: 'Bathtub, fixtures - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: null,
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C3',
    categoryName: 'Retail',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    taskId: 'I0C3T3',
    taskName: 'Clean and disinfect grocery belt',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T20',
    taskName: 'Cart, dressing - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C10',
    categoryName: 'Manufacturing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C10T6',
    taskName: 'Clean mezzanine restrooms',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C1',
    categoryName: 'Correctional',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C1T2',
    taskName: 'Damp wipe surface with disinfectant',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C3',
    categoryName: 'Steps and landing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C3T4',
    taskName: 'Dust mop',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I2',
    industryName: 'Ceilings & Walls',
    industryImageUrl: null,
    categoryId: 'I2C2',
    categoryName: 'Ceiling Cleaning',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    taskId: 'I2C2T1',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I1',
    industryName: 'Carpet Maintenance',
    industryImageUrl: null,
    categoryId: 'I1C12',
    categoryName: 'Spraying and spotting',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    taskId: 'I1C12T1',
    taskName: 'Spot Removal - Difficult',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T47',
    taskName: 'Sphygmomanometer - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T16',
    taskName: 'Bedside stand - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T23',
    taskName: 'Curtains, bed cubical - pull down',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I15',
    industryName: 'Tools',
    industryImageUrl: null,
    categoryId: 'I15C1',
    categoryName: 'Tool cleaning and changing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    taskId: 'I15C1T2',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    unit: 'unit',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C2',
    categoryName: 'Window blinds',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C2T1',
    taskName: 'Dust',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T3',
    taskName: 'Disinfect elevator buttons',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C3',
    categoryName: 'Steps and landing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C3T5',
    taskName: 'Pick up debris',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I7',
    industryName: 'Floor Care',
    industryImageUrl: null,
    categoryId: 'I7C8',
    categoryName: 'Manual cleaning and debris pickup',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    taskId: 'I7C8T1',
    taskName: 'Manual cleaning and debris pickup',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T33',
    taskName: 'Hamper stand - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I6',
    industryName: 'Exteriors',
    industryImageUrl: null,
    categoryId: 'I6C1',
    categoryName: 'Exterior cleaning, Snow removal & shoveling',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    taskId: 'I6C1T5',
    taskName: 'Shovel and spread ice melt',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T5',
    taskName: 'Clean interior stairs',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C10',
    categoryName: 'Manufacturing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C10T1',
    taskName: 'Breakdown card-board',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'unit',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C3',
    categoryName: 'Windows',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C3T4',
    taskName: 'Wash Interior',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C3',
    categoryName: 'Restrooms cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C3T6',
    taskName: 'Empty trash, clean and disinfect fixures, wipe mirrors, replace supplies, dust, sweep and wet mop floor',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T13',
    taskName: 'Replace sanitizer soap cassette refill',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T46',
    taskName: 'Sinks, scrub - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T48',
    taskName: 'Table, over bed - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C2',
    categoryName: 'Restroom assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C2T4',
    taskName: 'Routine cleaning-empty trash, flush, wipe, disinfect, mop, and refill dispensers',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C6',
    categoryName: 'Health care bundled times',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C6T4',
    taskName: 'Trash/clean and disinfect surfaces and bath/replace supplies/vacuum floor',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I16',
    industryName: 'Trash Barrels',
    industryImageUrl: null,
    categoryId: 'I16C1',
    categoryName: 'Trash Barrels',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    taskId: 'I16C1T3',
    taskName: 'Empty 44 gal, trash can, spot wipe inside and out, replace liner',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T42',
    taskName: 'Scale, weight - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C2',
    categoryName: 'Elevator cabs',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C2T7',
    taskName: 'Wet mop',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'unit',
  },
  {
    industryId: 'I1',
    industryName: 'Carpet Maintenance',
    industryImageUrl: null,
    categoryId: 'I1C11',
    categoryName: 'Robot Vacuuming',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    taskId: 'I1C11T1',
    taskName: 'Vacuum hallways - high speed',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I16',
    industryName: 'Trash Barrels',
    industryImageUrl: null,
    categoryId: 'I16C1',
    categoryName: 'Trash Barrels',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    taskId: 'I16C1T7',
    taskName: 'Wash with pressure washer',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T16',
    taskName: 'Replace toilet tissue rolls in single-roll dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C3',
    categoryName: 'Restrooms cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C3T2',
    taskName: 'Clean toilet, urinal or sink',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C4',
    categoryName: 'General asset vacuuming',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C4T2',
    taskName: 'Vacuum with hand-held duster ',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T3',
    taskName: 'Bassinets, nursery - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C3',
    categoryName: 'Retail',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    taskId: 'I0C3T2',
    taskName: 'Clean and disinfect changing room',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C10',
    categoryName: 'Manufacturing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C10T2',
    taskName: 'Clean and disinfect locker room without fixtures',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C1',
    categoryName: 'Glass cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C1T1',
    taskName: 'Clean entry door',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T44',
    taskName: 'Sink - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T15',
    taskName: 'Replace toilet tissue rolls in quad-roll dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I2',
    industryName: 'Ceilings & Walls',
    industryImageUrl: null,
    categoryId: 'I2C3',
    categoryName: 'Light cleaning',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    taskId: 'I2C3T1',
    taskName: 'Damp wipe',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C2',
    categoryName: 'Railings',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C2T1',
    taskName: 'Damp wipe',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I1',
    industryName: 'Carpet Maintenance',
    industryImageUrl: null,
    categoryId: 'I1C12',
    categoryName: 'Spraying and spotting',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    taskId: 'I1C12T4',
    taskName: 'Spray',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C6',
    categoryName: 'Schools',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    taskId: 'I0C6T3',
    taskName: 'Ensure doors are locked or lock them',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    unit: 'unit',
  },
  {
    industryId: 'I6',
    industryName: 'Exteriors',
    industryImageUrl: null,
    categoryId: 'I6C1',
    categoryName: 'Exterior cleaning, Snow removal & shoveling',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    taskId: 'I6C1T4',
    taskName: 'Shovel',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I15',
    industryName: 'Tools',
    industryImageUrl: null,
    categoryId: 'I15C1',
    categoryName: 'Tool cleaning and changing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    taskId: 'I15C1T10',
    taskName: 'Wring and rinse',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T15',
    taskName: 'Bedside commode - empty',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T17',
    taskName: 'biohazard bad - seal and remove',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C3',
    categoryName: 'Windows',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C3T3',
    taskName: 'Wash Exterior 25"',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T30',
    taskName: 'Gurney - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C1',
    categoryName: 'Drains',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C1T1',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T6',
    taskName: 'Don or doff gloves',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T29',
    taskName: 'Grab Rail - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C3',
    categoryName: 'Mats',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C3T2',
    taskName: 'Wash fatigue mats in place',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I7',
    industryName: 'Floor Care',
    industryImageUrl: null,
    categoryId: 'I7C9',
    categoryName: 'Mopping',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    taskId: 'I7C9T1',
    taskName: 'Mopping',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C4',
    categoryName: 'Microwave, Refrigerators, Coffee pots and Dispensers',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C4T1',
    taskName: 'Clean Microwave - light soil',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C6',
    categoryName: 'Schools',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    taskId: 'I0C6T1',
    taskName: 'Clean and disinfect student desk',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    unit: 'unit',
  },
  {
    industryId: 'I7',
    industryName: 'Floor Care',
    industryImageUrl: null,
    categoryId: 'I7C6',
    categoryName: 'Machine scrub and extract',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    taskId: 'I7C6T1',
    taskName: 'Machine scrub and extract',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    unit: 'unit',
  },
  {
    industryId: 'I6',
    industryName: 'Exteriors',
    industryImageUrl: null,
    categoryId: 'I6C1',
    categoryName: 'Exterior cleaning, Snow removal & shoveling',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    taskId: 'I6C1T6',
    taskName: 'Sweep',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C3',
    categoryName: 'Steps and landing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C3T1',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C1',
    categoryName: 'Glass cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C1T2',
    taskName: 'Clean glass panel or relight',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C1',
    categoryName: 'Elevator Tracks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C2T1',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C4',
    categoryName: 'General asset vacuuming',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C4T1',
    taskName: 'Vacuum with backpack vacuum',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I1',
    industryName: 'Carpet Maintenance',
    industryImageUrl: null,
    categoryId: 'I1C12',
    categoryName: 'Spraying and spotting',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    taskId: 'I1C12T5',
    taskName: 'Stain removal - color added',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C4',
    categoryName: 'Microwave, Refrigerators, Coffee pots and Dispensers',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C4T8',
    taskName: 'Wipe exterior front of Refrigerator',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C4',
    categoryName: 'Microwave, Refrigerators, Coffee pots and Dispensers',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C4T6',
    taskName: 'Wipe cabinets inside and out',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C3',
    categoryName: 'Restrooms cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C3T3',
    taskName: 'Empty feminine hygiene disposal and replace bag',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C1',
    categoryName: 'Glass cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C1T6',
    taskName: 'Vacuum',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C3',
    categoryName: 'Retail',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    taskId: 'I0C3T6',
    taskName: 'Clean haircut work-station mirror and chair, and sweep floor',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    unit: 'unit',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C1',
    categoryName: 'Light-duty assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C1T2',
    taskName: 'Empty desk-side trash can and reline with liner and tie knot',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C6',
    categoryName: 'Health care bundled times',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C6T2',
    taskName: 'Trash/clean and disinfect surfaces and bath/replace supplies/dust mop floor',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C3',
    categoryName: 'Retail',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    taskId: 'I0C3T8',
    taskName: 'Clean standard pet kennel',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C1',
    categoryName: 'General asset cleaning',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C1T1',
    taskName: 'Damp wipe with trigger sprayer and cloth',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C2',
    categoryName: 'Restroom disinfection',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C2T1',
    taskName: 'Damp wipe surface with disinfectant',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I8',
    industryName: 'Furniture',
    industryImageUrl: null,
    categoryId: 'I8C1',
    categoryName: 'Hard surface',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    taskId: 'I8C3T1',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T54',
    taskName: 'X-ray illuminator, wall mount - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T4',
    taskName: 'Clean exterior stairs',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C3',
    categoryName: 'General asset trash removal and pickup',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C3T1',
    taskName: 'Empty trash/ashtrays/pencil sharpener and wipe clean',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I16',
    industryName: 'Trash Barrels',
    industryImageUrl: null,
    categoryId: 'I16C1',
    categoryName: 'Trash Barrels',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    taskId: 'I16C1T1',
    taskName: 'Empty 13 gal, trash can, spot wipe inside and out, replace liner',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C4',
    categoryName: 'Microwave, Refrigerators, Coffee pots and Dispensers',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C4T2',
    taskName: 'Restock napkin dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T9',
    taskName: 'Replace roll towels',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I4',
    industryName: 'Drinking fountains',
    industryImageUrl: null,
    categoryId: 'I4C1',
    categoryName: 'Drinking fountains',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    taskId: 'I4C1T1',
    taskName: 'Clean and dry',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T9',
    taskName: 'Replace hand soap in wall dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T1',
    taskName: 'Disinfect Toilet',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C3',
    categoryName: 'Retail',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C3T1',
    taskName: 'Clean and disinfect 6ft rounder touch points',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T8',
    taskName: 'Replace hand soap in cassette refill',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T24',
    taskName: 'Dispenser, drinking cups - refill and damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T53',
    taskName: 'Whirlpool - damp wipe, interior and exterior',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C6',
    categoryName: 'Health care bundled times',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C6T3',
    taskName: 'Trash/clean and disinfect surfaces and bath/replace supplies/flat mop floor',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C10',
    categoryName: 'Manufacturing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C10T3',
    taskName: 'Clean and disinfect Semi Circular Classic Wash Fountain basin sink - full sized',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'unit',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C3',
    categoryName: 'Elevator walls aluminium',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C3T2',
    taskName: 'Wash Interior',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I2',
    industryName: 'Ceilings & Walls',
    industryImageUrl: null,
    categoryId: 'I2C2',
    categoryName: 'Ceiling Cleaning',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    taskId: 'I2C2T2',
    taskName: 'Wash',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C1',
    categoryName: 'Drains',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C1T3',
    taskName: 'Clean and flush',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T11',
    taskName: 'Bed floorboard - spray and damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C6',
    categoryName: 'Health care bundled times',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C6T1',
    taskName:
      'Trash/clean and disinfect surfaces and bath/replace supplies/clean floor with dispense-and-vacuum machine',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T2',
    taskName: 'Empty trash, replace supplies, and spot clean surfaces - heavy use',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C3',
    categoryName: 'Retail',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    taskId: 'I0C3T7',
    taskName: 'Clean refrigerated display case',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-non-mall+_retail.svg',
    unit: 'unit',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C1',
    categoryName: 'Escalator rails',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C1T1',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I7',
    industryName: 'Floor Care',
    industryImageUrl: null,
    categoryId: 'I7C7',
    categoryName: 'Machine sweeping',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    taskId: 'I7C7T1',
    taskName: 'Machine sweeping',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    unit: 'unit',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C1',
    categoryName: 'Glass cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C1T5',
    taskName: 'Clean small display case exterior',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T8',
    taskName: 'Replace hand towels',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C7',
    categoryName: 'Transportation',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    taskId: 'I0C9T1',
    taskName: 'Disinfect bicycle',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T10',
    taskName: 'Replace hand towels',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T51',
    taskName: 'Toilet-disinfect with application of EPA-approved chemical and brush agitation',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I6',
    industryName: 'Exteriors',
    industryImageUrl: null,
    categoryId: 'I6C1',
    categoryName: 'Exterior cleaning, Snow removal & shoveling',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    taskId: 'I6C1T3',
    taskName: 'Remove cigarette butts from ash urn',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T17',
    taskName: 'Vacuum furniture',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I16',
    industryName: 'Trash Barrels',
    industryImageUrl: null,
    categoryId: 'I16C1',
    categoryName: 'Trash Barrels',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    taskId: 'I16C1T6',
    taskName: 'Empty slim trash can, spot wipe inside and out, and replace liner',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    unit: 'unit',
  },
  {
    industryId: 'I1',
    industryName: 'Carpet Maintenance',
    industryImageUrl: null,
    categoryId: 'I1C12',
    categoryName: 'Spraying and spotting',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    taskId: 'I1C12T2',
    taskName: 'Spot Removal - Simple',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-carpet_maintenance.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C6',
    categoryName: 'Schools',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    taskId: 'I0C6T4',
    taskName: 'Mop locker room floor, clean and disinfect fixtures and hard surfaces, fill dispensers, and empty trash',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T25',
    taskName: 'Dumbwaiter - damp wipe, interior and exterior',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T45',
    taskName: 'Sink, fixtures - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T5',
    taskName: 'Bathroom, shower fixtures - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T32',
    taskName: 'Hamper liner - remove and replace',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T18',
    taskName: 'Wash hands',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T34',
    taskName: 'Intercom panel - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T2',
    taskName: 'Autoclave, interior',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C1',
    categoryName: 'Drains',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C1T5',
    taskName: 'Remove debris',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T10',
    taskName: 'Replace roll towels including stub roll',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C1',
    categoryName: 'Correctional',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C8T1',
    taskName: 'Disinfect surfaces',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'unit',
  },
  {
    industryId: 'I2',
    industryName: 'Ceilings & Walls',
    industryImageUrl: null,
    categoryId: 'I2C4',
    categoryName: 'Vent cleaning',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    taskId: 'I2C4T1',
    taskName: 'Dust',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T8',
    taskName: 'Bathtub - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C4',
    categoryName: 'Vacuuming Assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C4T1',
    taskName: 'Detail Vacuum',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C3',
    categoryName: 'General asset trash removal and pickup',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C3T2',
    taskName: 'Empty trash/ashtrays/pencil sharpener, wipe clean and reline basket',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C3',
    categoryName: 'Steps and landing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C3T6',
    taskName: 'Sweep',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C5',
    categoryName: 'Elevator walls mirror or glass',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C5T1',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I6',
    industryName: 'Exteriors',
    industryImageUrl: null,
    categoryId: 'I6C1',
    categoryName: 'Exterior cleaning, Snow removal & shoveling',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    taskId: 'I6C1T1',
    taskName: 'Apply liquid ice melt',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-exteriors.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T10',
    taskName: 'Clean parlor',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C1',
    categoryName: 'Glass cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C1T3',
    taskName: 'Clean glass-door hardware',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C4',
    categoryName: 'Vacuuming Assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C4T2',
    taskName: 'Routine Vacuum',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T3',
    taskName: 'Empty trash, replace supplies, and spot clean surfaces - light use',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I15',
    industryName: 'Tools',
    industryImageUrl: null,
    categoryId: 'I15C1',
    categoryName: 'Tool cleaning and changing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    taskId: 'I15C1T4',
    taskName: 'Replace',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T12',
    taskName: 'Bed frame - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T19',
    taskName: 'Cardiac monitor - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T12',
    taskName: 'Replace toilet tissue rolls in single-roll dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T2',
    taskName: 'Damp wipe surface with disinfectant-not including any additional dwell time if required',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T35',
    taskName: 'IV Unit - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C2',
    categoryName: 'Restroom assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C2T1',
    taskName: 'Detail clean toilets, urinals and sinks',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I15',
    industryName: 'Tools',
    industryImageUrl: null,
    categoryId: 'I15C1',
    categoryName: 'Tool cleaning and changing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    taskId: 'I15C1T3',
    taskName: 'Clear debris, brush, spray and hang',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C6',
    categoryName: 'Schools',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    taskId: 'I0C6T2',
    taskName: 'Clean open gang-type shower',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-school%26university.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T7',
    taskName: 'Bathroom, soap dispenser - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C10',
    categoryName: 'Manufacturing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C10T5',
    taskName: 'Clean and disinfect workout area',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C4',
    categoryName: 'Microwave, Refrigerators, Coffee pots and Dispensers',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C4T4',
    taskName: 'Spot clean coffee pots',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I16',
    industryName: 'Trash Barrels',
    industryImageUrl: null,
    categoryId: 'I16C1',
    categoryName: 'Trash Barrels',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    taskId: 'I16C1T4',
    taskName: 'Empty 5 gal, trash can, spot wipe inside and out, replace liner',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-trash+barrels.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T28',
    taskName: 'Footstool - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C7',
    categoryName: 'Transportation',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    taskId: 'I0C9T2',
    taskName: 'Disinfect rental car',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    unit: null,
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T1',
    taskName: 'Apply antimicrobial coating to high touch points',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I10',
    industryName: 'Kitchens',
    industryImageUrl: null,
    categoryId: 'I10C4',
    categoryName: 'Microwave, Refrigerators, Coffee pots and Dispensers',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    taskId: 'I10C4T5',
    taskName: 'Wash coffee pot',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-kitchens.svg',
    unit: 'unit',
  },
  {
    industryId: 'I7',
    industryName: 'Floor Care',
    industryImageUrl: null,
    categoryId: 'I7C4',
    categoryName: 'Floor stripping and scrubbing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    taskId: 'I7C4T1',
    taskName: 'Floor stripping and scrubbing',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-floor_care.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I4',
    industryName: 'Drinking fountains',
    industryImageUrl: null,
    categoryId: 'I4C1',
    categoryName: 'Drinking fountains',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    taskId: 'I4C1T5',
    taskName: 'Polish',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C1',
    categoryName: 'General asset cleaning',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C1T2',
    taskName: 'Dust with duster',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C1',
    categoryName: 'Correctional',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I0C1T1',
    taskName: 'Clean standard jail cell',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I4',
    industryName: 'Drinking fountains',
    industryImageUrl: null,
    categoryId: 'I4C1',
    categoryName: 'Drinking fountains',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    taskId: 'I4C1T6',
    taskName: 'Remove heavy buildup',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C1',
    categoryName: 'General asset cleaning',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C1T3',
    taskName: 'Dust with treated cloth',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C2',
    categoryName: 'Restroom assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C2T3',
    taskName: 'Place wet floor signs and prop open door',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T49',
    taskName: 'Tables, operating room - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C7',
    categoryName: 'Transportation',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    taskId: 'I0C7T3',
    taskName: 'Clean and disinfect regular-sized bus with no bathroom',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    unit: 'unit',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C2',
    categoryName: 'Elevator cabs',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C2T4',
    taskName: 'Polish',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'unit',
  },
  {
    industryId: 'I12',
    industryName: 'Restrooms',
    industryImageUrl: null,
    categoryId: 'I12C4',
    categoryName: 'Restrooms spot clean/portering',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    taskId: 'I12C4T6',
    taskName: 'Replace hand soap cassette refill',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-restrooms.svg',
    unit: 'unit',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T3',
    taskName: 'Clean corridor with hard surface flooring',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I0',
    industryName: 'Additional Industries',
    industryImageUrl: null,
    categoryId: 'I0C7',
    categoryName: 'Transportation',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    taskId: 'I0C7T4',
    taskName: 'Disinfect airplane interior (76 seater)',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-services_provider.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I8',
    industryName: 'Furniture',
    industryImageUrl: null,
    categoryId: 'I8C2',
    categoryName: 'Upholstered',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    taskId: 'I8C2T1',
    taskName: 'Shampoo',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-furnitue.svg',
    unit: 'unit',
  },
  {
    industryId: 'I14',
    industryName: 'Stairwells',
    industryImageUrl: null,
    categoryId: 'I14C2',
    categoryName: 'Railings',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    taskId: 'I14C2T3',
    taskName: 'Polish',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-stairwells.svg',
    unit: 'unit',
  },
  {
    industryId: 'I4',
    industryName: 'Drinking fountains',
    industryImageUrl: null,
    categoryId: 'I4C1',
    categoryName: 'Drinking fountains',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    taskId: 'I4C1T7',
    taskName: 'Remove light buildup',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-drinking_fountains.svg',
    unit: 'unit',
  },
  {
    industryId: 'I11',
    industryName: 'Pandemic',
    industryImageUrl: null,
    categoryId: 'I11C1',
    categoryName: 'Infection prevention tasks',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I11C1T14',
    taskName: 'Replace toilet tissue roles in twin-roll dispenser',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T13',
    taskName: 'Bedpan - clean',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C2',
    categoryName: 'Elevator cabs',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C2T2',
    taskName: 'Dust',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'unit',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C1',
    categoryName: 'Light-duty assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C1T3',
    taskName: 'Routine clean - empty trash and reline can, wipe, spot dust, and pick up debris from floor',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I2',
    industryName: 'Ceilings & Walls',
    industryImageUrl: null,
    categoryId: 'I2C1',
    categoryName: 'Carpeted walls',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    taskId: 'I2C1T1',
    taskName: 'Vacuum',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I15',
    industryName: 'Tools',
    industryImageUrl: null,
    categoryId: 'I15C1',
    categoryName: 'Tool cleaning and changing',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    taskId: 'I15C1T8',
    taskName: 'Wipe Exterior',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-tools.svg',
    unit: 'unit',
  },
  {
    industryId: 'I13',
    industryName: 'Specialist',
    industryImageUrl: null,
    categoryId: 'I13C3',
    categoryName: 'Utility assignments',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    taskId: 'I13C3T1',
    taskName: 'Floor care, elevators, stairs, trash removal, carpet care, window cleaning and other tasks',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-specialist.svg',
    unit: 'unit',
  },
  {
    industryId: 'I3',
    industryName: 'Dorm & Resident Halls',
    industryImageUrl: null,
    categoryId: 'I3C6',
    categoryName: 'Common area cleaning',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    taskId: 'I3C6T6',
    taskName: 'Clean Kitchen',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-apartment.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T22',
    taskName: 'Cupboard - damp wipe, interior and exterior',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I2',
    industryName: 'Ceilings & Walls',
    industryImageUrl: null,
    categoryId: 'I2C5',
    categoryName: 'Walls',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    taskId: 'I2C5T1',
    taskName: 'Wash',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I2',
    industryName: 'Ceilings & Walls',
    industryImageUrl: null,
    categoryId: 'I2C3',
    categoryName: 'Light cleaning',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    taskId: 'I2C3T2',
    taskName: 'Dust',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-ceilings_walls.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C3',
    categoryName: 'Windows',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C3T2',
    taskName: 'Wash Exterior',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I5',
    industryName: 'Elevators',
    industryImageUrl: null,
    categoryId: 'I5C4',
    categoryName: 'Elevator walls control panel',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    taskId: 'I5C4T1',
    taskName: 'Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-elevators.svg',
    unit: 'sqft',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T40',
    taskName: 'Mattress - clean and sanitize double-size mattress against pathogens and mites with machine',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I9',
    industryName: 'Health Care',
    industryImageUrl: null,
    categoryId: 'I9C5',
    categoryName: 'Health care assets',
    categoryImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    taskId: 'I9C5T31',
    taskName: 'Hamper - damp wipe',
    taskImageUrl:
      'https://abrightlab-static-assets.s3.amazonaws.com/icons/facility-types/ic-hospital_health%26medical.svg',
    unit: 'unit',
  },
  {
    industryId: 'I17',
    industryName: 'Windows & Glass',
    industryImageUrl: null,
    categoryId: 'I17C2',
    categoryName: 'Window blinds',
    categoryImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    taskId: 'I17C2T2',
    taskName: 'Remove and Clean',
    taskImageUrl: 'https://abrightlab-static-assets.s3.amazonaws.com/icons/service-categories/ic-windows_glass.svg',
    unit: 'unit',
  },
];
// export const servicesData = [
//   {
//     id: 1,
//     serviceName: 'Floor care',
//     taskTotal: 9,
//     taskCompleted: 4,
//     facilityId: '1',
//     sericeIcon: 'url',
//     facilityName: 'Stay Fit Gym - Cander Park',
//     Jobname: 'Daily general cleaning',
//     tableData: [
//       {
//         no: 1,
//         taskName: 'Sweeping',
//         floor: '1',
//         timeTaken: '35 min',
//         status: 'Issue raised',
//         comments: 'Lorem ipsum dolar sit',
//         proof: '12',
//         date: '2023-07-04T07:15:07.135Z',
//       },
//       {
//         no: 2,
//         taskName: 'Vacuuming',
//         floor: '1',
//         timeTaken: '',
//         status: 'Approved',
//         comments: 'Lorem ipsum dolar sit s',
//         proof: '12',
//         date: '2023-06-14T07:15:07.135Z',
//       },
//       {
//         no: 3,
//         taskName: 'Sweeping',
//         floor: '1',
//         timeTaken: '35 min',
//         status: 'Issue raised',
//         comments: '',
//         proof: '12',
//         date: '2023-06-16T07:15:07.135Z',
//       },
//       {
//         no: 4,
//         taskName: 'Sweeping',
//         floor: '1',
//         timeTaken: '35 min',
//         status: 'Complete',
//         comments: '',
//         proof: '12',
//         date: '2023-06-19T07:15:07.135Z',
//       },
//     ],
//   },
//   {
//     id: 2,
//     serviceName: 'Furniture',
//     taskTotal: 4,
//     taskCompleted: 1,
//     facilityId: '2',
//     sericeIcon: 'url',
//     facilityName: 'Stay Fit Gym  - Sandy Springs, GA',
//     Jobname: 'Daily general cleaning',
//     tableData: [
//       {
//         no: 1,
//         taskName: 'Sweeping',
//         floor: '1',
//         timeTaken: '35 min',
//         status: 'Issue raised',
//         comments: 'Lorem ipsum dolar sit... Read more',
//         proof: '12',
//         date: '2023-06-19T07:15:07.135Z',
//       },
//       {
//         no: 2,
//         taskName: 'Vacuuming',
//         floor: '1',
//         timeTaken: '35 min',
//         status: 'Issue raised',
//         comments: 'Lorem ipsum dolar sit... Read more',
//         proof: '12',
//         date: '2023-05-19T07:15:07.135Z',
//       },
//     ],
//   },
// ];

// export const dropdownData1 = [
//   { id: 0, label: 'All facilities', value: 'all' },
//   { id: 1, label: 'Stay Fit Gym  - Sandy Springs, GA', value: '2' },
//   { id: 2, label: 'Stay Fit Gym - Cander Park', value: '1' },
// ];

// export const dropdownData2 = [
//   { id: 1, label: 'Daily General Cleaning', value: 'daily' },
//   { id: 2, label: 'Deep Cleaning', value: 'deep' },
//   { id: 3, label: 'Topical Cleaning', value: 'topical' },
// ];

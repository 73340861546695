/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  buttonStyles,
  ContentsContainer,
  ImageContainer,
  DetailsContainer,
  HeadingContainer,
  Heading,
  DeleteOption,
  AddTagContainer,
  Tags,
  TagText,
  FullScreenContainer,
} from './AddEditOneTimeJob.Style';

import { Icons } from '../../../../Shared/Constants/Icons';
import AddTags from './AddTags';
import { mdAttachmentType } from '../../../../API';
import theme from '../../../../Shared/Themes/theme';
import { DocumentType } from '../../../../Shared/Constants/App';
import ApproveDialog from '../../../Jobs/Components/TaskDetails/logoutDialog';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

interface IPhotosAndVideosProps {
  customerId: string;
  jobId: string;
  details: any;
  uploadedFileTags: any;
  handleAttachmentDetails: (prevFiles: any) => void;
  handleUploadedFileTags: (tags: any[]) => void;
  handleDeleteAttachment: (index: number) => void;
  handleDeleteSelectedTag: (id: string, tag: string, item: any) => void;
  handleInstructionsChange: (id: string, comments: string) => void;
  isEditPhotosAndVideos?: boolean;
}

export interface IDetailsProps {
  attachmentId?: string;
  comments?: string;
  fileName?: string;
  id?: string;
  mdAttachmentType?: mdAttachmentType;
  url?: string;
  t: any;
}

const FullScreenView = ({ url, onClose, mediaType }: any): JSX.Element => {
  return (
    <FullScreenContainer onClick={onClose}>
      {mediaType === 'image' ? <img src={url} alt="full-screen" /> : <video controls src={url} />}
    </FullScreenContainer>
  );
};

const PhotosAndVideos = ({
  customerId,
  jobId,
  details,
  uploadedFileTags,
  handleAttachmentDetails,
  handleUploadedFileTags,
  handleDeleteAttachment,
  handleDeleteSelectedTag,
  isEditPhotosAndVideos,
}: IPhotosAndVideosProps): JSX.Element => {
  const { t } = useTranslation(['oneTimeJob']);
  const { headingText, addButton, addButtonText } = buttonStyles;

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [isAddTagDialogOpen, setAddTagDialogOpen] = useState(false);

  const selectedFileIndex = null;
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedMediaType, setSelectedMediaType] = useState<string>('image');
  const [isShowSessionExpirePopUp, setIsShowSessionExpirePopUp] = useState(false);
  const [isShowSessionExpirePopUpLoading, setIsShowSessionExpirePopUpLoading] = useState(false);
  const navigate = useNavigate();

  const handleMediaClick = (detail: any): void => {
    setSelectedMedia(detail.url);
    setSelectedMediaType(detail.mdAttachmentType.includes('image') ? 'image' : 'video');
  };

  const handleCloseFullScreen = (): void => {
    setSelectedMedia(null);
  };

  const handleDeleteClick = (index: number): void => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    handleDeleteAttachment(index);
  };

  const handleUploadClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // const handleSpecialInstructions = (id: string, comments: string) => {
  //   handleInstructionsChange(id, comments);
  // };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const fileDetails = Array.from(selectedFiles).map((file) => {
        const attachmentUniqueId = uuidv4();
        const fileNameSplits = file.name.split('.');
        return {
          url: URL.createObjectURL(file),
          fileName: fileNameSplits[0],
          fileExtension: fileNameSplits[fileNameSplits.length - 1],
          mdAttachmentType: file.type.includes('image') ? DocumentType.Image : DocumentType.Video,
          attachmentId: '',
          id: attachmentUniqueId,
          comments: '',
          file: file,
        };
      });
      handleAttachmentDetails([...details, ...fileDetails]);
    }
  };

  // const handleAddTagClick = (index: number) => {
  //   setAddTagDialogOpen(true);
  //   setSelectedFileIndex(index);
  // };

  const handleCloseAddTagDialog = (): void => {
    setAddTagDialogOpen(false);
  };

  const updateUploaddFileTags = (index: number, selectedTags: any): void => {
    handleUploadedFileTags(selectedTags);
  };

  const handleCancelTag = (id: string, tag: string, item: any): void => {
    handleDeleteSelectedTag(id, tag, item);
  };

  const signOut = useCallback(async () => {
    try {
      setIsShowSessionExpirePopUpLoading(true);
      await Auth.signOut();
      localStorage.removeItem('auth');
      localStorage.removeItem('customerDetails');
      navigate('/login');
    } catch (error) {
      console.error('An error occurred during sign out:', error);
    } finally {
      setIsShowSessionExpirePopUpLoading(false);
    }
  }, []);

  const LogoutUI = (): JSX.Element => {
    return (
      <div>
        {t('logout:logoutConfirm')} <br /> <strong>{t('logout:peazyApplication')}</strong>
      </div>
    );
  };

  return (
    <div style={{ height: '100%', marginTop: isEditPhotosAndVideos ? '1rem' : '0rem' }}>
      {!isEditPhotosAndVideos && <Typography sx={headingText}>{t('oneTimeJob:photosAndVideos')}</Typography>}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*,video/*"
        multiple
        onChange={handleFileChange}
      />

      <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
        {details.map((detail: any, index: number) => (
          <ContentsContainer key={index}>
            <ImageContainer onClick={() => handleMediaClick(detail)}>
              {detail.mdAttachmentType.includes('image') ? (
                <img src={detail.url} alt={detail.fileName} onClick={() => handleMediaClick(detail)} />
              ) : (
                <video src={detail.url} onClick={() => handleMediaClick(detail)} />
              )}
            </ImageContainer>
            <DetailsContainer sx={{ justifyContent: 'center' }}>
              <HeadingContainer>
                <Heading>{detail.fileName}</Heading>
                <DeleteOption>
                  <img src={Icons.DeleteIcon} alt={t('altTexts:delete')} onClick={() => handleDeleteClick(index)} />
                </DeleteOption>
              </HeadingContainer>
              <AddTagContainer>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem', cursor: 'pointer' }}>
                  {uploadedFileTags[index] &&
                    uploadedFileTags[index].tags.map((tag: any, tagIndex: number) => (
                      <Tags
                        key={tagIndex}
                        onClick={() => handleCancelTag(detail.id, tag, detail)}
                        style={{ backgroundColor: theme.palette.secondary.customColor7 }}>
                        <TagText>{tag}</TagText>
                        <img src={Icons.CancelIcon} alt={'cancel'} />
                      </Tags>
                    ))}
                </Box>
              </AddTagContainer>
              {isAddTagDialogOpen && selectedFileIndex === index && (
                <AddTags
                  open={isAddTagDialogOpen}
                  onClose={handleCloseAddTagDialog}
                  customerId={customerId}
                  jobId={jobId}
                  fileIndex={index}
                  attachmentId={detail.id}
                  updateUploaddFileTags={updateUploaddFileTags}
                  alreadySelectedTags={
                    uploadedFileTags[index] && uploadedFileTags[index].tags ? uploadedFileTags[index].tags : []
                  }
                />
              )}
            </DetailsContainer>
          </ContentsContainer>
        ))}
      </div>
      {selectedMedia && (
        <FullScreenView url={selectedMedia} onClose={handleCloseFullScreen} mediaType={selectedMediaType} />
      )}
      <Button
        variant="outlined"
        sx={addButton}
        onClick={() => {
          const authInfo = localStorage.getItem('auth');
          const { signedInTime } = JSON.parse(authInfo as string);
          const isPreviousSignedSessionMoreThan_55_minutes = new Date().getTime() - signedInTime > 1000 * 60 * 55; //checking 55 minutes difference from singed in time.
          if (isPreviousSignedSessionMoreThan_55_minutes) {
            setIsShowSessionExpirePopUp(true);
          } else {
            handleUploadClick();
          }
        }}>
        <img src={Icons.UploadIcon} alt={t('altTexts:upload')} />
        <Typography sx={{ ...addButtonText, textTransform: 'none' }}>{`${t('oneTimeJob:upload')} ${
          details.length ? `${t('oneTimeJob:more')}` : ''
        } ${t('oneTimeJob:photos/videos')}`}</Typography>
      </Button>
      <ApproveDialog
        title={t('logout:sessionTimedOutTitle')}
        loading={isShowSessionExpirePopUpLoading}
        ChildComponent={<LogoutUI />}
        isApproveWindowOpen={isShowSessionExpirePopUp}
        showCancelButton={false}
        isShowCloseIcon={false}
        handleCloseApproveWindow={() => setIsShowSessionExpirePopUp(false)}
        handleApprove={() => signOut()}
      />
    </div>
  );
};

export default PhotosAndVideos;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { FacilityData } from '../../../API';

export interface IFacilityDetails {
  buildingName: string;
  mdFacilityType: string;
  noOfFloors: number;
  sqFootage: number;
  address?: any;
}
export interface ISelectedService {
  categoryId: string;
  categoryName: string;
  mdServiceUnitType: string;
  serviceQuantity: string;
}

export interface IAccessFacility {
  accessCode?: string | null;
  accessContactDetails: string;
  accessContactPerson: string;
  accessContactPersonName: string;
  accessInfo?: string | null;
  day?: string | null;
  facilityAccessDetailId: string;
  facilityId: string;
  from?: string | null;
  jobId?: string | null;
  otherInformation?: string | null;
  roleName: string;
  to?: string | null;
}

export enum AccessOptions {
  AccessPin = 'Access pin',
  ContactPerson = 'Contact Person',
  KeyCard = 'Key card',
  LockboxWithKey = 'Lockbox with key',
}

export interface ITypes {
  label: string;
  value: string;
}

export interface IAddAccessFacilityInfoForm {
  access: string;
  personOfContact: string;
  parkingAt: string;
  codeForAccess?: string;
}

export interface ICustomerTeam {
  customerId?: string | null;
  email?: string | null;
  facilityId?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
  roleId?: string | null;
  roleName?: string | null;
  userId: string;
}

export interface IBuildingMakeup {
  Collection: string;
  Single: string;
  Subset: string;
}

export const defaultFieldValues: FacilityData = {
  facilityId: '',
  customerId: '',
  addressId: '',
  contactId: '',
  buildingName: '',
  buildingMakeup: '',
  noOfFloors: 0,
  sqFootage: null,
  mdFacilityType: '',
  serviceableSqFootage: 0,
  occupancy: 0,
  emergencyContactName: '',
  emergencyContactPhone: '',
  cleaningInstructions: '',
  facilityTrafficLevel: '',
  supplyStorageAvailability: false,
  transportRequiredWithinLocation: false,
};

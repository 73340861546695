/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const styles = {
  checkBoxText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h3?.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '1.75rem',
    letterSpacing: '0.009375rem',
    color: theme.palette.text.primary,
  },
  dividerStyle: {
    width: '80%',
    '@media (max-width: 64rem)': {
      width: '60%',
    },
  },
};

export const HeadingText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  display: 'flex',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  width: '15%',
  [theme.breakpoints.down('laptop')]: {
    width: '30%',
  },
}));

export const ModifyField = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const IconContainer = styled('div')(() => ({
  width: '5%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('laptop')]: {
    width: '10%',
  },
}));

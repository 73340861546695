/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

/* eslint-disable no-restricted-syntax */
import { FeatureKeys, FeatureMap, defaultFeatures } from './FeatureSwitchConstants';

export type HashMap<T> = Record<string, T>;

class FeatureSwitchServiceBase {
  public featureFlags: FeatureMap = <FeatureMap>{};

  public featureKeys;

  constructor() {
    this.featureFlags = { ...defaultFeatures };
    this.featureKeys = FeatureKeys;
  }

  public featureIsEnabled(featureKey: FeatureKeys): boolean {
    return this.featureFlags[featureKey] || this.featureFlags[FeatureKeys.EnableAll];
  }

  public featureValue(featureKey: FeatureKeys): string {
    return this.featureFlags[featureKey]?.toString();
  }

  public setFeaturesFromMap(features: HashMap<boolean | string>): void {
    if (features) {
      for (const featureKey in this.featureKeys) {
        if (this.hasOwnPropertyCaseInsensitive(features, featureKey)) {
          if (['boolean', 'string'].includes(typeof features[featureKey.toLowerCase()])) {
            (this.featureFlags as any)[featureKey] = features[featureKey.toLowerCase()];
          } else if (['boolean', 'string'].includes(typeof features[featureKey])) {
            (this.featureFlags as any)[featureKey] = features[featureKey];
          }
        }
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public hasOwnPropertyCaseInsensitive(obj: HashMap<string | boolean>, property: string): boolean {
    const props = [];
    for (const i in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(i)) {
        props.push(i);
      }
    }
    let prop;
    // eslint-disable-next-line no-cond-assign
    while ((prop = props.pop())) {
      if (prop.toLowerCase() === property.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
}

export default FeatureSwitchServiceBase;

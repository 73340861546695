/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useMediaQuery, useTheme } from '@mui/material';

import { IMultiBarGraphProps } from '../Graphs.Model';
import CustomTooltip from './CustomTooltip';

import theme from '../../../../Themes/theme';

const MultiBarGraph: React.FC<IMultiBarGraphProps> = ({ graphData, labels, yAxisLabel, xAxisLabel }) => {
  const theming = useTheme();
  const matches = useMediaQuery(theming.breakpoints.down('laptop'));
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={graphData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 20,
        }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={{ stroke: `${theme.palette.divider}` }}
          tick={{
            fill: `${theme.palette.secondary.contrastText}`,
            fontSize: theme.typography.customSize2?.fontSize,
            fontWeight: theme.typography.fontWeightLight,
          }}
          label={{
            value: xAxisLabel,
            position: 'insideBottom',
            fill: theme.palette.text.primary,
            style: {
              fontFamily: theme.typography.fontFamily,
              fontSize: matches ? theme.typography.h5?.fontSize : theme.typography.h4?.fontSize,
              fontWeight: theme.typography.fontWeightBold,
            },
            dy: 12,
          }}
        />
        <YAxis
          tickLine={false}
          axisLine={{ stroke: `${theme.palette.divider}` }}
          tick={{ fill: `${theme.palette.secondary.contrastText}` }}
          label={{
            value: yAxisLabel,
            position: 'insideLeft',
            angle: -90,
            fill: theme.palette.text.primary,
            style: {
              fontFamily: theme.typography.fontFamily,
              fontSize: matches ? theme.typography.h5?.fontSize : theme.typography.h4?.fontSize,
              fontWeight: theme.typography.fontWeightBold,
            },
            dy: 60,
          }}
        />
        <Tooltip content={<CustomTooltip labels={labels} labelName={xAxisLabel} />} cursor={false} />
        <Legend wrapperStyle={{ margin: -12 }} />

        {labels.map((bar, index) => (
          <Bar key={index} dataKey={bar.dataKey} fill={bar.fill} name={bar.name} barSize={16} radius={[10, 10, 0, 0]} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MultiBarGraph;

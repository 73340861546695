/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { Dialog, styled } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import theme from '../../../../Shared/Themes/theme';
import { BoxShadow, StyleConstants } from '../../../../Shared/Constants/Style.Constants';
const wrapperWidth = 1024 + 80;

export const buttonStyles = {
  mediaContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25rem',
    textDecoration: 'none',
    marginTop: '0.5rem',
    '@media (max-width: 64rem)': {
      marginTop: '1rem',
    },
  },
  textField: {
    minWidth: '5rem',
    width: '22.5rem',
    marginRight: '1.5rem',
    '& .MuiInputBase-root': {
      paddingRight: '0.75rem !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.light,
        borderRadius: '1rem',
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.h4?.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '1.5rem',
      letterSpacing: '0.00375rem',
      '&.Mui-focused': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h5?.fontSize,
      },
    },
    '@media (max-width: 64rem)': {
      width: '19.5rem',
      marginRight: '1.25rem',
      '& .MuiInputLabel-root': {
        fontSize: '.875rem',
        lineHeight: '1.375rem',
        letterSpacing: StyleConstants.LetterSpacingDefault,
        '&.Mui-focused': {
          fontSize: theme.typography.h6?.fontSize,
        },
      },
    },
  },
  mediaText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    letterSpacing: StyleConstants.LetterSpacingTight,
    color: theme.palette.primary.dark,
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: StyleConstants.LetterSpacingDefault,
    },
  },

  divider: {
    border: `00.03125rem solid ${theme.palette.divider}`,
    width: '100%',
    margin: '1.5rem 0',
  },

  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    flex: 1,
    lineHeight: '1.5rem',
    letterSpacing: StyleConstants.LetterSpacingTight,
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: '0.0125rem',
    },
  },

  createJobButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1.25rem',
    maxWidth: '11.25rem',
    flex: 1,
    display: 'flex',
    padding: '10px 0px',
    margin: '0px',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
    },
  },

  cancelButton: {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    background: theme.palette.common.white,
    borderRadius: '1.25rem',
    textTransform: 'none',
    margin: '0px 10px 0px 0px',
    flex: 1,
    padding: '0px',
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
      marginRight: '1rem',
    },
  },

  executionType: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    '@media (max-width: 64rem)': {
      gridTemplateColumns: 'repeat(2,1fr)',
    },
  },

  radioButton: {
    width: '1.25rem',
    height: '1.25rem',
    color: theme.palette.common.black,
    '&.Mui-checked': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.main,
      '& + span:last-child': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },

  radioButtonText: {
    fontFamily: theme.typography.fontFamily,
    marginRight: '0 !important',
    marginLeft: '0',
    gap: '1rem',
    '&.MuiFormControlLabel-root': {
      '&> span:last-child': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.h3?.fontSize,
        fontWeight: theme.typography.fontWeightLight,
        lineHeight: '1.375rem',
        letterSpacing: StyleConstants.LetterSpacingTight,
        color: theme.palette.text.primary,
      },
    },
    '@media (max-width: 64rem)': {
      '&.MuiFormControlLabel-root': {
        '&> span:last-child': {
          fontSize: theme.typography.h3?.fontSize,
          lineHeight: '1.5rem',
        },
      },
    },
  },

  vendorDetailsContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '3.25rem',
  },

  PunchInButtonStyles: {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0',
    cursor: 'pointer',
  },
};

export const datePickerStyles = {
  selectDateStyles: {
    maxWidth: '13rem',
    marginTop: '20px',
    marginRight: '40px',
    position: 'absolute',
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    '& .MuiOutlinedInput-root': {
      '& .MuiInputBase-input': {
        padding: '0.5625rem 0 0.5625rem 1rem',
        color: theme.palette.primary.dark,
      },
    },
  },
};
export const FacilityAndVendorDetailsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: StyleConstants.JustifyContentStart,
}));

export const FacilityDetailsContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: BoxShadow.BoxShadow2,
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  marginBottom: '1.5rem',
  padding: '1.5rem',
}));

export const ShiftDetailsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: BoxShadow.BoxShadow2,
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  marginBottom: '1.5rem',
  padding: '1.5rem',
  flex: 1,
}));

export const FacilityContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '1.5rem',
}));

export const DetailsContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: '1rem',
  justifyContent: StyleConstants.JustifyContentBetween,
}));

export const ImageContainer = styled('div')(() => ({
  width: '3.5rem',
  height: '3.5rem',
  borderRadius: '50%',
  overflow: 'hidden',

  '& img': {
    width: 'inherit',
    height: 'inherit',
  },
}));

export const DetailsContentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const DetailsHeadingContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: StyleConstants.JustifyContentStart,
  paddingLeft: '1rem',
}));

export const TextFieldWrapper = styled('div')(() => ({
  paddingBottom: '1.5rem',
}));

export const HeadingText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

export const AssignTeamDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '30px',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
}));

export const TeamDisplayDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '2rem',
  position: 'relative',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
}));

export const DetailsAndDate = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
}));

export const Details = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.primary,
  marginBottom: '0.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: StyleConstants.LetterSpacingDefault,
  },
}));

export const PunchinHeading = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  paddingBottom: '1rem',
  paddingRight: '1rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: StyleConstants.LetterSpacingDefault,
    paddingRight: '0rem',
  },
}));

export const PunchInHeader = styled('div')(() => ({
  display: 'flex',
}));

export const DetailsTitle = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  marginBottom: '0.5rem',
  paddingBottom: '1rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: StyleConstants.LetterSpacingDefault,
  },
}));

export const Date = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingDefault,
  color: theme.palette.secondary.contrastText,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1rem',
    letterSpacing: '0.025rem',
  },
}));

export const FacilitiesAndBidOpenTo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: StyleConstants.JustifyContentStart,
  width: '100%',
  gap: '1rem',
  justifyContent: 'center',
}));

export const Match = styled('div')(() => ({
  padding: '0.25rem 0.5rem',
  backgroundColor: theme.palette.secondary.dark,
  borderRadius: '0.375rem',
}));

export const MatchText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingDefault,
  color: theme.palette.common.black,
  display: 'inline-flex',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
  },
}));

export const TagWrapper = styled('div')(() => ({
  padding: '0.25rem 0.5rem',
  backgroundColor: '#FDECEC',
  borderRadius: '0.375rem',
  height: StyleConstants.MaxWidth,
  width: StyleConstants.MaxWidth,
  right: '50px',
  position: 'absolute',
  top: '-20px',
  fontSize: '0.875rem',
  fontWeight: '500',
}));

export const TagWrapperText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h6.fontSize,
  fontStyle: 'normal',
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingDefault,
  color: theme.palette.error.main,
  display: 'inline-flex',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
  },
}));

export const FacilitiesDescription = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: StyleConstants.JustifyContentStart,
}));

export const DescriptionText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingDefault,
  color: theme.palette.secondary.contrastText,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
  },
}));

export const TimeAndInstructions = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '1rem',
  justifyContent: 'flex-start',
}));

export const VendorInfoWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: StyleConstants.JustifyContentStart,
  width: '100%',
  gap: '1rem',
}));

export const TitleImgWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const LabelItemWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '30%',
}));

export const LabelWrapper = styled('div')(() => ({
  fontFamily: 'Manrope',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '22px',
  letterSpacing: '0.25px',
}));

export const ValueWrapper = styled('div')(() => ({
  fontFamily: 'Manrope',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.15px',
}));

export const BidCountContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}));

export const BidCountText = styled('div')(() => ({
  fontWeight: '600',
  fontSize: '1.125rem',
  lineHeight: '22px',
  letterSpacing: '0.25px',
  width: '8em',
  maxWidth: StyleConstants.MaxWidth,
  marginTop: '0.9em',
  marginRight: '1em',
  whiteSpace: 'nowrap',
}));

export const BidCardsContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: StyleConstants.JustifyContentBetween,
  gap: '1rem',
}));

export const BidFinalQuoteContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: StyleConstants.JustifyContentBetween,
  width: '100%',
  margin: '1% 2% 0% 2%',
}));

export const BidFinalQuoteText = styled('div')(() => ({
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '22px',
  color: '#484E68',
  letterSpacing: '0.25px',
  marginTop: '2.5px',
}));

export const BidFinalQuoteValue = styled('div')(() => ({
  fontWeight: '700',
  fontSize: '20px',
  color: '#0F1532',
}));

export const NoBidsFoundText = styled('div')(() => ({
  alignSelf: 'center',
  fontSize: '1rem',
  fontWeight: '700',
}));

export const AssignmentCardWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    width: '50%',
    padding: '2em',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
}));

export const AssignmentCard = styled('div')(() => ({
  borderRadius: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: BoxShadow.BoxShadow2,
  backgroundColor: theme.palette.common.white,
  minWidth: '37rem',
  minHeight: '20rem',
  position: 'relative',
}));

export const AssignmentTeamCard = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.common.white,
  minHeight: '20rem',
  width: '100%',
  position: 'relative',
}));

export const TitleCard = styled('div')(() => ({
  border: `1px solid ${theme.palette.divider}`,
  // width: '100%',
  textAlign: 'center',
  borderRadius: '10px',
  // boxShadow: '0px 0px 4px 4px rgba(168, 160, 140, 0.1)',
  boxShadow: '0 0.025rem 0.25rem 0.175rem rgba(121, 87, 0, 0.10)',
  padding: '1em',
  marginBottom: '1em',
  fontFamily: theme.typography.fontFamily,
  cursor: 'pointer',
}));

export const TimePicker = styled(MobileTimePicker)(() => ({
  '& button': {
    width: '3em',
    marginBottom: '1em',
    backgroundColor: theme.palette.primary.dark,
  },
  '& .MuiPickersToolbar-root': {
    visibility: 'hidden',
  },
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  color: theme.palette.text.secondary,
  alignItems: 'center',
  columnGap: '1rem',
  '& img': {
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  '> div': {
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    minWidth: '2.7rem',
    height: '2.7rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.8rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const TeamContainer = styled('div')(({ theme }) => ({
  marginLeft: '1rem',
  fontFamily: theme.typography.fontFamily,
  '> .Title': {
    minWidth: '2.5rem',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  '> .Position': {
    minWidth: '2.5rem',
    fontSize: '0.8rem',
    marginTop: '0.8rem',
    fontWeight: theme.typography.fontWeightBold,
    color: '#77AD90',
  },
}));

export const PunchTeamContainer = styled('div')(({ theme }) => ({
  marginLeft: '1rem',
  fontFamily: theme.typography.fontFamily,
  '> .Title': {
    minWidth: '2.5rem',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  '> .Position': {
    minWidth: '2.5rem',
    fontSize: '0.8rem',
    fontWeight: theme.typography.fontWeightBold,
    color: '#77AD90',
  },
}));

export const PunchInButton = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  minWidth: '2.5rem',
  fontSize: '0.8rem',
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.primary.dark,
}));

export const DateRange = styled('span')(() => ({
  whiteSpace: 'pre',
}));

export const CheckContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '10px',
  fontFamily: theme.typography.fontFamily,
  '> span': {
    marginLeft: '1rem',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  '> .Position': {
    minWidth: '2.5rem',
    fontSize: '0.8rem',
    marginTop: '0.8rem',
    fontWeight: theme.typography.fontWeightBold,
    color: '#77AD90',
  },
}));
export const ScrollContainer = styled('div')(() => ({
  height: '50vh',
  overflowY: 'auto',
  padding: '1em',
}));
export const PreShiiftImageContainer = styled('div')(() => ({
  width: '3rem',
  height: '3rem',
  borderRadius: '10px',
  overflow: 'hidden',

  '& img': {
    width: 'inherit',
    height: 'inherit',
  },
}));
export const CenterButton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
export const CardContainer = styled('div')(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  marginBottom: '1.5em',
  fontFamily: theme.typography.fontFamily,
  borderRadius: '1em',
  boxShadow: BoxShadow.BoxShadow2,
  padding: '2em',
  '> .TitleContainer': {
    fontSize: '1rem',
    marginBottom: '0.4rem',
    marginTop: '1.0rem',
    opacity: '0.8',
  },
  '> .contentFlex': {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      height: '1.5rem',
      width: '1.5rem',
      marginRight: '1rem',
    },
    '> .Name': {
      fontSize: '1rem',
      fontWeight: '700',
    },
  },
}));

export const AssignJobText = styled('span')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5?.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingDefault,
  color: theme.palette.secondary.contrastText,
}));

export const TeamCard = styled('div')(() => ({
  display: 'flex',
  justifyContent: StyleConstants.JustifyContentBetween,
  boxShadow: '0px 10px 20px 6px rgba(121, 87, 0, 0.10)',
  padding: '10px',
  borderRadius: '10px',
  height: '100%',
  marginBottom: '1rem',
  marginRight: '1rem',
  minWidth: '324px',
  alignItems: 'center',
}));

export const TeamCardOuterDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: '10px',
  paddingBottom: '10px',
  position: 'relative',
}));

export const CardTitle = styled('span')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.primary,
  textAlign: 'left',
  marginBottom: '1em',
}));

export const CardContainerWrapper = styled('div')(() => ({
  marginTop: '0.625rem',
  height: '35vh',
  overflowY: 'auto',
  paddingLeft: '1em',
  paddingTop: '0.5em',
}));

export const PunchCardContainerWrapper = styled('div')(() => ({
  paddingRight: '1em',
}));

export const AwardJobText = styled('span')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3?.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.primary,
  textAlign: 'left',
}));

export const NoDataContainer = styled('div')(() => ({
  width: '100%',
}));

export const DatePickerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: '2.5rem',
  columnGap: '1.5rem',
  width: '100%',
  [theme.breakpoints.down(wrapperWidth)]: {
    flexWrap: 'wrap',
    marginBottom: '0.5rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
}));

export const NewDatePickerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: '2.5rem',
  rowGap: '1.5rem',
  flexDirection: 'column',
  [theme.breakpoints.down(wrapperWidth)]: {
    flexWrap: 'wrap',
    rowGap: '1.5rem',
    flexDirection: 'column',
    marginBottom: '0.5rem',
    display: 'flex',
  },
}));

export const SelectDatePickerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100px',
  border: '2px solid yellow',
  marginBottom: '2.5rem',
  rowGap: '1.5rem',
  [theme.breakpoints.down(wrapperWidth)]: {
    flexWrap: 'wrap',
    rowGap: '1.5rem',
    flexDirection: 'column',
    marginBottom: '0.5rem',
    display: 'flex',
  },
}));

export const FacilityAccessContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  background: theme.palette.common.white,
  marginBottom: '1.5rem',
  padding: '1.5rem',
  borderRadius: '1rem',
}));

export const FacilityAccessLabelContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '0.5rem',
  alignItems: 'center',
  marginBottom: '1rem',
}));

export const AddSchedule = styled('div')(() => ({
  background: 'white',
  width: '100%',
  borderRadius: '1rem',
  marginBottom: '1.5rem',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  justifyContent: 'space-between',
}));

export const AddDataContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  SelectChangeEvent,
  InputAdornment,
  IconButton,
} from '@mui/material';

import { isEmpty } from 'lodash';
import {
  IMultiSelectDropdown,
  ICustomDropdownIconProps,
} from '../../../../Modules/Jobs/Models/AddEditOneTimeJob.Model';

import { Icons } from '../../../Constants/Icons';

import { ErrorMsgWrapper, dropdownStyles } from './MultiSelectDropdownWithCheckboxes.Style';

const MultiSelectDropdownWithCheckboxes = ({
  label,
  isMandatory,
  dropdownValues,
  onSelect,
  defaultValues = [],
  error = '',
  showSelectAll = true,
}: IMultiSelectDropdown): JSX.Element => {
  const { t } = useTranslation(['altTexts']);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const { inputField, labelStyles, selectField, checkBox, listItemStyles } = dropdownStyles;

  const CustomDropdownIcon: React.FC<ICustomDropdownIconProps> = ({ isOpen, Icons }) => (
    <img src={isOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon} alt={isOpen ? 'up' : 'down'} />
  );

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    let value = event.target.value;
    value = typeof value === 'string' ? value.split(',') : value;
    if (value.includes('all')) {
      if (value.length === 1 || value.length > dropdownValues.length || !selectedValues.includes('all')) {
        selectAllTheValueByDefault();
      } else {
        setIsSelectAll(false);
        const selectedValues = value.filter((option: string) => option !== 'all');
        setSelectedValues(selectedValues);
        onSelect(selectedValues);
      }
    } else if (selectedValues.includes('all') || isEmpty(value)) {
      setSelectedValues([]);
      onSelect([]);
      setIsSelectAll(false);
    } else if (value.length === dropdownValues.length) {
      setIsSelectAll(true);
      setSelectedValues([...value, 'all']);
      onSelect([...value]);
    } else {
      setIsSelectAll(false);
      setSelectedValues([...value]);
      onSelect(value);
    }
  };

  const selectAllTheValueByDefault = () => {
    const allSelectedValues = dropdownValues?.map((value: any) => {
      return value.taskId;
    });
    if (!isEmpty(allSelectedValues)) {
      setIsSelectAll(true);
      setSelectedValues(showSelectAll ? [...allSelectedValues, 'all'] : [...allSelectedValues]);
      onSelect([...allSelectedValues]);
    }
  };

  useEffect(() => {
    if (
      isEmpty(defaultValues) ||
      defaultValues?.length === dropdownValues?.length ||
      !dropdownValues?.some((option: any) => defaultValues?.includes(option.taskId as string))
    ) {
      selectAllTheValueByDefault();
    } else {
      setSelectedValues([...defaultValues]);
    }
  }, [dropdownValues]);

  return (
    <>
      <Box sx={{ marginRight: '1.5rem' }}>
        <FormControl sx={{ ...inputField }}>
          <InputLabel sx={{ ...labelStyles }}>
            {label} {isMandatory && <span className="mandatory-field">*</span>}
          </InputLabel>
          <Select
            sx={{ ...selectField }}
            labelId="multiple-checkbox-label"
            id="multiple-checkbox"
            multiple
            open={isOpen}
            value={selectedValues}
            onChange={handleChange}
            IconComponent={() => null}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            input={
              <OutlinedInput
                label={
                  <span>
                    {label} {isMandatory && <span className="mandatory-field">*</span>}
                  </span>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setIsOpen(!isOpen)} style={{ padding: 0 }}>
                      <CustomDropdownIcon isOpen={isOpen} Icons={Icons} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            }
            renderValue={(selected) => {
              const selectedTaskNames = selected?.map(
                (id: string) => dropdownValues?.find((value: any) => value.taskId === id)?.taskName || id
              );
              return selectedTaskNames.filter((task: string) => task !== 'all').join(', ');
            }}>
            {showSelectAll && (
              <MenuItem value="all" sx={{ maxWidth: '22.5rem' }}>
                {isSelectAll ? (
                  <img src={Icons.CheckedIcon} alt={t('altTexts:checked')} style={{ ...checkBox }} />
                ) : (
                  <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} style={{ ...checkBox }} />
                )}
                <ListItemText primary="Select All" sx={{ ...listItemStyles }} />
              </MenuItem>
            )}
            {dropdownValues?.map((value: any, index: number) => (
              <MenuItem key={index} value={value.taskId} sx={{ maxWidth: '22.5rem' }}>
                {selectedValues.indexOf(value.taskId as string) > -1 ? (
                  <img src={Icons.CheckedIcon} alt={t('altTexts:checked')} style={{ ...checkBox }} />
                ) : (
                  <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} style={{ ...checkBox }} />
                )}
                <ListItemText primary={value.taskName} sx={{ ...listItemStyles }} />
              </MenuItem>
            ))}
          </Select>
          <ErrorMsgWrapper>{error}</ErrorMsgWrapper>
        </FormControl>
      </Box>
    </>
  );
};

export default MultiSelectDropdownWithCheckboxes;

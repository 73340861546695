/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { VendorType } from '../../../Shared/Models/Vendors.Models';

export interface BidApprovalResponseModel {
  bidId: string;
  vendorId: string;
  vendorName: string;
  vendorEmail: string;
  bidName: string;
  totalCost: number;
  profitMargin: number;
  finalQuote: number;
  mdFrequencyType: string;
  isFlatMargin: string | null;
  comments: string | null;
  bidCreationDate: string;
  bidTemplate: string;
  bidCreatedFromSample: string;
  bidVersion: number;
  bidVersionCreatedDate: string;
  bidEstimationType: string;
  submittedBy: string;
  bidSubmissionDate: string;
  draftStep: string;
  draftStatus: string;
  dataStatus: string;
  jobId: string;
  bidSimpleEstimation: [];
  bidAdditionalExpenses: [];
  jobCustodianAddress: string;
  jobCustodianAddressName: string;
  customerAddressName: string;
  customerAddress: string;
  addressName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  jobs: {
    createdBy: string;
    customerName: string;
    customerId: string;
    intReferenceId: string;
    startDate: string;
    stopDate: string;
    jobFrequencies: [
      {
        fromTime: string;
        toTime: string;
      },
    ];
    jobFacilities: [
      {
        facilityAddress: string;
      },
    ];
    jobServices: [
      {
        mdServiceId: string;
      },
      {
        mdServiceId: string;
      },
    ];
    jobDueDates: [];
  };
}

export interface EmailTransactionDetailsModel {
  emailTransactionId: string;
  processName: string;
  IPAddress: string;
  transactionStatus: TransactionStatus;
  dataJson: null;
  createdBy: string;
  jobId: string;
  bidId: string;
  mdVendorType: VendorType;
  isReadOnly: boolean;
}

export enum TransactionStatus {
  PendingApproval = 'PendingApproval',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export const RESPONSE_CODES = {
  BIDS: {
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    EXPIRED: 'EXPIRED',
    ALREADY_APPROVED: 'ALREADY_APPROVED',
    RECEIVED_REJECTION: 'RECEIVED_REJECTION',
    RECEIVED_APPROVAL: 'RECEIVED_APPROVAL',
    SUCCESS: 'SUCCESS',
  },
};

export interface ICommentModel {
  commentId: string;
  keyId: string;
  keyType: string;
  comment: string;
  commenterType: string;
  dataStatus: string;
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
// eslint-disable-next-line import/prefer-default-export
export enum VendorType {
  All = 'All',
  Invite = 'Invite',
  Preferred = 'Preferred',
  SearchVendors = 'SearchVendors',
  SelfPerform = 'SelfPerform',
  SubVendor = 'SubVendor',
}

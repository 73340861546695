/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  CongratsText,
  CongratsContainer,
  SuccessWrapper,
  CloseIconStyle,
} from '../../../../Vendor/Components/CreateBid.Style';
import { Icons } from '../../../../../Shared/Constants/Icons';

interface BidApprovalProps {
  open: boolean;
  onClose: () => void;
  headerImg: any;
  message: string;
}

const BidApprovalPopup: React.FC<BidApprovalProps> = ({ open, onClose, headerImg, message }) => {
  const { t } = useTranslation(['altTexts']);
  return (
    <SuccessWrapper open={open} onClose={onClose}>
      <CloseIconStyle onClick={onClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      <CongratsContainer sx={{ borderRadius: '1.5rem' }}>
        <Box sx={{ margin: '3rem 0 2.5rem 0' }}>
          <img src={headerImg} />
        </Box>
        <CongratsText sx={{ marginLeft: '1rem', marginRight: '1rem', textAlign: 'center' }}>{message}</CongratsText>
      </CongratsContainer>
    </SuccessWrapper>
  );
};

export default BidApprovalPopup;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, DialogTitle, DialogContent, IconButton, TextField, InputAdornment } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { IShiftDetails, IShiftTimings } from './AwardJob';
import {
  formatDate,
  formattingDate,
  getDoubleDigitNumberString,
  isDateSameOrAfter,
} from '../../../../Shared/Utilities/utils';
import { CloseIconStyle, DialogWrapper } from '../../../Vendor/Components/CreateBid.Style';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { DatePickerContainer } from './OneTimeJobDetails.Style';
import { JobType } from '../../../../API';
import { Icons } from '../../../../Shared/Constants/Icons';
import { buttonStyles } from '../AddEditOneTimeJob/AddEditOneTimeJob.Style';

interface TimeSelectionDialogProps {
  isDialogOpen: boolean;
  handleDialogOpen: () => void;
  handleDialogClose: () => void;
  shift: any;
  hoursList: { value: string; label: string }[];
  setStartTimeSelectOpen: (isOpen: boolean) => void;
  setEndTimeSelectOpen: (isOpen: boolean) => void;
  isStartTimeSelectOpen: boolean;
  isEndTimeSelectOpen: boolean;
  buttonStyles: { textField: React.CSSProperties };
  t: (key: string) => string;
  theme: any;
  onSave: any;
  shiftDetailId: string;
  shiftTimings: any;
  isPrimaryButtonLoading: boolean;
  shiftDetails: any;
  shiftId: string;
  jobData: any;
  workOrderData: any;
  sectionTitle: string;
}

const ShiftTimeSelection: React.FC<TimeSelectionDialogProps> = ({
  isDialogOpen,
  handleDialogClose,
  shift,
  hoursList,
  setStartTimeSelectOpen,
  setEndTimeSelectOpen,
  isStartTimeSelectOpen,
  isEndTimeSelectOpen,
  t,
  onSave,
  isPrimaryButtonLoading = false,
  jobData,
  sectionTitle,
}): JSX.Element => {
  const [isOffsetChecked, setIsOffsetChecked] = useState(false);
  const [offsetDays, setOffsetDays] = useState<number>();

  const { textField } = buttonStyles;

  const formatDateString = (dateString: any) => {
    if (dateString) {
      return dateString.replace(/Z$/, '');
    }
    return dateString;
  };

  const shiftWorkOrderJobStartDate = shift.workOrder.jobStartDate;
  const shiftWorkOrderJobEndDate = shift.workOrder.jobEndDate;

  const shiftStartDate = formattingDate(shiftWorkOrderJobStartDate);
  const shiftEndDate = formattingDate(shiftWorkOrderJobEndDate);

  const formattedJobStartDate = formatDateString(shiftStartDate);
  const formattedJobEndDate = formatDateString(shiftEndDate);

  const { control, handleSubmit, getValues, watch, setError, clearErrors } = useForm({
    defaultValues: {
      startDate: shift?.scheduleStartDate ? formatDate(shift?.scheduleStartDate) : null,
      endDate: shift?.scheduleEndDate ? formatDate(shift?.scheduleEndDate) : null,
      startTime: shift?.startTime ?? '',
      endTime: shift?.endTime ?? '',
      offsetDays: 0,
    },
  });

  const handleOnPressConfirm = async (duration: any) => {
    const timeDetails = {
      startTime: {
        hours: duration?.startTime?.split(':')[0] ?? '',
        minutes: duration?.startTime?.split(':')[1] ?? '',
      },
      endTime: {
        hours: duration?.endTime?.split(':')[0] ?? '',
        minutes: duration?.endTime?.split(':')[1] ?? '',
      },
      scheduleStartDate: duration?.startDate ?? '',
      scheduleEndDate: duration?.endDate ?? '',
      offsetDays: isOffsetChecked ? offsetDays : duration?.offsetDays,
    };
    await handleOnUpdateShiftTime(timeDetails);
    handleDialogClose();
  };

  const handleOnUpdateShiftTime = useCallback(async (shiftDetails: IShiftTimings) => {
    const { startTime, endTime, offsetDays = 0 } = shiftDetails;
    const startDate = shiftDetails?.scheduleStartDate ?? '';
    const endDate = shiftDetails?.scheduleEndDate ?? '';
    const modifiedShiftDetails: IShiftDetails = {
      scheduleStartDate: startDate ?? '',
      scheduleEndDate: endDate ?? '',
      endTime,
      startTime,
      offsetDays,
    };
    await addShiftTiming(modifiedShiftDetails);
  }, []);

  const addShiftTiming = async (details: IShiftDetails) => {
    const { startTime, endTime } = details;
    await onSave(
      [
        {
          shiftDetailId: shift?.shiftDetailId,
          startTime: {
            hours: getDoubleDigitNumberString(Number(startTime.hours)),
            minutes: getDoubleDigitNumberString(Number(startTime.minutes)),
          },
          endTime: {
            hours: getDoubleDigitNumberString(Number(endTime.hours)),
            minutes: getDoubleDigitNumberString(Number(endTime.minutes)),
          },
        },
      ],
      details,
      sectionTitle
    );
  };

  const handleOffsetCheckboxChange = () => {
    setIsOffsetChecked((prev) => !prev);
  };

  useEffect(() => {
    if (shift?.endDateOffset) {
      setOffsetDays(shift?.endDateOffset);
      setIsOffsetChecked(true);
    }
  }, [shift?.endDateOffset]);

  const watchFields = watch();

  const isNonRecurringJob = () => jobData?.jobDetails?.jobType !== 'Recurring';

  const isDateRangeInvalid = (startDate: string | null | undefined, endDate: string | null | undefined) =>
    startDate && endDate && !isDateSameOrAfter(startDate, endDate);

  const isDateRangeIncomplete = (startDate: string | null, endDate: string | null, startTime: any, endTime: any) =>
    !(startTime && endTime && startDate && endDate);

  const handleErrorForEndDate = (isError: boolean, message = '') => {
    if (isError) {
      setError(FieldNames.endDate, { type: 'validate', message });
    } else {
      clearErrors(FieldNames.endDate);
    }
  };

  const disableUpdateButton = () => {
    const { startTime, endTime, startDate, endDate } = getValues();

    if (isNonRecurringJob()) {
      if (isDateRangeInvalid(startDate, endDate)) {
        handleErrorForEndDate(true, 'End date cannot be before start date');
        return true;
      }
      handleErrorForEndDate(false);

      return !!isDateRangeIncomplete(startDate, endDate, startTime, endTime);
    }

    return !(startTime && endTime);
  };

  useEffect(() => {
    disableUpdateButton();
  }, [watchFields]);

  return (
    <DialogWrapper open={isDialogOpen} onClose={handleDialogClose} key={shift.shiftDetailId} sx={{ height: '100%' }}>
      <CloseIconStyle onClick={handleDialogClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '1rem',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <DialogTitle sx={{ fontSize: '1.25rem', padding: 0, marginBottom: '1.5rem' }}>
          Select time
          <IconButton onClick={handleDialogClose} style={{ position: 'absolute', right: '1rem', top: '-1.6rem' }}>
            <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', padding: '0', overflowX: 'hidden' }}>
          <>
            {jobData?.jobDetails?.jobType !== JobType.Recurring && (
              <DatePickerContainer>
                <DateSelection
                  requiredText={t('oneTimeJob:startDateFieldReq')}
                  control={control}
                  fieldName={FieldNames.startDate}
                  label={t('calendar:startDate')}
                  iconColor="black"
                  isExcludePastDate={false}
                  showSpecificRangeDates={{ from: formattedJobStartDate, to: formattedJobEndDate }}
                />
                <DateSelection
                  requiredText={t('oneTimeJob:endDateFieldReq')}
                  control={control}
                  fieldName={FieldNames.endDate}
                  label={t('calendar:endDate')}
                  iconColor="black"
                  isExcludePastDate={false}
                  showSpecificRangeDates={{ from: formattedJobStartDate, to: formattedJobEndDate }}
                />
              </DatePickerContainer>
            )}
          </>
          <DatePickerContainer>
            <Controller
              control={control}
              name={FieldNames.startTime}
              defaultValue={shift.startTime}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Box>
                  <TextField
                    select
                    fullWidth
                    sx={{ ...textField, marginRight: '0' }}
                    label={t('calendar:startTime')}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)}
                            style={{ padding: 0 }}>
                            <img
                              src={isStartTimeSelectOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                              alt={isStartTimeSelectOpen ? 'down' : 'up'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      open: isStartTimeSelectOpen,
                      IconComponent: () => null,
                      onOpen: () => setStartTimeSelectOpen(true),
                      onClose: () => setStartTimeSelectOpen(false),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                          },
                        },
                      },
                    }}>
                    {hoursList.map((item) => (
                      <Options key={'startTime' + item.value} value={item.value}>
                        {item.label}
                      </Options>
                    ))}
                  </TextField>
                  <span>{error && error.message}</span>
                </Box>
              )}
            />
            <Controller
              control={control}
              name={FieldNames.endTime}
              defaultValue={shift.endTime}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Box>
                  <TextField
                    select
                    sx={{ ...textField, marginRight: '0' }}
                    label={t('calendar:endTime')}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setEndTimeSelectOpen(!isEndTimeSelectOpen)} style={{ padding: 0 }}>
                            <img
                              src={isEndTimeSelectOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                              alt={isEndTimeSelectOpen ? 'down' : 'up'}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      open: isEndTimeSelectOpen,
                      IconComponent: () => null,
                      onOpen: () => setEndTimeSelectOpen(true),
                      onClose: () => setEndTimeSelectOpen(false),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                          },
                        },
                      },
                    }}>
                    {hoursList.map((item) => (
                      <Options key={'endTime' + item.value} value={item.value}>
                        {item.label}
                      </Options>
                    ))}
                  </TextField>
                  <span>{error && error.message}</span>
                </Box>
              )}
            />
          </DatePickerContainer>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <IconButton onClick={handleOffsetCheckboxChange} sx={{ padding: 0 }}>
                <img
                  src={isOffsetChecked ? Icons.CheckedIcon : Icons.Unchecked}
                  alt={isOffsetChecked ? 'checked' : 'unchecked'}
                />
              </IconButton>
              <span style={{ marginLeft: '0.5rem' }}>{t('rescheduleShiftDate:addEndDateOffset')}</span>
            </Box>
            {isOffsetChecked && (
              <Controller
                control={control}
                name="offsetDays"
                render={({ field }) => (
                  <TextField
                    label={t('rescheduleShiftDate:shiftDaysMessage')}
                    variant="outlined"
                    fullWidth
                    type="number"
                    {...field}
                    value={offsetDays}
                    onChange={(e) => {
                      setOffsetDays(parseInt(e.target.value));
                    }}
                    sx={{ ...buttonStyles.textField, width: '100%', marginBottom: '1.5rem' }}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                )}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <Button
              type={ButtonType.Primary}
              label="Update"
              disabled={disableUpdateButton()}
              onClick={handleSubmit(handleOnPressConfirm)}
              isLoading={isPrimaryButtonLoading}
            />
          </Box>
        </DialogContent>
      </Box>
    </DialogWrapper>
  );
};

export default ShiftTimeSelection;

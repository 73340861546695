/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text } from 'recharts';
// import { useTranslation } from 'react-i18next';

import { GraphWrapper, CustomTooltipWrapper } from '../style';
import { IDataV2 } from '../Graphs.Model';
import { formatAxisValue } from '../graphUtils';
import theme from '../../../../Themes/theme';

interface IBarGraphProps {
  barColor: string;
  graphData: IDataV2[];
  graphView: string;
  yAxisLabel: string;
}

const CustomTooltip = ({ payload, yAxisLabel }: any) => {
  const currentValue = (payload && payload[0]) ?? { name: '', value: '', payload: { name: '', value: '' } };
  const label = yAxisLabel;

  return (
    <CustomTooltipWrapper>
      <Box sx={{ display: 'flex' }}>
        <Typography
          sx={{ whiteSpace: 'nowrap' }}>{`Facility Name${'\u00A0\u00A0\u200A\u200A'}:${'\u00A0'}`}</Typography>
        <Typography sx={{ fontWeight: theme.typography.fontWeightBold, overflow: 'hidden' }}>{`${
          currentValue?.payload?.name as string
        }`}</Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography>{`${label}:${'\u00A0'}`}</Typography>
        <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>{`${
          currentValue?.value as string
        }`}</Typography>
      </Box>
    </CustomTooltipWrapper>
  );
};

export const CustomXAxisTick = ({ x, y, payload, isHorizontal }: any): JSX.Element | null => {
  if (payload && payload.value) {
    const labelLines: string[] = payload.value.split(' ');

    const maxLines = 2;
    const truncatedLines: string[] = labelLines.slice(0, maxLines);

    const lineHeight = 16;

    const verticalPosition: number = isHorizontal ? (y as number) - (maxLines - 1) * lineHeight : (y as number);

    const textStyle = {
      fontSize: '0.625rem',
      fontWeight: theme.typography.fontWeightLight,
      fontFamily: theme.typography.fontFamily,
      letterSpacing: '0.03125rem',
    };

    return (
      <g>
        {truncatedLines.map((line: string, index: number) => (
          <Text
            key={index}
            fill={`${theme.palette.secondary.contrastText}`}
            width={200}
            x={x}
            y={verticalPosition + index * lineHeight}
            textAnchor={isHorizontal ? 'end' : 'middle'}
            verticalAnchor={isHorizontal ? 'middle' : 'start'}
            style={textStyle}>
            {line}
          </Text>
        ))}
      </g>
    );
  }
  return null;
};

/**
 * @param {String} barColor
 * @param {String} graphView
 * @param {IDataV2} graphData
 * @param {String} yAxisLabel
 * @returns It will return BarGraph
 */
const BarGraph = ({ barColor, graphData, graphView, yAxisLabel }: IBarGraphProps): JSX.Element => {
  const theming = useTheme();
  const matches = useMediaQuery(theming.breakpoints.down('laptop'));
  const isHorizontal = graphView === 'horizontal';
  // eslint-disable-next-line prefer-spread
  const maxJobCount = Math.max.apply(
    Math,
    graphData.map((dataItem) => dataItem.amount)
  );

  return (
    <GraphWrapper>
      <ResponsiveContainer width="99%" height="94%">
        <BarChart
          data={graphData}
          layout={isHorizontal ? 'vertical' : undefined}
          margin={{
            top: matches ? 16 : 24,
            right: matches ? 24 : 32,
            left: matches ? 16 : 24,
            bottom: matches ? 32 : 40,
          }}>
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={isHorizontal ? true : false}
            horizontal={isHorizontal ? false : true}
          />
          {isHorizontal ? (
            <>
              <XAxis
                tick={{ fill: `${theme.palette.secondary.contrastText}` }}
                tickLine={false}
                type="number"
                axisLine={{ stroke: `${theme.palette.divider}` }}
                tickFormatter={formatAxisValue}
              />
              <YAxis
                type="category"
                width={80}
                dataKey="name"
                domain={[0, maxJobCount + 5]}
                axisLine={{ stroke: `${theme.palette.divider}` }}
                tickLine={false}
                allowDecimals={false}
                tick={<CustomXAxisTick isHorizontal={isHorizontal} />}
              />
            </>
          ) : (
            <>
              <XAxis
                axisLine={{ stroke: `${theme.palette.divider}` }}
                tickLine={false}
                dataKey="name"
                tick={<CustomXAxisTick isHorizontal={isHorizontal} />}
                label={{
                  value: 'Facility Name',
                  position: 'insideBottom',
                  offset: matches ? -26 : -34,
                  fill: theme.palette.text.primary,
                  style: {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: matches ? '0.875rem' : '1rem',
                    fontWeight: theme.typography.fontWeightBold,
                  },
                }}
              />
              <YAxis
                axisLine={{ stroke: `${theme.palette.divider}` }}
                tick={{ fill: `${theme.palette.secondary.contrastText}` }}
                tickLine={false}
                domain={[0, maxJobCount + 5]}
                allowDecimals={false}
                tickFormatter={formatAxisValue}
                label={{
                  value: yAxisLabel,
                  position: 'insideLeft',
                  angle: -90,
                  fill: theme.palette.text.primary,
                  style: {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: matches ? '0.875rem' : '1rem',
                    fontWeight: theme.typography.fontWeightBold,
                  },
                  dy: 60,
                }}
              />
            </>
          )}
          <Tooltip
            cursor={false}
            content={(tooltipProps) => <CustomTooltip yAxisLabel={yAxisLabel} payload={tooltipProps.payload} />}
          />
          <Bar dataKey="value" barSize={16} fill={barColor} radius={isHorizontal ? [0, 10, 10, 0] : [10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </GraphWrapper>
  );
};

export default BarGraph;

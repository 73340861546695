/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import colorConfigs from '../../../../Shared/Configs/colorConfigs';
import theme from '../../../../Shared/Themes/theme';

export const BidApprovalContainer = styled('div')(() => ({
  backgroundColor: colorConfigs.mainBg,
  [theme.breakpoints.down('laptop')]: {
    // height: '100vh',
  },
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const ConversationsPageContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1rem',
  margin: '9rem 1rem 8rem 1rem',
}));

export const ConversationContainer = styled('div')(() => ({
  width: '24%',
  boxShadow: '0 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10);',
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  marginBottom: '1.5rem',
  padding: '1.5rem',
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  color: theme.palette.text.secondary,
  alignItems: 'center',
  columnGap: '1rem',
  '& img': {
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  '> div': {
    borderRadius: '50%',
    minWidth: '2.7rem',
    height: '2.7rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.8rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const ReplyTag = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  marginTop: '1rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.009375rem',
  textTransform: 'none',
  paddingRight: '0.5rem',
  cursor: 'pointer',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    paddingRight: '0.25rem',
  },
}));

export const BottomBarContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  zIndex: '3',
  height: '6.5rem',
  paddingRight: '1rem',
  paddingLeft: '5.5rem',
  [theme.breakpoints.down('laptop')]: {
    height: '5.5rem',
    paddingRight: '1.5rem',
    paddingLeft: '6.5rem',
  },
}));

export const Field = styled('div')(({ theme }) => ({
  border: `0.0625rem solid ${theme.palette.secondary.light}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '10px',
  width: '100%',
  height: '2rem',
  padding: '1rem 0.75rem 1rem 0.25rem',
  [theme.breakpoints.down('laptop')]: {
    marginLeft: 0,
    padding: '1rem 0.5rem',
  },
}));

export const TextField = styled('textarea')(({ theme }) => ({
  border: `0.0625rem solid ${theme.palette.secondary.light}`,
  borderRadius: '10px',
  width: '100%',
  maxWidth: '100%',
  marginLeft: '0.5rem',
  marginRight: '0.25rem',
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '22px',
  color: theme.palette.text.primary,
  letterSpacing: '0.25px',
  fontFamily: theme.typography.fontFamily,
  padding: '1rem',
  resize: 'none',
  [theme.breakpoints.down('laptop')]: {
    marginLeft: '0.5rem',
    height: '26px',
  },
}));

export const ErrorMessage = styled('div')(() => ({
  margin: 'auto',
  marginTop: 'inherit',
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../Themes/theme';

export const styles = {
  toolbar: {
    width: '3rem',
    padding: 0,
    justifyContent: 'center',
  },
  logo: {
    width: 'fit-content',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::before': {
      content: 'none',
    },
  },
  icon: {
    color: theme.palette.common.black,
    width: '1.25rem',
    minWidth: '1.25rem',
  },
  iconName: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h3?.fontSize,
    color: theme.palette.text.secondary,
    marginLeft: '0.875rem',
  },
  leftNavBarIcons: {
    margin: '0 0.75rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  divider: {
    border: `0.0625rem solid ${theme.palette.divider}`,
    marginTop: '1rem',
    marginBottom: '2rem',
    width: '100%',
  },
  iconStyle: {
    color: theme.palette.common.black,
    width: '1.5rem',
    minWidth: '1.5rem',
  },
};

export const LogoStyle = styled('div')(() => ({
  marginBottom: '0.5rem',
  padding: '1rem 0',
  cursor: 'pointer',
  ...styles.logo,
}));

export const LeftNavBarField = styled('div')(() => ({
  overflowY: 'auto',
  height: '100%',
}));

export const TextStyle = styled('div')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3?.fontSize,
  color: theme.palette.text.secondary,
  marginLeft: '0.875rem',
  whiteSpace: 'nowrap',
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  color: theme.palette.text.secondary,
  alignItems: 'center',
  columnGap: '1rem',
  '& img': {
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  '> div': {
    borderRadius: '50%',
    backgroundColor: theme.palette.info.contrastText,
    color: theme.palette.common.white,
    minWidth: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.8rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useEffect, useRef, useState } from 'react';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Icons,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableInstance,
  useMaterialReactTable,
  MRT_ColumnFiltersState,
} from 'material-react-table';
import { Box } from '@mui/material';
import { t } from 'i18next';

import { TaskData } from '../../../../Modules/Jobs/Components/WorkTasks/WorkTasks';
import { IJobOverview } from '../../../../Modules/Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../../Modules/Jobs/Components/JobView/JobsView';
import { IBidList } from '../../../../Modules/Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../../Modules/Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import theme from '../../../Themes/theme';
import { Icons } from '../../../Constants/Icons';
import { StyleConstants } from '../../../Constants/Style.Constants';
import { IUpcomingShift } from '../../../../Modules/Jobs/Components/UpcomingShifts/UpcomingShifts.Model';

interface IDataGridProps {
  vendorTypes?: any;
  columns: MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[];
  data: TaskData[] | IJobOverview[] | IJobs[] | IBidList[] | IProfileCardProps[] | IUpcomingShift[];
  onRowChange?: (rowSelection: object, data?: TaskData[] | IJobOverview[]) => void;
  enableRowSelect?: boolean;
  enableColumnPinning?: boolean;
  expanded?: boolean | number;
  leftPinArr: string[];
  rightPinArr: string[];
  enableEditing?: boolean;
  sortingObj?: MRT_SortingState;
  sort?: (sorting: any) => void;
  filter?: (sorting: any) => void;
  sortingFns?: any;
  loader?: boolean;
  errorMessageTitle?: string;
  errorMessageDescription?: string;
  handleSaveCell?: (cell: any, value: string) => void;
  editingMode: 'cell' | 'table' | 'modal' | 'row' | undefined;
  jobType?: string;
}

const CustomIcons: Partial<MRT_Icons> = {
  MoreVertIcon: () => <img src={Icons.SortingIcon} alt={t('altTexts:more')} />,
};

/**
 *
 * @returns DataGrid for Given Data
 */
const DataGrid = ({
  columns,
  data,
  onRowChange,
  expanded,
  enableEditing,
  sort,
  filter,
  loader,
  editingMode,
}: IDataGridProps) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  const tableInstanceRef = useRef<MRT_TableInstance<IJobOverview | TaskData | IJobs>>(null);

  useEffect(() => {
    setRowSelection({});
  }, [data, expanded]);

  useEffect(() => {
    // rowSelection having current selected state value through that we triggered onRowChange
    if (onRowChange && Object.keys(rowSelection)?.length) {
      onRowChange(rowSelection);
    }
  }, [rowSelection]);

  useEffect(() => {
    filter?.(columnFilters);
  }, [columnFilters]);

  useEffect(() => {
    sort?.(sorting);
  }, [sorting]);

  const handleRow = (value: any) => {
    setRowSelection(value);
    // ref instance having previous selected state value through that we triggered onRowChange
    // reason: once we unchecked select all option then rowSelection obj is empty so we can't hide approve and reject button
    const rowSelect = tableInstanceRef?.current?.getState().rowSelection;
    const PreviouslySelectedRowLen = rowSelect && Object.keys(rowSelect)?.length;
    const currentSelectedRowLen = Object.keys(value())?.length;
    if (
      onRowChange &&
      ((PreviouslySelectedRowLen && currentSelectedRowLen > 1) ||
        ((PreviouslySelectedRowLen === 1 || PreviouslySelectedRowLen === data?.length) && currentSelectedRowLen === 0))
    ) {
      onRowChange(value);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    // enableRowSelection: true, //table options as options to this hook
    enablePagination: false,
    manualPagination: true,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    editDisplayMode: editingMode,
    enableEditing: enableEditing,
    enableColumnActions: false,
    columnFilterDisplayMode: 'popover',
    enableStickyHeader: true,
    manualFiltering: true,
    onColumnFiltersChange: (filters) => {
      setColumnFilters(filters);
    },
    state: { sorting, rowSelection, isLoading: loader, columnFilters },
    muiTableHeadCellProps: {
      sx: {
        border: `0.03125rem solid ${theme.palette.divider}`,
        padding: '0 0.75rem',
        paddingTop: 0,
        paddingBottom: 0,
        boxSizing: 'border-box',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.h4?.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.primary,
        lineHeight: '1.5rem',
        letterSpacing: StyleConstants.LetterSpacingTight,
        height: '4.5rem',
        verticalAlign: 'middle',
        [theme.breakpoints.down('laptop')]: {
          fontSize: theme.typography.h5?.fontSize,
          lineHeight: '1.375rem',
          letterSpacing: '0.015625rem',
        },
        '& .MuiCheckbox-root:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
        '& .Mui-TableHeadCell-Content': {
          justifyContent: 'space-between',
          '& .MuiIconButton-root': {
            opacity: 1,
          },
          '& .MuiCheckbox-indeterminate': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: `0.03125rem solid ${theme.palette.divider}`,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.h4?.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '1.5rem',
        letterSpacing: StyleConstants.LetterSpacingTight,
        color: theme.palette.text.secondary,
        height: '4rem',
        boxSizing: 'border-box',
        padding: '0 0.75rem',
        zIndex: 0,
        [theme.breakpoints.down('laptop')]: {
          fontSize: theme.typography.h5?.fontSize,
          lineHeight: '1.375rem',
          letterSpacing: '0.015625rem',
          height: '3rem',
        },
        '& .MuiCheckbox-root:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
        '& .MuiCheckbox-root.Mui-disabled': {
          filter: 'opacity(0.5)',
        },
      },
    },
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 320px)' } },
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        '&.Mui-selected': {
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
          backgroundColor: theme.palette.secondary.dark,
        },
      },
    },
    muiSelectCheckboxProps: {
      checkedIcon: <img src={Icons.CheckedIcon} alt={t('altTexts:checked')} />,
      icon: <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} />,
    },

    muiSelectAllCheckboxProps: {
      checkedIcon: <img src={Icons.CheckedIcon} alt={t('altTexts:checked')} />,
      icon: <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} />,
    },
    muiSearchTextFieldProps: {
      placeholder: 'Search all users',
      sx: { minWidth: '300px' },
      variant: 'outlined',
    },

    muiFilterTextFieldProps: {
      sx: {
        color: 'red',
        '& .MuiInputBase-root': {
          borderRadius: '1rem',
          border: `0.0625rem solid ${theme.palette.primary.dark}`,
          '&:hover:not(.Mui-disabled, .Mui-error)': {
            '&::before': {
              borderBottom: 'none',
            },
          },
          '&::before,&::after': {
            borderBottom: 'none',
          },
        },
        '& .MuiInputBase-input': {
          paddingLeft: '0.625rem',
          '&::placeholder': {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h4?.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '1.5rem',
            letterSpacing: StyleConstants.LetterSpacingTight,
            color: theme.palette.text.secondary,
          },
        },
        '& .MuiIconButton-root': {
          color: theme.palette.primary.dark,
          marginRight: '0.25rem',
          '&.Mui-disabled': {
            color: theme.palette.primary.dark,
          },
        },
      },
      onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          (event.target as HTMLInputElement).blur();
        }
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    manualSorting: true,
    onSortingChange: (ar) => {
      setSorting(ar);
    },
    muiCircularProgressProps: {
      sx: {
        color: theme.palette.primary.dark,
      },
    },
    onRowSelectionChange: handleRow,
    icons: CustomIcons,
    enableFilterMatchHighlighting: false,
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <Box style={{ position: 'relative', opacity: loader ? 0.5 : 1 }}>
        <MaterialReactTable table={table} />
      </Box>
    </Box>
  );
};

DataGrid.defaultProps = {
  enableRowSelect: true,
  enableColumnPinning: true,
  leftPinArr: '',
  rightPinArr: 'Action',
  enableEditing: false,
  editingMode: 'table',
};

export default DataGrid;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { mdAttachmentType } from '../../../API';

export interface IServiceCategoryItem {
  categoryId: string;
  categoryName: string;
  unit: string;
}

export interface IFacilitiesItem {
  id: string;
  facilityName: string;
  facilityId: string;
  mdFacilityType: string;
  facilityError: string;
  facilityAddress: string;
}

export interface IServiceItem {
  name: string | undefined;
  id: string;
  service: string;
  quantity: string;
  unit: string;
  mdServiceId: string;
  nameError: string;
  asError: string;
  tasks: string;
  tasksError: string;
}

export interface IFacilityMenuOption {
  jobName: string;
  facilityId: string;
  buildingName: string;
  mdFacilityType: string;
  customerId: string;
}

export interface RecommendedJobsResponse {
  data: any[];
}

export interface FormValues {
  startDate: null | string;
  endDate: null | string;
  startTime: string;
  endTime: string;
}

export interface ITag {
  owner: string;
  type: string;
  value: string;
  id: string;
  family: string;
}

export enum FieldNames {
  startDate = 'startDate',
  startTime = 'startTime',
  endTime = 'endTime',
  endDate = 'endDate',
  jobName = 'jobName',
  instructions = 'instructions',
  bidDueDate = 'bidDueDate',
  searchedVendors = 'searchedVendors',
  shiftStartDate = 'shiftStartDate',
  shiftEndDate = 'shiftEndDate',
  dateFormat = 'YYYY-MM-DD',
  punchInDate = 'punchInDate',
  punchInTime = 'punchInTime',
  punchOutDate = 'punchOutDate',
  punchOutTime = 'punchOutTime',
}

export enum AddUserName {
  fullName = 'name',
  email = 'email',
  roleId = 'roleId',
  phoneNum = 'phoneNumber',
}

export interface IVendor {
  vendorName: string;
  vendorId: string;
  index: number;
  email: string;
}

export interface IDetailsProps {
  attachmentId?: string;
  comments?: string;
  fileName?: string;
  id: string;
  mdAttachmentType?: mdAttachmentType;
  url?: string;
  fileExtension?: string;
}
export interface ITagsProps {
  id?: string;
  tags?: string[];
}

export interface ISelectedFacility {
  address: {
    addressId: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    addressLine3: string | null;
    addressName: string | null;
    city: string | null;
    county: string | null;
    postalCode: string;
  };
  customerId: string;
  facilityId: string;
  intCustomerNumber: number;
  jobDescription: string;
  jobNumber: string;
  mdCountryCode: string;
  regionId: string;
  stateCode: string;
  subRegionId: string;
  userId: string;
  vendorId: string;
  roleId: string;
  mdFacilityType: string;
  buildingName: string;
}

export interface IMultiSelectDropdown {
  label: string;
  isMandatory: boolean;
  dropdownValues: any;
  defaultValues?: string[];
  onSelect: (data: string[]) => void;
  error?: string;
  showSelectAll?: boolean;
}

export interface ICustomDropdownIconProps {
  isOpen: boolean;
  Icons: {
    DropdownUpIcon: string;
    DropdownDownIcon: string;
  };
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const AwardCardContainer = styled('div')(() => ({
  display: 'flex',
  gap: '5%',
  flexWrap: 'wrap',
}));

export const AwardJobCard = styled('div')(({ theme }) => ({
  display: 'grid',
  maxWidth: '30%',
  minWidth: '30%',
  backgroundColor: theme.palette.common.white,
  borderRadius: '1em',
  marginBottom: '2em',
  padding: '20x',
  [theme.breakpoints.down('laptop')]: {
    maxWidth: '40%',
    minWidth: '40%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '1em',
    marginBottom: '2em',
  },
}));

export const Label = styled('span')(({ theme }) => ({
  fontSize: theme.typography.h5?.fontSize,
}));

export const LoaderTag = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '200px',
  margin: 'auto',
}));

export const NoDataContainer = styled('div')(() => ({
  margin: 'auto',
  marginTop: '6%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const NoDataTag = styled('div')(() => ({
  color: theme.palette.text.disabled,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5?.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  padding: '0.5rem 0 3rem 0',
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { DialogTitle, DialogContent, DialogActions, Button, Box, Typography, CircularProgress } from '@mui/material';
import { ApprovalText, buttonStyles, LoginDialogWrapper, TaskDetails } from './TaskDetailsPage.Style';
import theme from '../../../../Shared/Themes/theme';
import { Icons } from '../../../../Shared/Constants/Icons';

interface IApproveDialogProps {
  handleCloseApproveWindow: () => void;
  isApproveWindowOpen: boolean;
  rowDetails?: any;
  isShowApproveAll?: boolean;
  handleApprove: () => void;
  ChildComponent?: React.ReactNode | null;
  title?: string;
  showCancelButton?: boolean;
  loading?: boolean;
  t?: any;
  isShowCloseIcon?: boolean;
}

const ApproveDialog = ({
  isApproveWindowOpen,
  handleCloseApproveWindow,
  handleApprove,
  isShowApproveAll,
  rowDetails,
  ChildComponent,
  title = 'Approve work task',
  loading,
  t,
  isShowCloseIcon,
}: IApproveDialogProps) => {
  const { closeIconStyle, rejectHeaderText, buttonText, logoutButton } = buttonStyles;

  return (
    <LoginDialogWrapper
      open={isApproveWindowOpen}
      onClose={() => {
        if (isShowCloseIcon) {
          handleCloseApproveWindow();
        }
      }}>
      {isShowCloseIcon && (
        <Box sx={closeIconStyle} onClick={handleCloseApproveWindow}>
          <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
        </Box>
      )}
      <DialogTitle id="alert-dialog-title" sx={rejectHeaderText}>
        {title}
      </DialogTitle>
      <DialogContent>
        {ChildComponent ? (
          ChildComponent
        ) : (
          <ApprovalText id="alert-dialog-description">
            {isShowApproveAll ? (
              `${t('dashboard:approveRows')}`
            ) : (
              <>
                `${t('dashboard:approve')}`{' '}
                {rowDetails.taskName ? <TaskDetails>{rowDetails.taskName}</TaskDetails> : null}
              </>
            )}
          </ApprovalText>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            padding: '0.81rem',
          }}>
          <Button
            sx={logoutButton}
            onClick={() => {
              if (!loading) {
                handleApprove();
              }
            }}>
            {loading ? (
              <CircularProgress size={30} sx={{ color: 'white' }} />
            ) : (
              <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>{t('logout:logout')}</Typography>
            )}
          </Button>
        </Box>
      </DialogActions>
    </LoginDialogWrapper>
  );
};

ApproveDialog.defaultProps = {
  rowDetails: '',
  isShowApproveAll: false,
  isShowCloseIcon: true,
};

export default withTranslation(['dashboard', 'logout', 'altTexts'])(ApproveDialog);

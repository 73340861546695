/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export enum FeatureKeys {
  AccessPreShiftCheckList = 'AccessPreShiftCheckList',
  AssociateServiceFilters = 'AssociateServiceFilters',
  EnableAll = 'EnableAll',
  EnableFacilityAccess = 'EnableFacilityAccess',
  EnableGeoFence = 'EnableGeoFence',
  InviteVendor = 'InviteVendor',
  ServiceCategoryV2 = 'ServiceCategoryV2',
  ShowAssociates = 'ShowAssociates',
  Subcontractor = 'Subcontractor',
  UseOtp = 'UseOtp',
  VendorCOIHome = 'VendorCOIHome',
}

export type FeatureMap = { [state in FeatureKeys]: boolean };

export const defaultFeatures: FeatureMap = {
  [FeatureKeys.AccessPreShiftCheckList]: true,
  [FeatureKeys.EnableAll]: false,
  [FeatureKeys.VendorCOIHome]: false,
  [FeatureKeys.ServiceCategoryV2]: true,
  [FeatureKeys.InviteVendor]: false,
  [FeatureKeys.AssociateServiceFilters]: false,
  [FeatureKeys.UseOtp]: false,
  [FeatureKeys.Subcontractor]: true,
  [FeatureKeys.EnableGeoFence]: true,
  [FeatureKeys.ShowAssociates]: false,
  [FeatureKeys.EnableFacilityAccess]: false,
};

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
enum AsyncStorageKeys {
  AddFacilityInfo = 'AddFacilityInfo',
  AssociateInfo = 'AssociateInfo',
  AssociateWorkLocationEnterAndExit = 'AssociateWorkLocationEnterAndExit',
  BidCreate = 'BidCreate',
  ConfirmPassword = 'ConfirmPassword',
  CustomerCompanyInfo = 'CustomerCompanyInfo',
  DeepLinkingUrl = 'DeepLinkingUrl',
  DurationDataOfJob = 'DurationDataOfJob',
  HideUpdateProfile = 'HideUpdateProfile',
  IsLocationPermissionDenied = 'IsLocationPermissionDenied',
  JobCreate = 'JobCreate',
  JobTime = 'JobTime',
  KilledScreen = 'KilledScreen',
  Language = 'Language',
  Locale = 'Locale',
  NAICSCode = 'NAICSCode',
  ServiceBreakTime = 'ServiceBreakTime',
  ServiceCategories = 'serviceCategories',
  TimerId = 'TimerId',
  Timezone = 'Timezone',
  UserRoles = 'UserRoles',
  Username = 'username',
  VendorCompanyInfo = 'VendorCompanyInfo',
  VendorLogoutInformation = 'VendorLogoutInformation',
  WorkOrderDetails = 'WorkOrderDetails',
  viewJob = 'viewJob',
  currentJob = 'currentJob',
  uploadQuote = 'uploadQuote',
}

export default AsyncStorageKeys;

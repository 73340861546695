/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useState } from 'react';
import InfoWindow from './InfoWindow';
import { useTranslation } from 'react-i18next';
import { Icons } from '../../../Constants/Icons';

import { FilterType } from './Marker.Model';

interface IMarkerProps {
  type: FilterType;
  chipType?: FilterType;
  logoUrl: string;
  addressDetails: any;
  facilityId: string;
  data: any;
  lat: number;
  lng: number;
  selectedDate: any;
  workOrderId?: string | null;
  jobId?: string | null;
  customerId?: string | null;
}

const Marker = ({
  type,
  data,
  facilityId,
  addressDetails,
  logoUrl,
  selectedDate,
  chipType,
  workOrderId,
  jobId,
  customerId,
}: IMarkerProps) => {
  const { t } = useTranslation(['altTexts']);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [iconSize, setIconSize] = useState({ width: '2rem', height: '2rem' });
  const [facilityIconSize, setFacilityIconSize] = useState({ width: '25px', height: '25px' });

  const transition = 'all 0.2s ease';

  const handleMarkerClick = () => {
    setShowInfoWindow(true);
    setIconSize({ width: '3rem', height: '3rem' });
    setFacilityIconSize({ width: '2.375rem', height: '2.375rem' });
  };

  const handleCloseClick = () => {
    setShowInfoWindow(false);
    setIconSize({ width: '2rem', height: '2rem' });
    setFacilityIconSize({ width: '1.62rem', height: '1.62rem' });
  };

  const { buildingName = '', address, scheduled, completed, inProgress, noShow, noJob } = addressDetails || {};

  const renderStatusIcon = () => {
    return (
      <>
        {type === FilterType.Scheduled && chipType !== FilterType.All && (
          <img
            src={Icons.PendingReviewsMapIcon}
            alt={t('altTexts:scheduled')}
            style={{ ...iconSize, transition: transition }}
          />
        )}
        {type === FilterType.NoShow && chipType !== FilterType.All && (
          <img src={Icons.NoShowMapIcon} alt={t('altTexts:missed')} style={{ ...iconSize, transition: transition }} />
        )}
        {type === FilterType.InProgress && chipType !== FilterType.All && (
          <img
            src={Icons.InprogressMapIcon}
            alt={t('altTexts:inprogress')}
            style={{ ...iconSize, transition: transition }}
          />
        )}
        {type === FilterType.Completed && chipType !== FilterType.All && (
          <img
            src={Icons.ApprovedCustomerMap}
            alt={t('altTexts:complete')}
            style={{ ...iconSize, transition: transition }}
          />
        )}
        {type === FilterType.NoJob && chipType !== FilterType.All && (
          <img
            src={Icons.InCompleteMapIcon}
            alt={t('altTexts:noJob')}
            style={{ ...iconSize, transition: transition }}
          />
        )}
        {type === FilterType.PendingReview && (
          <img
            src={Icons.PendingReviewsMapIcon}
            alt={t('altTexts:pending')}
            style={{ ...iconSize, transition: transition }}
          />
        )}
        {type === FilterType.Approved && (
          <img
            src={Icons.ApprovedCustomerMap}
            alt={t('altTexts:approved')}
            style={{ ...iconSize, transition: transition }}
          />
        )}
        {chipType === FilterType.All && (
          <img
            src={Icons.FacilityMapIcon}
            alt={t('altTexts:facilities')}
            style={{ ...facilityIconSize, transition: transition }}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '0.3125rem',
        }}
        onClick={handleMarkerClick}>
        {renderStatusIcon()}
      </div>
      {showInfoWindow && (
        <InfoWindow
          facilityId={facilityId}
          logoUrl={logoUrl}
          onClose={handleCloseClick}
          facilityName={buildingName}
          facilityAddress={address}
          tasks={data}
          // type={type}
          selectedDate={selectedDate}
          // chipType={chipType}
          scheduled={scheduled}
          completed={completed}
          inProgress={inProgress}
          noShow={noShow}
          noJob={noJob}
          workOrderId={workOrderId}
          jobId={jobId}
          customerId={customerId}
        />
      )}
    </div>
  );
};

export default Marker;

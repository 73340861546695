/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';

import { Labels } from '../../../../Shared/Components/Common/GraphContainer/GraphContainer';
import { NumberOfRowsOptions, ActionTypes } from '../../../../Shared/Constants/App';

import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../Jobs/Components/JobView/JobsView';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';

import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';

import { Icons } from '../../../../Shared/Constants/Icons';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';
import { DropDown, SelectInput } from '../../../../Shared/Components/Common/GraphContainer/GraphContainer.styles';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../../../Shared/Utilities/utils';

interface SortingState {
  id: string;
  desc: boolean;
}

interface DataGridComponentProps {
  columns: MRT_ColumnDef<IJobOverview | TaskData | IBidList | IProfileCardProps | IJobs>[];
  data: TaskData[] | IJobOverview[] | IJobs[] | IBidList[] | IProfileCardProps[];
  sorting: SortingState[];
  isLoading: boolean | undefined;
  handleSort: ((sorting: any) => void) | undefined;
  myCustomSortingFn: any;
  handleChange: (event: ChangeEvent<unknown>, value: number) => void;
  totalCount: number;
  handleApi: (pageNumber: number, limit?: number, sortBy?: string, sortOrder?: boolean) => void;
  currentPage: number | undefined;
  newSortOrder: any;
  newSort: boolean | undefined;
  sortBy: any;
  itemsPerPage: number;
  setItemsPerPage: any;
  filterJobs: (filtersObj: any) => void;
}

const DataGridComponent = ({
  columns,
  data,
  sorting,
  isLoading,
  handleSort,
  myCustomSortingFn,
  handleChange,
  totalCount,
  handleApi,
  currentPage,
  newSortOrder,
  newSort,
  itemsPerPage,
  setItemsPerPage,
  filterJobs,
}: DataGridComponentProps): JSX.Element => {
  const { t } = useTranslation(['vendor', 'tableHeader', 'altTexts', 'vendorBidCreation', 'jobAward']);
  const [isValueOpen, setValueOpen] = useState(false);

  const toggleValue = () => {
    setValueOpen(!isValueOpen);
  };

  const handleItemsPerPageChange = (event: SelectChangeEvent<number>) => {
    const newItemsPerPage = Number(event.target.value);
    setItemsPerPage(newItemsPerPage);
    handleApi(1, newItemsPerPage, newSortOrder, newSort);
  };

  return (
    <>
      <DataGrid
        columns={columns}
        data={data}
        enableRowSelect={false}
        enableColumnPinning={false}
        sortingObj={sorting}
        loader={isLoading}
        sort={handleSort}
        sortingFns={{
          myCustomSortingFn: myCustomSortingFn,
        }}
        errorMessageTitle={t(ActionTypes.NoDataYet)}
        errorMessageDescription={t(ActionTypes.NoDataComeBack)}
        filter={filterJobs}
      />
      <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
        {!isLoading && totalCount > NUMBER_OF_ITEMS_PER_PAGE ? (
          <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
            <StyledPagination
              count={Math.ceil(totalCount / itemsPerPage)}
              page={currentPage}
              onChange={(event, value) => {
                handleChange(event, value);
                if (newSortOrder) {
                  handleApi(value, itemsPerPage, newSortOrder, newSort);
                } else {
                  handleApi(value, itemsPerPage, '', false);
                }
              }}
              color="primary"
            />
          </Stack>
        ) : null}
        {!isLoading && (
          <Stack
            spacing={2}
            justifyContent="center"
            sx={{
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'row',
              gap: '3rem',
            }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: '1rem',
              }}>
              {t('dashboard:totalItems')}: {totalCount}
            </Typography>
            {totalCount > NUMBER_OF_ITEMS_PER_PAGE && (
              <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <Typography>{t('dashboard:recordsPerPage')}:</Typography>
                <DropDown>
                  <Box onClick={toggleValue}>
                    <Select
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                      label="Records"
                      input={<SelectInput />}
                      IconComponent={() => (
                        <img
                          src={Icons.DropdownIcon}
                          style={{
                            transform: isValueOpen ? Labels.Rotate : 'none',
                            position: 'absolute',
                            right: '0.75rem',
                          }}
                        />
                      )}
                      open={isValueOpen}
                      onClose={toggleValue}
                      onOpen={toggleValue}>
                      {NumberOfRowsOptions.map((dataItem) => (
                        <Options key={dataItem.id} value={dataItem.value}>
                          {dataItem.label}
                        </Options>
                      ))}
                    </Select>
                  </Box>
                </DropDown>
              </Box>
            )}
          </Stack>
        )}
      </Box>
    </>
  );
};

export default DataGridComponent;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useState } from 'react';
import { ListItemButton, ListItemIcon, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';

import { AuthenticationService } from '../../../Services/AuthenticationService';
import { UserAuthEventInput } from '../../../../API';
import { CognitoUserAuthenticationStatus } from '../../../Constants/App';
import { getAuthData } from '../../../Utilities/utils';

import ApproveDialog from '../../../../Modules/Jobs/Components/TaskDetails/logoutDialog';
import LogoutDialog from './LogoutDialog';

import { Icons } from '../../../Constants/Icons';
import { TextStyle, styles } from './LeftNavBar.styles';

interface ILogoutDialogProps {
  isSideBarVisible: boolean;
  theme: any;
  isTablet: boolean;
}

const Logout: React.FC<ILogoutDialogProps> = ({ isSideBarVisible, theme, isTablet }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(['logout']);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const signOut = useCallback(async () => {
    try {
      setLoading(true);
      const { userId } = getAuthData();
      const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);
      const loggedInUserId = userDetails?.data?.userId ?? '';
      const currentDeviceDate = new Date()?.toJSON();
      const userAuthEventInput: UserAuthEventInput = {
        authEventType: CognitoUserAuthenticationStatus.SignOut,
        authEventTimeStamp: currentDeviceDate,
        userId: loggedInUserId,
      };
      await AuthenticationService.createUserAuthEvent(userAuthEventInput);
      await Auth.signOut();
      localStorage.removeItem('auth');
      localStorage.removeItem('customerDetails');
      navigate('/login');
    } catch (error) {
      console.error('An error occurred during sign out:', error);
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  return (
    <>
      <ListItemButton
        onClick={handleClickOpen}
        sx={{
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
          },
          padding: isTablet ? '0.5rem 0.75rem' : '1rem',
          borderRadius: isTablet ? '0.5rem' : '1rem',
          marginBottom: '0.5rem',
          alignItems: isSideBarVisible ? 'flexStart' : 'center',
          justifyContent: isSideBarVisible ? 'flexStart' : 'center',
          width: '100%',
        }}>
        <ListItemIcon
          sx={{
            ...styles.iconStyle,
          }}>
          <img src={Icons.LogoutIcon} alt={t('altTexts:logout')} />
        </ListItemIcon>
        {isSideBarVisible && <TextStyle>{t('logout:logout')}</TextStyle>}{' '}
      </ListItemButton>
      <Divider
        sx={{
          ...styles.divider,
        }}
      />
      <ApproveDialog
        title="Logout"
        loading={loading}
        ChildComponent={<LogoutDialog />}
        isApproveWindowOpen={open}
        showCancelButton={false}
        handleCloseApproveWindow={() => setOpen(false)}
        handleApprove={signOut}
      />
    </>
  );
};

export default Logout;

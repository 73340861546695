/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { t } from 'i18next';
import moment, { Moment } from 'moment';
import { JobStatus as JobStatues, WorkOrderService } from '../../API';
import FacilityApiService from '../../Modules/Facility/Services/FacilityApiService';
import {
  IPAddressAPI,
  Meridiem,
  PlatformType,
  S3BucketLevel,
  UserType,
  XMLHttpRequestResponseType,
} from '../Constants/App';
import { Storage } from 'aws-amplify';
import { isEmpty } from 'lodash';
import { AddUserName, FieldNames } from '../../Modules/Jobs/Models/AddEditOneTimeJob.Model';
import { v4 as uuidv4 } from 'uuid';
import { Auth } from '@aws-amplify/auth';
import { FilterItem } from '../../Modules/Jobs/Components/JobsListView/JobsListView';
import { GetAllBids, GetAllJobs } from '../../Modules/Vendor/Models/Marketplace.model';

export const isMock = false;

interface ITimeFormateResponse {
  hours: number;
  meridiem: string;
  mins?: number;
  sec: number;
  formattedMins: string | number;
}

/**
 * @param startDate
 * @param endDate
 * @returns It will return based on given dates are same or after
 */
export const isDateSameOrAfter = (startDate?: string, endDate?: string) => {
  if (startDate && endDate) {
    const formattedStartDate = moment(startDate).format(FieldNames.dateFormat);
    const formattedEndDate = moment(endDate).format(FieldNames.dateFormat);
    return moment(formattedEndDate).isSameOrAfter(formattedStartDate);
  }
  return false;
};

export const isDatesSameOrAfter = (startDate: Moment, endDate: Moment) => {
  const formattedStartDate = startDate ? startDate.format(FieldNames.dateFormat) : '';
  const formatteEndDate = endDate ? endDate.format(FieldNames.dateFormat) : '';
  if (startDate && endDate) {
    return moment(formatteEndDate).isSameOrAfter(formattedStartDate);
  }
  return false;
};

/**
 * @param startDate
 * @param endDate
 * @returns It will return based on given dates are same or before
 */
export const isDateSameOrBefore = (startDate?: string, endDate?: string) => {
  if (startDate && endDate) {
    const formattedStartDate = moment(startDate).format(FieldNames.dateFormat);
    const formattedEndDate = moment(endDate).format(FieldNames.dateFormat);
    return moment(formattedEndDate).isSameOrBefore(formattedStartDate);
  }
  return false;
};

/**
 * @param startDate
 * @param endDate
 * @returns It will return based on given dates are before
 */
export const isDateBefore = (startDate?: string, endDate?: string) => {
  if (startDate && endDate) {
    const formattedStartDate = moment(startDate).format(FieldNames.dateFormat);
    const formattedEndDate = moment(endDate).format(FieldNames.dateFormat);
    return moment(formattedStartDate).isBefore(formattedEndDate);
  }
  return false;
};

/**
 * @param startDate
 * @param endDate
 * @returns It will return based on given dates are after
 */
export const isDateAfter = (startDate?: string, endDate?: string) => {
  if (startDate && endDate) {
    const formattedStartDate = moment(startDate).format(FieldNames.dateFormat);
    const formattedEndDate = moment(endDate).format(FieldNames.dateFormat);
    return moment(formattedStartDate).isAfter(formattedEndDate);
  }
  return false;
};

/**
 * @returns It will return time in 24 hours format
 */
export const hours = Array.from({ length: 48 }, (_, hour) =>
  moment({
    hour: Math.floor(hour / 2),
    minutes: hour % 2 === 0 ? 0 : 30,
  }).format('HH:mm')
);

export const getStoredCustomerDetails = () => {
  try {
    const value = localStorage.getItem('customerDetails');

    if (value) {
      const parsedValue = JSON.parse(value);

      if (parsedValue && typeof parsedValue === 'object' && !Array.isArray(parsedValue)) {
        const { organization, userId } = parsedValue;

        if (organization !== null) {
          const { organizationId = '', organizationName = '' } = organization;

          if (organizationId && organizationName) {
            return { customerId: organizationId, organizationName: organizationName, userId };
          }
        }
      }
    }
  } catch (error) {
    console.error('Error parsing stored customer details:', error);
  }

  return { customerId: '', organizationName: '' };
};

export const getAuthDetails = () => {
  const value = localStorage.getItem('auth');
  const parsedValue = value && JSON.parse(value);
  if (parsedValue) {
    return { ...parsedValue?.attributes };
  }
  return {};
};

export const getAcronymName = (name: string) => {
  const words = name?.split(' ');
  if (!name) {
    return '';
  }
  if (words.length >= 2) {
    const acronym = words.map((word: string) => word.charAt(0)).join('');
    return acronym.toUpperCase();
  } else if (name.length < 5) {
    return name;
  } else {
    const abbreviation = name.substring(0, 4);
    return abbreviation.toUpperCase();
  }
};
const dayText = t('jobAward:day');
const daysText = t('jobAward:days');

export const getTimeFromGivenDate = (date: string, is24Hours = false): ITimeFormateResponse => {
  const now = moment(date);
  const hours = now.hour();
  const mins = now.minutes();
  const formattedMins = mins < 10 ? `0${mins}` : mins.toString();
  const sec = now.seconds();
  const meridiem = now.hour() >= 12 ? Meridiem.PM : Meridiem.AM;
  const hours24Formate = is24Hours ? hours : hours % 12 || 12;

  return {
    hours: hours24Formate,
    formattedMins,
    sec,
    meridiem,
  };
};

// `returnDurationInDays` returns the difference between start and stop date in number of days format
export const returnDurationInDays = (_startDate: Moment, _stopDate: Moment) => {
  return parseInt(_stopDate.diff(_startDate, 'days', true)?.toString(), 10);
};

export const getDurationDaysLeft = (endDate: Moment) => {
  const currentDate = moment(new Date().toJSON());
  if (1 / returnDurationInDays(currentDate, endDate) === -Infinity) {
    return 0;
  }
  if (returnDurationInDays(currentDate, endDate) >= 0) {
    return returnDurationInDays(currentDate, endDate) + 1;
  }
  return returnDurationInDays(currentDate, endDate);
};

// getDurationInDaysOrMonths returns the difference between start(Moment) and stop(Moment) date in number of days or months or both format including stop date
export const getDurationInDaysOrMonths = (startDate: string, stopDate: string, isExcludeCurrentDate?: boolean) => {
  const date1 = moment(startDate);
  const date2 = moment(stopDate);
  const months = moment(stopDate).diff(startDate, 'months');
  date1.add(months, 'months');
  const days = isExcludeCurrentDate ? getDaysBtnTwoDates(date1, date2) - 1 : getDaysBtnTwoDates(date1, date2);

  if (months === 0 && days === 0) return t('vendorBidCreation:todayLeft');

  if (days < 0 || months < 0) {
    return 'Negative';
  }
  if (days === 0) {
    return `${months} ${t('vendorBidCreation:months')}`;
  }
  if (months === 0) {
    return `${days} ${days === 1 ? dayText : daysText}`;
  }
  return `${months} ${months === 1 ? t('vendorBidCreation:month') : t('vendorBidCreation:months')} ${days} ${
    days === 1 ? dayText : daysText
  }`;
};

export const formatDaysLeft = (days: number) => {
  const isOverdue = days && days < 0;
  const totalDays = Math.abs(days);
  if (isOverdue) {
    return t('vendor:jobOverdue');
  } else {
    return `${totalDays ?? 0} ${totalDays === 1 || totalDays < 1 ? dayText : daysText}`;
  }
};

// returnDurationInDaysByMoment returns the difference between start(Moment) and stop(Moment) date in number of days format
export const returnDurationInDaysByMoment = (startDate: Moment, stopDate: Moment) => {
  return parseInt(stopDate.diff(startDate, 'days', true).toString(), 10);
};

// getDaysBtnTwoDates returns the difference between start(Moment) and stop(Moment) date in number of days format including stop date
export const getDaysBtnTwoDates = (startDate: Moment, endDate: Moment) => {
  if (1 / returnDurationInDaysByMoment(startDate, endDate) === -Infinity) {
    return 0;
  }
  if (returnDurationInDaysByMoment(startDate, endDate) >= 0) {
    return returnDurationInDaysByMoment(startDate, endDate) + 1;
  }
  return returnDurationInDaysByMoment(startDate, endDate);
};

export enum BidAwardType {
  Award = 'Awarded',
  Closed = 'Close for bids',
  Completed = 'Completed',
  Delayed = 'Delayed',
  InProgress = 'InProgress',
  Open = 'Open for bids',
  Priority = 'Priority',
}

export enum WorkOrderStatus {
  Created = 'Created',
  Accepted = 'Accepted',
  Started = 'Started',
  Expired = 'Expired',
  InProgress = 'In Progress',
  OnHold = 'On Hold',
  Completed = 'Completed',
  Rejected = 'Rejected',
  InComplete = 'In Complete',
}

export enum DraftStatus {
  Awarded = 'C',
  Bids = 'A',
  Drafts = 'D',
}

export enum JobDetails {
  Self = 'self',
  SubVendor = 'subVendor',
  EstimateWithinPeazy = 'estimateWithinPeazy',
  EstimateOutsidePeazy = 'estimateOutsidePeazy',
  All = 'All',
}

export enum JobExecution {
  Self = 'self',
  SubVendor = 'SubVendor',
  EstimateWithinPeazy = 'estimateWithinPeazy',
  EstimateOutsidePeazy = 'estimateOutsidePeazy',
  All = 'All',
}

export enum AssignmentCardType {
  ShiftTimings = 'shiftTimings',
  AssignTeam = 'assignTeam',
  AssignSupervisor = 'assignSupervisor',
  AssignAssociate = 'assignAssociate',
  SupervisorPunchIn = 'supervisorPunchIn',
  FacilityAccess = 'facilityAccess',
  ProofOfCompletionTasks = 'proofOfCompletionTasks',
  PreShiftChecklist = 'preShiftChecklist',
  ModifyQuote = 'modifyQuote',
}

export enum AccessOptions {
  AccessPin = 'Access pin',
  ContactPerson = 'Contact Person',
  KeyCard = 'Key card',
  LockboxWithKey = 'Lockbox with key',
}

export enum UserRoles {
  AccountHolder = 'AccountHolder',
  Admin = 'Admin',
  Associate = 'Associate/Technician',
  DistrictManager = 'DistrictManager',
  NonAdmin = 'NonAdmin',
  OTJAdmin = 'OTJAdmin',
  RegionalManager = 'RegionalManager',
  SubRegionalManager = 'Sub-RegionalManager',
  Supervisor = 'Supervisor',
}

export enum JobSize {
  Large = 'L',
  Medium = 'M',
  Small = 'S',
}

// `getJobStatus` returns the job status based on the type and draft status accordingly
export const getJobStatus = (
  _bidDueDate: Moment,
  _startDate: Moment,
  _endDate: Moment,
  dataStatus: string,
  draftStatus: string
) => {
  if ((dataStatus as JobStatues) === JobStatues.Awarded) return 'Awarded';
  if ((draftStatus as DraftStatus) === DraftStatus.Bids) {
    if (getDurationDaysLeft(_bidDueDate) >= 0) return 'Open for Bids';
    if (getDurationDaysLeft(_bidDueDate) < 0) return 'Closed for Bids';
  }
  if ((draftStatus as DraftStatus) === DraftStatus.Drafts) return 'Draft Saved';
  return '';
};

// `blobToData` converts the blob to image url
const blobToData = (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const getFacilityIcon = async (logoUrl: string) => {
  let facilityIcon;
  try {
    const facilityIconBlob = await FacilityApiService.facilityIcon(logoUrl);
    if (facilityIconBlob?.data) {
      facilityIcon = await blobToData(facilityIconBlob.data);
    }

    return facilityIcon;
  } catch (e) {
    console.error('Error in fetching facility icon', e);
    return '';
  }
};

export const getAcronym = (name: string) => {
  if (name) {
    return name
      .split(' ')
      .slice(0, 2)
      .map((word) => word.charAt(0).toUpperCase())
      .join('');
  }
  return name;
};

/**
 * @param taskData
 * @returns group Objects based on given ID
 */
export const groupById = (taskData: any, groupBy: any) => {
  return taskData?.reduce(function (updatedResult: any, individualFacility: any) {
    // based on facilityid grouping the object
    updatedResult[individualFacility[groupBy]] = updatedResult[individualFacility[groupBy]] || [];
    updatedResult[individualFacility[groupBy]].push(individualFacility);
    return updatedResult;
  }, Object.create(null));
};

export const getMapDateRange = (filterType = 'Last 3 months') => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  if (filterType === 'Last week') {
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay() - 6);
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() - today.getDay());
    return { startDate: startOfWeek, endDate: today };
  } else if (filterType === 'Yesterday') {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return { startDate: yesterday, endDate: yesterday };
  } else if (filterType === 'Today') {
    return { startDate: today, endDate: today };
  } else if (filterType === 'Tomorrow') {
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return { startDate: tomorrow, endDate: tomorrow };
  } else if (filterType === 'Next week') {
    const startOfNextWeek = new Date(today);
    startOfNextWeek.setDate(today.getDate() + (7 - today.getDay()));
    const endOfNextWeek = new Date(startOfNextWeek);
    endOfNextWeek.setDate(startOfNextWeek.getDate() + 6);
    return { startDate: startOfNextWeek, endDate: endOfNextWeek };
  } else if (filterType === 'Last 3 months') {
    const startOfLast3Months = new Date(today);
    startOfLast3Months.setMonth(today.getMonth() - 3);
    // const endOfLast3Months = new Date(today);
    return { startDate: startOfLast3Months, endDate: today };
  } else {
    throw new Error('Invalid filter type');
  }
};

export const getStatus = (
  inprogress: number,
  approvedbysupervisor: number,
  approvedbycustomer: number,
  completed: number,
  totaltasks: number
  // noShowStatus: any
) => {
  if (inprogress > 0) {
    return 'In progress';
  } else if (approvedbysupervisor) {
    return 'Pending Reviews';
  } else if (completed === totaltasks) {
    return 'Complete';
  } else {
    return 'Incomplete';
  }
};

export const formatDateToYYMMDDFormat = (oldFormatDate: string | number | Date) => {
  return moment(oldFormatDate).format(FieldNames.dateFormat);
};

/**
 * @param {string} str - The string to be parsed.
 * @param {number} val - The number of decimal places to keep.
 * @returns {number} - The parsed float value.
 */
export function ParseFloat(str: string | string[], val: number) {
  str = str?.toString();
  str = str?.slice(0, str?.indexOf('.') + val + 1);
  return str ? Number(str) : 0;
}

export function isFloat(value: number) {
  return Number(value) === value && value % 1 !== 0;
}

export const fetchDataFromS3Bucket = async (
  navigate: (routeScreenName: string) => void,
  key: string,
  level = S3BucketLevel.public
) => {
  try {
    // await refreshSession(navigate);
    return await Storage.get(key, { level });
  } catch (e) {
    throw new Error('failed to fetch data');
  }
};

export function checkImageValid(url: string, onSuccess: () => void, onError: () => void): void {
  const img = new Image();

  img.onload = () => {
    onSuccess();
  };

  img.onerror = () => {
    onError();
  };

  img.src = url;
}

export const deepClone = (itemArr: any[]) => {
  return [...itemArr].map((dataItem) => {
    return {
      ...dataItem,
    };
  });
};

export function calculateTotalEstimationWithProfit(total: number, profitPercentage: number) {
  const profitAmount = (total * profitPercentage) / 100;
  return total + profitAmount;
}

export const requestBlob = (uri: string) => {
  return new Promise((resolve, reject) => {
    const xhr: any = new XMLHttpRequest();
    xhr.onload = () => resolve(xhr.response);
    xhr.onerror = () => reject(new TypeError('common:networkRequestFailed'));
    xhr.responseType = XMLHttpRequestResponseType.blob;
    xhr.open('GET', uri, true);
    xhr.send(null);
  });
};

export const uploadDocumentToS3Bucket = async (
  navigate: (routeScreenName: string) => void,
  s3BucketPath: any,
  url: any,
  config: any,
  fileExtension?: string
) => {
  let body;
  let storedDocument;
  let modifiedConfig = config;

  try {
    // await refreshSession(navigate);
    if (fileExtension && fileExtensionExtraction(fileExtension) !== '') {
      modifiedConfig = { ...config, contentType: fileExtensionExtraction(fileExtension) };
    }
    body = await requestBlob(url);
    storedDocument = await Storage.put(s3BucketPath, body, modifiedConfig);
    return { file: storedDocument, error: {} };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const fileExtensionExtraction = (fileExtension: string) => {
  if (fileExtension === 'png') {
    return 'image/png';
  }
  if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
    return 'image/jpeg';
  }
  if (fileExtension === 'heic' || fileExtension === 'heif') {
    return 'image/heic';
  }
  if (fileExtension === 'pdf') {
    return 'application/pdf';
  }
  return '';
};

export const getTagValues = (tags: string | null) => {
  if (isEmpty(tags)) {
    return [];
  }
  const categoriesArray = tags?.split(';');
  const categoryNames: string[] = [];
  categoriesArray?.forEach((category: string) => {
    const parts = category.split('|');
    if (parts.length === 3) {
      categoryNames.push(parts[2]);
    }
  });
  return categoryNames;
};

export const removeJobLocalData = () => {
  const keysToRemove = [
    'jobName',
    'jobAttachments',
    'jobServices',
    'selectedCustomer',
    'selectedFacility',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'bidDueDate',
  ];

  keysToRemove.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const getIpAddress = async () => {
  return fetch(IPAddressAPI)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => console.error('Fetch error:', error));
};

export const removeLeadingZeros = (value: string | number) => {
  const stringValue = String(value);
  const parsedValue = parseFloat(stringValue);
  if (isNaN(parsedValue)) {
    throw new Error('Input is not a valid number.');
  }
  // Remove leading zeros from the integer part
  const integerPart = parseInt(stringValue.split('.')[0], 10);
  return parseFloat(
    integerPart.toString() + (parsedValue % 1 !== 0 ? '.' + parsedValue.toString().split('.')[1] : '')
  ).toFixed(2);
};

export const joinByComma = (keywordsList: (string | null | undefined)[]) => {
  let joinedByCommas = '';
  if (keywordsList?.length) {
    keywordsList.forEach((keyword: string | null | undefined, index: number) => {
      if (keyword) {
        if (keywordsList.length - 1 !== index && keywordsList[index + 1]) {
          joinedByCommas += `${keyword}, `;
        } else {
          joinedByCommas += keyword;
        }
      }
    });
  } else {
    return joinedByCommas;
  }
  return joinedByCommas;
};

export function convertToReadableFormat(inputString: any) {
  const readableString = inputString?.replace(/([a-z])([A-Z])/g, '$1 $2');

  return readableString?.charAt(0)?.toUpperCase() + readableString?.slice(1);
}

export const getAuthData = () => {
  let role: UserType | '' = '';
  let userId: string = '';
  let isDistrictManager: boolean = false;
  const authValue = localStorage.getItem('auth');
  if (authValue !== null) {
    const authData = JSON.parse(authValue);
    role = authData?.attributes?.['custom:Role'] || '';
    userId = authData?.username || '';
    isDistrictManager = authData?.canCreateOneTimeJob ?? false;
  }
  return { role, userId, isDistrictManager };
};

export const getDefaultFormValue = (type: FieldNames) => {
  if (type === FieldNames.startDate) {
    return localStorage.getItem('startDate') ?? null;
  } else if (type === FieldNames.endDate) {
    return localStorage.getItem('endDate') ?? null;
  } else if (type === FieldNames.bidDueDate) {
    return localStorage.getItem('bidDueDate') ?? null;
  } else if (type === FieldNames.jobName) {
    return localStorage.getItem('jobName') ?? '';
  } else {
    return localStorage.getItem('searchedVendors') ?? '';
  }
};

export const getDefaultAddUserValue = (type: string) => {
  if ((type as AddUserName) === AddUserName.fullName) {
    return localStorage.getItem('name') ?? null;
  } else if ((type as AddUserName) === AddUserName.email) {
    return localStorage.getItem('email') ?? null;
  } else if ((type as AddUserName) === AddUserName.phoneNum) {
    return localStorage.getItem('phoneNumber') ?? '';
  } else {
    return localStorage.getItem('roleId') ?? '';
  }
};

export const getDefaultFacilitiesList = () => {
  const selectedFacility = localStorage.getItem('selectedFacility');
  return selectedFacility
    ? JSON.parse(selectedFacility)
    : [
        {
          facilityName: '',
          id: uuidv4(),
          facilityId: '',
          mdFacilityType: '',
          facilityError: '',
        },
      ];
};

export const getDefaultServiceValue = () => {
  const selectedService = localStorage.getItem('jobServices');
  return selectedService
    ? JSON.parse(selectedService)
    : [
        {
          service: '',
          id: uuidv4(),
          quantity: '',
          unit: '',
          mdServiceId: '',
          nameError: '',
          asError: '',
          name: '',
          tasks: '',
          tasksError: '',
        },
      ];
};

export const getDoubleDigitNumberString = (digit: number) => {
  if (Number(digit) < 10) {
    return `0${digit}`;
  }
  return digit;
};

export enum RolesEnum {
  AccountHolder = 'AccountHolder',
  AssociateTechnician = 'Associate/Technician',
}

export const removeNonValueFromStringArray = (inputArr: string[]) => {
  return inputArr.filter((item) => {
    return item ? true : false;
  });
};

export const getAccessFacilityLockKeyLabel = (label: AccessOptions) => {
  if (!label) return '';
  switch (label) {
    case AccessOptions.LockboxWithKey:
      return t('facility:lockBoxKey');
    case AccessOptions.KeyCard:
      return t('facility:keyCard');
    case AccessOptions.AccessPin:
      return t('facility:accessPin');
    default:
      return '';
  }
};

export enum AreaSize {
  L = '25000',
  S = '5000',
}

export const calculateWorkVolume = (services: Array<WorkOrderService>) => {
  let sum = 0;
  services.forEach((service: WorkOrderService) => {
    sum += service.serviceMeasure || 0;
  });
  if (sum >= Number(AreaSize.L)) return 'L';
  if (sum <= Number(AreaSize.S)) return 'S';
  return 'M';
};

export const getTimeFormat = (time: string) => {
  if (time) {
    const hour24 = time?.split(':');
    const minutes: string = time?.split(':')[1];
    const AmOrPm = +hour24[0] >= 12 ? 'PM' : 'AM';
    const hours = +hour24[0] % 12 || 12;
    return `${hours}:${minutes} ${AmOrPm}`;
  }
};

export const getTimeDifferenceInMinutes = (actualStartTime: string, actualEndTime: string) => {
  const startTime: Date = new Date(actualStartTime);
  const endTime: Date = new Date(actualEndTime);

  const timeDifferenceMs: number = endTime.getTime() - startTime.getTime();

  return Math.floor(timeDifferenceMs / (1000 * 60));
};

export const convertDateFormatToIso = (dateString: string): string => {
  try {
    const dateObject: Date = new Date(dateString);

    const isoDateString: string = dateObject.toISOString();

    return isoDateString;
  } catch (error) {
    console.error('Error converting date:', error);
    return '';
  }
};

export const refreshSession = async (navigate: (routeScreenName: string) => void) => {
  try {
    await Auth.currentSession();
    return true;
  } catch (e: any) {
    localStorage.removeItem('auth');
    localStorage.removeItem('customerDetails');
    await Auth.signOut();
    if (navigate) {
      navigate('/login');
    }
    return false;
  }
};

export const getDaySuffix = (day: number): string => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getRoleName = (role?: string) => {
  if (!role) return '';
  switch (role as UserRoles) {
    case UserRoles.Admin:
      return t('roleName:admin');
    case UserRoles.NonAdmin:
      return t('roleName:nonAdmin');
    case UserRoles.AccountHolder:
      return t('roleName:accountHolder');
    case UserRoles.Associate:
      return t('roleName:associateOrTechnician');
    case UserRoles.DistrictManager:
      return t('roleName:districtManager');
    case UserRoles.RegionalManager:
      return t('roleName:regionalManager');
    case UserRoles.Supervisor:
      return t('roleName:supervisor');
    default:
      return '';
  }
};

export enum ErrorCodesForRescheduleShift {
  ALREADY_PUNCHED_IN = 'ALREADY_PUNCHED_IN',
  DATE_NOT_AVAILABLE = 'DATE_NOT_AVAILABLE',
  USER_NOT_ALLOWED_TO_RESCHEDULE = 'USER_NOT_ALLOWED_TO_RESCHEDULE',
  CUSTOMER_PROHIBITS_RESCHEDULE = 'CUSTOMER_PROHIBITS_RESCHEDULE',
  SHIFT_ALREADY_EXIST = 'SHIFT_ALREADY_EXIST',
}

export const getRescheduleShiftErrorMessage = (errorCode: ErrorCodesForRescheduleShift): string => {
  if (isEmpty(errorCode)) return '';
  switch (errorCode) {
    case ErrorCodesForRescheduleShift.ALREADY_PUNCHED_IN:
      return 'rescheduleShiftDate:rescheduleShiftHasPunchInRecords';
    case ErrorCodesForRescheduleShift.DATE_NOT_AVAILABLE:
      return 'rescheduleShiftDate:rescheduleShiftDateNotAvailable';
    case ErrorCodesForRescheduleShift.USER_NOT_ALLOWED_TO_RESCHEDULE:
      return 'rescheduleShiftDate:rescheduleShiftDateNotAuthorized';
    case ErrorCodesForRescheduleShift.CUSTOMER_PROHIBITS_RESCHEDULE:
      return 'rescheduleShiftDate:rescheduleShiftDateNotAllowedByCustomer';
    case ErrorCodesForRescheduleShift.SHIFT_ALREADY_EXIST:
      return 'rescheduleShiftDate:rescheduleShiftDateIsAlreadyExist';
    default:
      return '';
  }
};

export const formatDate = (
  date?: string,
  format:
    | 'MM-DD-YYYY'
    | 'MM/DD/yyyy'
    | 'MM/DD/YYYY'
    | 'YYYY-MM-DD'
    | 'MMM D, YYYY'
    | 'MMM D, YYYY HH:mm:ss.sss' = 'MM-DD-YYYY'
): string => {
  if (!date) return '-';
  const formattedDate = date.endsWith('Z') ? date.slice(0, -1) : date;
  return moment(formattedDate).format(format);
};

export const formattingDate = (date: string | undefined): string => {
  if (!date) return '';
  return date.endsWith('Z') ? date.slice(0, date.length - 1) : date;
};

export const getDateAfterAddingZForTimeZone = (date: string) => {
  if (date) {
    return date?.endsWith('Z') ? date : `${date}Z`;
  }
  return '';
};

export const parseTime = (time: any) => {
  const [hours, minutes] = time.split(':').map(Number);
  return { hours, minutes };
};

export enum ErrorCodesForPunChIn {
  ALREADY_PUNCHED_IN = 'ALREADY_PUNCHED_IN',
  ALREADY_EXIST = 'ALREADY_EXIST',
}

export const getDateRange = (days: number) => {
  const today = new Date();
  const fromDate = new Date(today);
  fromDate.setDate(today.getDate() - days + 1);
  return { fromDate, toDate: today };
};

export const NUMBER_OF_ITEMS_PER_PAGE = 10;

export const getJobBids = (selectedBid: GetAllJobs, customerId: string) => [
  {
    bidId: selectedBid?.bidId ?? '',
    jobId: selectedBid?.jobId ?? '',
    bidName: selectedBid?.jobName ?? '',
    vendorId: customerId,
  },
];

export const getVendorBids = (selectedBid: GetAllBids, customerId: string) => [
  {
    bidId: selectedBid?.bidId ?? '',
    jobId: selectedBid?.jobId ?? '',
    bidName: selectedBid?.jobName ?? '',
    finalQuote: selectedBid?.finalQuote,
    bidCreationDate: selectedBid?.bidCreatedOn,
    draftStatus: selectedBid?.bidDraftStatus,
    dataStatus: selectedBid?.bidDataStatus,
    bidVersion: selectedBid?.bidVersion,
    vendorId: customerId,
  },
];

export const getJobFacility = (selectedBid: GetAllJobs) => ({
  jobId: selectedBid?.jobId ?? '',
  facilityAddress: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
  status: null,
  jobStartDate: selectedBid?.jobStartDate,
  jobEndData: selectedBid?.jobStopDate,
});

export const getBidFacility = (selectedBid: GetAllBids) => ({
  jobId: selectedBid?.jobId ?? '',
  facilityAddress: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
  status: null,
  jobStartDate: selectedBid?.startDate,
  jobEndData: selectedBid?.stopDate,
});

export const getBidAddress = (selectedBid: GetAllBids) => ({
  addressName: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
  noOfBuildings: 1,
  addressLine1: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
  addressLine2: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
  addressLine3: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
});

export const getJobAddress = (selectedBid: GetAllJobs) => ({
  addressName: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
  addressLine1: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
  addressLine2: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
  addressLine3: selectedBid?.jobFacilities?.[0]?.facilityAddress ?? '',
});

export const handleSort = async (
  sortingObj: any,
  currentSortOrder: string,
  setSorting: (object: any[]) => void,
  setNewSortOrder: (objectId: string) => void,
  setNewSort: (objectType: boolean) => void,
  fetchFunction: (
    pageNumber?: number,
    limit?: number,
    sortBy?: any,
    sortOrder?: boolean,
    filter?: string
  ) => Promise<void>,
  hasFetchedDataRef: React.MutableRefObject<boolean>,
  currentSort?: boolean,
  currentPage?: number,
  itemsPerPage?: number
) => {
  if (sortingObj?.length && (sortingObj[0].id !== currentSortOrder || sortingObj[0].desc !== currentSort)) {
    setSorting(sortingObj);
    setNewSortOrder(sortingObj[0].id);
    setNewSort(sortingObj[0].desc);

    await fetchFunction(currentPage, itemsPerPage, sortingObj[0].id, sortingObj[0].desc);
  } else {
    setSorting([]);
    if (hasFetchedDataRef.current) {
      await fetchFunction(currentPage, itemsPerPage);
    }
  }
};

export const handleFilter = async (
  filtersObj: FilterItem[],
  setColumnFilters: (object: any[]) => void,
  fetchFunction: (
    pageNumber?: number,
    limit?: number,
    sortBy?: any,
    sortOrder?: boolean,
    filterBy?: string,
    filter?: string
  ) => Promise<void>,
  currentPage: number,
  itemsPerPage: number,
  sortOrder: string,
  hasFetchedDataRef: React.MutableRefObject<boolean>,
  sortDirection?: boolean
) => {
  if (filtersObj?.length) {
    setColumnFilters(filtersObj);
    await fetchFunction(
      currentPage,
      itemsPerPage,
      sortOrder,
      sortDirection,
      filtersObj?.[0]?.id,
      filtersObj?.[0]?.value
    );
  } else {
    setColumnFilters([]);
    if (hasFetchedDataRef.current) {
      await fetchFunction(currentPage, itemsPerPage);
    }
  }
};
export const getWebInfo = () => {
  return {
    'x-pzy-client-version': '2.7.1',
    'x-pzy-client-type': PlatformType.Web,
  };
};

export const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();
  const period = utcHours >= 12 ? 'PM' : 'AM';
  const hourIn12HrFormat = utcHours % 12 || 12;
  const formattedMinutes = utcMinutes < 10 ? `0${utcMinutes}` : utcMinutes;
  return `${hourIn12HrFormat}:${formattedMinutes} ${period}`;
};

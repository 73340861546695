/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import awsConfig from '../../../Configuration/environment-config';
import { formatDateToYYMMDDFormat } from '../../../Shared/Utilities/utils';

export enum AWSAuthMode {
  API_KEY = 'API_KEY',
  COGNITO = 'AMAZON_COGNITO_USER_POOLS',
}

export enum UserType {
  Customer = 'Customer',
  Vendor = 'Vendor',
}

export interface IGraphQLResponse {
  data?: any;
  errors?: any;
}

export default class JobViewApiService {
  static async jobView(
    id: string,
    userId: any,
    fromDate: string | number | Date,
    jobType: string,
    pageIndex: number,
    sortBy: string,
    sortOrder: string
  ) {
    try {
      const jobView = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'Jobs'
      );
      if (!jobView?.endpoint) {
        return { data: [], errors: [{ message: 'Endpoint not found' }] };
      }

      const requestOptions = {
        method: 'POST',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      const fromDateYMDFormat = formatDateToYYMMDDFormat(fromDate);
      const sortByString = sortBy ? `&sortBy=${sortBy}` : '';
      const sortOrderString = sortOrder ? `&sortOrder=${sortOrder}` : '';
      const queryParams = `?fromDate=${fromDateYMDFormat}&customerId=${id}&jobType=${jobType}&userId=${userId}&pageIndex=${pageIndex}${sortByString}${sortOrderString}`;

      const endpointWithParams = `${jobView.endpoint}${queryParams}`;

      const jobViewResponse = await fetch(endpointWithParams, requestOptions);

      const responseJson = await jobViewResponse.json();

      return { data: responseJson ?? null, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}

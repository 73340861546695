/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export enum StyleConstants {
  LetterSpacingDefault = '0.015625rem',
  LetterSpacingTight = '0.009375rem',
  JustifyContentStart = 'flex-start',
  JustifyContentBetween = 'space-between',
  GridTemplateThreeColumns = 'repeat(3, 1fr)',
  GridTemplateTwoColumns = 'repeat(2, 1fr)',
  MaxWidth = 'fit-content',
}

export enum PaddingValues {
  Padding1 = '1.5rem 1.5rem 0 1.5rem',
  Padding2 = '0.75rem 2rem',
  Padding3 = '1.0625rem 2rem',
}

export enum BoxShadow {
  BoxShadow = '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
  BoxShadow1 = '0 0.625rem 1.25rem 0.375rem #7957001A',
  BoxShadow2 = '0 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10)',
}

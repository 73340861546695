/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import {
  DialogWrapper,
  CloseIconStyle,
  PopupHeadingText,
  PhotosCountText,
  ChevronStyle,
  buttonStyle,
} from './MediaPopUp.Styles';

import { Icons } from '../../../Constants/Icons';

interface MediaPopupProps {
  isOpen: boolean;
  onClose: () => void;
  currentMediaIndex: number;
  media: any[];
  showPreviousMedia: () => void;
  showNextMedia: () => void;
  numMedia: number;
  type: 'photo' | 'video';
}

const MediaPopup: React.FC<MediaPopupProps> = ({
  isOpen,
  onClose,
  currentMediaIndex,
  media,
  showPreviousMedia,
  showNextMedia,
  numMedia,
  type,
}) => {
  const { t } = useTranslation(['altTexts']);
  const { photoAndVideoStyle } = buttonStyle;

  const handleDownloadMedia = (mediaUrl: string) => {
    window.open(mediaUrl);
  };

  return (
    <DialogWrapper open={isOpen} onClose={onClose}>
      <CloseIconStyle onClick={onClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      <PopupHeadingText>
        {type === 'photo' ? 'Photos' : 'Videos'}
        <Button
          sx={{
            padding: '0',
            '&:hover': {
              background: 'none',
            },
          }}
          onClick={() => handleDownloadMedia((media[currentMediaIndex]?.url as string) ?? '')}>
          <img src={Icons.DownloadBlackIcon} alt={t('altTexts:download')} />
        </Button>
      </PopupHeadingText>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ position: 'relative' }}>
          {type === 'photo' ? (
            <img
              src={media[currentMediaIndex]?.url}
              alt={t('altTexts:image')}
              style={{ ...photoAndVideoStyle, objectFit: 'contain' }}
            />
          ) : (
            <video
              controls
              src={media[currentMediaIndex]?.url}
              style={{ ...photoAndVideoStyle, objectFit: 'contain' }}
            />
          )}
          {numMedia > 1 && (
            <>
              <ChevronStyle
                sx={{
                  left: 0,
                }}
                onClick={showPreviousMedia}>
                <img src={Icons.LeftChevronIcon} alt={t('altTexts:backArrow')} />
              </ChevronStyle>
              <ChevronStyle
                sx={{
                  right: 0,
                }}
                onClick={showNextMedia}>
                <img src={Icons.RightChevronIcon} alt={t('altTexts:rightArrow')} />
              </ChevronStyle>
            </>
          )}
          <Box sx={{ textAlign: 'center', marginTop: '0.5rem' }}>
            <PhotosCountText>
              {currentMediaIndex + 1}/{numMedia}
            </PhotosCountText>
          </Box>
        </Box>
      </Box>
    </DialogWrapper>
  );
};

export default MediaPopup;

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

/* eslint-disable sonarjs/cognitive-complexity */
import React, { SetStateAction, useCallback, useState } from 'react';
import { HeadingText, buttonStyles, CheckContainer, ScrollContainer } from './OneTimeJobDetails.Style';
import { Button, CircularProgress, Divider, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './OneTimeJob.css';
import Grid from '@material-ui/core/Grid';
import { Icons } from '../../../../Shared/Constants/Icons';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { UpdateTaskScheduleInput, WorkOrderYesNoType } from '../../../../API';
import { IEachOptionsProps } from '../WorkTasks/WorkTasks';
import theme from '../../../../Shared/Themes/theme';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import { AssignmentCardType } from '../../../../Shared/Utilities/utils';
export interface IVendorProofOfCompletion {
  category?: string;
  categoryTitle?: string;
  id?: string;
  options: IEachOptionsProps[];
  serviceImage?: string;
  totalProofRequiredTasks?: number;
  totalTasks?: number;
  isSelectAll?: boolean;
}

export type ServiceCategoryV3 = {
  taskId: string;
  serviceCategoryId: string;
  serviceCategoryName: string;
  categoryImageUrl?: string | null;
  categoryDescription?: string | null;
  orgId: string;
  mdServiceType: string;
  taskName: string;
  taskImageUrl?: string | null;
  taskDescription?: string | null;
  unit?: string | null;
  mdAreaType?: string | null;
  modifiedBy?: string | null;
  createdBy?: string | null;
};

export interface IAllServiceCategory {
  categoryId: string;
  categoryImageUrl: string;
  categoryName: string;
  industryId: string;
  industryImageUrl: string;
  industryName: string;
  taskId: string;
  taskImageUrl: string;
  taskName: string;
}
interface TasksRequireProofs {
  workOrderId: string;
  subWorkOrderId: string;
  isOnlySubVendor: boolean;
  workOrderData: any;
  onClose: (type: AssignmentCardType) => void;
  proofOfServiceAndTaskData: IVendorProofOfCompletion[];
  setProofOfServiceAndTaskData: (data: IVendorProofOfCompletion[]) => void;
  isDataLoading: boolean;
  assignmentType: AssignmentCardType;
  fetchScheduleDetails: (workOrderId: string) => Promise<void>;
}

const TasksRequireProofs = ({
  workOrderData,
  onClose,
  proofOfServiceAndTaskData,
  setProofOfServiceAndTaskData,
  isDataLoading,
  assignmentType,
  fetchScheduleDetails,
}: TasksRequireProofs) => {
  const { divider, buttonText, createJobButton } = buttonStyles;
  const { t } = useTranslation(['assignJob', 'oneTimeJob', 'altTexts']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isLoadingSaveProofs, setIsLoadingSaveProofs] = useState<boolean>(false);

  const handleTaskOnCheck = (parentIndex: number, type: string, childIndex?: number) => {
    const tasksData: IVendorProofOfCompletion[] = [...proofOfServiceAndTaskData];
    if (type == 'all') {
      const allTasksSelected = tasksData?.[parentIndex]?.isSelectAll;
      tasksData?.[parentIndex]?.options?.map((child: IEachOptionsProps) => {
        child.isProofOfCompletionRequired = allTasksSelected ? WorkOrderYesNoType.No : WorkOrderYesNoType.Yes;
        tasksData[parentIndex].isSelectAll = !allTasksSelected;
      });
    } else {
      if (childIndex !== undefined) {
        tasksData[parentIndex].options[childIndex].isProofOfCompletionRequired =
          tasksData?.[parentIndex]?.options?.[childIndex]?.isProofOfCompletionRequired === WorkOrderYesNoType.No
            ? WorkOrderYesNoType.Yes
            : WorkOrderYesNoType.No;
        const allAreChecked: boolean = tasksData?.[parentIndex]?.options?.every(
          (task: any) => task?.isProofOfCompletionRequired === WorkOrderYesNoType.Yes
        ) as boolean;
        tasksData[parentIndex].isSelectAll = allAreChecked;
      }
    }
    setProofOfServiceAndTaskData([...tasksData]);
  };

  const handleOnMarkSelected = async () => {
    if (!isEmpty(proofOfServiceAndTaskData)) {
      let selectedMandatoryTaskProofIds: string[] = [];

      const servicesData: IVendorProofOfCompletion[] = [...proofOfServiceAndTaskData];
      const selectedCheckList = servicesData.map((service: IVendorProofOfCompletion) => {
        const checkedIds = service.options.map((option: IEachOptionsProps) => {
          if (option.isProofOfCompletionRequired === WorkOrderYesNoType.Yes) {
            return option.id;
          }
        });
        return {
          sectionID: service.id,
          checkedOptionIds: checkedIds,
        };
      });

      if (!isEmpty(selectedCheckList)) {
        selectedCheckList.forEach((service) => {
          if (!isEmpty(service?.checkedOptionIds)) {
            selectedMandatoryTaskProofIds = [
              ...selectedMandatoryTaskProofIds,
              ...(service?.checkedOptionIds as string[]),
            ];
          }
        });
      }
      const updateTaskScheduleInput: UpdateTaskScheduleInput[] = [];
      proofOfServiceAndTaskData?.forEach((service) => {
        if (!isEmpty(service?.options)) {
          service?.options?.forEach((task: IEachOptionsProps) => {
            updateTaskScheduleInput.push({
              isProofOfCompletionRequired: selectedMandatoryTaskProofIds?.includes(task.id)
                ? WorkOrderYesNoType.Yes
                : WorkOrderYesNoType.No,
              taskScheduleId: task.taskScheduleId as string,
            });
          });
        }
      });
      try {
        setIsLoadingSaveProofs(true);
        const updateTaskScheduleResponse = await WorkOrdersApiService.updateTaskSchedule(updateTaskScheduleInput);
        if (updateTaskScheduleResponse?.data?.length) {
          setSnackbarMessage(t('assignJob:configuredProofOfCompletion'));
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setTimeout(() => {
            onClose(assignmentType);
          }, 1000);
          await fetchScheduleDetails(
            (workOrderData?.subWorkOrders[0]?.workOrderId
              ? workOrderData?.subWorkOrders[0]?.workOrderId
              : workOrderData?.workOrderId) as string
          );
        } else {
          setSnackbarMessage(updateTaskScheduleResponse?.errors[0]?.message as SetStateAction<string>);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
        setIsLoadingSaveProofs(false);
      } catch (e) {
        setIsLoadingSaveProofs(false);
        console.log(JSON.stringify(e));
      }
    }
  };

  const getNoOfTasksChecked = useCallback(
    (index: number) => {
      const servicesData: IVendorProofOfCompletion[] = [...proofOfServiceAndTaskData];
      const tasksChecked = servicesData?.[index]?.options?.filter((option: IEachOptionsProps) => {
        if (option?.isProofOfCompletionRequired === WorkOrderYesNoType.Yes) {
          return option;
        }
      });
      return tasksChecked?.length;
    },
    [proofOfServiceAndTaskData]
  );

  // const handleDisableMarkAsSelected = useCallback(() => {
  //   const servicesData: IVendorProofOfCompletion[] = [...proofOfServiceAndTaskData];
  //   const selectedCheckList = servicesData.map((service: IVendorProofOfCompletion) => {
  //     const checkedIds = service.options.map((option: IEachOptionsProps) => {
  //       if (option.isProofOfCompletionRequired === WorkOrderYesNoType.Yes) {
  //         return option.id;
  //       }
  //     });
  //     return {
  //       sectionID: service.id,
  //       checkedOptionIds: checkedIds,
  //     };
  //   });
  //   const hasValuesOtherThanNull = selectedCheckList.some((item) => {
  //     return item.checkedOptionIds.some((id) => id !== null && id !== undefined);
  //   });
  //   return !hasValuesOtherThanNull;
  // }, [proofOfServiceAndTaskData]);

  return (
    <>
      <HeadingText>{t('assignJob:tasksRequiringProofOfCompletion')}</HeadingText>
      <Divider sx={divider} />
      <div
        style={{
          fontFamily: 'manrope',
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '1.375rem',
          letterSpacing: '0.009375rem',
          color: '#0F1532',
          textAlign: 'left',
          marginBottom: '1em',
        }}>
        {t('assignJob:SelectTasksThatRequireProofOfCompletion')}
      </div>
      {isDataLoading ? (
        <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
      ) : (
        <ScrollContainer>
          <div>
            {proofOfServiceAndTaskData.map((accord: any, index: number) => {
              return (
                <>
                  <Accordion className="MuiAccordion-root">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={accord.categoryTitle + '-content'}
                      id={accord.categoryTitle + '-header'}>
                      <div>
                        <HeadingText>{accord.categoryTitle}</HeadingText>
                        <div
                          style={{
                            backgroundColor:
                              getNoOfTasksChecked(index) === 0
                                ? theme.palette.error.light
                                : getNoOfTasksChecked(index) === accord?.options?.length
                                  ? theme.palette.secondary.main
                                  : theme.palette.secondary.dark,
                            color:
                              getNoOfTasksChecked(index) === 0
                                ? theme.palette.error.main
                                : getNoOfTasksChecked(index) === accord?.options?.length
                                  ? theme.palette.success.main
                                  : theme.palette.common.black,
                            padding: '5px 10px',
                            borderRadius: '10px',
                            marginTop: '0.5em',
                          }}>
                          {t('assignJob:ProofOfCompletionRequired')} : {getNoOfTasksChecked(index)}/
                          {accord?.options?.length}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CheckContainer>
                        {accord?.isSelectAll ? (
                          <img
                            src={Icons.CheckedIcon}
                            alt={t('altTexts:checked')}
                            onClick={() => handleTaskOnCheck(index, 'all')}
                            style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                          />
                        ) : (
                          <img
                            src={Icons.Unchecked}
                            alt={t('altTexts:unchecked')}
                            onClick={() => handleTaskOnCheck(index, 'all')}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                        <span>{t('assignJob:selectAll')}</span>
                      </CheckContainer>
                      {/* <Divider sx={{ margin: '1rem 0' }}></Divider> */}

                      {accord?.options?.map((checks: any, innerIndex: number) => {
                        return (
                          <>
                            <Divider sx={divider}></Divider>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={4}
                              style={{ width: '100%' }}>
                              <Grid item alignItems="center" className="leftDiv">
                                <CheckContainer>
                                  {checks.isProofOfCompletionRequired !== 'No' ? (
                                    <img
                                      src={Icons.CheckedIcon}
                                      alt={t('altTexts:checked')}
                                      onClick={() => handleTaskOnCheck(index, 'child', innerIndex)}
                                      style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                                    />
                                  ) : (
                                    <img
                                      src={Icons.Unchecked}
                                      alt={t('altTexts:unchecked')}
                                      onClick={() => handleTaskOnCheck(index, 'child', innerIndex)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  )}
                                  <span>{checks?.title}</span>
                                </CheckContainer>
                              </Grid>
                              <Grid item>
                                {checks?.floorNumber} {accord.category}
                              </Grid>
                            </Grid>
                          </>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })}
          </div>
        </ScrollContainer>
      )}
      <div style={{ display: 'flex' }}>
        {/* <Button
          sx={{ ...cancelButton, cursor: 'pointer', maxWidth: '100%', marginTop: '2em', width: '100%' }}
          onClick={onClose}>
          <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>{t('assignJob:skipForNow')}</Typography>
        </Button> */}
        <Button
          // disabled={handleDisableMarkAsSelected()}
          sx={{
            ...createJobButton,
            maxWidth: '100%',
            marginTop: '2em',
            width: '100%',
            // opacity: handleDisableMarkAsSelected() ? 0.5 : 1,
          }}
          onClick={handleOnMarkSelected}>
          {isLoadingSaveProofs ? (
            <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {t('assignJob:markSelected')}
            </Typography>
          )}
        </Button>
      </div>
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};
export default TasksRequireProofs;

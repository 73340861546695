/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AlertColor, Box, IconButton, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { useForm, useWatch } from 'react-hook-form';

import { DateRangeSelector } from '../../../../Shared/Components/Common/DateRangeSelection/DateRangeSelection.Model';
import {
  formatTime,
  getAuthData,
  getAuthDetails,
  getRescheduleShiftErrorMessage,
  getStoredCustomerDetails,
  isDateBefore,
} from '../../../../Shared/Utilities/utils';
import {
  BooleanTypes,
  DateRangeOptionType,
  DefaultOptions,
  FilterName,
  FormFieldType,
  JobType,
  Role,
  UserType,
} from '../../../../Shared/Constants/App';

import moment from 'moment';
import { isEmpty } from 'lodash';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { TitleWrapper, TableWrapper } from '../JobsListView/JobsListView.styles';
import WorkOrdersApiService, { IGetAllUpcomingShifts } from '../../Services/WorkOrdersService';
import {
  BooleanType,
  CreateWorkOrderTeam,
  RescheduleWorkDayInput,
  UserForUserManagement,
  WorkDayTeamStatus,
} from '../../../../API';
import { TaskData } from '../WorkTasks/WorkTasks';
import { IJobs } from '../JobView/JobsView';
import { IBidList } from '../JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { IUpcomingShift } from './UpcomingShifts.Model';
import { IJobOverview } from '../JobsListView/JobsListView';
import Modal from '../../Utilities/Shared/Components/Modal';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import { ITeamMemberData, IWorkOrderTeam } from '../JobDetails/JobAssignmentCard';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import JobCreateApiService from '../../Services/JobCreateService';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import { AssociateShiftDateProps } from '../AssociateOnTheJob/SupervisorPunchIn';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import AddTeamMember from '../AssociateOnTheJob/AddTeamMember';
import UpdateShiftDateCard from '../UpdateShiftDate/UpdateShiftDate';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import DateRangeSelection from '../../../../Shared/Components/Common/DateRangeSelection/DateRangeSelection';
import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import CustomDropDown from '../../../../Shared/Components/Common/CustomDropDown/CustomDropdown';

import theme from '../../../../Shared/Themes/theme';
import { Icons } from '../../../../Shared/Constants/Icons';

import { JobsTitle } from '../IssuesRaised/JobsIssuesRaised.styles';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';
import { HeadingText } from '../JobDetails/OneTimeJobDetails.Style';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';
import { ActionsColumn } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import CustomerApiService from '../../../Customer/Services/CustomerService';

const UpcomingShifts = (): JSX.Element => {
  const { t } = useTranslation(['Dashboard', 'homePage', 'calendar']);
  const [jobs, setJobs] = useState<IUpcomingShift[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeSelector>({
    fromDate: null,
    toDate: null,
  });
  const [openUpdateShiftDate, setOpenUpdateShiftDate] = useState(false);
  const [isStartTimeSelectOpen, setStartTimeSelectOpen] = useState(false);
  const [isEndTimeSelectOpen, setEndTimeSelectOpen] = useState(false);
  const [isShowUpdateShiftDateLoading, setIsShowUpdateShiftDateLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
  const [scheduleId, setScheduleId] = useState<string>('');
  const [workOrderId, setWorkOrderId] = useState<string | null>(null);
  const [subWorkOrderId, setSubWorkOrderId] = useState<string | null>(null);
  const [loggedInUsersOrganizationId, setLoggedInUsersOrganizationId] = useState<string | null>(null);
  const [jobId, setJobId] = useState<string | null>(null);
  const [rowInfo, setRowInfo] = useState<any>({});
  const [isAddAssociatesModalOpen, setIsAddAssociatesModalOpen] = useState<boolean>(false);
  const [workOrderData, setWorkOrderData] = useState<any>({});
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState<boolean>(false);
  const [selectedAssociates, setSelectedAssociates] = useState<any>([]);
  const [editAssociateShiftDate, setEditAssociateShiftDate] = useState<AssociateShiftDateProps>({
    userId: '',
    associateStartDate: '',
    associateEndDate: '',
  });
  const [isAssociateDataLoading, setIsAssociateDataLoading] = useState<boolean>(false);
  const [associatesTeam, setAssociatesTeam] = useState<ITeamMemberData[]>([]);
  const [originalAssociatesTeam, setOriginalAssociatesTeam] = useState<ITeamMemberData[]>([]);
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
  const [isDeleteModelOpen, setDeleteModalOpen] = useState(false);
  const [allAssociates, setAllAssociates] = useState<{ id: string; name: string }[]>([]);
  const [selectedAssociate, setSelectedAssociate] = useState<string>(DefaultOptions.All);
  const [isAssociateAndVendorLoading, setIsAssociateAndVendorLoading] = useState(false);
  const [facility, setFacility] = useState<{ id: string; name: string }[]>([]);
  const [isFacilityLoading, setIsFacilityLoading] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<string>(DefaultOptions.All);
  const [selectedFacilityId, setSelectedFacilityId] = useState<string>();
  const [selectedAssociateId, setSelectedAssociateId] = useState<string>();
  const [selectedRoleBasedId, setSelectedRoleBasedId] = useState<string>();
  const [customersAndVendors, setCustomersAndVendors] = useState<{ id: string; name: string }[]>([]);
  const [selectedCustomerVendor, setSelectedCustomerVendor] = useState<string>(DefaultOptions.All);
  const [isCustomerVendorLoading, setIsCustomerVendorLoading] = useState(false);

  const { isDistrictManager } = getAuthData();
  const location = useLocation();
  const selectedDate = location.state?.selectedDate;
  const defaultDateRangeOption = '1';
  const itemsPerPage = 10;
  const storedAuthDetails = getAuthDetails();
  const CUSTOM_ROLE_KEY = 'custom:Role';
  const role = storedAuthDetails[CUSTOM_ROLE_KEY] as UserType;
  const defaultRoleBasedOption = DefaultOptions.All;

  const { control } = useForm({
    defaultValues: {
      specificDate: selectedDate ?? new Date().toISOString(),
    },
  });

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  const dateRangeOptions = [
    { id: 1, label: 'Today', value: '1', type: DateRangeOptionType.Days },
    { id: 2, label: 'Last 7 Days', value: '7', type: DateRangeOptionType.Days },
    { id: 3, label: 'Last 30 Days', value: '30', type: DateRangeOptionType.Days },
    { id: 4, label: 'Custom Date Range', value: 'custom', type: DateRangeOptionType.CustomDateRange },
  ];

  const filters = [
    {
      title:
        storedAuthDetails[CUSTOM_ROLE_KEY] === UserType.Customer ? t('dashboard:vendors') : t('dashboard:associates'),
      type: FormFieldType.DropDown,
      name: FilterName.Associates,
      options: allAssociates.map((associateAndVendor) => ({
        label: associateAndVendor.name,
        value: associateAndVendor.id,
      })),
    },
    {
      title: t('dashboard:location'),
      type: FormFieldType.DropDown,
      name: FilterName.Location,
      options: facility.map((facility) => ({
        label: facility.name,
        value: facility.id,
      })),
    },
    {
      title:
        storedAuthDetails[CUSTOM_ROLE_KEY] === UserType.Customer ? t('dashboard:vendors') : t('dashboard:customers'),
      type: FormFieldType.DropDown,
      name: FilterName.CustomerAndVendor,
      options: customersAndVendors.map((customersAndVendor) => ({
        label: customersAndVendor.name,
        value: customersAndVendor.id,
      })),
    },
  ];

  const showSnackbar = (severity: AlertColor, message: string) => {
    setSnackbarOpen(true);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  const handleDateRangeChange = (dateRange: DateRangeSelector) => {
    setSelectedDateRange({
      fromDate: dateRange.fromDate || new Date(),
      toDate: dateRange.toDate || new Date(),
    });
  };

  const handlePaginationChange = (event: any, value: number) => {
    setCurrentPage(value);
    void fetchData(
      selectedDateRange?.fromDate ?? new Date(),
      selectedDateRange?.toDate ?? new Date(),
      currentPage,
      selectedAssociateId as string,
      selectedFacilityId as string,
      selectedRoleBasedId as string,
      ''
    );
  };

  const handleAssociateVendorChange = (selectedAssociateId: string) => {
    setSelectedAssociateId(selectedAssociateId);
    void fetchData(
      selectedDateRange?.fromDate ?? new Date(),
      selectedDateRange?.toDate ?? new Date(),
      currentPage,
      selectedAssociateId,
      selectedFacilityId as string,
      selectedRoleBasedId as string,
      ''
    );
  };

  const handleAssociateCustomerChange = (selectedRoleBasedId: string) => {
    setSelectedRoleBasedId(selectedRoleBasedId);
    void fetchData(
      selectedDateRange?.fromDate ?? new Date(),
      selectedDateRange?.toDate ?? new Date(),
      currentPage,
      selectedAssociateId as string,
      selectedFacilityId as string,
      selectedRoleBasedId,
      ''
    );
  };

  const fetchData = async (
    fromDate: Date,
    toDate: Date,
    pageNumber: number,
    selectedAssociateId: string,
    selectedFacilityId: string,
    selectedRoleBasedId: string,
    vendorId: string,
    workDayTeamStatus?: string,
    searchText?: string
  ) => {
    try {
      setIsLoading(true);
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails?.customerId;
      const organizationDetails = await MasterDataApiService.getOrgDetailsByUserName();
      const organizationId = organizationDetails?.data?.organization?.organizationId;
      setLoggedInUsersOrganizationId(organizationId);

      const payload: IGetAllUpcomingShifts = {
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        pageNumber: pageNumber ?? '',
        selectedUserId: selectedAssociateId ?? DefaultOptions.All,
        ...(role === UserType.Customer && { selectedFacilityId: selectedFacilityId ?? DefaultOptions.All }),
        selectedRoleBasedId: selectedRoleBasedId ?? '',
        itemsPerPage: itemsPerPage ?? '',
        vendorId: vendorId,
        workDayTeamStatus: WorkDayTeamStatus.A,
        searchText: searchText ?? '',
      };
      const shiftsResponse = await WorkOrdersApiService.getAllUpcomingShifts(payload);
      if (shiftsResponse?.data?.length > 0) {
        const modifiedJobs = shiftsResponse.data as IUpcomingShift[];
        setJobs(modifiedJobs);
        setTotalCount(shiftsResponse.metadata.totalCount);
      } else {
        setJobs([]);
      }
    } catch (e) {
      console.log('Error', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseAddButton = (roleType: Role) => {
    if (roleType === Role.Supervisor) {
      // setIsAddSupervisorsModalOpen(false);
    } else if (roleType === Role.Associate) {
      setIsAddAssociatesModalOpen(false);
    }
  };

  const fetchAllAssociates = async () => {
    setIsAssociateAndVendorLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails?.customerId;
      const loggedInUser = await MasterDataApiService.getCurrentUserInfo();
      const userDetails = await AuthenticationService.getUserByCognitoUserId(loggedInUser.username);
      const userId = userDetails?.data?.userId;
      const response = isDistrictManager
        ? await WorkOrdersApiService.getVendorTeam(userId, '', BooleanType.True, '', 1, 10, false)
        : await CustomerApiService.getAllUsers(vendorId, '', 0, 10, BooleanType.True, false);
      const associateData = response.data.map((associate: { userId: any; username: any }) => ({
        id: associate.userId,
        name: associate.username,
      }));
      setAllAssociates([{ id: DefaultOptions.All, name: DefaultOptions.All }, ...associateData]);
    } catch (error) {
      console.error('Error fetching users', error);
    } finally {
      setIsAssociateAndVendorLoading(false);
    }
  };

  const fetchCustomersAndVendors = async () => {
    setIsCustomerVendorLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      let customerVendorData = [];
      let response;
      if (role === UserType.Vendor) {
        const vendorId = customerDetails?.customerId;
        response = await JobCreateApiService.getVendorAffiliations(vendorId);
      } else if (role === UserType.Customer) {
        const customerId = customerDetails?.customerId;
        response = await JobCreateApiService.getCustomerAffiliations(customerId);
      }
      customerVendorData = response?.data.map((customerVendor: { orgId: any; orgName: any }) => ({
        id: customerVendor.orgId,
        name: customerVendor.orgName,
      }));
      setCustomersAndVendors([{ id: DefaultOptions.All, name: DefaultOptions.All }, ...customerVendorData]);
    } catch (error) {
      console.error(t('dashboard:errorFetchingUsers'), error);
    } finally {
      setIsCustomerVendorLoading(false);
    }
  };

  const fetchFacilities = async () => {
    setIsFacilityLoading(true);
    try {
      let facilitiesData = [];
      const { customerId = '' } = getStoredCustomerDetails() || {};
      const response = await FacilityApiService.getAllFacilities(customerId);
      facilitiesData = response.data.map((facility: { facilityId: any; buildingName: any }) => ({
        id: facility.facilityId,
        name: facility.buildingName,
      }));

      setFacility([{ id: DefaultOptions.All, name: DefaultOptions.All }, ...facilitiesData]);
    } catch (error) {
      console.error(t('dashboard:errorFetchingFacility'));
    } finally {
      setIsFacilityLoading(false);
    }
  };

  const handleAssociateVendorChanges = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedAssociate(value);
    handleAssociateVendorChange(value);
  };

  const handleCustomerVendorChanges = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedCustomerVendor(value);
    const id = value === DefaultOptions.All.toString() ? '' : value;
    handleAssociateCustomerChange(id);
  };

  const handleLocationChange = (facilityId: string) => {
    setSelectedFacilityId(facilityId);
    void fetchData(
      selectedDateRange?.fromDate ?? new Date(),
      selectedDateRange?.toDate ?? new Date(),
      currentPage,
      selectedAssociateId as string,
      facilityId,
      selectedRoleBasedId as string,
      ''
    );
  };

  const handleLocationChanges = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedFacility(value);
    handleLocationChange(value);
  };

  const renderAddAssociatesHeaderContent = () => {
    return <HeadingText>{t('assignJob:assignAssociates')}</HeadingText>;
  };

  const handleRescheduleWorkDayResponse = (rescheduleWorkDaysResponse: any): void => {
    if (
      !isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleFailure ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.errors ?? [])
    ) {
      const errorMsg = `${t('rescheduleShiftDate:failedToRescheduleShift')}. ${t(getRescheduleShiftErrorMessage(rescheduleWorkDaysResponse?.data?.rescheduleFailure?.[0]?.errorCode ?? ''))}`;
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else if (
      !isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleSuccess ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.data?.rescheduleFailure ?? []) &&
      isEmpty(rescheduleWorkDaysResponse?.errors ?? [])
    ) {
      setSnackbarMessage(t('rescheduleShiftDate:shiftReschedulingSuccessMsg'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } else if (!isEmpty(rescheduleWorkDaysResponse?.errors ?? [])) {
      setSnackbarMessage(rescheduleWorkDaysResponse?.errors[0]?.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleOnUpdateShiftDate = async (duration: any): Promise<void> => {
    setIsShowUpdateShiftDateLoading(true);
    try {
      let newDate = moment(duration?.shiftDate).format('YYYY-MM-DDT00:00:00.000');
      newDate = moment(newDate).format('YYYY-MM-DD');

      const rescheduleWorkDayInput: RescheduleWorkDayInput = {
        newDate: newDate,
        workDayId: scheduleId ? scheduleId : '',
        startTime: !isEmpty(duration?.startTime) ? `${duration?.startTime?.hours}:${duration?.startTime?.minutes}` : '',
        endTime: !isEmpty(duration?.endTime) ? `${duration?.endTime?.hours}:${duration?.endTime?.minutes}` : '',
        endDateOffset: Number(duration?.offsetDays),
      };

      const mSubWorkOrderId = subWorkOrderId ?? '';
      const mWorkOrderId = workOrderId ?? '';
      const rescheduleWorkDaysResponse = await WorkOrdersApiService.rescheduleWorkDays(
        !isViewOnly ? mSubWorkOrderId : mWorkOrderId,
        [rescheduleWorkDayInput]
      );

      handleRescheduleWorkDayResponse(rescheduleWorkDaysResponse);
      setOpenUpdateShiftDate(false);
    } catch (e: any) {
      console.log(e);
      setOpenUpdateShiftDate(false);
    } finally {
      setIsShowUpdateShiftDateLoading(false);
      setOpenUpdateShiftDate(false);
    }
  };

  const getWorkOrderDetails = async (jobId: string) => {
    try {
      const workOrderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId });
      const workOrderData = workOrderResponse?.data[0];
      setWorkOrderData(workOrderData);
    } catch (error) {
      console.error('Failed to fetch work orders:', error);
    }
  };

  const renderActionsCell = (row: any) => {
    const isSelfPerformed =
      row.original.hasChild === BooleanTypes.False &&
      row.original.jobType != JobType.Recurring &&
      row.original.jobCustodian === loggedInUsersOrganizationId; //flow 1

    const isPrimeVendorsJob =
      row.original.hasChild === BooleanTypes.True &&
      row.original.jobType != JobType.Recurring &&
      row.original.jobCustodian === loggedInUsersOrganizationId; //flow 2 pm, flow 3 pm

    const isSubVendorsJob =
      row.original.hasChild === BooleanTypes.False &&
      row.original.jobType != JobType.Recurring &&
      row.original.jobCustodian !== loggedInUsersOrganizationId &&
      row.original.jobCustodian !== null; // flow 3 sv

    const normalJob =
      row.original.jobCustodian === null ||
      (row.original.jobCustodian !== null && row.original.jobType === JobType.Recurring);

    return (
      <ActionsColumn>
        {(!isSelfPerformed || !isPrimeVendorsJob || !isSubVendorsJob || normalJob) && (
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
            onClick={() => {
              setRowInfo(row?.original);
              setScheduleId(row?.original?.workDayId);
              setWorkOrderId(row?.original?.workOrderId);
              setSubWorkOrderId(row?.original?.subWorkOrderId);
              setIsViewOnly(row?.original?.hasChild);
            }}>
            <WhiteTooltip title={t('rescheduleShiftDate:rescheduleShift')} onClick={() => setOpenUpdateShiftDate(true)}>
              <img src={Icons.RescheduleShift} alt={t('altTexts:updateShift')} />
            </WhiteTooltip>
          </IconButton>
        )}

        {(!isPrimeVendorsJob || normalJob) && (
          <IconButton
            size="large"
            sx={{
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
            onClick={async () => {
              setRowInfo(row.original);
              setScheduleId(row.original.workDayId);
              setWorkOrderId(row.original.workOrderId);
              setSubWorkOrderId(row.original.subWorkOrderId);
              setIsViewOnly(row?.original?.hasChild);
              setJobId(row?.original.jobId);
              await getWorkOrderDetails(row.original.jobId);
            }}>
            <WhiteTooltip title={t('assignJob:assignAssociates')} onClick={() => setIsAddAssociatesModalOpen(true)}>
              <img src={Icons.AssignAssociateIcon} alt={t('altTexts:updateShift')} />
            </WhiteTooltip>
          </IconButton>
        )}
      </ActionsColumn>
    );
  };

  const saveTeamAssignments = async (createScheduleAssignmentsInput: CreateWorkOrderTeam[], userId?: string) => {
    if (isEmpty(userId)) {
      return await WorkOrdersApiService.addTeamAssignmentWorkOrderTeam({
        workOrderId: workOrderId as string,
        createScheduleAssignmentsInput,
        woScheduleMasterId: scheduleId,
      });
    } else {
      return await WorkOrdersApiService.updateTeamAssignmentWorkOrderTeam({
        workOrderId: workOrderId as string,
        updateScheduleAssignmentsInput: createScheduleAssignmentsInput,
      });
    }
  };

  const toISOStringOrNull = (date: Date | null | undefined): string | null => {
    return date ? new Date(date).toISOString() : null;
  };

  const parseDateString = (dateString: string | null): Date | null => {
    return dateString ? new Date(dateString) : null;
  };

  const getTeamAssignments = (
    finalMembers: any[],
    startDate: string,
    endDate: string,
    vendorId: string
  ): CreateWorkOrderTeam[] => {
    const parsedStartDate = parseDateString(startDate);
    const parsedEndDate = parseDateString(endDate);

    return finalMembers.map((item: any) => {
      const newMemberInput: CreateWorkOrderTeam = {
        userId: item.id || '',
        vendorId,
        roleId: item.roleId as string,
        isSupervisor: item.isSupervisor,
      };

      if (item.teamId) {
        return {
          ...newMemberInput,
          teamId: item.teamId,
          associateStartDate: toISOStringOrNull(parsedStartDate),
          associateEndDate: toISOStringOrNull(parsedEndDate),
        };
      } else if (!item.isSupervisor) {
        return {
          ...newMemberInput,
          associateStartDate: toISOStringOrNull(parsedStartDate),
          associateEndDate: toISOStringOrNull(parsedEndDate),
        };
      }
      return newMemberInput;
    });
  };

  const handleAddAssociates = async (startDate: string, endDate: string) => {
    setIsConfirmButtonLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails.customerId;

      if (isDateBefore(endDate, startDate)) {
        setIsConfirmButtonLoading(false);
        showSnackbar('error', `${t('oneTimeJob:endDateShouldBeGreaterThanStartDate')}`);
        return;
      }

      const finalAssociates = selectedAssociates.filter((data: { isAlreadyChecked: any }) => !data?.isAlreadyChecked);
      const editTeamMembers = selectedAssociates.filter(
        (data: { id: string }) => data?.id === editAssociateShiftDate?.userId
      );
      let finalMembers;
      if (isEmpty(editAssociateShiftDate?.userId)) {
        finalMembers = finalAssociates;
      } else {
        finalMembers = editTeamMembers;
      }
      if (finalMembers.length) {
        const createScheduleAssignmentsInput = getTeamAssignments(finalMembers, startDate, endDate, vendorId);

        const confirmedTeamResponse = await saveTeamAssignments(
          createScheduleAssignmentsInput,
          editAssociateShiftDate?.userId
        );

        if (confirmedTeamResponse.errors?.length) {
          showSnackbar('error', confirmedTeamResponse.errors[0].message);
        } else {
          showSnackbar('success', `${t('messages:successfullyAssignedAssociate')}`);
          handleCloseAddButton(Role.Associate);
          await getWorkOrderDetails(jobId as string);
          await fetchAllAssociateTeam(workOrderId as string);
        }
      }
      setIsConfirmButtonLoading(false);
    } catch (e) {
      setIsConfirmButtonLoading(false);
      console.error('Error', e);
    }
  };

  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleDeleteTeamMember = async (teamMemberDetails: ITeamMemberData | undefined) => {
    try {
      setIsDeleteButtonLoading(true);
      const userId = teamMemberDetails?.id ?? '';
      const deleteAssociateResponse = await WorkOrdersApiService.deleteTeamAssignmentWorkOrderTeam({
        workOrderId: workOrderId as string,
        userIds: [userId],
        woScheduleMasterId: scheduleId,
      });
      console.log('Team member deleted successfully', deleteAssociateResponse);
      if (teamMemberDetails?.role === Role.Associate) {
        showSnackbar('success', `${t('messages:successfullyDeletedAssociate')}`);
      } else {
        console.log('e');
      }
      setIsDeleteButtonLoading(false);
      handleCloseDeleteModal();
      handleCloseAddButton(teamMemberDetails?.role === Role.Associate ? Role.Associate : Role.Supervisor);
      await fetchAllAssociateTeam(workOrderId as string);
    } catch (error) {
      setIsDeleteButtonLoading(false);
      console.error('Failed to delete team member', error);
    } finally {
      setIsDeleteButtonLoading(false);
    }
  };

  const renderAddAssociatesBodyContent = (type: Role) => {
    return (
      <>
        <AddTeamMember
          teamMembers={associatesTeam}
          setTeamMembers={setAssociatesTeam}
          originalTeam={originalAssociatesTeam}
          setOriginalTeam={setOriginalAssociatesTeam}
          selectedTeamMembers={selectedAssociates}
          setSelectedTeamMembers={setSelectedAssociates}
          isDataLoading={isAssociateDataLoading}
          onClose={() => handleCloseAddButton(type)}
          handleAddTeamMember={handleAddAssociates}
          assignmentType={type}
          workOrderData={workOrderData}
          isButtonLoading={isConfirmButtonLoading}
          deleteTeamMember={true}
          editTeamMember={true}
          handleDeleteTeamMember={handleDeleteTeamMember}
          isDeleteModelOpen={isDeleteModelOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          isDeleteButtonLoading={isDeleteButtonLoading}
          handleCloseDeleteModal={handleCloseDeleteModal}
          editAssociateShiftDate={editAssociateShiftDate}
          setEditAssociateShiftDate={setEditAssociateShiftDate}
          shiftData={[rowInfo]}
        />
      </>
    );
  };

  const getAssociateTeamResponse = async (
    userDetails: any,
    vendorId: string,
    searchString?: string,
    pageNumber?: number,
    limit?: number
  ) => {
    return isDistrictManager
      ? await WorkOrdersApiService.getVendorTeam(
          userDetails?.data?.userId,
          '',
          BooleanType.True,
          searchString || '',
          pageNumber,
          limit,
          true
        )
      : await OrganizationService.getAllUsers(vendorId, '', '', pageNumber, limit, BooleanType.True, true);
  };

  const processUserData = (user: UserForUserManagement) => {
    const { roleName = '', roleId = '' } = !isEmpty(user?.roles) ? user?.roles?.[0] || {} : {};

    return {
      id: user?.userId,
      userId: user?.userId,
      name: user?.username,
      isChecked: false,
      role: roleName,
      roleId,
      isSupervisor: false,
      isDisabled: false,
      isJobNotAccepted: false,
    };
  };

  const processMemberData = (
    member: any,
    workOrderTeamData: any[],
    tempCheckedMember: ITeamMemberData[],
    onBehalfOfAssociate: boolean
  ) => {
    const tempMember = { ...member };
    const workOrderTeamMember = workOrderTeamData?.find(
      (teamMember: IWorkOrderTeam) => teamMember?.userId === member?.userId
    );

    if (!workOrderTeamMember) return tempMember;

    const isChecked = true;
    const isAlreadyChecked = true;
    const teamId = workOrderTeamMember.teamId || '';
    const isSupervisor = workOrderTeamMember.isSupervisor;

    if (onBehalfOfAssociate || !isSupervisor) {
      tempCheckedMember.push({
        ...tempMember,
        isChecked,
        isAlreadyChecked,
        isDisabled: onBehalfOfAssociate,
        teamId,
        isSupervisor,
        associateStartDate: workOrderTeamMember?.associateStartDate,
        associateEndDate: workOrderTeamMember?.associateEndDate,
      });

      return {
        ...tempMember,
        isChecked,
        isAlreadyChecked,
        isDisabled: onBehalfOfAssociate,
        teamId,
        isSupervisor,
        associateStartDate: workOrderTeamMember?.associateStartDate,
        associateEndDate: workOrderTeamMember?.associateEndDate,
      };
    }

    return { ...tempMember, teamId };
  };

  const processAssociateTeamData = async (
    vendorTeamData: any[],
    workOrderTeamData: any[],
    onBehalfOfAssociate: boolean
  ) => {
    const tempCheckedMember: ITeamMemberData[] = [];

    const modifiedVendorTeam = await Promise.all(vendorTeamData?.map((user) => processUserData(user)));

    const modifiedData = await Promise.all(
      modifiedVendorTeam.map((member: any) =>
        processMemberData(member, workOrderTeamData, tempCheckedMember, onBehalfOfAssociate)
      )
    );

    if (!onBehalfOfAssociate) {
      setSelectedAssociates([...tempCheckedMember]);
    }

    return modifiedData;
  };

  const fetchAllAssociateTeam = async (
    workOrderId: string,
    pageNumber?: number,
    limit?: number,
    isPaginationDataLoader?: boolean,
    searchString?: string
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const customerDetails = getStoredCustomerDetails();
    const vendorId = customerDetails?.customerId;
    const { userId } = getAuthData();
    const onBehalfOfAssociate = false;
    let workOrderTeamResponse;
    if (userId || vendorId) {
      setIsAssociateDataLoading(true);
      try {
        const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);

        let associateTeamResponse;
        associateTeamResponse = await getAssociateTeamResponse(userDetails, vendorId, searchString, pageNumber, limit);

        if (associateTeamResponse?.metadata?.totalCount > 10) {
          associateTeamResponse = await getAssociateTeamResponse(
            userDetails,
            vendorId,
            searchString,
            pageNumber,
            associateTeamResponse?.metadata?.totalCount
          );
        }

        if (associateTeamResponse.errors.length) {
          showSnackbar('error', associateTeamResponse?.errors[0]?.message);
          setIsAssociateDataLoading(false);
          return;
        }
        if (workOrderId && scheduleId) {
          workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeams({
            workOrderId,
            woScheduleMasterId: scheduleId,
          });
          if (workOrderTeamResponse.errors.length) {
            showSnackbar('error', workOrderTeamResponse?.errors[0]?.message);
            setIsAssociateDataLoading(false);
            return;
          }
        }
        const modifiedData = await processAssociateTeamData(
          associateTeamResponse.data,
          workOrderTeamResponse?.data || [],
          onBehalfOfAssociate
        );

        setAssociatesTeam(modifiedData);
        setOriginalAssociatesTeam(modifiedData);
        setIsAssociateDataLoading(false);
      } catch (e) {
        console.error('Error', e);
        setAssociatesTeam([]);
        setOriginalAssociatesTeam([]);
        setIsAssociateDataLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchCustomerId = async () => {
      try {
        const organizationDetails = await MasterDataApiService.getOrgDetailsByUserName();
        const organizationId = organizationDetails?.data?.organization?.organizationId;
        setLoggedInUsersOrganizationId(organizationId);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      }
    };

    void fetchCustomerId();
  }, []);

  useEffect(() => {
    if (jobId) {
      void getWorkOrderDetails(jobId);
    }
  }, [jobId]);

  useEffect(() => {
    const customerDetails = getStoredCustomerDetails();
    const vendorIds = customerDetails?.customerId;
    const vendorId = vendorIds;
    const workDayTeamStatus = 'A';
    const fromDate = selectedDateRange?.fromDate ?? new Date();
    const toDate = selectedDateRange?.toDate ?? new Date();
    void fetchData(
      fromDate,
      toDate,
      currentPage,
      selectedAssociateId as string,
      selectedFacilityId as string,
      selectedRoleBasedId as string,
      vendorId,
      workDayTeamStatus,
      ''
    );
  }, [selectedDateRange, itemsPerPage, currentPage, '', '']);

  useEffect(() => {
    void fetchAllAssociateTeam(workOrderId as string, currentPage, 10, false, '');
  }, [workOrderId, scheduleId]);

  useEffect(() => {
    void fetchAllAssociates();
  }, [role]);

  useEffect(() => {
    void fetchCustomersAndVendors();
  }, []);

  useEffect(() => {
    void fetchFacilities();
  }, []);

  const columns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'workOrderName',
        header: 'Job Name',
        enableSorting: false,
        enableColumnFilter: false,
        size: 200,
        Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
        enableHiding: false,
        enableColumnActions: true,
      },
      {
        accessorKey: 'facilityName',
        header: 'Facility Name',
        enableSorting: false,
        enableColumnFilter: false,
        size: 200,
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
        enableColumnActions: false,
      },
      {
        accessorKey: 'workDate',
        header: 'Schedule Date',
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
        enableColumnActions: false,
      },
      {
        accessorKey: 'shiftStartDateTime',
        header: 'Schedule Start Time',
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ renderedCellValue }) => {
          const time = typeof renderedCellValue === 'string' ? formatTime(renderedCellValue) : '-';
          return <span>{time}</span>;
        },
        enableColumnActions: false,
      },
      {
        accessorKey: 'shiftEndDateTime',
        header: 'Schedule End Time',
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ renderedCellValue }) => {
          const time = typeof renderedCellValue === 'string' ? formatTime(renderedCellValue) : '-';
          return <span>{time}</span>;
        },
        enableColumnActions: false,
      },
      ...(storedAuthDetails[CUSTOM_ROLE_KEY] === UserType.Vendor
        ? [
            {
              header: t('tableHeader:actions'),
              enableColumnActions: false,
              enablePinning: false,
              size: 90,
              Cell: ({ row }: any) => renderActionsCell(row),
            },
          ]
        : []),
    ],
    [specificDate]
  );

  return (
    <>
      <PageTitle title={t('homePage:upcomingShifts')} />
      <CustomerContainer>
        <TitleWrapper>
          <JobsTitle>{t('homePage:upcomingShifts')}</JobsTitle>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <DateRangeSelection
              onDateRangeChange={handleDateRangeChange}
              defaultOption={defaultDateRangeOption}
              options={dateRangeOptions}
            />
            {role === UserType.Vendor && (
              <CustomDropDown
                title={filters.find((filter) => filter.name === FilterName.Associates)?.title}
                options={
                  filters.find((filter: { name: string }) => filter.name === FilterName.Associates)?.options || []
                }
                selectedValue={selectedAssociate}
                onChange={handleAssociateVendorChanges}
                isLoading={isAssociateAndVendorLoading}
                defaultOption={defaultRoleBasedOption}
              />
            )}
            {role === UserType.Customer && (
              <CustomDropDown
                title={filters.find((filter) => filter.name === FilterName.Location)?.title}
                options={filters.find((filter: { name: string }) => filter.name === FilterName.Location)?.options || []}
                selectedValue={selectedFacility}
                onChange={handleLocationChanges}
                isLoading={isFacilityLoading}
                defaultOption={defaultRoleBasedOption}
              />
            )}
            <CustomDropDown
              title={filters.find((filter) => filter.name === FilterName.CustomerAndVendor)?.title}
              options={
                filters.find((filter: { name: string }) => filter.name === FilterName.CustomerAndVendor)?.options || []
              }
              selectedValue={selectedCustomerVendor}
              onChange={handleCustomerVendorChanges}
              isLoading={isCustomerVendorLoading}
              defaultOption={defaultRoleBasedOption}
            />
          </Box>
        </TitleWrapper>
        <TableWrapper>
          <>
            <DataGrid
              columns={columns}
              data={jobs}
              enableRowSelect={false}
              enableColumnPinning={false}
              loader={isLoading}
              errorMessageTitle={''}
              errorMessageDescription={t('homePage:shiftNotFound')}
            />
            <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
              {!isLoading && totalCount > 10 && (
                <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                  <StyledPagination
                    count={Math.ceil(totalCount / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    color="primary"
                  />
                </Stack>
              )}
              {!isLoading && (
                <>
                  <Stack
                    spacing={2}
                    justifyContent="center"
                    sx={{
                      marginTop: '1rem',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '3rem',
                    }}>
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '1rem',
                      }}>
                      {t('dashboard:totalItems')}: {totalCount}
                    </Typography>
                  </Stack>
                  <SnackbarMessage
                    open={snackbarOpen}
                    successMessage={snackbarMessage}
                    errorMessage={snackbarMessage}
                    severity={snackbarSeverity}
                    onClose={() => setSnackbarOpen(false)}
                  />
                  <UpdateShiftDateCard
                    openUpdateShift={openUpdateShiftDate}
                    handleShiftDateClose={() => setOpenUpdateShiftDate(false)}
                    isDataLoading={isShowUpdateShiftDateLoading}
                    handleOnConfirm={handleOnUpdateShiftDate}
                    shiftData={jobs}
                    setStartTimeSelectOpen={setStartTimeSelectOpen}
                    setEndTimeSelectOpen={setEndTimeSelectOpen}
                    isStartTimeSelectOpen={isStartTimeSelectOpen}
                    isEndTimeSelectOpen={isEndTimeSelectOpen}
                  />
                  <Modal
                    open={isAddAssociatesModalOpen}
                    onClose={() => handleCloseAddButton(Role.Associate)}
                    renderHeader={renderAddAssociatesHeaderContent()}
                    renderBody={renderAddAssociatesBodyContent(Role.Associate)}
                  />
                </>
              )}
            </Box>
          </>
        </TableWrapper>
      </CustomerContainer>
    </>
  );
};

export default UpcomingShifts;

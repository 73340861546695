/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, IconButton, Snackbar, Stack, Typography } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { BooleanType } from '../../../../API';
import { getAuthData, getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import { FilterItem, UserType } from '../../../../Shared/Constants/App';
import { CompanyUserInterface } from '../../Models/Company.Model';
import VendorApiService from '../../../../Shared/Services/VendorService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../Jobs/Models/JobsView.Model';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import { isEmpty } from 'lodash';
import SnackbarMessage from '../../../../Shared/Components/Common/SnackbarMessage/SnackbarMessage';
import DeletePopup from '../../../../Shared/Components/Common/DeletePopup/DeletePopup';

import { Icons } from '../../../../Shared/Constants/Icons';

import theme from '../../../../Shared/Themes/theme';

import {
  ActionsColumn,
  Linking,
  Menus,
  MenuWrapper,
} from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { SnackBarWrapper } from '../../../Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';

const UsersList = (props: any): JSX.Element => {
  const [userData, setUserData] = useState<CompanyUserInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState('username');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [newSort, setNewSort] = useState<boolean>();
  const [newSortOrder, setNewSortOrder] = useState('');
  const [clickedRow, setClickedRow] = useState(null);
  const [rowInfo, setRowInfo] = useState<any>({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);
  const [deleteUserOrgId, setDeleteUserOrgId] = useState<string | null>(null);
  const [deleteUserName, setDeleteUserName] = useState<string | null>(null);
  const [columnFilters, setColumnFilters] = useState<FilterItem[]>([]);

  const { isDistrictManager } = getAuthData() || {};
  const itemsPerPage = 10;
  const { t } = useTranslation(['Dashboard', 'tableHeader', 'homePage', 'altTexts', 'calendar', 'delete']);

  const handleChange = (event: any, value: number): void => {
    setCurrentPage(value);
    getUserList(value, newSortOrder, newSort, columnFilters);
  };

  const getUserList = useCallback(
    async (pageNumber?: number, sortBy?: any, sortOrder?: any, columnFilters: FilterItem[] = []) => {
      try {
        setIsLoading(true);
        const { customerId = '' } = getStoredCustomerDetails() || {};
        const { role, isDistrictManager } = getAuthData() || {};

        let teamsResponse: any = { data: [], metadata: {} };
        let sortOrderString = sortOrder;
        if (sortOrder) {
          sortOrderString = 'desc';
        } else {
          if (sortBy) sortOrderString = 'asc';
        }
        const userNameSearchString = columnFilters.find((filter) => filter.id === 'username')?.value;

        if (role === UserType.Vendor) {
          const loggedInUser = await MasterDataApiService.getCurrentUserInfo();
          const userDetails = await AuthenticationService.getUserByCognitoUserId(loggedInUser?.username);

          teamsResponse = isDistrictManager
            ? await VendorApiService.getVendorTeam(
                userDetails?.data?.userId,
                '',
                undefined,
                userNameSearchString,
                pageNumber,
                itemsPerPage,
                sortBy,
                sortOrderString
              )
            : await OrganizationService.getAllUsers(
                customerId,
                '',
                '',
                pageNumber,
                itemsPerPage,
                BooleanType.True,
                true,
                sortBy,
                sortOrderString,
                userNameSearchString
              );
        } else if (role === UserType.Customer) {
          teamsResponse = await OrganizationService.getAllUsers(
            customerId,
            '',
            '',
            pageNumber,
            itemsPerPage,
            BooleanType.False,
            true,
            sortBy,
            sortOrderString,
            userNameSearchString
          );
        }
        // eslint-disable-next-line sonarjs/no-unused-collection
        const updatedUserData: any[] = [];
        teamsResponse?.data.map(async (user: any) => {
          const updatedUser: any = { ...user };
          updatedUser.roleName = user?.roles?.[0]?.roleName;
          updatedUserData.push({ ...updatedUser });
        });
        setUserData([...updatedUserData]);
        setTotalCount(teamsResponse?.metadata?.totalCount);
        setIsLoading(false);

        return [];
      } catch (e) {
        console.error('Error', e);
        return [];
      }
    },
    []
  );

  const myCustomSortingFn = (): void => {
    setSortBy('DESC');
  };

  const handleSort = async (sortingObj: any): Promise<void> => {
    setSorting(sortingObj);
    try {
      if (sortingObj.length) {
        setNewSortOrder(sortingObj[0].id);
        setNewSort(sortingObj[0].desc);
        await getUserList(currentPage, sortingObj[0].id, sortingObj[0].desc, columnFilters);
      } else {
        await getUserList(currentPage);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const filterJobs = (filtersObj: any): void => {
    setColumnFilters(filtersObj);
    if (filtersObj?.length) {
      getUserList(currentPage, newSortOrder, newSort, filtersObj);
    } else {
      getUserList(currentPage, newSortOrder, newSort, []);
    }
  };

  const onSecondaryButtonClick = async (): Promise<void> => {
    setIsDeletePopupVisible(false);
    if (deleteUserId && deleteUserOrgId && deleteUserName) {
      await deleteUser(deleteUserOrgId, deleteUserId, deleteUserName);
    }
  };

  const deleteUser = async (organizationId: string, userId: string, username: string): Promise<void> => {
    try {
      setIsLoading(true);
      const payload = { organizationId, userIds: [userId] };
      const deleteUserResponse = await OrganizationService.deleteUsers(payload);
      if (deleteUserResponse.data && isEmpty(deleteUserResponse?.errors)) {
        const updatedUsers = userData.filter((user) => user.userId !== userId);
        setUserData(updatedUsers);
        setTotalCount(updatedUsers.length);
        setSnackbarMessage(`${username} ${t('delete:deletedSuccessfully')}`);
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(`${t('delete:anErrorOccurred')}`);
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    } catch (e) {
      console.error('Error deleting user:', e);
      setSnackbarMessage(`${t('delete:anErrorOccurred')}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  // material UI Logic
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const renderActionsCell = (row: any): JSX.Element => {
    return (
      <ActionsColumn>
        <IconButton
          onClick={(e) => {
            handleClick(e);
            setClickedRow(row.index);
            setRowInfo(row.original);
          }}
          key={row.index}
          sx={{
            backgroundColor: clickedRow === row.index && open ? theme.palette.primary.main : 'unset',
            '&:hover': {
              background: theme.palette.primary.main,
            },
          }}
          size="large"
          aria-haspopup="true"
          {...(open && { 'aria-expanded': 'true', 'aria-controls': 'account-menu' })}>
          <MoreVertIcon sx={{ color: theme.palette.common.black }}></MoreVertIcon>
        </IconButton>
        <MenuWrapper anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
          <Linking to={{ pathname: `/company/userDetails` }} state={{ rowInfo }}>
            <Menus>
              <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} /> {t('dashboard:viewDetails')}
            </Menus>
          </Linking>
          {!isDistrictManager && rowInfo.roleName !== 'AccountHolder' && (
            <Menus
              onClick={() => {
                setDeleteUserId(rowInfo.userId);
                setDeleteUserOrgId(rowInfo.organizationId);
                setDeleteUserName(rowInfo.username);
                setIsDeletePopupVisible(true);
              }}>
              <img src={Icons.DeleteIcon} alt={t('delete:delete')} /> {t('delete:delete')}
            </Menus>
          )}
        </MenuWrapper>
      </ActionsColumn>
    );
  };

  const userColumns = useMemo<
    MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | CompanyUserInterface | IProfileCardProps>[]
  >(
    () => [
      {
        accessorKey: 'username',
        header: t('tableHeader:name'),
        enableSorting: true,
        enableColumnFilter: true,
        size: 234,
        manualSorting: true,
        state: { sortBy, columnFilters },
        onSortingChange: setSortBy,
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        Cell: ({ renderedCellValue }) => props.renderCell(renderedCellValue),
      },
      {
        accessorKey: 'roleName',
        header: t('tableHeader:role'),
        enableSorting: true,
        enableColumnFilter: false,
        size: 234,
        manualSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnActions: true,
        sortingFn: 'alphanumericCaseSensitive',
        Cell: ({ renderedCellValue }) => props.renderCell(renderedCellValue),
      },
      {
        accessorKey: 'email',
        header: t('customer:emailAddress'),
        enableSorting: true,
        enableColumnFilter: false,
        size: 178,
        enableColumnActions: true,
        Cell: ({ renderedCellValue }) => props.renderCell(renderedCellValue),
      },
      {
        accessorKey: 'phoneNumber',
        header: t('customer:contactNumber'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 178,
        enableColumnActions: true,
        Cell: ({ renderedCellValue }) => props.renderCell(renderedCellValue),
      },
      {
        header: t('tableHeader:actions'),
        enableColumnActions: false,
        enablePinning: false,
        size: 90,
        Cell: ({ row }) => renderActionsCell(row),
      },
    ],
    [handleClose]
  );

  useEffect(() => {
    getUserList(currentPage);
  }, []);

  return (
    <>
      <>
        <DataGrid
          columns={userColumns}
          data={userData}
          enableRowSelect={false}
          enableColumnPinning={false}
          loader={isLoading}
          sortingObj={sorting}
          filter={filterJobs}
          sort={handleSort}
          sortingFns={{
            myCustomSortingFn: myCustomSortingFn,
          }}
          errorMessageTitle={t('noData:noUsersYet')}
          errorMessageDescription={t('noData:userComeBack')}
        />
        {!isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {!isLoading && totalCount > itemsPerPage && (
              <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                <StyledPagination
                  count={Math.ceil(totalCount / itemsPerPage)}
                  page={currentPage}
                  onChange={(event, value) => {
                    handleChange(event, value);
                    if (newSortOrder) {
                      getUserList(value, newSortOrder, newSort, columnFilters);
                    } else {
                      getUserList(value, '', '', []);
                    }
                  }}
                  color="primary"
                />
              </Stack>
            )}
            {totalCount > 0 && (
              <Stack spacing={2} justifyContent="center" style={{ marginTop: '1.25rem' }}>
                <Typography>
                  {' '}
                  {t('dashboard:totalItems')}: {totalCount}
                </Typography>
              </Stack>
            )}
          </Box>
        )}
      </>
      <SnackBarWrapper>
        <Link to="/addUsers">
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            action={
              <Button
                icon={<img src={Icons.PlusWhiteIcon} alt={t('altTexts:addIcon')} />}
                type={ButtonType.Primary}
                label={t('homePage:addUser')}
              />
            }
          />
        </Link>
      </SnackBarWrapper>

      <DeletePopup
        open={isDeletePopupVisible}
        onClose={() => setIsDeletePopupVisible(false)}
        message={`${t('delete:deleteUserText')}`}
        name={`${deleteUserName}?`}
        primaryButtonLabel={t('delete:no')}
        primaryAction={() => setIsDeletePopupVisible(false)}
        secondaryButtonLabel={t('delete:yes')}
        secondaryAction={onSecondaryButtonClick}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default UsersList;

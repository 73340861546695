/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  DialogProps,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import {
  ErrorMsgWrapper,
  JobNameWrapper,
  ServiceFieldWrapper,
  TextFieldAlignment,
  buttonStyles,
} from '../AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { useTranslation } from 'react-i18next';
import { Icons } from '../../../../Shared/Constants/Icons';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { AssignmentCardWrapper, HeadingText } from './OneTimeJobDetails.Style';
import { CloseIconStyle } from '../../../Vendor/Components/CreateBid.Style';
import theme from '../../../../Shared/Themes/theme';
import { v4 as uuidv4 } from 'uuid';
import { getAuthData } from '../../../../Shared/Utilities/utils';
import { IDataProps } from './PreShiftCheckList';
import { isEmpty } from 'lodash';

interface AddItem {
  addItemOpen: boolean;
  handleAddItemCardClose: () => void;
  handleAddItem: (data: any, isEdit: boolean) => void;
  addItemData: any;
  handleAddItemDelete: (data: any) => void;
  checkListData: IDataProps[];
  selectedCatalogue: string;
  setSelectedCatalogue: (id: string) => void;
}

const AddItem = ({
  addItemOpen,
  handleAddItemCardClose,
  handleAddItem,
  addItemData,
  handleAddItemDelete,
  checkListData,
  selectedCatalogue,
  setSelectedCatalogue,
}: AddItem) => {
  const { t } = useTranslation(['assignJob', 'oneTimeJob', 'calendar', 'altTexts']);
  const typeOfUnitData = [
    {
      label: t('assignJob:rolls'),
      value: 'rolls',
    },
    {
      label: t('assignJob:pair'),
      value: 'pair',
    },
    {
      label: t('assignJob:unit'),
      value: 'unit',
    },
    {
      label: t('assignJob:kg'),
      value: 'kg',
    },
    {
      label: t('assignJob:litre'),
      value: 'litre',
    },
  ];
  const typeOfUnitField = typeOfUnitData.map((item) => {
    return {
      id: item.value,
      isSelected: false,
      value: item.value,
      //   textNode: <TextLabel type={TextLabelType.Body1Regular}>{item.label}</TextLabel>,
      textLabel: item.label, // item.value,
      disabled: false,
    };
  });
  const {
    textField,
    serviceScheduleContent,
    radioButton,
    radioButtonText,
    executionType,
    RadioType,
    divider,
    textFieldService,
    saveExitButton,
    buttonText,
    createJobButton,
  } = buttonStyles;

  const [itemName, setItemName] = useState<string>(addItemData.title ?? '');
  const [selectedToBeOption, setSelectedToBeOption] = useState<string>(addItemData.status ?? '');
  const [selectedTypeOfUnit, setSelectedTypeOfUnit] = useState<string>(addItemData.quantityName ?? '');
  const [isTypeOfUnitOpen, setTypeOfUnitOpen] = useState<boolean>(false);
  const [unitQuantity, setUnitQuantity] = useState<string>(addItemData.quantity ?? '');
  const [unitQuanityError, setUnitQuantityError] = useState<string>('');
  const { isDistrictManager } = getAuthData();

  useEffect(() => {
    setItemName(addItemData.title ?? '');
    setSelectedToBeOption(addItemData.status ?? '');
    setSelectedTypeOfUnit(addItemData.quantityName ?? '');
    setUnitQuantity(addItemData.quantity ?? '');
  }, [addItemData]);

  const handleToBeOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedToBeOption(event.target.value);
  };

  const handleUnitQuantityChange = (value: string) => {
    if (/^\d+$/.test(value) || value === '') {
      setUnitQuantity(value);
      setUnitQuantityError('');
    } else {
      setUnitQuantityError('Please enter a valid number.');
    }
  };

  const handleAddItemClose: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleAddItemCardClose();
  };

  const handleTypeOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCatalogue(event.target.value);
  };

  const handleSaveButton = () => {
    const itemData = {
      id: addItemData.id || String(uuidv4()),
      title: itemName,
      status: selectedToBeOption,
      quantityName: selectedTypeOfUnit,
      quantity: parseFloat(unitQuantity),
    };
    handleAddItem(itemData, addItemData.id ? true : false);
    setItemName('');
    setSelectedToBeOption('');
    setSelectedTypeOfUnit('');
    setUnitQuantity('');
  };

  const disableAddItemButton = useCallback(() => {
    return itemName && selectedToBeOption && selectedTypeOfUnit && unitQuantity ? false : true;
  }, [itemName, selectedToBeOption, selectedTypeOfUnit, unitQuantity]);

  return (
    <AssignmentCardWrapper open={addItemOpen} onClose={handleAddItemClose}>
      <CloseIconStyle onClick={handleAddItemCardClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      <HeadingText>{t('assignJob:addItem')}</HeadingText>
      <Divider sx={{ ...divider, margin: '1em 0' }} />
      <JobNameWrapper sx={{ marginBottom: '1em', width: '100%' }}>
        <TextField
          autoComplete="off"
          sx={{ ...textField, width: '100%' }}
          id="outlined-required"
          label={
            <span>
              {t('assignJob:itemName')} <span className="mandatory-field">*</span>
            </span>
          }
          placeholder={t('assignJob:enterItemName')}
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
        />
      </JobNameWrapper>
      <div style={{ marginBottom: '1em' }}>
        Type <span className="mandatory-field">*</span>
      </div>
      <Box
        sx={{
          ...serviceScheduleContent,
          marginBottom: '2em',
        }}>
        <FormControl
          component="fieldset"
          sx={{
            width: '100%',
            pointerEvents: !isEmpty(addItemData) ? 'none' : 'auto',
            opacity: !isEmpty(addItemData) ? 0.5 : 1,
          }}>
          <RadioGroup
            row
            value={selectedCatalogue}
            onChange={handleTypeOptionChange}
            sx={{ ...executionType, display: 'flex', flexDirection: 'column' }}>
            {checkListData.map((item: any, index: number) => {
              return (
                <Box key={index} sx={{ marginBottom: '0.5em' }}>
                  <FormControlLabel
                    value={item.id}
                    control={
                      <Radio
                        sx={{
                          ...radioButton,
                        }}
                      />
                    }
                    label={item.categoryTitle}
                    sx={{
                      ...radioButtonText,
                    }}
                  />
                </Box>
              );
            })}
          </RadioGroup>
        </FormControl>
      </Box>
      <div style={{ marginBottom: '1em' }}>
        To be <span className="mandatory-field">*</span>
      </div>
      <Box
        sx={{
          ...serviceScheduleContent,
          marginBottom: '2em',
        }}>
        <FormControl component="fieldset" sx={{ width: '100%' }}>
          <RadioGroup row value={selectedToBeOption} onChange={handleToBeOptionChange} sx={RadioType}>
            <Box>
              <FormControlLabel
                value="toBeBrought"
                control={
                  <Radio
                    sx={{
                      ...radioButton,
                    }}
                  />
                }
                label={t('assignJob:toBeBrought')}
                sx={{
                  ...radioButtonText,
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                value="provided"
                control={
                  <Radio
                    sx={{
                      ...radioButton,
                    }}
                  />
                }
                label={t('assignJob:provided')}
                sx={{
                  ...radioButtonText,
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                value="buyFromBestBuy"
                control={
                  <Radio
                    sx={{
                      ...radioButton,
                    }}
                  />
                }
                label={t('assignJob:buyFromBestBuy')}
                sx={{
                  ...radioButtonText,
                }}
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
      {/* <Divider sx={{ ...divider, margin: '1em 0' }} /> */}
      <TextFieldAlignment>
        <Box sx={{ width: '100%' }}>
          <TextField
            select
            sx={{ ...textField, marginBottom: '2rem', width: '100%' }}
            label={
              <span>
                {t('assignJob:typeOfUnit')} <span className="mandatory-field">*</span>
              </span>
            }
            value={selectedTypeOfUnit}
            onChange={(event) => setSelectedTypeOfUnit(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setTypeOfUnitOpen(!isTypeOfUnitOpen)}
                    style={{
                      padding: 0,
                    }}>
                    <img
                      src={isTypeOfUnitOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                      alt={isTypeOfUnitOpen ? 'down' : 'up'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            SelectProps={{
              open: isTypeOfUnitOpen,
              IconComponent: () => null,
              onOpen: () => setTypeOfUnitOpen(true),
              onClose: () => setTypeOfUnitOpen(false),
              MenuProps: {
                PaperProps: {
                  style: {
                    boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)',
                  },
                },
              },
            }}>
            {typeOfUnitField.map((unit: any, index: number) => (
              <Options key={index} value={unit.value}>
                {unit.textLabel}
              </Options>
            ))}
          </TextField>
        </Box>
        <ServiceFieldWrapper sx={{ width: '100%' }}>
          <TextField
            sx={{ ...textFieldService, width: '100%' }}
            label={
              <span>
                {t('assignJob:units')} <span className="mandatory-field">*</span>
              </span>
            }
            placeholder={t('assignJob:enterUnits')}
            value={unitQuantity}
            onChange={(e) => handleUnitQuantityChange(e.target.value)}
            autoComplete="off"
          />
          <ErrorMsgWrapper>{unitQuanityError}</ErrorMsgWrapper>
        </ServiceFieldWrapper>
      </TextFieldAlignment>
      <div style={{ display: 'flex' }}>
        {!isDistrictManager && (
          <Button
            disabled={!addItemData.id}
            sx={{
              ...saveExitButton,
              opacity: !addItemData.id ? '0.5' : '1',
              cursor: 'pointer',
              maxWidth: '100%',
              marginTop: '2em',
              width: '100%',
            }}
            onClick={() =>
              handleAddItemDelete({
                id: addItemData.id,
                title: itemName,
                quantity: Number(unitQuantity),
                quantityName: selectedTypeOfUnit,
                status: addItemData.status,
              })
            }>
            <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>{t('altTexts:delete')}</Typography>
          </Button>
        )}
        <Button
          disabled={disableAddItemButton()}
          sx={{
            ...createJobButton,
            opacity: disableAddItemButton() ? '0.5' : '1',
            maxWidth: '100%',
            marginTop: '2em',
            width: '100%',
          }}
          onClick={handleSaveButton}>
          <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>{t('assignJob:save')}</Typography>
        </Button>
      </div>
    </AssignmentCardWrapper>
  );
};

export default AddItem;

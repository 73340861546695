/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';

import { allJobBidEstimation, jobBidSummary } from '../../../graphql/queries';

import { DeleteJobBidInput } from '../../../API';
import { deleteJobBids } from '../../../graphql/mutations';
import { AuthHeader, AWSAuthMode } from '../../../Shared/Constants/App';
import { getWebInfo } from '../../../Shared/Utilities/utils';
import { IGraphQLResponse } from '../../../Shared/Models';
import { AuthenticationService } from '../../../Shared/Services/AuthenticationService';

export default class VendorApiService {
  static async allServicesLinkedToBid(jobId: string, bidId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: allJobBidEstimation,
          variables: { bidId, jobId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.allJobBidEstimation ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async getBidSummary(bidId: string) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const response: IGraphQLResponse = await API.graphql(
        {
          query: jobBidSummary,
          variables: { bidId },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: response.data?.jobBidSummary ?? [], errors: [] };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  static async deleteJobBids(deleteJobBidsInput: DeleteJobBidInput) {
    try {
      const authHeaders: AuthHeader = await AuthenticationService.getAuthHeaders();
      const deleteJobBidsResponse: IGraphQLResponse = await API.graphql(
        {
          query: deleteJobBids,
          variables: { deleteJobBidsInput },
          authMode: AWSAuthMode.COGNITO,
        },
        { ...getWebInfo(), ...authHeaders }
      );
      return { data: deleteJobBidsResponse.data?.deleteJobBids ?? {}, errors: [] };
    } catch (e: any) {
      return { data: {}, errors: e?.errors ?? [] };
    }
  }
}

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';

export const buttonStyles = {
  starRatingButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1.25rem',
    padding: '1.0625rem 2.95rem',
    textTransform: 'none',
    maxWidth: '100%',
    marginTop: '2em',
    width: '100%',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
    },
  },
  cancelButton: {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    background: theme.palette.common.white,
    borderRadius: '1.25rem',
    textTransform: 'none',
    marginRight: '1.5rem',
    padding: '1.0625rem 2rem',
    cursor: 'pointer',
    maxWidth: '100%',
    marginTop: '2em',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
      marginRight: '1rem',
    },
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    letterSpacing: '0.009375rem',
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: '0.0125rem',
    },
  },
};

export const RatingSystemContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', //'center',
  boxShadow: '0 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10);',
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  marginBottom: '1.5rem',
  padding: '1.5rem',
}));

export const HeadingText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

export const ButtonWrapper = styled('div')(() => ({
  display: 'flex',
  width: '50%',
  margin: 'auto',
}));

export const RatingWrapper = styled('div')(() => ({
  margin: 'auto',
}));

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../../Shared/Themes/theme';

export const UserDetailsContainer = styled('div')(() => ({
  padding: '1.5rem',
  borderRadius: '1rem',
  background: theme.palette.common.white,
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '1.5rem',
}));

export const NameAndLabel = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const LabelText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  color: theme.palette.text.secondary,
}));

export const ValueText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  letterSpacing: '0.015625rem',
  color: theme.palette.text.secondary,
}));

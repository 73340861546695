/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { withTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react';
import { MRT_ColumnDef } from 'material-react-table';

import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../Jobs/Components/JobView/JobsView';
import { styled, Button, Typography, Box, Stack, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { buttonStyle, HeaderContainer, EstimationTableInput } from '../CreateBid.Style';
import { buttonStyles } from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';

export const Wrapper = styled('div')(() => ({
  backgroundColor: theme.palette.common.white,
}));

export const HeadingText = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: '0.009375rem',
  color: theme.palette.text.primary,
  paddingBottom: '1rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

interface EstimationTable {
  frequency: string;
  name: any;
  facilityStatus: string;
  serviceMeasure: string;
  numOfHours: number;
  ratePerHour: number;
  costPerService: number;
  servicesPerWeek: number;
  weeklyCost: number;
  profitPerWeek: number;
  weeklyBid: number;
  numOfWeeks: number;
  totalServices: number;
  additionalCategoryId: string;
  totalJobEstimation: number;
}

interface AdditionalExpensesTable {
  storedJob: any;
  totalEstimation: number;
  setTotalEstimation: (data: number) => void;
  additionalExpenseData: any[];
  setAdditionalExpenseData: (data: any) => void;
  additionalSubTotal: number;
  setAdditionalSubTotal: (data: number) => void;
  serviceOptions: any[];
  handleExpensesError: () => void;
  isEditable: boolean;
  isCardView: boolean;
  isServicesLoading?: boolean;
  t: any;
}

const BidEstimationTable = ({
  totalEstimation = 0,
  setTotalEstimation,
  additionalExpenseData,
  setAdditionalExpenseData,
  additionalSubTotal = 0,
  setAdditionalSubTotal,
  serviceOptions,
  handleExpensesError,
  isEditable,
  isCardView,
  isServicesLoading,
  t,
}: AdditionalExpensesTable): JSX.Element => {
  const { selectStyle } = buttonStyle;
  const [tableData, setTableData] = useState<any[]>([
    {
      name: '',
      totalJobEstimation: 0,
      mdServiceId: '',
      jobId: '',
      additionalCategoryId: '',
    },
  ]);
  const [selectedService, setSelectedService] = useState(
    additionalExpenseData
      ? additionalExpenseData.map((expenseItem) => {
          return {
            name: expenseItem.name,
            additionalCategoryId: `OTJ_AE${expenseItem.name}`,
            description: expenseItem.name,
            rate: expenseItem.totalJobEstimation,
            bidAdditionalExpenseId: '',
          };
        })
      : []
  );

  useEffect(() => {
    if (additionalExpenseData?.length) {
      setTableData(additionalExpenseData);
    } else {
      setTableData([
        {
          name: '',
          totalJobEstimation: 0,
          mdServiceId: '',
          jobId: '',
          additionalCategoryId: '',
        },
      ]);
    }
  }, [additionalExpenseData]);

  const { addButton, addButtonText, textField } = buttonStyles;

  const renderNameCell = (cellValue: any) => {
    if (isEditable) {
      return (
        <Autocomplete
          id="myDropdown"
          sx={{ ...selectStyle, width: '100%', height: 'auto', padding: '1rem 0' }}
          value={cellValue?.renderedCellValue ?? ''}
          onChange={(event: any, value: any) => {
            handleServicesName(value, cellValue.row.index, { type: 'change' });
            const updatedTableData = tableData.map((row, index) =>
              index === cellValue.row.index
                ? { ...row, mdServiceId: event.target.textContent, name: event.target.textContent }
                : row
            );
            setTableData(updatedTableData);
            setAdditionalExpenseData(updatedTableData);
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
            handleServicesName(event.target.value, cellValue.row.index, { type: 'blur' })
          }
          options={serviceOptions
            .filter((option) => !selectedService.some((service) => service?.name === option?.name))
            .map((option) => option?.name)}
          freeSolo // Allow free text entry
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ ...textField, width: 'inherit', height: 'inherit' }}
              label={t('vendor:expenses')}
            />
          )}
        />
      );
    } else {
      return cellValue.renderedCellValue;
    }
  };

  const handleServicesName = (value: string, index: number, event: { type: string }): void => {
    const selectedServiceObj = serviceOptions.find((service: any) => service?.name === value);
    const selectedServices = [...selectedService];
    if (event.type === 'blur') {
      const serviceOption = {
        name: value,
        additionalCategoryId: selectedServiceObj?.additionalCategoryId,
        description: value,
        rate: '',
        bidAdditionalExpenseId: '',
      };
      selectedServices[index] = serviceOption;
    } else {
      const selectedServiceOption = serviceOptions.find((service) => service?.name === value);
      selectedServices[index] = selectedServiceOption;
    }
    setSelectedService(selectedServices);
    const tableCopy = JSON.parse(JSON.stringify(tableData));
    tableCopy[index].name = value;
    setTableData([...tableCopy]);
  };

  const handleInputValue = (cell: any, value: any): void => {
    const tableCopy = JSON.parse(JSON.stringify(tableData));
    if (value.includes('.')) {
      const splicedInput = value.split('.');
      if (splicedInput?.[1]?.length <= 2) {
        tableCopy[cell.row.index][cell.column.id as keyof EstimationTable] = value;
      }
    } else {
      tableCopy[cell.row.index][cell.column.id as keyof EstimationTable] = value;
    }
    setTableData([...tableCopy]);
  };

  const renderTotalCell = (cellValue: string): JSX.Element => {
    let totalEstimation = 0;

    tableData.forEach((service) => {
      const cellVal = service[`${cellValue}`];
      if (cellVal) {
        totalEstimation += parseFloat(cellVal);
      }
    });

    setTotalEstimation(totalEstimation);

    // setAdditionalTotalEstimation(totalEstimation);

    return (
      <Box>
        <Stack>
          <Box>
            {isNaN(totalEstimation)
              ? '$0.00'
              : totalEstimation?.toLocaleString?.('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </Box>
        </Stack>
      </Box>
    );
  };

  const handleSaveCell = (cell: any, value: any): void => {
    const tableCopy = JSON.parse(JSON.stringify(tableData));

    let allowDecimalValue = false;
    if (value && value.includes('.')) {
      const splicedInput = value.split('.');
      if (splicedInput?.[1]?.length <= 2) {
        allowDecimalValue = true;
      } else {
        allowDecimalValue = false;
      }
    } else if (value && !value.includes('.')) {
      allowDecimalValue = true;
    }
    if (allowDecimalValue) {
      if (value && value > 0 && !selectedService?.[cell?.row?.index]?.name) {
        tableCopy[cell.row.index][cell.column.id as keyof EstimationTable] = 0;
        setTableData([...tableCopy]);
        handleExpensesError();
        return;
      }
      tableCopy[cell.row.index][cell.column.id as keyof EstimationTable] = value;
      tableCopy[cell.row.index].name = selectedService[cell.row.index] ? selectedService[cell.row.index].name : '';
      tableCopy[cell.row.index].additionalCategoryId = tableData?.length ? `OTJ_AE${tableData.length}` : 'OTJ_AE0';
      // finalTotalEstimation is table total amount
      const finalTotalEstimation = parseFloat(
        tableCopy.reduce((total: number, item: any) => {
          return parseFloat(String(total)) + parseFloat(item.totalJobEstimation);
        }, 0)
      );
      const totalCost =
        parseFloat(String(totalEstimation)) -
        parseFloat(String(additionalSubTotal)) +
        parseFloat(String(finalTotalEstimation));
      setTotalEstimation(totalCost);
      setAdditionalSubTotal(finalTotalEstimation);
      setTableData([...tableCopy]);
      setAdditionalExpenseData([...tableCopy]);
    }
  };

  useEffect(() => {
    if (!isEditable) {
      const storedJobData = localStorage.getItem(AsyncStorageKeys.viewJob);
      if (storedJobData) {
        const currentJobData = JSON.parse(storedJobData);
        if (currentJobData.additionalExpenseData?.length > 0) setTableData([...currentJobData.additionalExpenseData]);
      }
    }
  }, []);

  const columns = useMemo<MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Expense Name',
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: (cellValue) => renderNameCell(cellValue),
        Footer: () => t('vendor:subTotal'),
        size: 50,
      },
      {
        accessorKey: 'totalJobEstimation',
        header: 'Amount',
        size: 50,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ cell, renderedCellValue }: any) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.2em',
            }}>
            <span>${!isEditable ? renderedCellValue : null}</span>
            {isEditable && (
              <EstimationTableInput
                type="text"
                value={renderedCellValue}
                placeholder="Amount"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputValue(cell, event.target.value);
                  handleSaveCell(cell, event.target.value);
                }}
                // onBlur={(event: React.FocusEvent<HTMLInputElement>) => handleSaveCell(cell, event.target.value)}
                onInput={(event: React.FormEvent<HTMLInputElement>) => {
                  const inputElement = event.target as HTMLInputElement;
                  const inputValue = inputElement.value;
                  const numericInput = inputValue.replace(/[^0-9.]/, '');
                  inputElement.value = numericInput;
                }}
              />
            )}
          </Box>
        ),
        Footer: () => renderTotalCell('totalJobEstimation'),
      },
    ],
    [tableData, additionalSubTotal, serviceOptions]
  );

  const addRow = (): void => {
    const additionalCategoryId = tableData?.length ? `OTJ_AE${tableData.length + 1}` : 'OTJ_AE0';
    const row = {
      name: '',
      totalJobEstimation: 0,
      mdServiceId: '',
      jobId: '',
      additionalCategoryId,
    };
    setTableData((prevtable) => [...prevtable, row]);
  };

  const getInnerElements = (): JSX.Element => {
    return (
      <>
        <div style={{ display: 'flex', marginBottom: '1rem', alignItems: 'center', justifyContent: 'space-between' }}>
          <HeadingText sx={{ color: theme.palette.text.secondary }}>{t('vendor:additionalExpenses')} </HeadingText>
          {isEditable && (
            <Button
              onClick={addRow}
              variant="outlined"
              sx={{
                ...addButton,
                marginBottom: '0 !important',
                width: 'auto',
              }}>
              <img src={Icons.AddIcon} alt={t('altTexts:addIcon')} />
              <Typography sx={{ ...addButtonText, textTransform: 'none' }}>
                {t('vendor:addAdditionalExpenses')}
              </Typography>
            </Button>
          )}
        </div>
        <div>
          {isServicesLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
          ) : (
            <DataGrid
              columns={columns}
              data={tableData}
              enableColumnPinning={true}
              enableRowSelect={false}
              enableEditing={true}
              rightPinArr={['weeklyBid']}
              leftPinArr={['name', 'serviceMeasure']}
              handleSaveCell={handleSaveCell}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <HeaderContainer sx={!isCardView ? { boxShadow: 'unset', padding: 0 } : {}}>{getInnerElements()}</HeaderContainer>
  );
};

export default withTranslation(['vendor'])(BidEstimationTable);

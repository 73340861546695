/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { styled } from '@mui/material';
import theme from '../../../Themes/theme';

export const dropdownStyles = {
  inputField: {
    minWidth: '5rem',
    width: '22.5rem',
  },
  labelStyles: {
    color: `${theme.palette.text.secondary} !important`,
  },
  selectField: {
    paddingRight: '0.75rem !important',
    '& fieldset': {
      borderColor: `${theme.palette.secondary.light} !important`,
      borderWidth: 1,
      borderRadius: '1rem',
    },
  },
  checkBox: {
    cursor: 'pointer',
    height: '1.5rem',
    width: '1.5rem',
    margin: '0px 9px',
  },
  listItemStyles: {
    whiteSpace: 'break-spaces',
  },
};

export const ErrorMsg = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  paddingLeft: '0.625rem',
}));

export const ErrorMsgWrapper = styled(ErrorMsg)(() => ({
  position: 'absolute',
  bottom: '-23px',
  left: '0px',
}));

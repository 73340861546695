/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
export const BottomBar = styled('div')(() => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  zIndex: '3',
  height: '6.5rem',
  paddingRight: '9.75rem',
  [theme.breakpoints.down('laptop')]: {
    height: '5.5rem',
    paddingRight: '1.5rem',
  },
}));

export const EnterDetailsText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.h2.fontSize,
}));

export const FieldWrapper = styled('div')(() => ({
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2.5rem',
}));

export const textFieldStyles = {
  phoneNumberField: {
    minWidth: '5rem',
    width: '18rem',
    marginRight: '1.5rem',
    '& .MuiInputBase-root': {
      paddingRight: '0.75rem !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.light,
        borderTopRightRadius: '1rem',
        borderBottomRightRadius: '1rem',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.h4?.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '1.5rem',
      letterSpacing: '0.00375rem',
      '&.Mui-focused': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h5?.fontSize,
      },
    },
    '@media (max-width: 64rem)': {
      width: '15rem',
      marginRight: '1.25rem',
      '& .MuiInputLabel-root': {
        fontSize: '.875rem',
        lineHeight: '1.375rem',
        letterSpacing: '0.015625rem',
        '&.Mui-focused': {
          fontSize: theme.typography.h6?.fontSize,
        },
      },
    },
  },
  codeField: {
    width: '4.5rem',
    border: `0.0625rem solid ${theme.palette.secondary.light}`,
    height: '3.5rem',
    borderTopLeftRadius: '1rem',
    borderBottomLeftRadius: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  Toolbar,
  useMediaQuery,
  CircularProgress,
  IconButton,
  Divider,
  useTheme,
  Box,
} from '@mui/material';
import { Storage } from 'aws-amplify';

import LeftNavBarItem from './LeftNavBarItem';
import LeftNavBarItemCollapse from './LeftNavBarItemCollapse';
import appRoutes from '../../../../routes/appRoutes';
import sizeConfigs, { tabletSizeConfigs } from '../../../Configs/sizeConfigs';
import { getStoredCustomerDetails } from '../../../Utilities/utils';
import { UserType } from '../../../Constants/App';
import LanguageSwitcher from './LanguageSwitcher';
import AddOptions from './AddOptions';
import CompanyProfile from './CompanyProfile';
import Logout from './Logout';

import { Icons } from '../../../Constants/Icons';

import { LeftNavBarField, LogoStyle, styles } from './LeftNavBar.styles';
import FacilityApiService from '../../../../Modules/Facility/Services/FacilityApiService';
import { isEmpty } from 'lodash';

interface ILeftNavBar {
  isSideBarVisible: boolean;
  onSideBarVisibilityChange: (value: boolean) => void;
  i18n: { changeLanguage: (language: string) => void };
}

const LeftNavBar = (props: ILeftNavBar) => {
  const [customerLogo, setCustomerLogo] = useState<any>('');
  const [isShowAcronym, setIsShowAcronym] = useState<boolean>(false);
  const [isUserSwitchActive, setIsUserSwitchActive] = useState(false);
  const [isFacilitySwitchActive, setIsFacilitySwitchActive] = useState(false);
  const [isLogoutActive, setIsLogoutActive] = useState(false);
  // const [isTabletSize, setIsTabletSize] = useState(false);
  const [vendorRating, setVendorRating] = useState<number | null>(null);

  const { isSideBarVisible, onSideBarVisibilityChange } = props;
  const theme = useTheme();
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('laptop'));
  const sizeConfig = isTabletScreen ? tabletSizeConfigs : sizeConfigs;
  const sidebarWidth = isSideBarVisible ? sizeConfig.sidebar.openWidth : sizeConfig.sidebar.closedWidth;

  const { customerId = '' } = getStoredCustomerDetails() || {};
  const isTablet = useMediaQuery('(max-width: 64rem)');
  const navigate = useNavigate();

  const { t } = useTranslation(['altTexts']);

  const checkScreenSize = useCallback(() => {
    // setIsTabletSize(window.innerWidth <= 1024);
  }, []);

  //to encode S3 url into base64 so that it will not expose publicaly
  const toDataURL = async (url: string) => {
    try {
      return fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );
    } catch (e) {
      console.log(e);
    }
  };

  const renderCustomerLogo = (logo: string | undefined) => {
    if (logo) {
      return <img src={logo} onError={() => setIsShowAcronym(true)} onLoad={() => setIsShowAcronym(false)} />;
    } else {
      return <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />;
    }
  };

  const authValue = localStorage.getItem('auth');

  let userType: UserType = UserType.NoUser;
  if (authValue !== null) {
    const authData = JSON.parse(authValue);
    userType = authData?.attributes?.['custom:Role'] as UserType;
  }

  const optionsStyle = {
    options: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
      },
      padding: isTablet ? '0.5rem 0.75rem' : '1rem',
      borderRadius: isTablet ? '0.5rem' : '1rem',
      marginBottom: '0.5rem',
      alignItems: isSideBarVisible ? 'flexStart' : 'center',
      justifyContent: isSideBarVisible ? 'flexStart' : 'center',
      width: '100%',
    },
    toolbar: {
      '& .MuiDrawer-paper': {
        width: sidebarWidth,
        transition: 'width 0.5s',
        overflow: 'hidden',
      },
    },
  };

  const fetchVendorRating = async (vendorId: string) => {
    const vendorResponse = await FacilityApiService.vendorDetails(vendorId);
    if (vendorResponse?.data && isEmpty(vendorResponse.errors)) {
      setVendorRating(vendorResponse.data.vendorRating as number);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, [checkScreenSize]);

  useEffect(() => {
    try {
      const fetchCustomerLogo = async (customerId: string) => {
        const result = await Storage.get(`customer/${customerId}/company-logos/${customerId}`, { level: 'public' });
        const imgStr = await toDataURL(result);
        setCustomerLogo(imgStr);
      };
      fetchCustomerLogo(customerId as string);
      fetchVendorRating(customerId as string);
    } catch (e) {
      console.log('error', e);
    }
  }, [customerId]);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={true}
      sx={{
        ...optionsStyle.toolbar,
      }}>
      <LeftNavBarField>
        <List
          sx={{
            alignItems: isSideBarVisible ? 'flex-start' : 'center',
            ...styles.leftNavBarIcons,
          }}>
          <Box sx={{ width: '100%' }}>
            <LogoStyle onClick={() => navigate('/home')}>
              <img
                src={Icons.PeazyLogo}
                alt={t('altTexts:peazyLogo')}
                style={{ margin: 'auto', height: '3.5rem', width: '3.5rem' }}
              />
            </LogoStyle>

            <Toolbar sx={{ ...(isTabletScreen ? styles.toolbar : {}) }}>
              <Box>
                <IconButton
                  onClick={() => onSideBarVisibilityChange(!isSideBarVisible)}
                  sx={{ ...styles.logo, padding: 0 }}>
                  {isSideBarVisible ? (
                    <img src={Icons.SideBarCloseIcon} alt={t('altTexts:close')} style={{ ...styles.iconStyle }} />
                  ) : (
                    <img src={Icons.SideBarOpenIcon} alt={t('altTexts:open')} style={{ ...styles.iconStyle }} />
                  )}
                </IconButton>
              </Box>
            </Toolbar>

            <Divider
              sx={{
                ...styles.divider,
              }}
            />

            {appRoutes.map((route, index) => {
              if (route.users.includes(userType)) {
                if (route.userIds && !route.userIds.includes(customerId as string)) {
                  return null;
                }
                return route?.sidebarProps ? (
                  route?.child ? (
                    <LeftNavBarItemCollapse item={route} key={index} isSideBarVisible={isSideBarVisible} />
                  ) : (
                    <LeftNavBarItem item={route} key={index} isSideBarVisible={isSideBarVisible} />
                  )
                ) : undefined;
              }
              return null;
            })}

            <LanguageSwitcher isSideBarVisible={isSideBarVisible} theme={theme} isTablet={isTablet} />

            <Logout isSideBarVisible={isSideBarVisible} theme={theme} isTablet={isTablet} />

            <AddOptions
              isSideBarVisible={isSideBarVisible}
              isUserSwitchActive={isUserSwitchActive}
              setIsUserSwitchActive={setIsUserSwitchActive}
              isLogoutActive={isLogoutActive}
              setIsLogoutActive={setIsLogoutActive}
              isFacilitySwitchActive={isFacilitySwitchActive}
              setIsFacilitySwitchActive={setIsFacilitySwitchActive}
              role={userType}
              theme={theme}
              optionsStyle={optionsStyle}
              styles={styles}
              isTablet={isTablet}
            />
          </Box>

          <CompanyProfile
            isSideBarVisible={isSideBarVisible}
            isShowAcronym={isShowAcronym}
            logo={customerLogo}
            renderLogo={renderCustomerLogo}
            vendorRating={vendorRating}
          />
        </List>
      </LeftNavBarField>
    </Drawer>
  );
};

export default withTranslation(['logout'])(LeftNavBar);

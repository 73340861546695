/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { MRT_ColumnDef, MRT_RowData } from 'material-react-table';
import { JobInterestsActionType, WorkOrderStatus, JobInterestsActionTypeForQuery } from '../../../API';

export enum JobType {
  Accepted = 'Accepted',
  All = 'All',
  BidsInProgress = 'BidsInProgress',
  Completed = 'Completed',
  Created = 'Created',
  Drafts = 'drafts',
  InProgress = 'InProgress',
  OTJDrafts = 'OTJDrafts',
  OTJOneTimeJobs = 'OTJOneTimeJobs',
  OnHold = 'OnHold',
  OneTimeJobs = 'OneTime',
  RecommendedJob = 'RecommendedJob',
  SubmittedBids = 'submittedBids',
}

export enum MarketplaceTabs {
  RecommendedJobs = 'RecommendedJobs',
  BidsInProgress = 'BidsInProgress',
  AllJobs = 'AllJobs',
  NotInterestedJobs = 'NotInterestedJobs',
  SubmittedBids = 'SubmittedBids',
}

export interface ExtendedMRT_ColumnDef<T extends MRT_RowData> extends MRT_ColumnDef<T> {
  enableTabs?: string[]; // Define enableTabs as an optional array of strings
}

export interface ModifiedJobData {
  id?: string;
  bidDueDate?: string | null;
  createdOn: string;
  logo: string;
  address: string;
  facilityName: string;
  type: string;
  jobId: string;
}

export interface JobItem {
  jobId: string;
  jobName: string;
  specialInstructions?: string | null;
  startDate?: string;
  stopDate?: string;
  jobSize?: string;
  dataStatus?: string;
  jobType?: string;
  createdOn?: string;
  percentageMatch?: number;
  actionTime?: string;
  actionType?: JobInterestsActionType;
}

export interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface IJobRecommendationsType {
  data: any;
  metadata?: any;
  errors?: any;
}

export interface IVendorJobAwardData {
  customerCompanyName: string;
  customerFacilityAddress: string;
  customerFacilityName: string;
  customerIcon: string;
  customerId: string;
  facilityId: string;
  hasChild: boolean;
  jobCustodian?: string;
  jobEndDate: string;
  jobName: string;
  jobStartDate: string;
  jobType: string;
  logo: string;
  totalAmount: number;
  vendorCompanyName: string;
  vendorIcon: string;
  vendorId: string;
  workOrderId: string;
  workOrderStatus: WorkOrderStatus;
  workVolume: string;
  jobId: string;
}

export interface IFacilityType {
  icon: React.ReactNode;
  id: string;
  name: string;
  keyCode: string;
}

export interface IFacilityTypeItem {
  keyCode: string;
  value: string;
}

export interface GetAllBids {
  bidId?: string | null;
  vendorId?: string | null;
  bidSubmittedBy?: string | null;
  bidDataStatus?: string | null;
  bidDraftStatus?: string | null;
  bidDraftStep?: string | null;
  bidCreatedOn?: string | null;
  bidSubmissionDate?: string | null;
  bidDueDate?: string | null;
  bidVersion?: number | null;
  bidTemplate?: string | null;
  vendorBidComments?: string | null;
  vendorName?: string | null;
  vendorEmail?: string | null;
  jobId?: string | null;
  jobName?: string | null;
  startDate?: string | null;
  stopDate?: string | null;
  jobSize?: string | null;
  jobDataStatus?: string | null;
  jobType?: string | null;
  finalQuote?: number | null;
  markupPCT?: number | null;
  isFlatMargin?: string | null;
  mdFrequencyType?: string | null;
  submittedBy?: string | null;
  jobInterestActionType?: JobInterestsActionTypeForQuery | null;
  jobCustodian?: string | null;
  customerId?: string | null;
  percentageMatch?: number | null;
  totalSqftArea?: string | null;
  totalUnitsArea?: string | null;
  specialInstructions?: string | null;
  mdVendorType?: string | null;
  facilityBuildingName?: string | null;
  facilityIconUrl?: string | null;
  durationOfTheJob?: string | null;
  daysLeft?: number | null;
  bidType?: string | null;
  jobServices?: Array<{
    __typename: 'JobServices';
    mdServiceId?: string | null;
    serviceMeasure?: string | null;
    mdServiceUnits?: string | null;
    mdServiceName?: string | null;
  } | null> | null;
  jobAttachments?: Array<{
    __typename: 'JobAttachmentsForJobsAndBids';
    tags?: string | null;
    comments?: string | null;
    url?: string | null;
    fileExtension?: string | null;
    fileName?: string | null;
    mdFileContext?: string | null;
    mdAttachmentType?: string | null;
    attachmentId?: string | null;
  } | null> | null;
  jobFacilities?: Array<{
    __typename: 'JobFacilitiesForJobsAndBids';
    facilityId?: string | null;
    mdFacilityType?: string | null;
    facilityAddress?: string | null;
  } | null> | null;
}

export interface GetAllJobs {
  bidId?: string | null;
  jobId?: string | null;
  vendorId?: string | null;
  jobName?: string | null;
  jobDataStatus?: string | null;
  specialInstructions?: string | null;
  jobInterestActionType?: JobInterestsActionTypeForQuery | null;
  jobCustodian?: string | null;
  jobType?: string | null;
  jobStartDate?: string | null;
  jobStopDate?: string | null;
  jobCreatedOn?: string | null;
  mdDueType?: string | null;
  dueDate?: string | null;
  customerId?: string | null;
  customerName?: string | null;
  customerEmail?: string | null;
  totalSqftArea?: string | null;
  totalUnitsArea?: string | null;
  jobSize?: string | null;
  isPaid?: string | null;
  percentageMatch?: number | null;
  vendorCompanyName?: string | null;
  mdVendorType?: string | null;
  facilityBuildingName?: string | null;
  durationOfTheJob?: string | null;
  daysLeft?: number | null;
  facilityIconUrl?: string | null;
  logoUrl?: string | null;
  bidCount?: number | null;
  jobServices?: Array<{
    __typename: 'JobServices';
    mdServiceId?: string | null;
    serviceMeasure?: string | null;
    mdServiceUnits?: string | null;
    mdServiceName?: string | null;
  } | null> | null;
  jobAttachments?: Array<{
    __typename: 'JobAttachmentsForJobsAndBids';
    tags?: string | null;
    comments?: string | null;
    url?: string | null;
    fileExtension?: string | null;
    fileName?: string | null;
    mdFileContext?: string | null;
    mdAttachmentType?: string | null;
    attachmentId?: string | null;
  } | null>;
  jobInterests?: Array<{
    __typename: 'JobInterestForJobs';
    jobInterestActionType?: JobInterestsActionTypeForQuery | null;
    jobInterestVendorId?: string | null;
  } | null> | null;
  jobFacilities?: Array<{
    __typename: 'JobFacilitiesForJobsAndBids';
    facilityId?: string | null;
    mdFacilityType?: string | null;
    facilityAddress?: string | null;
  } | null> | null;
  jobBids?: Array<{
    __typename: 'JobBidsForJobs';
    bidId?: string | null;
    vendorId?: string | null;
  } | null> | null;
}

export type SortingItem = {
  id: string;
  value: string;
};

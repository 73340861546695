/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { SelectChangeEvent } from '@mui/material';
import { DateRangeOption } from '../DateRangeSelection/DateRangeSelection.Model';
import { FormFieldType } from '../../../Constants/App';

export interface IData {
  name: string;
  amount: number;
  date: string[];
  shiftDate?: string;
  scheduled?: number;
  missed?: number;
  completed?: number;
  barConfig?: IBarConfig[];
}

export interface IGraphData {
  title: string;
  graphView?: string;
  data: IData[];
  graphColor: string;
  xAxisLabel: string;
  yAxisLabel: string;
}

export interface IDataV2 {
  name: string;
  amount: number;
}

export enum GraphType {
  BarGraph = 'BarGraph',
  LineGraph = 'LineGraph',
  MultiBarGraph = 'MultiBarGraph',
}
export interface ITaskParameters {
  Status?: string;
  associateId?: string;
  facilityId?: string;
  fromDate?: string;
  serviceId?: string;
  status?: string;
  toDate?: string;
  workDayId?: string;
  workOrderId?: string;
}
export interface IBarConfig {
  dataKey: string;
  fill: string | undefined;
  name: string;
  legend?: boolean;
  stroke?: string;
}

export interface IDropdownOption {
  id: string;
  name: string;
}

interface FilterOption {
  label: string;
  value: string;
}
interface Filter {
  type: FormFieldType;
  name: string;
  options: FilterOption[];
  title: string;
}
export interface ISimpleBarGraphProps {
  graphData: IGraphData;
  type: string;
  loading: boolean;
  id: string;
  onDateRangeSelect: (range: { fromDate: Date | null; toDate: Date | null }, id: string) => void;
  handleAssociateChanges: (event: SelectChangeEvent<string>) => void;
  handleLocationChanges: (event: SelectChangeEvent<string>) => void;
  selectedAssociate: string;
  facility: IDropdownOption[];
  defaultDateRangeOption: string;
  defaultOption: string;
  selectedFacility: string;
  isAssociateLoading: boolean;
  isFacilityLoading: boolean;
  allAssociates: IDropdownOption[];
  labels: [{ dataKey: string; fill: string; name: string }];
  dateRangeOptions: DateRangeOption[];
  filters: Filter[];
  handleCustomerVendorChanges: (event: SelectChangeEvent<string>) => void;
  selectedCustomerVendor: string;
  isCustomerVendorLoading: boolean;
}

export interface IMultiBarGraphProps {
  graphData: IData[];
  yAxisLabel: string;
  labels: IBarConfig[];
  xAxisLabel: string;
}

export interface ICustomDropDownProps {
  options: {
    label: string;
    value: string;
  }[];
  selectedValue: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  isLoading: boolean;
  placeholder?: string;
  title?: string;
  defaultOption?: string;
}
